import styled, { keyframes } from 'styled-components';

const AccountLoaderAnimation = keyframes`
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

export const AccountLoaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const AccountLoaderDotStyle = styled.div`
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 2px;
  animation-name: ${AccountLoaderAnimation};
  animation-duration: 1.65s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  &:nth-child(2) {
    margin: 0 3px;
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;
