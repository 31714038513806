import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { stripIgnoredCharacters, ASTNode, print as printGraphql } from 'graphql';
import { noticeError } from 'lib/newrelic';
import { localStateTypeDefs, localStateResolvers, localStateDefaults } from './localState';
import { isBrowser, isServer } from '../isBrowser';
import { IS_CLIENT, ACCOUNT } from '../../client/constants';
import LocalStateQuery from '../../client/queries/localState/localStateQuery';

let apolloClient: ApolloClient<any> | null = null;

const apolloLinkTimeout = 10000;

function create(
  initialState: any,
  config: {
    graphql: string;
    kijkWebApi: string;
  },
  path?: string
): ApolloClient<any> {
  const cache = new InMemoryCache({
    typePolicies: {
      Time: {
        keyFields: []
      },
      SearchModal: {
        keyFields: []
      },
      ActiveVideo: {
        keyFields: []
      },
      ContinueWatching: {
        keyFields: []
      },
      VideoHeadingState: {
        keyFields: []
      },
      HeaderState: {
        keyFields: []
      }
    }
  }).restore(initialState || {});

  const errorLink = onError(({ networkError, operation, graphQLErrors }) => {
    const query = printGraphql(operation.query);
    const errorMessage = `Failed GraphQL request - "${operation.operationName}" ${
      query.startsWith('mutation') ? 'mutation' : 'query'
    }`;

    let graphqlError = 'Unknown error';

    if (networkError) {
      graphqlError = networkError.message;
    }

    if (graphQLErrors?.length) {
      graphqlError = graphQLErrors[0].message;
    }

    if (isServer) {
      // eslint-disable-next-line no-console
      console.error(`${errorMessage} on path "${path}": ${graphqlError}`);
      return;
    }

    noticeError(errorMessage, {
      graphql_error: graphqlError,
      graphql_query: query,
      graphql_variables: JSON.stringify(operation.variables)
    });
  });

  // switch between internal or external graphql api
  const apiGraphqlUrl = config.graphql;
  const playerApiGraphqlUrl = config.kijkWebApi;

  const timeoutLink = new ApolloLinkTimeout(apolloLinkTimeout);

  // Overriding the default print function to remove unnecessary whitespace from query
  const print = (ast: ASTNode, originalPrint: (_ast: ASTNode) => string) => stripIgnoredCharacters(originalPrint(ast));

  const kijkWebApiHttpLink = timeoutLink.concat(
    new HttpLink({
      print,
      uri: playerApiGraphqlUrl,
      useGETForQueries: true
    })
  );

  const videoApiPostHttpLink = timeoutLink.concat(
    new HttpLink({
      print,
      uri: apiGraphqlUrl,
      useGETForQueries: false
    })
  );
  const videoApiHttpLink = timeoutLink.concat(
    new HttpLink({
      print,
      uri: apiGraphqlUrl,
      useGETForQueries: true
    })
  );

  const authLink = setContext((operation, prevContext) => {
    // get the authentication token from local storage if it exists
    let token: string | null = null;
    const headers = prevContext.headers || {};

    // remove Authorization header from the query when this header is not affecting the response of the query
    switch (operation.operationName) {
      case 'profile':
      case 'drmToken':
      case 'deleteVideoProgress':
      case 'videoProgress':
        if (IS_CLIENT) {
          token = localStorage.getItem(ACCOUNT.AUTH_TOKEN);
          headers['Cache-Control'] = 'no-cache';
        }
        break;
      case 'overview':
      case 'collection':
      case 'series':
        // Make sure, KijkWeb API is using the same Video API
        headers['graphql-endpoint'] = apiGraphqlUrl;

        break;
      default:
        break;
    }
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    // return the headers to the context so httpLink can read them
    return {
      headers
    };
  });

  const addStatusLink = new ApolloLink((operation, forward) =>
    forward(operation).map(response => {
      // check for null slug values
      try {
        const { brands, programs, trendingPrograms, programsByDate } = response.data || {};
        const items = (programsByDate?.[0] || programs)?.items || trendingPrograms || brands;
        const program = items?.find((item: IProgramGraphql) => item.slug === null);

        if (program) {
          noticeError(`program ${program.guid} has no slug in operation ${operation.operationName}`);
        }
      } catch (ignore) {}

      return response;
    })
  );

  const graphqlHttpLink = ApolloLink.split(
    operation => ['overview', 'collection', 'series', 'navigations'].includes(operation.operationName),
    kijkWebApiHttpLink,
    ApolloLink.split(
      operation => ['profile', 'videoProgress', 'sourcesUncached'].includes(operation.operationName),
      videoApiPostHttpLink,
      videoApiHttpLink
    )
  );

  const client = new ApolloClient({
    cache,
    connectToDevTools: isBrowser,
    ssrMode: isServer,
    link: ApolloLink.from([addStatusLink, authLink, errorLink, graphqlHttpLink]),
    typeDefs: localStateTypeDefs,
    resolvers: localStateResolvers
  });

  client.writeQuery({
    query: LocalStateQuery,
    data: localStateDefaults
  });

  // When reseting the store, write the defaults to the state tree.
  client.onResetStore(() => Promise.resolve(localStateDefaults));

  return client;
}

export default function initApollo(
  initialState: any,
  config: {
    graphql: string;
    kijkWebApi: string;
  },
  path?: string
): ApolloClient<any> {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (isServer) {
    return create(initialState, config, path);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, config);
  }

  return apolloClient;
}
