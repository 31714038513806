import { PLAYER_ERROR_TYPE } from '../client/constants';
import getErrorGroup from './helpers/getErrorGroup';

export const setCustomAttributes = (data: { [key: string]: string | boolean | number | null }): void => {
  if (typeof window.newrelic !== 'object') return;

  Object.keys(data).forEach(key => {
    (window.newrelic as any).setCustomAttribute(key, data[key]);
  });
};

export function noticeError(msg: string, customAttributes: { [key: string]: string | number | boolean } = {}): void {
  if (typeof window.newrelic !== 'object') return;

  window.newrelic.noticeError(new Error(msg), customAttributes);
}

export function customInteraction(msg: string): void {
  if (typeof window.newrelic !== 'object') return;

  (window.newrelic as any).interaction().actionText(msg).save();
}

export const noticePlayerError = (info: IPlayerErrorEvent, metadata: any): boolean | void => {
  if (typeof window.newrelic !== 'object') {
    return false;
  }

  const customErrorAttributes: { [key: string]: string } = {
    error_code: info.code.toString(),
    error_type: info.type
  };

  if (metadata) {
    customErrorAttributes.media_id = metadata.media_id;
    customErrorAttributes.media_videotype = metadata.media_videotype;
  }

  if (info?.sourceError?.details) {
    customErrorAttributes.error_details = info.sourceError.details;
  }

  if (info?.sourceError?.response) {
    customErrorAttributes.error_response_code = info.sourceError.response.code;
  }
  if (info?.type === PLAYER_ERROR_TYPE.ERROR || info?.type === PLAYER_ERROR_TYPE.WARNING) {
    customErrorAttributes.error_group = getErrorGroup(info?.code, info.type);
  }

  return window.newrelic.noticeError(info, customErrorAttributes);
};
