import styled from 'styled-components';
import { Subtext } from 'components/Text/Text.style';
import Colors from '../../styles/colors';

export const RemoveItemDialogBoxStyle = styled.div<{ smLandscape?: boolean; isWatchlistPage?: boolean }>`
  position: relative;
  padding-top: ${props => (props.isWatchlistPage ? '150%' : '56.25%')};
  height: 0;
  background: ${Colors.nero};
  border-radius: 4px;
  margin-bottom: ${props => (props.isWatchlistPage ? '8px' : '0')};
  text-align: center;

  ${Subtext} {
    margin-bottom: 24px;
    text-align: center;
  }

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    height: 75px;
    background: ${Colors.nero};
    ${props =>
      props.smLandscape &&
      `
      height: 75px;
    `}
    ${props =>
      !props.smLandscape &&
      `
      height: auto;
    `}
  }
`;

export const RemoveItemDialogBoxInnerStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const RemoveItemDialogButtonsContainer = styled.div`
  div:first-of-type {
    margin-right: 5%;
    margin-bottom: 5%;
  }
`;

export const RemoveItemDialogBoxButtonsStyle = styled.div`
  display: inline-block;
  width: 58px;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 16px;
  background-color: ${Colors.brownGrey};
  text-align: center;
  cursor: pointer;
`;
export const RemoveItemIconCloseStyle = styled.div`
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 3;
`;
