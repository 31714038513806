import { isEmpty } from 'lib/helpers/helpers';
import SeriesModel from './series.model';

const REQUIRED_BRANDS_FIELDS = ['slug', 'id'];

export default class BrandModel {
  id: string;
  guid: string;
  slug: string;
  title: string;
  isValid: boolean;
  detailsLogoUrl: string | undefined;
  headerImageUrl: string | undefined;
  logoUrl: string | undefined;
  programs: SeriesModel[] = [];

  constructor(data: IBrandGraphql) {
    this.isValid = REQUIRED_BRANDS_FIELDS.every(field => !isEmpty(data, field));
    this.guid = data.id;
    this.title = data.title;
    this.detailsLogoUrl = data.detailsLogoMedia?.[0]?.url;
    this.headerImageUrl = data.headerMedia[0]?.url;
    this.logoUrl = data.logoMedia[0]?.url;
    this.id = data.id;
    this.slug = data.slug;

    if (data.programsA) {
      this.programs = [...data.programsA, ...(data.programsB || [])]
        .map((program, index) => new SeriesModel(program, { collectionTitle: "Programma's", position: index + 1 }))
        .filter(program => program.isValid);
    }
  }
}
