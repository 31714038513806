import { FunctionComponent } from 'react';
import { ButtonStyle } from './Button.style';

const Button: FunctionComponent<IButtonProps> = ({ children, onClick, isDisabled, isActionButton }) => (
  <ButtonStyle
    data-testid="buttonItem"
    isDisabled={isDisabled}
    isActionButton={isActionButton}
    onClick={isDisabled ? () => null : onClick}
  >
    {children}
  </ButtonStyle>
);

export default Button;
