import slugify from 'slugify';
import { getUploadDate, getShortDateTime, getVideoLabelText, secondsToISODuration } from 'lib/helpers/datetime';
import { isEmpty } from 'lib/helpers/helpers';
import { getProgramFullTitle, getMediaNameFromProgram } from 'lib/helpers/programs';
import { CLOUDINARY, IMAGE_MEDIA_TYPE, PROGRAM } from '../constants';
import ProgramModel from './program.model';

const REQUIRED_PROGRAM_FIELDS = ['guid', 'id', 'type', 'media'];
const REQUIRED_VIDEO_FIELDS = ['series'];
const REQUIRED_SERIES_FIELDS = ['guid', 'id'];

export default class VideoModel extends ProgramModel {
  availableRegion: string;
  isAvailable: boolean;
  series: {
    id: string;
    guid: string;
    slug: string;
    title?: string;
  };
  sources: ISourceGraphql[] = [];
  tracks: ITrackGraphql[];
  duration: number | null;
  path: string;
  longDescription: string;
  seasonId: string;
  tvSeasonId: string;
  isLive: boolean;
  isVideoValid: boolean;
  title: string;
  seriesTitle: string;
  eventLabel: string;
  tvSeasonEpisodeNumber: string = '';
  ctaLabel = '';
  labelText: string;
  isValid: boolean;
  fwassetid: string;
  uploadDate: string;
  programFullTitle: string;
  episodeNumberDetails = '';
  thumbnailTitle = '';
  thumbnailSubtitle = '';
  structuredData: { [key: string]: any };
  sourceProgram?: string;
  added?: number | null;
  publicationDateTime?: string | null;
  seriesId?: string | null;
  seasonNumber?: number;
  updated?: number;
  availableDate?: number;
  subtitle?: string;
  dateLabel?: string | number;
  showFormatTitle?: boolean;
  videoDateTime?: string;
  freewheel?: { custom: { [key: string]: string } } | null;

  constructor(data: IProgramGraphql, options: IPlaylistQueryParams = {}) {
    super(data, options);
    const hasRequiredFields =
      data.type === PROGRAM.MOVIE
        ? true
        : REQUIRED_VIDEO_FIELDS.every(field => !isEmpty(data, field)) &&
          REQUIRED_SERIES_FIELDS.every(field => !isEmpty(data.series, field));
    this.isAvailable = !!data.media?.[0]?.availabilityState;
    const isMediaValid = this.isAvailable ? !isEmpty(data, 'sources') : true;
    this.isValid = REQUIRED_PROGRAM_FIELDS.every(field => !isEmpty(data, field)) && hasRequiredFields && isMediaValid;
    this.isVideoValid = this.isValid;

    this.showFormatTitle = options.showFormatTitle ?? true;

    this.series = data.series as VideoModel['series'];
    // ! Temp workaround since useQuery occasionally changes the slug to null
    if (this.series && !this.series.slug && this.series.title) {
      this.series = {
        ...this.series,
        slug: slugify(this.series.title, {
          remove: /[^\w\s$_+-]/g,
          lower: true
        })
      };
    }

    if (data.sources) {
      this.sources = data.sources;
    }
    this.displayGenre = data.displayGenre || '';
    this.added = data.added || null;
    this.seriesId = data.seriesId || null;
    this.duration = data.duration || null;
    this.availableRegion = data.availableRegion;
    this.tracks = data.tracks || [];
    this.path = `/programmas/${this.series?.slug}/${this.guid}`;
    const { editorialTabSlug, playlistSlug } = options;
    if (editorialTabSlug) {
      this.path += `?tab=${editorialTabSlug}`;
    } else if (playlistSlug) {
      this.path += `?afspeellijst=${playlistSlug}`;
    }

    this.seriesTitle = data.series?.title || '';
    this.title = data.title || '';
    this.longDescription = data.longDescription || '';
    this.tvSeasonId = data.tvSeasonId || '';
    this.sourceProgram = data.sourceProgram;
    this.updated = data.updated;
    this.uploadDate = getUploadDate(data);
    this.programFullTitle = getProgramFullTitle(data);

    const [media] = data.media || [];
    this.labelText = getVideoLabelText(media, data.type);

    if (data.seasonNumber && data.tvSeasonEpisodeNumber) {
      this.episodeNumberDetails = `Seizoen ${data.seasonNumber}, aflevering ${data.tvSeasonEpisodeNumber}`;
    } else if (data.seasonNumber) {
      this.episodeNumberDetails = `Seizoen ${data.seasonNumber}`;
    }

    if (data.seasonNumber) {
      this.seasonNumber = data.seasonNumber;
    }
    if (data.tvSeasonEpisodeNumber) {
      this.tvSeasonEpisodeNumber = `${data.tvSeasonEpisodeNumber}`;
    }

    this.eventLabel =
      data.type === PROGRAM.CLIP
        ? this.title || this.episodeNumberDetails
        : `${this.seriesTitle || this.title}, ${this.seasonNumber}, ${this.tvSeasonEpisodeNumber}`;

    this.dateLabel =
      media?.airedDateTime || (media?.availableDate && Number.parseInt(media.availableDate, 10)) || data.added;
    this.isLive = media?.type === PROGRAM.LIVE;

    this.videoDateTime = getShortDateTime(media?.airedDateTime, media?.availableDate, this.added);
    if (media?.availableDate) {
      this.availableDate = +media.availableDate;
    }

    // Used in JW Player
    this.metadata = {
      ...data.metadata,
      // Temp fix until GraphQL is providing correct meta data
      media_name: getMediaNameFromProgram(data),
      media_program_name: this.seriesTitle || this.series?.title || data.metadata?.media_program_name
    };
    // Fix for live streams
    if (media?.type === PROGRAM.LIVE) {
      this.metadata.c_media_playbacktype = 'live';
      this.metadata.media_duration = 0;
      this.metadata.c_media_live = true;
      this.metadata.media_videotype = 'live';
    }
    this.fwassetid = (this.metadata?.media_id as string) || this.guid;

    this.structuredData = {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      name: this.programFullTitle,
      publisher: {
        '@type': 'Organization',
        name: 'KIJK',
        logo: {
          '@type': 'ImageObject',
          url: 'https://kijk.nl/static/logos/kijk_color.png',
          width: 1000,
          height: 632
        }
      },
      description: this.shortDescription,
      thumbnailUrl: [
        this.imageLandscape?.type === IMAGE_MEDIA_TYPE.CLOUDINARY
          ? `${CLOUDINARY.BASE_PATH}upload/${this.imageLandscape?.src}`
          : this.imageLandscape?.src
      ],
      uploadDate: this.uploadDate,
      duration: this.duration ? secondsToISODuration(this.duration) : '',
      embedUrl: `https://embed.kijk.nl/?video=${this.guid}`
    };

    const { file: contentUrl } = this.sources.find(source => source.type === 'm3u8') || {};

    if (contentUrl) {
      this.structuredData.contentUrl = contentUrl;
    }
  }
}
