import styled from 'styled-components';

const DefaultProps = {
  largeHeight: '42px',
  mediumHeight: '48px',
  midMediumHeight: '48px',
  smallHeight: '56px'
} as ILogoProps;

export const LogoStyle = styled.div<ILogoProps>`
  display: flex;
  height: 100%;
  cursor: pointer;
  align-items: center;

  img {
    height: ${props => props.smallHeight || DefaultProps.smallHeight};
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    img {
      height: ${props => props.mediumHeight || DefaultProps.mediumHeight};
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    img {
      height: ${props => props.midMediumHeight || DefaultProps.midMediumHeight};
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    img {
      height: ${props => props.largeHeight || DefaultProps.largeHeight};
    }
  }
`;
