import styled, { keyframes } from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';
import { ICONS } from '../../constants/icons';
import Colors from '../../styles/colors';

const NextUpAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const LoaderAnimationSm = keyframes`
  0% {
    transform: translateX(0) scale(0);
    opacity: 0;
  }

  40% {
    transform: translateX(13px) scale(1);
    opacity: 1;
  }

  60% {
    transform: translateX(13px) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(26px) scale(0);
    opacity: 0;
  }
`;

const LoaderAnimationMd = keyframes`
  0% {
    transform: translateX(0) scale(0);
    opacity: 0;
  }

  40% {
    transform: translateX(31px) scale(1);
    opacity: 1;
  }

  60% {
    transform: translateX(31px) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(63px) scale(0);
    opacity: 0;
  }
`;

export const PlayerDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const WideScreenDivStyle = styled.div`
  aspect-ratio: 16 / 9;
  height: calc(75vh - 146px);
  margin: auto;

  @media (max-width: ${props => props.theme.breakpoints.lgGrid}) {
    @supports (aspect-ratio: 16 / 9) {
      height: auto;
      width: 100%;
    }
  }

  @supports not (aspect-ratio: 16 / 9) {
    width: 100vw;
    height: 56.25vw;
    max-height: calc(75vh - 146px);
  }
`;

export const PlayerContainerStyle = styled(WideScreenDivStyle)`
  position: relative;

  & #player {
    position: relative;
    height: 100% !important;
    margin: 0 auto;
  }

  .jw-text,
  .jw-text-live,
  .jw-reset-text {
    font-family: Cera, sans-serif;
  }

  .jw-rightclick {
    display: none !important;
  }

  .jwplayer.jw-flag-user-inactive.jw-state-playing:not(.jw-flag-media-audio):not(.jw-flag-audio-player):not(
      .jw-flag-ads-vpaid-controls
    ):not(.jw-flag-casting)
    .controls,
  .jwplayer.jw-state-idle .controls,
  .jwplayer.jw-state-buffering .controls {
    display: none;
  }

  .jw-controls-backdrop {
    background-color: ${Colors.black40};

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      height: 64px;
      width: 100%;
      background-image: ${props =>
        linearGradient(props.theme.gradients.player.controls.direction, props.theme.gradients.player.controls.stops)};

      @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
        height: 96px;
      }
    }
  }

  .jwplayer.jw-flag-user-inactive.jw-state-playing:not(.jw-flag-media-audio):not(.jw-flag-audio-player):not(
      .jw-flag-ads-vpaid-controls
    ):not(.jw-flag-casting)
    .jw-controls::before {
    display: none;
  }

  .jw-nextup-close,
  .jw-nextup-thumbnail,
  .jw-nextup-duration {
    display: none;
  }

  .jw-nextup-container {
    display: none;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      padding: 12px 20px;
    }
  }

  .jw-nextup-container.jw-nextup-container-visible {
    display: none;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      display: block;
    }
  }

  .jw-flag-fullscreen .jw-nextup-container.jw-nextup-container-visible {
    display: block;
    top: 8px;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      top: auto;
    }
  }

  .jw-flag-fullscreen .jw-nextup-container .jw-nextup {
    max-width: unset;
    width: 300px;
  }

  .jw-nextup {
    border-radius: 4px;
  }

  .jw-nextup-tooltip {
    background-image: ${props =>
      linearGradient(props.theme.gradients.nextUp.background.direction, props.theme.gradients.nextUp.background.stops)};
  }

  .jw-tooltip-pip {
    left: 100% !important;
  }

  .jw-tooltip .jw-text {
    min-height: unset !important;
  }

  .jwplayer.jw-flag-ads .jw-controlbar {
    flex-direction: initial;
  }

  .jwplayer.jw-flag-ads .jw-slider-time .jw-slider-container {
    height: 5px !important;
    overflow: hidden;
  }

  .jw-nextup-body {
    justify-content: center;
    padding: 16px;
  }

  .jw-nextup-header {
    font-size: 12px;
    line-height: 20px;
    color: ${Colors.paleLavender};
    text-transform: uppercase;
  }

  .jw-nextup-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: ${Colors.white};

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .jw-nextup-container {
    top: 0 !important;
  }

  .jw-flag-small-player .jw-nextup-container .jw-nextup-tooltip {
    height: 80px;
  }

  .jw-flag-small-player .jw-nextup-container .jw-nextup-body {
    justify-content: center;
    align-items: unset;
    padding: 16px;
  }

  .jw-flag-small-player .jw-nextup-container .jw-nextup-title {
    display: block;
  }

  .jw-slider-time,
  .jw-button-container {
    padding: 0 24px;
  }

  .jw-timesegment-progress,
  .jw-timesegment-bar {
    border-radius: 2px;
  }

  .jw-controlbar {
    .jw-icon-playback,
    .jw-icon-next,
    .jw-related-btn,
    .jw-settings-submenu-button.jw-icon-settings {
      display: none;
    }

    .jw-button-container {
      .jw-text-elapsed,
      .jw-text-duration {
        order: 2;
      }

      .jw-text-live {
        display: flex;
        order: 2;
        padding-left: 2px;
        font-weight: normal;
        font-size: 16px;
        text-transform: capitalize;
        color: ${Colors.white};

        ::before {
          display: none;
        }
      }

      .jw-text-live.jw-dvr-live {
        box-shadow: none;
      }

      .jw-icon-inline {
        @media (max-width: ${props => props.theme.breakpoints.smMax}) {
          width: 36px;
          height: 36px;
        }
      }

      .jw-svg-icon {
        @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
          width: 32px;
          height: 32px;
        }
      }

      .jw-horizontal-volume-container {
        display: none;
      }

      .jw-icon-playback {
        .jw-svg-icon-play path,
        .jw-svg-icon-pause path {
          display: none;
        }

        .jw-svg-icon-play {
          background-image: url(${ICONS.PLAY_FILL});
          background-size: contain;
          background-repeat: no-repeat;
        }

        .jw-svg-icon-pause {
          background-image: url(${ICONS.PAUSE_FILL});
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .jw-icon-rewind {
        display: flex !important;
        order: 1;

        .jw-svg-icon-rewind path {
          display: none;
        }

        .jw-svg-icon-rewind {
          background-image: url(${ICONS.REWIND});
          background-size: contain;
          background-repeat: no-repeat;
        }

        .jw-tooltip-rewind {
          left: 0;
          transform: none;
        }
      }

      .jw-spacer {
        order: 3;
      }

      .jw-icon-volume {
        order: 6;

        &::after {
          content: none;
        }

        .jw-overlay {
          bottom: 39px;
          width: 50px;
          height: 144px;
        }

        .jw-svg-icon-volume-100 path {
          display: none;
        }

        .jw-svg-icon-volume-100 {
          background-image: url(${ICONS.VOLUME_FULL});
          background-size: contain;
          background-repeat: no-repeat;
        }

        .jw-svg-icon-volume-50 path {
          display: none;
        }

        .jw-svg-icon-volume-50 {
          background-image: url(${ICONS.VOLUME_HALF});
          background-size: contain;
          background-repeat: no-repeat;
        }

        .jw-svg-icon-volume-0 path {
          display: none;
        }

        .jw-svg-icon-volume-0 {
          background-image: url(${ICONS.VOLUME_OFF});
          background-size: contain;
          background-repeat: no-repeat;
        }

        .jw-slider-volume {
          background-image: ${props =>
            linearGradient(props.theme.gradients.player.volume.direction, props.theme.gradients.player.volume.stops)};
          border-radius: 4px;
          padding: 12px 0;

          .jw-rail {
            background-color: ${Colors.greyishBrown};
          }

          .jw-progress {
            background-color: ${Colors.greenishTeal};
          }

          .jw-slider-container {
            height: 120px;
          }
        }

        .jw-rail,
        .jw-buffer,
        .jw-progress {
          border-radius: 4px;
        }

        .jw-knob {
          width: 10px;
          height: 10px;
        }
      }

      .jw-icon-cc {
        order: 5;

        .jw-svg-icon-cc-on path {
          display: none;
        }

        .jw-svg-icon-cc-on {
          background-image: url(${ICONS.SUBTITLES_ACTIVE});
          background-size: contain;
          background-repeat: no-repeat;
        }

        .jw-svg-icon-cc-off path {
          display: none;
        }

        .jw-svg-icon-cc-off {
          background-image: url(${ICONS.SUBTITITLES_OFF});
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .jw-icon-cast {
        order: 7;
      }

      .jw-icon-airplay {
        order: 7;

        .jw-svg-icon path {
          display: none;
        }

        .jw-svg-icon-airplay-on {
          background-image: url(${ICONS.AIRPLAY_ACTIVE});
          background-size: contain;
          background-repeat: no-repeat;
        }

        .jw-svg-icon-airplay-off {
          background-image: url(${ICONS.AIRPLAY_OFF});
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .jw-icon-fullscreen {
        order: 9;

        .jw-svg-icon-fullscreen-on path {
          display: none;
        }

        .jw-svg-icon-fullscreen-on {
          background-image: url(${ICONS.FULLSCREEN_ON});
          background-size: contain;
          background-repeat: no-repeat;
        }

        .jw-svg-icon-fullscreen-off path {
          display: none;
        }

        .jw-svg-icon-fullscreen-off {
          background-image: url(${ICONS.FULLSCREEN_OFF});
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .jw-settings-sharing {
        display: none;
        order: 4;

        .jw-svg-icon-sharing path {
          display: none;
        }

        .jw-svg-icon-sharing {
          background-image: url(${ICONS.SHARE});
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }

    .jw-tooltip {
      margin-bottom: 0;

      .jw-text {
        background-color: ${Colors.greyishBrown};
        color: ${Colors.white};
        font-size: 16px;
      }

      &::after {
        display: none;
      }
    }
  }

  .jw-time-tip {
    margin-bottom: 5px;
    border-radius: 4px;
    overflow: hidden;

    &::after {
      display: none;
    }

    .jw-text {
      background-color: ${Colors.black};
      color: ${Colors.brownGrey};
      font-size: 18px;
    }
  }

  .jw-breakpoint-1 .jw-settings-open .jw-settings-menu {
    position: relative;
    bottom: 165px;
    margin: 0 auto;
  }

  .jw-settings-open .jw-settings-menu {
    bottom: 49px;
    width: 288px;
    height: auto;
    padding: 12px 8px;
    background-color: ${Colors.greyishBrown};
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    z-index: 1;

    @media (max-width: ${props => props.theme.breakpoints.smMax}) {
      max-height: 144px;
    }

    .jw-settings-topbar {
      display: none;
    }

    .jw-settings-submenu {
      padding: 0;
      overflow-y: auto;
    }

    .jw-submenu-topbar {
      display: none;
    }

    .jw-settings-submenu.jw-sharing-menu {
      display: none;
    }

    .jw-settings-content-item {
      padding: 10px 12px 10px 32px;
      border-radius: 4px;
      background: none;
      opacity: 1;
      font-size: 18px;
      line-height: 20px;
      color: ${Colors.white};
      overflow-x: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-image: ${props =>
          linearGradient(
            props.theme.gradients.player.settingMenu.item.hover.direction,
            props.theme.gradients.player.settingMenu.item.hover.stops
          )};
      }

      &:focus {
        font-weight: normal;
      }
    }

    .jw-settings-content-item[aria-label='Unknown CC'] {
      display: none;
    }

    .jw-settings-item-active {
      font-weight: normal;

      &::before {
        content: '';
        width: 30px;
        background: url(${ICONS.CHECK_DROPDOWN}) left 5px center / 24px 24px no-repeat, none;
      }
    }
  }

  .jw-controls {
    .jw-display-controls {
      .talpa-jw-disabled {
        opacity: 0.4;

        .jw-icon {
          cursor: default;
        }
      }

      .talpa-jw-hidden {
        display: none !important;
      }
    }

    .jw-display-icon-container {
      @media (max-width: ${props => props.theme.breakpoints.smMax}) {
        margin: 0 10px;
      }

      .jw-icon .jw-svg-icon {
        width: 100%;
        height: 100%;
      }
    }

    .jw-display-icon-rewind {
      display: none;
    }

    .jw-display .jw-icon {
      width: 32px;
      height: 32px;

      @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
        width: 80px;
        height: 80px;
      }
    }

    .talpa-jw-display-icon-prev {
      .talpa-jw-svg-icon-prev {
        background-image: url(${ICONS.PREVIOUS_FILL});
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .talpa-jw-display-icon-next {
      .talpa-jw-svg-icon-next {
        background-image: url(${ICONS.NEXT_FILL});
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .jw-display-icon-display {
      .jw-svg-icon-play path,
      .jw-svg-icon-pause path,
      .jw-svg-icon-replay path,
      .jw-svg-icon-buffer path {
        display: none;
      }

      .jw-svg-icon-play {
        background-image: url(${ICONS.PLAY_FILL_LARGE});
        background-size: contain;
        background-repeat: no-repeat;
      }

      .jw-svg-icon-pause {
        background-image: url(${ICONS.PAUSE_FILL_LARGE});
        background-size: contain;
        background-repeat: no-repeat;
      }

      .jw-svg-icon-replay {
        background-image: url(${ICONS.REPLAY});
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  .jwplayer.jw-state-setup .jw-overlays,
  .jwplayer.jw-state-buffering .jw-icon-display {
    position: relative;
    background-image: url('/static/images/icons/shape_eye.svg');
    background-size: contain;
    background-repeat: no-repeat;
    animation: none !important;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      left: 0;
      width: 10px;
      height: 12px;
      background-image: url(${ICONS.SHAPE_ARROW});
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0;
      animation-name: ${LoaderAnimationSm};
      animation-duration: 1.7s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-delay: -1s;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      &::after {
        top: 14px;
        width: 20px;
        height: 30px;
        animation-name: ${LoaderAnimationMd};
      }
    }
  }

  .jwplayer.jw-flag-floating .jw-overlays::after,
  .jwplayer.jw-flag-floating .jw-icon-display::after {
    width: 8px;
    height: 10px;
    left: -10px;
  }

  .jwplayer.jw-state-setup .jw-overlays {
    position: absolute;
    top: 50%;
    margin-top: -40px;
    left: 50%;
    margin-left: -40px;
    width: 80px;
    height: 80px;

    @media (max-width: ${props => props.theme.breakpoints.mdMin}) {
      margin-top: -20px;
      margin-left: -20px;
      width: 40px !important;
      height: 40px !important;
    }
  }

  .jwplayer.jw-state-playing:not(.jw-flag-touch):not(.jw-flag-small-player):not(.jw-flag-casting):not(
      .jw-flag-play-rejected
    )
    .jw-display {
    display: flex;
  }

  .jwplayer.jw-state-complete .talpa-jw-display-icon-prev,
  .jwplayer.jw-state-idle .talpa-jw-display-icon-prev,
  .jwplayer.jw-state-buffering .talpa-jw-display-icon-prev,
  .jwplayer.jw-state-complete .talpa-jw-display-icon-next,
  .jwplayer.jw-state-idle .talpa-jw-display-icon-next,
  .jwplayer.jw-state-buffering .talpa-jw-display-icon-next {
    display: none !important;
  }

  .jwplayer.jw-display-icon-rewind,
  .jwplayer .jw-display-icon-next {
    display: none;
  }

  .jwplayer.jw-state-paused:not(.jw-flag-touch):not(.jw-flag-small-player):not(.jw-flag-casting):not(
      .jw-flag-play-rejected
    )
    .jw-display {
    display: flex;
  }

  .jw-flag-floating .jw-wrapper.jw-float-to-top {
    bottom: 3rem;
  }

  .jwplayer.jw-reset.jw-floating-dismissible.jw-flag-floating.jw-flag-small-player:not(.jw-flag-user-inactive),
  .jwplayer.jw-state-paused:not(.jw-flag-controls-hidden) {
    .jw-float-bar-icon {
      display: flex;
    }
  }

  .jw-flag-floating {
    &.jw-flag-ads .jw-slider-time {
      margin-right: 0;
    }

    .jw-wrapper {
      max-width: none !important;
      top: 0 !important;
      left: 0;
      bottom: auto;
      right: auto;
      border-radius: 4px;
      overflow: hidden;

      &::after {
        @media (max-width: ${props => props.theme.breakpoints.smMax}) {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          position: absolute;
          bottom: 0;
          background: ${props =>
            linearGradient(props.theme.gradients.header.solid.direction, props.theme.gradients.header.solid.stops)};
        }
      }
    }

    .jw-nextup-container {
      display: none;
    }

    .jw-display {
      padding: 0;
    }

    .jw-display-container {
      padding-bottom: 20px;
    }

    .jw-display-controls {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .jw-controls .jw-display .jw-icon {
      width: 32px;
      height: 32px;
    }

    .jw-controls .jw-display .jw-icon.jw-icon-next,
    .jw-controls .jw-display .talpa-jw-display-icon-prev .jw-icon,
    .jw-controls .jw-display .talpa-jw-display-icon-next .jw-icon {
      width: 32px;
      height: 32px;
    }

    .jw-float-bar-icon {
      display: none;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;

      &:hover {
        background-color: ${Colors.brownGrey};
        border-radius: 50px;
      }

      @media (max-width: ${props => props.theme.breakpoints.smMax}) {
        display: none !important;
      }

      .jw-svg-icon-floating-close path {
        display: none;
      }

      .jw-svg-icon-floating-close {
        background-image: url(${ICONS.CLOSE});
        background-size: contain;
        background-repeat: no-repeat;
        width: 26px;
        height: 26px;
      }
    }

    .jw-button-container {
      padding: 0 15px;
      .jw-icon-rewind {
        display: none !important;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      .jw-wrapper {
        top: auto !important;
        left: auto;
        bottom: 40px;
        right: 40px;
        max-width: 400px !important;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.8);
      }

      .jw-display-container {
        padding-bottom: 0;
      }

      .jw-button-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 38px;
      }

      .jw-icon-volume {
        top: 2px;
      }

      .jw-icon-display {
        width: 40px !important;
        height: 40px !important;
      }

      .jw-display-icon-display {
        .jw-icon-display {
          width: 50px !important;
          height: 50px !important;
        }
      }

      .jw-text-countdown,
      .jw-spacer,
      .jw-icon-cc,
      .jw-icon-airplay,
      .jw-settings-sharing,
      .jw-settings-submenu-button,
      .jw-settings-submenu-button,
      .jw-icon-fullscreen {
        display: none !important;
      }

      .jw-slider-time {
        height: 38px;
        margin-right: 45px;
        padding-left: 20px;
      }

      .jw-text-elapsed,
      .jw-text-countdown,
      .jw-text-duration {
        display: none;
      }

      .jw-float-bar {
        top: 0 !important;
        background: transparent;
      }

      .jw-icon-volume {
        position: absolute;
        right: 10px;
        width: 32px;
        height: 40px;
        z-index: 1;
        top: -1px;
      }

      .jw-icon-pip {
        display: none;
      }
    }
  }

  .jw-slider-time .jw-cue {
    width: 16px;
    background-color: ${Colors.butter};
    border-radius: 2px;
  }

  .jwplayer.jw-flag-ads {
    .jw-display {
      display: none !important;
    }

    .jw-controls-backdrop {
      background-color: ${Colors.transparent};
    }

    .jw-controls-backdrop::before {
      height: 64px;

      @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
        height: 96px;
      }
    }

    .jw-controlbar {
      flex-wrap: wrap;
      z-index: 999999;
    }

    .jw-controlbar .jw-slider-time {
      height: 14px;
      padding: 0 24px;
    }

    .jw-slider-container {
      height: 4px;
      border-radius: 2px;
      background-color: ${Colors.greyishBrown};

      .jw-timesegment-resetter {
        transform: translate(0, -50%) scale(1, 0.8);
      }
    }

    .jw-slider-time .jw-progress {
      border-radius: 2px;
      background-color: ${Colors.butter};
    }

    .jw-controlbar .jw-button-container .jw-icon-rewind {
      display: none !important;
    }

    .jw-controlbar .jw-icon-playback {
      display: flex;
    }

    .jw-controlbar .jw-button-container .jw-icon-playback .jw-svg-icon-play {
      background-image: url(${ICONS.PLAY});
    }

    .jw-controlbar .jw-button-container .jw-icon-playback .jw-svg-icon-pause {
      background-image: url(${ICONS.PAUSE});
    }

    .jw-controlbar .jw-text-alt {
      order: 2;
      margin-left: 10px;
      font-size: 16px;
      overflow: hidden;
    }

    &.jw-flag-floating .jw-button-container {
      display: none;
    }
  }

  .jw-flag-floating .jw-wrapper {
    z-index: 214748364 !important;
  }

  .jw-icon-pip {
    order: 8;
  }

  .jwplayer.jw-state-complete,
  .jwplayer.jw-state-paused:not(.jw-flag-controls-hidden),
  .jwplayer.jw-state-playing:not(.jw-flag-user-inactive) {
    &:not(.jw-flag-ads):not(.jw-flag-overlay-open-related) {
      .full-episode-button {
        display: flex;
      }
    }
  }

  .next-up-card {
    width: 400px;
    height: 80px;
    background: ${Colors.black};
    position: absolute;
    top: 24px;
    right: 24px;
    border: none;
    display: flex;
    animation-name: ${NextUpAnimation};
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-delay: 0;
    cursor: pointer;

    @media screen and (max-width: ${props => props.theme.breakpoints.smMax}) {
      width: 120px;
      height: 30px;
    }
  }

  .next-up-card-picture {
    background-position: center;
    background-size: cover;
    flex: 0 0 120px;
    height: 100%;

    @media screen and (max-width: ${props => props.theme.breakpoints.smMax}) {
      flex: 0 0 60px;
    }
  }

  .next-up-text-wrapper {
    flex: 1;
    padding: 12px 14px;
    color: ${Colors.white};
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;

    @media screen and (max-width: ${props => props.theme.breakpoints.smMax}) {
      padding: 4.8px;
    }
  }

  .next-up-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.35;

    @media screen and (max-width: ${props => props.theme.breakpoints.smMax}) {
      font-size: 12px;
      margin: auto;
    }
  }

  .next-up-subtitle {
    font-size: 14px;
    line-height: 1.35;
    font-family: Cera, sans-serif;
    max-width: 100%;
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
    overflow: hidden;

    @media screen and (max-width: ${props => props.theme.breakpoints.smMax}) {
      display: none;
    }
  }

  .next-up-video-length {
    align-self: flex-end;
    text-align: right;
    font-size: 12px;

    @media screen and (max-width: ${props => props.theme.breakpoints.smMax}) {
      display: none;
    }
  }

  .next-up-close-button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    outline: none;
    height: 24px;
    width: 24px;
    cursor: pointer;
    margin: 6px;
    background-color: ${Colors.transparent};
    background-image: url(${ICONS.CLOSE});
    background-size: contain;
    background-repeat: no-repeat;

    @media screen and (max-width: ${props => props.theme.breakpoints.smMax}) {
      display: none;
    }
  }

  .full-episode-button {
    display: none;
    width: 252px;
    height: 56px;
    padding: 8px 24px 8px 8px;
    border-radius: 28px;
    position: absolute;
    top: 24px;
    right: 24px;
    margin: 0;
    background-image: linear-gradient(
      114deg,
      ${Colors.violet},
      ${Colors.royal} 17%,
      ${Colors.black} 53%,
      ${Colors.black} 54%
    );
    border: none;
    cursor: pointer;
    z-index: 1;

    &::before {
      content: '';
      background: center / contain no-repeat url(${ICONS.PLAY_BUTTON_CIRCLE});
      width: 40px;
      height: 40px;
    }

    &:link {
      text-decoration: none;
    }

    span {
      width: 168px;
      height: 19px;
      margin: auto auto auto 12px;
      font-family: Cera, sans-serif;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      white-space: nowrap;
      color: ${Colors.white};
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.smMax}) {
      top: 16px;
      right: 16px;
      width: 174px;
      height: 32px;
      padding: 4px 12px 4px 4px;
      border-radius: 16px;

      &::before {
        height: 23px;
        width: 30px;
      }

      span {
        margin: auto auto auto 8px;
        line-height: 1.45;
        font-size: 12px;
      }
    }
  }
`;

export const PlayerLoader = styled.div`
  height: 24px;
  width: 32px;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  position: absolute;
  background-image: url(${ICONS.SHAPE_EYE});
  background-size: contain;
  background-repeat: no-repeat;
  animation: none !important;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 0;
    width: 10px;
    height: 12px;
    background-image: url(${ICONS.SHAPE_ARROW});
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0;
    animation-name: ${LoaderAnimationSm};
    animation-duration: 1.7s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-delay: -1s;
  }
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: 58px;
    width: 80px;
    top: calc(50% - 29px);
    left: calc(50% - 29px);

    &::after {
      top: 14px;
      width: 20px;
      height: 30px;
      animation-name: ${LoaderAnimationMd};
    }
  }
`;
