import { gql } from '@apollo/client';
import { ImageMediaFragment, MediaFragment, RatingsFragment, SeriesTvSeasonsFragment } from './programs.fragments';

const GET_SERIES_BY_SLUG = gql`
  query GetSeriesBySlug($slugs: [String]) {
    programs(slugs: $slugs, programTypes: SERIES) {
      totalResults
      items {
        __typename
        id
        guid
        slug
        sortTitle
        type
        title
        description
        shortDescription
        displayGenre
        metadata
        ...ImageMedia
        ...Media
        ...SeriesTvSeasons
        ...Ratings
      }
    }
  }
  ${MediaFragment}
  ${ImageMediaFragment}
  ${SeriesTvSeasonsFragment}
  ${RatingsFragment}
`;

export default GET_SERIES_BY_SLUG;
