import { TEALIUM_EVENT_LABEL } from './tealium.constants';

export enum USER_CODE_RESPONSE {
  INCORRECT = 'De login code is onjuist.',
  EXPIRED = 'De login code is verlopen.',
  LOGGED_OUT = 'Je bent niet ingelogd',
  DEFAULT = 'Oeps, er ging iets fout. Kan je het later nog eens proberen?'
}

export enum USER_CODE_ERROR {
  EXPIRED = 'User code expired',
  NOT_FOUND = 'Not found',
  LOGGED_OUT = 'Logged out'
}

export const TEALIUM_CODE_LABEL: { [key: string]: string } = {
  [USER_CODE_ERROR.EXPIRED]: TEALIUM_EVENT_LABEL.USER_CODE_EXPIRED
};

export const CODE_ERROR_MESSAGE: { [key: string]: string } = {
  [USER_CODE_ERROR.EXPIRED]: USER_CODE_RESPONSE.EXPIRED,
  [USER_CODE_ERROR.NOT_FOUND]: USER_CODE_RESPONSE.INCORRECT,
  [USER_CODE_ERROR.LOGGED_OUT]: USER_CODE_RESPONSE.LOGGED_OUT
};
