import getConfig from 'next/config';
import {
  getTime,
  addDays,
  endOfDay,
  isAfter,
  isBefore,
  subDays,
  toDate,
  differenceInDays,
  parseISO,
  formatDistanceStrict,
  addHours
} from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { nl } from 'date-fns/locale';
import resources from '../../client/constants/resources';
import { PROGRAM } from '../../client/constants';

const { publicRuntimeConfig } = getConfig();

export const isLive = (startTime: string, endTime: string) => {
  const nowUtc = endOfDay(new Date());
  const startTimeUtc = endOfDay(toDate(Date.parse(startTime)));
  const endTimeUtc = endOfDay(toDate(Date.parse(endTime)));
  return nowUtc >= startTimeUtc && nowUtc <= endTimeUtc;
};

export const formatTime = (dateTime: Date, timeZone: string): string => format(dateTime, 'HH:mm', { timeZone });

export const getSocialDate = (date: string | number = '', formatDate: string = 'dd-MMMM-yyyy'): string => {
  if (!date) return '';
  const episodeDate = endOfDay(toDate(typeof date === 'number' ? date : parseISO(date)));
  const today = endOfDay(new Date());
  switch (differenceInDays(episodeDate, today)) {
    case 0:
      return 'Vandaag';
    case -1:
      return 'Gisteren';
    case 1:
      return 'Morgen';
    default:
      return format(episodeDate, formatDate, { locale: nl }).replace(/-/g, ' ');
  }
};

export const formatSeconds = (seconds?: number): string => {
  if (!seconds) return '';
  const SecondsInMinute: number = 60;
  const minutesInHour: number = 60;
  const SecondsInHour: number = 60 * SecondsInMinute;
  const SecondsInDay: number = 24 * SecondsInHour;

  if (seconds < SecondsInMinute) {
    return `${Math.floor(seconds)}s`;
  }

  if (seconds < SecondsInHour) {
    return `${Math.round(seconds / SecondsInMinute)}m`;
  }

  if (seconds < SecondsInDay) {
    const DurationInMinutes: number = Math.round(seconds / SecondsInMinute);
    const HourDuration: number = Math.floor(DurationInMinutes / minutesInHour);
    const MinutesRemainder: number = DurationInMinutes % minutesInHour;

    return MinutesRemainder ? `${HourDuration}u${MinutesRemainder}m` : `${HourDuration}u`;
  }

  return `${Math.round(seconds / SecondsInHour)}u`;
};

export const formatDateToLocalTime = (date: Date, formatDate: string = 'HH:mm'): string => {
  if (date.toString().toLowerCase().includes('invalid')) {
    return '';
  }
  return format(date, formatDate, { locale: nl }).replace(/[.]/g, '');
};

export const formatToLocalDate = (videoDate: Date): string | undefined => {
  if (!videoDate) return '';

  const amsterdamDate = utcToZonedTime(new Date(), publicRuntimeConfig.app.timezone);

  const today = endOfDay(amsterdamDate);
  const yesterday = endOfDay(subDays(amsterdamDate, 1));
  const beforeYesterday = endOfDay(subDays(amsterdamDate, 2));

  if (videoDate < beforeYesterday || isAfter(videoDate, today)) {
    return (
      // TODO: There is a problem with MPX time and date. MPX will provide new field that will
      // contain correct timestamp that would be used. For now we are just displaying date.
      format(videoDate, 'eeeeee', { locale: nl, timeZone: publicRuntimeConfig.app.timezone }).charAt(0).toUpperCase() +
      format(videoDate, 'eeeeee d MMM', { locale: nl, timeZone: publicRuntimeConfig.app.timezone })
        .slice(1)
        .replace(/[.]/g, '') +
      (videoDate.getFullYear() !== new Date().getFullYear() ? ` ${videoDate.getFullYear()}` : '')
    );
  }

  if (videoDate < yesterday) {
    return 'Gisteren';
  }

  if (videoDate < today) {
    return 'Vandaag';
  }
};

export const availableDateFormat = (availableDate: string | undefined): string => {
  let available;

  if (availableDate) {
    available = utcToZonedTime(new Date(Number(availableDate)), publicRuntimeConfig.app.timezone);
  } else {
    return '';
  }

  if (isBefore(available, new Date())) {
    // time is in the past
    return '';
  }
  const today = endOfDay(new Date());
  const tomorrow = endOfDay(addDays(new Date(), 1));
  const inWeek = endOfDay(addDays(new Date(), 7));
  if (available < today) {
    return resources.today(formatDateToLocalTime(available));
  }
  if (available < tomorrow) {
    return resources.tomorrow();
  }
  if (available < inWeek) {
    return resources.futureDays(differenceInDays(available, today) + 1);
  }
  return resources.futureDate(formatDateToLocalTime(available, 'd MMM'));
};

export const isPromoValid = (startDateTime: number, endDateTime: number): boolean => {
  const UserLocalTime = getTime(new Date());
  if (!startDateTime && !endDateTime) {
    return true;
  }
  if (isAfter(startDateTime, UserLocalTime)) {
    return false;
  }
  return !isBefore(endDateTime, UserLocalTime);
};

export const getUploadDate = (video: IProgramGraphql): string => {
  const { media, updated, added } = video || {};
  return new Date(media?.[0]?.airedDateTime || updated || added || Date.now()).toISOString();
};

export const secondsToISODuration = (duration: number): string => {
  const time = Math.round(duration);
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor((time - hours * 60 * 60) / 60);
  const seconds = time - hours * 60 * 60 - minutes * 60;

  let formatedDuration = 'PT';
  if (hours > 0) {
    formatedDuration = `${formatedDuration}${hours}H`;
  }
  if (minutes > 0) {
    formatedDuration = `${formatedDuration}${minutes}M`;
  }
  return `${formatedDuration}${seconds}S`;
};

export const getRemainingAvailability = (expirationTimestamp: string | number): string => {
  const now = new Date();
  const inTwoHours = addHours(now, 2);
  const fiveDaysFromToday = addDays(now, 5);
  const expirationDate = toDate(Number(expirationTimestamp));

  if (isBefore(expirationDate, fiveDaysFromToday) && isAfter(expirationDate, inTwoHours)) {
    return `Kijk nog ${formatDistanceStrict(expirationDate, now, {
      locale: nl
    })}`;
  }
  return '';
};

export const getShortDateTime = (airedDateTime?: string, availableDate?: string, added?: number | null): string => {
  let date: Date | null = null;

  if (airedDateTime) date = utcToZonedTime(new Date(airedDateTime), publicRuntimeConfig.app.timezone);
  else if (availableDate && availableDate !== '0') {
    date = utcToZonedTime(new Date(Number(availableDate)), publicRuntimeConfig.app.timezone);
  } else if (added) date = new Date(added);
  else return '';

  const localDate = formatToLocalDate(date);
  const localTime = formatDateToLocalTime(date);
  if (!localDate || !localTime) return '';

  return `${localDate.toUpperCase()}, ${localTime}`;
};

export const getVideoLabelText = (media: IMediaGraphql | undefined, programType: string): string => {
  if (!media) return '';
  if (!media.availabilityState) return availableDateFormat(media.availableDate);
  if (media.type === PROGRAM.LIVE) return PROGRAM.LIVE;
  if (programType === PROGRAM.EPISODE && media.airedDateTime && isAfter(new Date(media.airedDateTime), new Date())) {
    return 'Kijk vooruit';
  }
  if ((programType === PROGRAM.EPISODE || programType === PROGRAM.MOVIE) && media.expirationDate) {
    return getRemainingAvailability(media.expirationDate);
  }
  return '';
};

export const formatToMinutesSeconds = (time?: number | null): string => {
  if (!time) return '';

  const seconds = Math.round(time % 60);
  const minutes = Math.floor(time / 60);

  return `${`${minutes}`.padStart(2, '0')}:${`${seconds}`.padStart(2, '0')}`;
};
