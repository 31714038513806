import { gql } from '@apollo/client';
import { ImageMediaFragment, MediaFragment, SourcesFragment } from '../programs/programs.fragments';

const TrendingProgramsQuery: any = gql`
  query trendingPrograms($limit: Int) {
    trendingPrograms(limit: $limit) {
      guid
      slug
      id
      type
      title
      sortTitle
      displayGenre
      metadata
      ...Media
      ...Sources
      ...ImageMedia
    }
  }
  ${ImageMediaFragment}
  ${MediaFragment}
  ${SourcesFragment}
`;

export default TrendingProgramsQuery;
