import styled from 'styled-components';
import {
  SkeletonVideoItemWrapperStyle,
  SkeletonVideoItemTextWrapperStyle,
  SkeletonProgramItemTitleStyle,
  SkeletonTitleWrapperStyle
} from '../SkeletonElement/SkeletonElement.style';
import { PickerItemStyle } from '../Picker/Picker.style';
import { Heading2 } from '../Text/Text.style';

export const MissedStyle = styled.div`
  flex-grow: 1;
  padding-top: 102px;
  padding-bottom: 50px;

  ${Heading2} {
    margin-bottom: 32px;
  }

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    ${SkeletonVideoItemTextWrapperStyle} {
      div:nth-child(2) {
        bottom: unset;
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.smMax}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) {
    ${SkeletonProgramItemTitleStyle} {
      margin-bottom: 26px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    padding-top: 120px;
    padding-bottom: 120px;

    ${SkeletonVideoItemTextWrapperStyle} {
      div:last-child {
        margin-bottom: 50px;
      }
    }
  }
`;

export const MissedListWrapperStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-bottom: 78px;

  span {
    display: flex;

    a {
      display: block;
      width: 100%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    ${SkeletonVideoItemWrapperStyle} {
      div:last-child {
        top: 0;
      }

      div:first-child {
        bottom: -3px;
      }
    }

    ${SkeletonVideoItemWrapperStyle} + ${SkeletonTitleWrapperStyle} {
      margin-top: 75px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    grid-template-columns: repeat(3, minmax(187px, 1fr));
    grid-gap: 16px;

    ${SkeletonVideoItemWrapperStyle} {
      div:last-child {
        bottom: 0;
      }
    }

    ${SkeletonVideoItemTextWrapperStyle} {
      div:last-child {
        bottom: -15px;
      }
    }

    ${SkeletonProgramItemTitleStyle} {
      margin-top: 5px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    grid-template-columns: repeat(4, minmax(226px, 1fr));
    grid-gap: 24px;
  }
`;

export const MissedPickerStyle = styled.div<{ isHeaderHidden: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  transition: transform ${props => props.theme.transitions.easeIn};
  transform: ${props => (props.isHeaderHidden ? 'none' : 'translateY(56px)')};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    transition: transform ${props => props.theme.transitions.easeIn};
    transform: ${props => (props.isHeaderHidden ? 'none' : 'translateY(70px)')};
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    transform: ${props => (props.isHeaderHidden ? 'none' : 'translateY(80px)')};
  }

  ${PickerItemStyle} {
    min-width: fit-content;
  }
`;
