import SVG from 'react-inlinesvg';
import useImprovedQuery from 'hooks/useImprovedQuery';
import VideosProgressQuery from 'queries/videos/videosProgressQuery.query';
import { Grid, GridElement } from '../../../styles/grid';
import {
  ClickableTitleStyle,
  HomeContinueWatchingSectionWrapperStyle,
  HomeContinueWatchingCarouselStyle
} from '../Home.style';
import ContinueWatching from '../../ContinueWatching/ContinueWatching';
import { SkeletonLandscapeItemCarousel } from '../../SkeletonElement/SkeletonElement';
import ErrorMessage from '../../ErrorMessage';
import ErrorBoundary from '../../ErrorBoundary';
import CommonFallback from '../../CommonFallback';
import {
  FALLBACK_UI_LABELS,
  FETCH_POLICY,
  ICONS,
  TEALIUM_EVENT_NAME,
  TEALIUM_EVENT_CATEGORY
} from '../../../constants';
import Link from '../../Link';
import { Heading2 } from '../../Text/Text.style';

const SKELETON_NUMBER_OF_PROGRAM_ITEMS = 3;

const ContinueWatchingSection = ({ title }: { title: string }) => {
  const {
    loading,
    processedData: guids,
    error
  } = useImprovedQuery<{ videoProgress: IVideo[] }, string[]>(VideosProgressQuery, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    variables: { completed: false },
    processData: data => {
      if (!data.videoProgress?.length) return null;
      const guids = data.videoProgress.map(({ guid }) => guid);

      return guids;
    }
  });

  if (loading) {
    return <SkeletonLandscapeItemCarousel noOfItems={SKELETON_NUMBER_OF_PROGRAM_ITEMS} />;
  }
  if (error) {
    return <ErrorMessage />;
  }

  if (!guids?.length) return null;

  return (
    <ErrorBoundary onError={(_: any) => <CommonFallback message={FALLBACK_UI_LABELS.CONTINUE_WATCHING} />}>
      <HomeContinueWatchingSectionWrapperStyle data-testid="continueWatchingSection">
        <Grid>
          <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
            <ClickableTitleStyle data-testid="onDemandLinkStyleContinueWatching">
              <Heading2 data-testid="continueWatchingTitle">
                <Link
                  path="/account/kijk-verder"
                  clickEventValues={{
                    name: TEALIUM_EVENT_NAME.CONTENT_LISTING_MORE,
                    category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
                    label: title
                  }}
                >
                  {title}
                  <SVG src={ICONS.ARROW_SMALL_30} id="continueWatchingArrow" />
                </Link>
              </Heading2>
            </ClickableTitleStyle>
          </GridElement>
        </Grid>
        <HomeContinueWatchingCarouselStyle data-testid="continueWatchingSectionCarousel">
          <ContinueWatching isCarousel collectionTitle={title} progressFetchPolicy={FETCH_POLICY.CACHE_ONLY} />
        </HomeContinueWatchingCarouselStyle>
      </HomeContinueWatchingSectionWrapperStyle>
    </ErrorBoundary>
  );
};

export default ContinueWatchingSection;
