/* eslint-disable no-case-declarations */
import getConfig from 'next/config';
import { ApolloClient } from '@apollo/client';
import { HeartBeat, JwMonitor } from '@4tn/talpatv-video-analytics';
import { getContinueWatchingByGuidQuery } from 'queries/continueWatching/continueWatchingActions';
import VideosProgressQuery from 'queries/videos/videosProgressQuery.query';
import { customInteraction, noticeError } from 'lib/newrelic';
import { refetchSources, secondsToISODuration } from 'lib/helpers';
import isSearchBot from 'lib/isSearchBot';
import VideoModel from 'models/video.model';
import { getCustomFreewheelParameters } from 'lib/helpers/freewheel';
import { FEATURE_SLUG, CLIENT, IS_CLIENT, ACCOUNT, FREEWHEEL_TARGET, PLAYER_ERRORS } from '../../constants';
import featureTooling from '../FeatureTooling';

const { publicRuntimeConfig } = getConfig();

export const PLAYER_ID = 'player';

/* istanbul ignore next */
// tslint:disable-next-line:no-empty
const noop = () => {};

const heartBeatScheme = [
  { start: 0, interval: 10 },
  { start: 60, interval: 30 }
];

const customAppId = '877D17E1';

class VideoJWPlayer {
  userInteracted: boolean = false;
  playerOptions: IPlayerOptions;
  isPlayerLoaded: boolean = false;
  isAdPlaying: boolean = false;
  apolloClient: ApolloClient<object>;
  viewMode: string;
  playerInstance: jwplayer.JWPlayer | null = null;

  onSetupError: (event: IPlayerErrorEvent) => void;
  onHeartBeatCallback: () => void;
  onReadyCallback: () => void;
  onPlayCallback: () => void;
  onTimeCallback: () => void;
  onSeekedCallback: () => void;
  onPauseCallback: () => void;
  onFirstFrameCallback: () => void;
  onCompleteCallback: () => void;
  onPlaylistItemCallback: (event: any) => void;
  stopHeartBeatCallback: () => void;
  onMuteCallback: () => void;
  onAdPlayCallback: () => void;
  onAdBreakEndCallback: () => void;
  onAdCompletedCallback: (event: IAdCompleteEvent) => void;
  onPlayerErrorCallback: (event: IPlayerErrorEvent) => void;
  onPlayerWarningCallback: (event: IPlayerErrorEvent) => void;
  onFloating: () => void;
  onCast: (event: any) => void;
  onSourcesError: () => void;

  constructor() {
    if (IS_CLIENT) {
      HeartBeat.init({
        scheme: heartBeatScheme,
        events: { onHeartBeat: this.heartBeatCallback }
      });
      document.body.addEventListener('click', this.onClick);
    }
  }

  onClick = (): void => {
    this.userInteracted = true;
    document.body.removeEventListener('click', this.onClick);
  };

  setPlayerOptions = (options: IPlayerOptions) => {
    this.playerOptions = options;
  };

  initPlayer = async (client: any, viewMode: string): Promise<void> => {
    if (this.isPlayerLoaded) {
      return;
    }
    this.apolloClient = client;
    this.viewMode = viewMode;

    const playerConfig = {
      // Fix for apollo client immutable data
      ...JSON.parse(JSON.stringify(this.playerOptions.config)),
      aspectratio: '16:9',
      displaytitle: false,
      displaydescription: false,
      autostart: document.location.search.includes('zoek=') ? 'false' : 'true',
      mute: false,
      key: this.playerOptions?.config?.licenseKey,
      liveTimeout: 0,
      preload: false,
      intl: {
        nl: {
          nextUp: 'Volgende',
          advertising: {
            cuetext: 'Advertentie',
            podmessage: 'Adv. __AD_POD_CURRENT__ van __AD_POD_LENGTH__',
            loadingAd: 'Advertentie laden'
          }
        }
      },
      autoPause: { viewability: false },
      floating: {
        dismissible: true,
        mode: viewMode === CLIENT.MOBILE ? 'never' : 'notVisible'
      }
    };

    playerConfig.logo.file = '';
    playerConfig.autoPause.pauseAds = true;
    if (playerConfig.advertising?.freewheel) {
      const freewheelTargetDevice = viewMode === CLIENT.DESKTOP ? FREEWHEEL_TARGET.DESKTOP : FREEWHEEL_TARGET.MOBILEWEB;
      const freewheelTargetSection = 'vod';
      playerConfig.advertising.vpaidcontrols = true;
      playerConfig.advertising.freewheel.sectionid = `talpa_kijk2_${freewheelTargetDevice}_${freewheelTargetSection}`;
      playerConfig.advertising.freewheel.adManagerURL = `https://mssl.fwmrm.net/libs/adm/${publicRuntimeConfig.admanager.version}/AdManager.js`;
      await featureTooling.waitUntilReady();
      if (featureTooling.isFeatureEnabled(FEATURE_SLUG.CASTING)) {
        playerConfig.cast.customAppId = featureTooling.getFeatureVariable(
          FEATURE_SLUG.CASTING,
          'customAppId',
          customAppId
        );
      } else {
        playerConfig.cast = null;
      }

      // required to set a default schedule which will  eventually being overwritten by freewheel adSchedule
      playerConfig.advertising.schedule = {
        adbreak: {
          offset: 'pre',
          tag: 'placeholder_preroll'
        }
      };
    }

    if (isSearchBot() && featureTooling.isFeatureEnabled(FEATURE_SLUG.AUTOPLAY_GOOGLEBOT)) {
      playerConfig.advertising = null;
      playerConfig.autostart = false;
    }

    this.playerOptions = {
      id: PLAYER_ID,
      config: playerConfig,
      debug: this.playerOptions.debug,
      version: publicRuntimeConfig.jwplayer.version
    };
    this.playerInstance = window.jwplayer(this.playerOptions.id);

    this.isPlayerLoaded = true;
  };

  load = async (playlistItem: VideoModel): Promise<void> => {
    if (!this.isPlayerLoaded || !this.playerOptions) {
      setTimeout(this.load, 50, playlistItem);
      return;
    }

    // Fix for apollo client immutable data
    this.playerOptions.config.playlist = JSON.parse(JSON.stringify([playlistItem]));
    this.onSetup();
  };

  getStartTime = async (guid: string): Promise<number> => {
    if (!guid) return 0;
    try {
      // Get start time from local storage
      const lastActiveVideo: IVideo = JSON.parse(localStorage.getItem('lastActiveVideo') || '');
      if (lastActiveVideo.guid === guid && !lastActiveVideo.completed) return lastActiveVideo.position;
    } catch (ignore) {}

    // Get start time from cache
    const { data } = await getContinueWatchingByGuidQuery<{
      continueWatchingByGuid?: { completed: boolean; position: number };
    }>(this.apolloClient, guid);

    if (data.continueWatchingByGuid?.position) return data.continueWatchingByGuid.position;

    // Get start time for logged in users
    const loggedIn = !!localStorage.getItem(ACCOUNT.AUTH_TOKEN);
    if (loggedIn) {
      try {
        const {
          data: { videoProgress }
        } = await this.apolloClient.query<{ videoProgress: { position: number; completed: boolean }[] }>({
          query: VideosProgressQuery,
          variables: {
            guids: [guid]
          },
          fetchPolicy: 'no-cache'
        });
        const [progress] = videoProgress;
        if (progress && !progress.completed) {
          return progress.position;
        }
      } catch (ignore) {}
    }

    return 0;
  };

  setPlaylistItemCallback(): void {
    (this.playerInstance as any)?.setPlaylistItemCallback(
      async (item: VideoModel & { sources: ISourceGraphql[]; starttime: number }) => {
        const { metadata, guid } = item;

        // Check for ContinuousWatching and fetch sources from Video API
        const [startTime, sources, freewheelConfig] = await Promise.all([
          this.getStartTime(guid),
          refetchSources({
            apolloClient: this.apolloClient,
            item
          }),
          getCustomFreewheelParameters({ playerOptions: this.playerOptions, metadata, guid, viewMode: this.viewMode })
        ]);
        item.starttime = startTime;
        item.freewheel = freewheelConfig;

        if (sources.length) item.sources = sources;

        if (!sources.length) {
          // refetch sources using POST
          item.sources = await refetchSources({
            apolloClient: this.apolloClient,
            item,
            uncached: true
          });
        }

        if (!item.sources.length) {
          noticeError('No valid sources', {
            media_id: item.metadata?.media_id!,
            error_code: PLAYER_ERRORS.NO_PLAYABLE_SOURCES_FOUND,
            error_type: PLAYER_ERRORS.ERROR
          });
          this.onSourcesError();
          return null;
        }
        return item;
      }
    );
  }

  setPlaylistItem(index: number): void {
    this.playerInstance?.playlistItem(index);
  }

  seek = (position: number): void => {
    this.playerInstance?.seek?.(position);
  };

  play = (): void => {
    this.playerInstance?.play?.();
  };

  stop = (): void => {
    this.playerInstance?.stop?.();
  };

  pause = (): void => {
    this.playerInstance?.pause?.();
  };
  getFloating = (): boolean | undefined => this.playerInstance?.getFloating?.();
  setMute = (shouldBeMuted: boolean): void => {
    this.playerInstance?.setMute?.(shouldBeMuted);
  };

  getPlaylistItem = (): VideoModel | null => {
    try {
      return this.playerInstance?.getPlaylistItem();
    } catch {
      return null;
    }
  };
  getPlaylistIndex = (): number | undefined => {
    try {
      if (this.playerInstance) return this.playerInstance?.getPlaylistIndex();
    } catch {
      return 0;
    }
  };

  getMute = (): boolean => {
    try {
      if (!this.playerInstance) return false;
      return this.playerInstance.getMute();
    } catch {
      return false;
    }
  };

  getState = (): string | undefined => {
    try {
      return this.playerInstance?.getState();
    } catch {
      return '';
    }
  };
  getGuid = (): string | null => {
    try {
      return this.getPlaylistItem()?.guid || null;
    } catch {
      return '';
    }
  };
  getCurrentTime = (): number | undefined => {
    try {
      return (this.playerInstance as any)?.getCurrentTime();
    } catch {
      return 0;
    }
  };
  getDuration = (): number | undefined => {
    try {
      return this.playerInstance?.getDuration();
    } catch {
      return 0;
    }
  };
  getContainer = (): HTMLElement | undefined | null => {
    try {
      return this.playerInstance?.getContainer();
    } catch {
      return null;
    }
  };

  onSetup = (): void => {
    if (!this.playerInstance) return;
    this.playerInstance.setup(this.playerOptions.config);

    JwMonitor.init({
      defaultLayer: {
        player_version: `${publicRuntimeConfig.jwplayer.version}`,
        player_channel: 'kijkweb',
        player_channelid: 'kijkweb',
        player_id: 'kijkweb',
        player_name: 'jwplayer'
      },
      events: {
        idle: () => false,
        playlist: () => false,
        remove: () => false
      }
    });

    this.setPlaylistItemCallback();
    this.playerInstance.on('ready', this.onReadyCallback);
    this.playerInstance.on('play', this.onPlayCallback);
    this.playerInstance.on('time', this.onTimeCallback);
    this.playerInstance.on('seeked', this.onSeekedCallback);
    this.playerInstance.on('pause', this.onPauseCallback);
    this.playerInstance.on('firstFrame', this.onFirstFrameCallback);
    this.playerInstance.on('complete', this.onCompleteCallback);
    this.playerInstance.on('playlistItem', this.onPlaylistItemCallback);
    this.playerInstance.on('remove', this.stopHeartBeatCallback);
    this.playerInstance.on('mute', this.onMuteCallback);
    this.playerInstance.on('adBreakStart' as any, this.onAdBreakStart);
    this.playerInstance.on('adBreakEnd' as keyof jwplayer.EventParams, this.onAdBreakEndCallback);
    this.playerInstance.on('adComplete' as any, this.onAdCompletedCallback);
    this.playerInstance.on('adError', this.onAdError);
    this.playerInstance.on('adPlay', this.onAdPlay);
    this.playerInstance.on('adPause', this.onAdPause);
    this.playerInstance.on('adStarted', this.onVpaidAdStart);
    this.playerInstance.on('setupError' as any, this.onSetupError);
    this.playerInstance.on('error' as any, this.onPlayerErrorCallback);
    this.playerInstance.on('warning' as any, this.onPlayerWarningCallback);
    this.playerInstance.on('float' as keyof jwplayer.EventParams, this.onFloating);
    this.playerInstance.on('cast', this.onCast);
  };

  onPlayerError = (error: IPlayerErrorEvent): void => {
    /**
     * Ignore non-blocking error and remove the error message overlay
     * The player will automatically re-create the overlay with future errors that are triggered
     * In the future if you want to display custom error messages you can use
     * error.code to identify what type of JWPlayer error it is or
     * error.sourceError to drill further down into the error details
     */
    if (error.sourceError?.message === `Cannot read property 'paused' of null`) {
      this.pause();
      this.stop();
      this.play();
    }
  };

  onVpaidAdStart = (): void => {
    // force vpaid ads to show player controls
    this.playerInstance?.getContainer().classList.remove('jw-flag-ads-vpaid');
  };

  onAdBreakStart = (event: { adposition: 'pre' | 'mid' | 'post' }): void => {
    if (event.adposition === 'post') document.querySelector('.next-up-card')?.remove();
    if (this.userInteracted) {
      this.playerInstance?.setMute?.(false);
    }
  };

  onAdError = (): void => {
    customInteraction('Failed Ad');
  };

  onAdPlay = (): void => {
    if (HeartBeat.state === 'idle') HeartBeat.start();
  };
  onAdPause = (): void => {
    HeartBeat.stop();
  };

  heartBeatCallback = (data: { currentHeartBeat: number; currentInterval: number }): any => {
    const FirstHeartBeatInterval = 180;
    const HeartBeatInterval = 300;

    this.onHeartBeatCallback();
    try {
      const { metadata } = this.playerInstance?.getPlaylistItem();

      const { currentHeartBeat } = data;
      // 3 min
      if (FirstHeartBeatInterval === currentHeartBeat) {
        return {
          ...metadata,
          event_label: 180 / 60,
          event_value: null
        };
      }
      // 5 * n min
      if (currentHeartBeat % HeartBeatInterval === 0) {
        customInteraction(`Milestone ${secondsToISODuration(currentHeartBeat)}`);
        return {
          ...metadata,
          event_label: currentHeartBeat / 60,
          event_value: null
        };
      }
      return false;
    } catch (error) {
      console.log(error);
      HeartBeat.stop();
    }

    return false;
  };

  // Set Callbacks
  setOnSetupError(callback: (event: IPlayerErrorEvent) => void): void {
    this.onSetupError = callback;
  }
  setOnHeartBeatCallback(callback: () => void): void {
    this.onHeartBeatCallback = callback;
  }
  setOnReadyCallback(callback: () => void): void {
    this.onReadyCallback = callback;
  }
  setOnPlayCallback(callback: () => void): void {
    this.onPlayCallback = callback;
  }
  setOnTimeCallback(callback: () => void): void {
    this.onTimeCallback = callback;
  }
  setOnSeekedCallback(callback: () => void): void {
    this.onSeekedCallback = callback;
  }
  setOnPauseCallback(callback: () => void): void {
    this.onPauseCallback = callback;
  }
  setOnFirstFrameCallback(callback: () => void): void {
    this.onFirstFrameCallback = callback;
  }
  setOnCompleteCallback(callback: () => void): void {
    this.onCompleteCallback = callback;
  }
  setOnPlaylistItemCallback(callback: (event: any) => void): void {
    this.onPlaylistItemCallback = callback;
  }
  setStopHeartBeatCallback(callback: () => void): void {
    this.stopHeartBeatCallback = callback;
  }
  setOnMuteCallback(callback: () => void): void {
    this.onMuteCallback = callback;
  }
  setOnAdPlayCallback(callback: () => void): void {
    this.onAdPlayCallback = callback;
  }
  setOnAdBreakEndCallback(callback: () => void): void {
    this.onAdBreakEndCallback = callback;
  }
  setOnAdCompletedCallback(callback: (event: IAdCompleteEvent) => void): void {
    this.onAdCompletedCallback = callback;
  }
  setOnPlayerErrorCallback(callback: (event: IPlayerErrorEvent) => void): void {
    this.onPlayerErrorCallback = callback;
  }
  setOnPlayerWarningCallback(callback: (event: IPlayerErrorEvent) => void): void {
    this.onPlayerWarningCallback = callback;
  }
  setOnCastCallback(callback: (event: any) => void): void {
    this.onCast = callback;
  }

  setOnSourcesErrorCallback(callback: () => void): void {
    this.onSourcesError = callback;
  }

  resetCallbacks = (): void => {
    if (this.isPlayerLoaded && typeof this.playerInstance?.remove === 'function') {
      try {
        this.isPlayerLoaded = false;
        this.playerInstance.remove();
        // tslint:disable-next-line:no-empty
      } catch {}
    }

    this.onSetupError = noop;
    this.onHeartBeatCallback = noop;
    this.onReadyCallback = noop;
    this.onPlayCallback = noop;
    this.onTimeCallback = noop;
    this.onSeekedCallback = noop;
    this.onPauseCallback = noop;
    this.onFirstFrameCallback = noop;
    this.onCompleteCallback = noop;
    this.onPlaylistItemCallback = noop;
    this.stopHeartBeatCallback = noop;
    this.onMuteCallback = noop;
    this.onAdPlayCallback = noop;
    this.onAdBreakEndCallback = noop;
    this.onAdCompletedCallback = noop;
    this.onPlayerErrorCallback = noop;
    this.onPlayerWarningCallback = noop;
    this.onFloating = noop;
    this.onCast = noop;
    this.onSourcesError = noop;
  };
}

const videoJWPlayer = new VideoJWPlayer();

export default videoJWPlayer;
