import styled, { css } from 'styled-components';
import Colors from '../../styles/colors';

export const SearchInputStyle = styled.input<{ isOpen: boolean }>`
  width: 100%;
  height: 40px;
  z-index: 1;
  background-color: transparent;
  color: ${Colors.white};
  padding-left: 48px;
  font-size: 18px;
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: 48px;
  }

  opacity: 0;
  transition: opacity 0.1s linear 0s;
  ${props =>
    props.isOpen &&
    css`
      transition-delay: 0.8s;
      opacity: 1;
    `};

  &::placeholder {
    color: ${Colors.white};
  }

  &::-ms-clear {
    display: none;
  }
`;
