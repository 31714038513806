import { FunctionComponent } from 'react';
import { AccountLoaderStyle, AccountLoaderDotStyle } from './AccountLoader.style';

const AccountLoader: FunctionComponent = () => {
  return (
    <AccountLoaderStyle data-testid="accountLoader">
      <AccountLoaderDotStyle />
      <AccountLoaderDotStyle />
      <AccountLoaderDotStyle />
    </AccountLoaderStyle>
  );
};

export default AccountLoader;
