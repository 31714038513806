import { IPlaceholderData } from 'typings/IPlaceholderData';
import { isEmpty } from 'lib/helpers';
import { PLACEHOLDER } from '../constants';

const REQUIRED_PLACEHOLDER_FIELDS = ['type'];

export default class PlaceholderModel {
  type: PLACEHOLDER;
  slug: string;
  title: string;

  isValid: boolean;
  isOnSearch?: boolean;

  constructor(data: IPlaceholderData, options: { isOnSearch?: boolean } = {}) {
    if (!data) return;
    this.isValid = REQUIRED_PLACEHOLDER_FIELDS.every(field => !isEmpty(data, field));
    this.type = data.type;
    this.slug = data.slug || '';
    this.title = data.title || '';
    this.isOnSearch = options.isOnSearch;
  }
}
