import { getStaticAsset } from 'lib/helpers/resources';
import { Grid, GridElement } from '../../styles/grid';
import {
  FooterStyle,
  FooterHeadingStyle,
  FooterSpacingStyle,
  FooterBannerStyle,
  FooterAppStoreWrapperStyle,
  FooterAppStoreStyle,
  FooterPlayStoreStyle,
  FooterBannerLeftStyle,
  FooterCopyStyle,
  FooterMidRowStyle
} from './Footer.style';
import { FooterLink } from './FooterLink';
import { Heading3 } from '../Text/Text.style';
import Logo from '../Logo';
import Link from '../Link';
import { IMAGE_MEDIA_TYPE, TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME, FOOTER_LINKS } from '../../constants';
import ImageMedia from '../ImageMedia';

const Year: number = new Date().getFullYear();

const Footer: React.FunctionComponent<{}> = () => (
  <FooterStyle data-testid="footer">
    <Grid gridMdRowGap="54px" gridSmRowGap="15px">
      <GridElement data-testid="sectionServiceAndContact" lgColumn="1 / 4" mdColumn="1 / 7" smColumn="1 / 7">
        <FooterHeadingStyle data-testid="footerServiceContact">
          <Heading3 data-testid="footerServiceContactTitle">Service & contact</Heading3>
        </FooterHeadingStyle>
        <FooterLink target="_blank" href="https://kijkers.tv/aanmelden">
          {FOOTER_LINKS.AANMELDEN_VOOR_PROGRAMMA}
        </FooterLink>
        <FooterLink target="_blank" href="https://klantenservice.kijk.nl">
          {FOOTER_LINKS.VEELGESTELDE_VRAGEN}
        </FooterLink>
        <FooterLink target="_blank" href="https://kijkers.tv/acties">
          {FOOTER_LINKS.ACTIES}
        </FooterLink>
        <FooterSpacingStyle />
      </GridElement>

      <GridElement data-testid="sectionOverKijk" lgColumn="4 / 7" mdColumn="7 / 13" smColumn="1 / 7">
        <FooterHeadingStyle data-testid="footerOver">
          <Heading3 data-testid="overKijkFooterTitle">Over KIJK</Heading3>
        </FooterHeadingStyle>
        <FooterLink path="/over">{FOOTER_LINKS.OVER_KIJK}</FooterLink>
        <FooterLink target="_blank" href="https://talpanetwork.com/media-solutions">
          {FOOTER_LINKS.ADVERTEREN}
        </FooterLink>
        <FooterLink target="_blank" href="https://talpa-network.homerun.co/">
          {FOOTER_LINKS.VACATURES}
        </FooterLink>
        <FooterSpacingStyle />
        <FooterLink target="_blank" href="https://privacy.talpanetwork.com/nl/privacy/">
          {FOOTER_LINKS.PRIVACY_POLICY}
        </FooterLink>
        <FooterLink target="_blank" href="https://privacy.talpanetwork.com/nl/cookies/">
          {FOOTER_LINKS.COOKIE_POLICY}
        </FooterLink>
        <FooterLink
          onClick={() => {
            if (window.OneTrustEnabled) {
              return window.OneTrust?.ToggleInfoDisplay();
            }
            window.__tcfapi?.('showConsentManager');
          }}
        >
          {FOOTER_LINKS.COOKIE_INSTELLINGEN}
        </FooterLink>
        <FooterLink target="_blank" href="https://privacy.talpanetwork.com/nl/voorwaarden/">
          {FOOTER_LINKS.GEBRUIKSVOORWAARDEN}
        </FooterLink>
      </GridElement>

      <GridElement data-testid="sectionDownloadAndSocialMedia" lgColumn="7 / 10" mdColumn="1 / 7" smColumn="1 / 7">
        <FooterHeadingStyle data-testid="footerSocialMedia">
          <Heading3 data-testid="socialMediaFooterTitle">Volg KIJK</Heading3>
        </FooterHeadingStyle>
        <FooterLink href="https://www.facebook.com/kijk.nl/" target="_blank">
          {FOOTER_LINKS.FACEBOOK}
        </FooterLink>
        <FooterLink href="https://www.instagram.com/kijknl/" target="_blank">
          {FOOTER_LINKS.INSTAGRAM}
        </FooterLink>
        <FooterHeadingStyle data-testid="footerDownload">
          <FooterMidRowStyle>
            <Heading3 data-testid="downloadFooterTitle">Download de KIJK app</Heading3>
          </FooterMidRowStyle>
        </FooterHeadingStyle>
        <FooterAppStoreWrapperStyle data-testid="socialMediaLink">
          <FooterAppStoreStyle data-testid="appStoreImage">
            <Link
              data-testid="appStoreLink"
              href="https://itunes.apple.com/nl/app/kijk/id877572689?mt=8"
              target="_blank"
              clickEventValues={{
                name: TEALIUM_EVENT_NAME.FOOTER_CLICK,
                category: TEALIUM_EVENT_CATEGORY.FOOTER,
                label: FOOTER_LINKS.APP_STORE
              }}
            >
              <ImageMedia
                publicId={getStaticAsset('/static/images/badge-appstore.png')}
                title="App Store"
                imageMedia={{ type: IMAGE_MEDIA_TYPE.REMOTE }}
                shouldLazyLoad={false}
                transformations={{
                  height: '40',
                  crop: 'fill',
                  fetch_format: 'auto'
                }}
              />
            </Link>
          </FooterAppStoreStyle>
          <FooterPlayStoreStyle data-testid="playStoreImage">
            <Link
              href="https://play.google.com/store/apps/details?id=nl.sbs.kijk"
              target="_blank"
              clickEventValues={{
                name: TEALIUM_EVENT_NAME.FOOTER_CLICK,
                category: TEALIUM_EVENT_CATEGORY.FOOTER,
                label: FOOTER_LINKS.GOOGLE_STORE
              }}
            >
              <ImageMedia
                publicId={getStaticAsset('/static/images/badge-playstore.png')}
                title="Play Store"
                imageMedia={{ type: IMAGE_MEDIA_TYPE.REMOTE }}
                shouldLazyLoad={false}
                transformations={{
                  height: '40',
                  crop: 'fill',
                  fetch_format: 'auto'
                }}
              />
            </Link>
          </FooterPlayStoreStyle>
        </FooterAppStoreWrapperStyle>
      </GridElement>

      <GridElement data-testid="sectionNewsletter" lgColumn="10 / 13" mdColumn="7 / 13" smColumn="1 / 7">
        {/* <FooterHeadingStyle data-testid="footerNewsletter">
          <Heading3 data-testid="footerNewsletterTittle">Nieuwsbrief</Heading3>
        </FooterHeadingStyle>
        <FooterTextStyle>
          <Heading4 data-testid="footerNewsletterText">Altijd op de hoogte wat er te zien is!</Heading4>
        </FooterTextStyle>
        <Link
          path={`/?account-action=${GIGYA_ACCOUNT_ACTION.OPTIN}`}
          clickEventValues={{
            name: TEALIUM_EVENT_NAME.FOOTER_CLICK,
            category: TEALIUM_EVENT_CATEGORY.FOOTER,
            label: FOOTER_LINKS.AANMELDEN
          }}
        >
          <FooterBtnStyle data-testid="footerNewsletterButton">{FOOTER_LINKS.AANMELDEN}</FooterBtnStyle>
        </Link> */}
      </GridElement>
    </Grid>

    <FooterBannerStyle>
      <Grid>
        <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
          <FooterBannerLeftStyle data-testid="footerBannerLeftStyle">
            <Logo smallHeight="42px" mediumHeight="56px" largeHeight="56px" />
            <FooterCopyStyle data-testid="footerCopyStyle">
              &copy; {Year} Talpa Network B.V. Alle rechten voorbehouden.
            </FooterCopyStyle>
          </FooterBannerLeftStyle>
        </GridElement>
      </Grid>
    </FooterBannerStyle>
  </FooterStyle>
);

export default Footer;
