import Tealium from '@4tn/talpatv-video-analytics';
import { debounce } from 'throttle-debounce';
import { setDataLayer } from 'lib/tealium';
import { FIXED_COUNT } from '../../client/constants';

const handleScroll = () => {
  const pageHeight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight
  );
  const { scroll_percentage: previousScrollPercentage } = Tealium.defaultLayer;
  const lastMilestone = typeof previousScrollPercentage === 'string' ? parseInt(previousScrollPercentage) : 0;

  const scrollPercent = (window.scrollY / (pageHeight - window.innerHeight)) * 100;
  const currentMilestone = Math.floor(scrollPercent / 20) * 20;

  if (!currentMilestone || currentMilestone <= lastMilestone) return;

  setDataLayer({ scroll_percentage: `${currentMilestone}%` });
};

export const debouncedHandleScroll = debounce(FIXED_COUNT.DEBOUNCE_TIME, handleScroll);
