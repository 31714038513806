import styled from 'styled-components';
import { CarouselItemsStyle } from 'components/Lists/Carousel/Carousel.style';
import { Heading2 } from '../Text/Text.style';
import { TileListStyle } from '../Lists/TileList/TileList.style';
import { ClickableTitleStyle } from '../Home/Home.style';

export const FormatAToZStyle = styled.div`
  flex: 1;
  padding-top: 80px;
`;

export const FormatAToZPickerStyle = styled.div<{ isHeaderHidden: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  transition: transform ${props => props.theme.transitions.easeIn};
  transform: ${props => (props.isHeaderHidden ? 'none' : 'translateY(56px)')};
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    transform: ${props => (props.isHeaderHidden ? 'none' : 'translateY(70px)')};
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    transform: ${props => (props.isHeaderHidden ? 'none' : 'translateY(80px)')};
  }
`;

export const FormatsSectionStyle = styled.div`
  grid-column: 1 / 13;
  margin-top: 36px;

  &:last-child {
    margin-bottom: 83px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 46px;

    &:last-child {
      margin-bottom: 120px;
    }
  }

  ${TileListStyle} {
    grid-row-gap: 32px;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      grid-row-gap: 45px;
    }
  }

  ${CarouselItemsStyle} {
    & > *:first-child {
      margin-left: 0;
    }
  }

  ${ClickableTitleStyle} {
    cursor: pointer;
  }
`;

export const FormatsSectionTitleStyle = styled(Heading2)`
  margin-bottom: 14px;
  text-transform: capitalize;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-bottom: 22px;
  }
`;
