import { createGlobalStyle } from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';
import { HeaderWrapperStyle } from 'components/Header/Header.style';
import { FormatAToZPickerStyle } from 'components/FormatAToZ/FormatAToZ.style';
import { MissedPickerStyle } from 'components/Missed/Missed.style';
import { MainContentStyle } from 'components/Main/Main.style';
import Colors from './colors';
import { ICONS } from '../constants';

const GlobalStyle = createGlobalStyle<{ isHeaderTransparent?: boolean }>`
  @font-face {
    font-family: 'Cera';
    src: url('https://static.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.eot');
    src: url('https://static.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('https://static.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.woff') format('woff'),
      url('https://static.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.woff2') format('woff2'),
      url('https://static.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Cera';
    src: url('https://static.kijk.nl/fonts/cera_pro_bold/CeraPro-Bold.eot');
    src: url('https://static.kijk.nl/fonts/cera_pro_bold/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('https://static.kijk.nl/fonts/cera_pro_bold/CeraPro-Bold.woff') format('woff'),
      url('https://static.kijk.nl/fonts/cera_pro_bold/CeraPro-Bold.woff2') format('woff2'),
      url('https://static.kijk.nl/fonts/cera_pro_bold/CeraPro-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, block  quote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, input, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html {
    box-sizing: border-box;
    margin: 0;
    font-family: 'Cera', sans-serif;
    font-size: 1.5rem;
    line-height: 2;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  :focus {
    outline: 0;
  }

  ::selection {
    background-color: ${Colors.greenishTeal};
    color: ${Colors.white};
  }

  ::-moz-selection {
    background-color: ${Colors.greenishTeal};
    color: ${Colors.white};
  }

  body {
    padding: 0;
    margin: 0;
    overflow: auto !important;
  }

  body:after {
    content: "";
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: -1; 
    background-image: ${props =>
      linearGradient(props.theme.gradients.main.small.direction, props.theme.gradients.main.small.stops)};
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      background-image: ${props =>
        linearGradient(props.theme.gradients.main.medium.direction, props.theme.gradients.main.medium.stops)};
    }

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      background-image: ${props =>
        linearGradient(props.theme.gradients.main.large.direction, props.theme.gradients.main.large.stops)};
    }
  }

  body.stop-scrolling {
    position: relative;
  }

  a, a:hover, a:focus, a:active, a:visited {
    text-decoration: none;
    color: inherit;
  }

  .talpa-meer__popup {
    z-index: 1000000000 !important;
  }

  /* Fix for KIJK-2195 */
  .talpa-meer__single-sign-on.popupclosed {
    display: none;
  }

  .modal-close-button {
    top: 14px;
    right: 8px;
    width: 36px;
    height: 36px;
    background-image: url(${ICONS.CLOSE});
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;

    @media (min-width: 641px) {
      right: 24px;
    }

    svg {
      display: none;
    }
  }
  
  .gigya-modal-close-button {
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    background-image: url(${ICONS.CLOSE});
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    
    svg {
      display: none;
    }
  }
  
  .gigya-ios.gigya-screen-dialog {
    right: 10px;
  }
  
  #gdpr-toggle {
    display: none !important;
    top: unset !important;
    left: unset !important;
    bottom: 60px !important;
    right: 10px !important;
    
    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      bottom: 10px !important;
    }
  }

  body.geo-banner {
    ${HeaderWrapperStyle}, ${FormatAToZPickerStyle}, ${MissedPickerStyle} {
      top: 50px !important;
    }

    ${MainContentStyle}{
      @media (max-width: ${props => props.theme.breakpoints.smMax}) {
        margin-top: 106px;
      }

      @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
        margin-top: ${props => (props.isHeaderTransparent ? '0' : '120px')};
      }

      @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
        margin-top: ${props => (props.isHeaderTransparent ? '0' : '130px')};
      }  
    }
  }

  #ot-sdk-btn-floating {
    display: none !important;
  }
`;

export default GlobalStyle;
