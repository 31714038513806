import React from 'react';
import SVG from 'react-inlinesvg';
import { useQuery } from '@apollo/client';
import ProgramsByProgramTypeQuery from 'queries/programs/programsByProgramTypeQuery.query';
import MovieModel from 'models/movie.program.model';
import { SkeletonPortraitItemCarousel } from 'components/SkeletonElement/SkeletonElement';
import ErrorMessage from 'components/ErrorMessage';
import Link from '../../Link';
import {
  PROGRAM,
  PROGRAM_SORT_KEY,
  FIXED_COUNT,
  ICONS,
  SWIMLANE_TITLE,
  TEALIUM_EVENT_NAME,
  TEALIUM_EVENT_CATEGORY
} from '../../../constants';
import ProgramList from '../ProgramListSection/ProgramList';
import { ClickableTitleStyle, MovieSectionWrap } from '../Home.style';
import { Heading2 } from '../../Text/Text.style';
import { Grid, GridElement } from '../../../styles/grid';

const MoviesSection: React.FC<{ isOnSearch?: boolean }> = ({ isOnSearch }) => {
  const [movies, setMovies] = React.useState<MovieModel[]>([]);
  const { loading, error } = useQuery<{ programs: { items: IProgramGraphql[] } }>(ProgramsByProgramTypeQuery, {
    variables: {
      programTypes: PROGRAM.MOVIE,
      sort: PROGRAM_SORT_KEY.AIREDDATETIME,
      limit: FIXED_COUNT.MOVIES_LIMIT
    },
    onCompleted: data => {
      const items = data.programs.items.map(
        item => new MovieModel(item, { collectionTitle: SWIMLANE_TITLE.FILMS, isOnSearch })
      );

      items
        .sort((a, b) => +b.isAvailable - +a.isAvailable)
        .forEach((item, index) => {
          item.eventValues.value = index + 1;
        });

      setMovies(items);
    }
  });

  if (loading) {
    return <SkeletonPortraitItemCarousel noOfItems={FIXED_COUNT.SKELETON_PROGRAM_NUMBER} />;
  }

  if (error) {
    return <ErrorMessage />;
  }

  if (!movies.length) {
    return null;
  }

  return (
    <MovieSectionWrap data-testid="movieSection">
      <Grid>
        <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
          <ClickableTitleStyle data-testid="movieSectionLinkStyle">
            <Heading2 data-testid="movieSectionTitle">
              <Link
                path="/films"
                clickEventValues={{
                  name: TEALIUM_EVENT_NAME.CONTENT_LISTING_MORE,
                  category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
                  label: SWIMLANE_TITLE.FILMS
                }}
              >
                {SWIMLANE_TITLE.FILMS}
                <SVG src={ICONS.ARROW_SMALL_30} id="movieSectionTitleArrow" />
              </Link>
            </Heading2>
          </ClickableTitleStyle>
        </GridElement>
      </Grid>
      <ProgramList programs={movies} sectionType="movies" />
    </MovieSectionWrap>
  );
};

export default MoviesSection;
