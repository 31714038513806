import SVG from 'react-inlinesvg';
import React from 'react';
import Link from 'components/Link';
import { Heading2 } from 'components/Text/Text.style';
import { ClickableTitleStyle } from 'components/Home/Home.style';
import { ICONS } from '../../constants';

const LinkWrapper: React.FC<{
  children: React.ReactNode;
  showMoreUrl?: string;
}> = ({ showMoreUrl, children }) => {
  if (!showMoreUrl) return <>{children}</>;

  const linkProps = showMoreUrl.startsWith('/') ? { path: showMoreUrl } : { href: showMoreUrl, target: '_blank' };
  return <Link {...linkProps}>{children}</Link>;
};

const SwimlaneTitle: React.FC<{
  title: string;
  showMoreUrl?: string;
  onClickHandler?: () => void;
}> = ({ showMoreUrl, title, onClickHandler }) => {
  if (!showMoreUrl && !onClickHandler) return <Heading2>{title}</Heading2>;

  return (
    <ClickableTitleStyle>
      <LinkWrapper showMoreUrl={showMoreUrl}>
        <Heading2 onClick={onClickHandler}>
          <span>{title}</span>
          <SVG src={ICONS.ARROW_SMALL_30} />
        </Heading2>
      </LinkWrapper>
    </ClickableTitleStyle>
  );
};

export default SwimlaneTitle;
