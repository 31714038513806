import { ApolloClient } from '@apollo/client/core';
import { trackClick } from 'lib/tealium/provider';
import SeriesModel from 'models/series.model';
import MovieModel from 'models/movie.program.model';
import { toggleWatchlistProgram } from '../../client/queries/user/userActions';
import { TEALIUM_EVENT_NAME } from '../../client/constants/tealium.constants';

export const handleWatchlistClick = async ({
  isInWatchlist,
  programGuid,
  apollo,
  clickEventValues
}: {
  isInWatchlist: boolean;
  programGuid: string;
  apollo: ApolloClient<object>;
  clickEventValues?: ITealiumClickEvent;
}): Promise<void> => {
  try {
    await toggleWatchlistProgram({ apollo, guid: programGuid, isAdded: isInWatchlist });

    if (clickEventValues) {
      trackClick({
        ...clickEventValues,
        name: isInWatchlist ? TEALIUM_EVENT_NAME.REMOVE_FROM_FAVORTIE : TEALIUM_EVENT_NAME.ADD_TO_FAVORITE
      });
    }
  } catch (error) {}
};

export const sortAlphabetically = ({ slug: slugA }: { slug: string }, { slug: slugB }: { slug: string }) => {
  const slugAStartsWithNumber = /^[0-9]/.test(slugA[0]);
  const slugBStartsWithNumber = /^[0-9]/.test(slugB[0]);
  if (slugAStartsWithNumber && !slugBStartsWithNumber) return 1;
  if (!slugAStartsWithNumber && slugBStartsWithNumber) return -1;
  return slugA.localeCompare(slugB);
};

export const sortProgramsAlphabetically = (programs: (SeriesModel | MovieModel)[]) => {
  programs.sort(sortAlphabetically);
};
