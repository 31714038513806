import styled from 'styled-components';
import Colors from '../../styles/colors';
import { Heading1, BodyText } from '../Text/Text.style';
import { LabelStyle } from '../Label/Label.style';
import { HIGHLIGHTED_SECTION_TYPE } from '../../constants/highlightedSectionType';
import { sharedButtonStyles } from '../Button/Button.style';

interface HighlightedSectionWrapperProps {
  backgroundTransparent: boolean;
  mediaType: string;
  hasImage: boolean;
}

interface HighlightedSectionBodyProps {
  mediaType: string;
  hasImage: boolean;
}

export const HighlightedSectionWrapper = styled.div<HighlightedSectionWrapperProps>`
  max-width: 1760px;
  margin: auto 16px;
  margin-top: 12px;
  background-color: ${props => (props.backgroundTransparent ? 'transparent' : Colors.nero)};

  @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
    margin: auto 80px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    margin: auto;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 42px;
  }

  justify-content: center;
  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    display: flex;
    flex-direction: ${props => (props.mediaType === HIGHLIGHTED_SECTION_TYPE.MEDIA_LEFT ? 'row' : 'row-reverse')};
    justify-content: ${props =>
      props.hasImage
        ? props.mediaType === HIGHLIGHTED_SECTION_TYPE.MEDIA_LEFT
          ? 'flex-start'
          : 'flex-end'
        : 'center'};
  }
`;

export const HighlightedSectionBody = styled.div<HighlightedSectionBodyProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => (props.hasImage ? 'flex-start' : 'center')};
  padding: 16px 0;
  ${BodyText} ${Heading1} {
    text-align: center;
  }
  ${BodyText} {
    margin-bottom: 16px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    max-width: ${props => (props.hasImage ? '720px' : 'none')};
    width: 50%;
    margin-left: ${props => (props.mediaType === HIGHLIGHTED_SECTION_TYPE.MEDIA_LEFT ? '24px' : '0')};
    margin-right: ${props => (props.mediaType === HIGHLIGHTED_SECTION_TYPE.MEDIA_LEFT ? '0' : '24px')};
  }
`;

export const LabelHighlightedSection = styled(LabelStyle)`
  position: relative;
  margin: 8px 8px 12px 0;
  padding: 2px 8px;
  background-color: ${Colors.violet};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin: 16px 16px 16px 0;
  }
`;

export const HighlightedSectionImage = styled.div`
  max-width: 720px;
  min-width: 288px;

  img {
    border-radius: 4px;
    width: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: 50%;
  }
`;

export const HighlightedSectionLink = styled('a')`
  ${sharedButtonStyles};
  border: none;
  background-color: ${Colors.white};
  font-size: 16px;
  font-weight: 700;
  color: ${Colors.black};
  &:hover {
    color: ${Colors.brownGrey};
  }
`;
