const ErrorAttributesModel =
  (objs: any[]) =>
  (uuid: string): any =>
    objs.reduce((rv: IErrorAttributesModel, obj: IErrorAttributesModel): IErrorAttributesModel => {
      const result: { [key: string]: string } = (({
        guid: media_id,
        tvSeasonId: tv_season_id,
        type,
        title,
        c_section_id: section_id,
        c_section_index: section_index,
        c_section_item_index: section_item_index,
        event_category,
        event_label
      }) => ({
        media_id,
        tv_season_id,
        type,
        title,
        section_id,
        section_index,
        section_item_index,
        event_category,
        event_label
      }))(obj);
      const onlyTruthy: any = Object.keys(result).reduce(
        (rv, key) => (result[key] ? { ...rv, [key]: `${result[key]} (${uuid || 'no uuid'})` } : rv),
        {}
      );
      return {
        ...rv,
        ...onlyTruthy
      };
    }, {});

export default ErrorAttributesModel;
