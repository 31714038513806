import { FunctionComponent } from 'react';
import { ERROR_MESSAGES } from '../../constants';
import { Grid, GridElement } from '../../styles/grid';
import { Heading1 } from '../Text/Text.style';

const CommonFallback: FunctionComponent<ICommonFallbackProps> = props => (
  <Grid>
    <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
      {(props.title && <Heading1>{props.title}</Heading1>) || null}
      <Heading1 data-testid="boundaryErrorMessage">
        {(!props?.message?.match(/.+ error$/) && props?.message) || ERROR_MESSAGES.DEFAULT}
      </Heading1>
    </GridElement>
  </Grid>
);
export default CommonFallback;
