import { useEffect } from 'react';
import { signOut, useSession } from 'next-auth/react';
import type { Session } from 'next-auth';
import Tealium, { CallType } from '@4tn/talpatv-video-analytics';
import { noticeError, setCustomAttributes } from 'lib/newrelic';
import { ACCOUNT } from '../../../client/constants';
import { AccountContainerStyle } from './Account.style';
import AccountMenu from './AccountMenu/AccountMenu';

const Account: React.FC = () => {
  const { status, data: session, update } = useSession();

  useEffect(() => {
    if (status === 'loading') {
      setCustomAttributes({
        profile_loginstatus: status
      });
      return;
    }
    const { idToken, id, error } =
      (session as null | (Session & { idToken: string; id: string; error?: string })) || {};
    if (error) {
      noticeError('AuthError', { error_message: error });

      if (error === 'RetryRefreshToken') {
        // Try to refresh again
        // update();
        setTimeout(update, 2000);
      } else {
        signOut();
      }
      return;
    }
    const loginStatus = status === 'authenticated' ? 'logged in' : 'logged out';
    if (Tealium.defaultLayer.profile_gigyaid !== id) {
      const accountDataFields = {
        profile_gigyaid: id || null,
        profile_loginstatus: loginStatus
      };
      Tealium.setDataLayer(accountDataFields);
      Tealium.track(CallType.VIEW, accountDataFields);
    }

    setCustomAttributes({ loginStatus });

    if (idToken) {
      localStorage.setItem(ACCOUNT.AUTH_TOKEN, idToken);
      return;
    }
    localStorage.removeItem(ACCOUNT.AUTH_TOKEN);
  }, [session, status]);

  // Polling for updating session every 10 min
  useEffect(() => {
    if (!session) return () => {};
    const interval = setInterval(() => {
      const { expiresAt } = session as Session & { expiresAt: number };
      const tenMinutes = 10 * 60 * 1000;

      if (Date.now() >= expiresAt * 1000 - tenMinutes) {
        // If the token is about to expire within the next 10 minutes, call the update function
        update();
      }
    }, 1000 * 60 * 10);
    return () => clearInterval(interval);
  }, [session, update]);

  const isLoading = status === 'loading';

  return (
    <div data-testid="accountHeader">
      <AccountContainerStyle isLoading={isLoading}>
        <AccountMenu />
      </AccountContainerStyle>
    </div>
  );
};
export default Account;
