import styled from 'styled-components';

const DefaultProps = {
  borderRadius: '4px'
} as ILabelProps;

export const LabelStyle = styled.div<ILabelProps>`
  position: absolute;
  top: 0;
  left: 0;
  text-transform: uppercase;
  border-radius: ${props => props.borderRadius || DefaultProps.borderRadius};
`;
