import styled from 'styled-components';
import { PROGRAM } from '../../../constants';
import { ActionLabel, Heading3, Heading4, VideoItemSubtitleDate } from '../../Text/Text.style';
import { LabelStyle } from '../../Label/Label.style';
import Colors from '../../../styles/colors';

export const TextLabelStyle = styled(LabelStyle)<{ smLandscape?: boolean; isFormatPage?: boolean }>`
  display: ${props => (props.smLandscape && !props.isFormatPage ? 'none' : 'flex')};
  align-items: center;
  top: 8px;
  left: 8px;
  min-height: 16px;
  padding: 5px;
  background: ${Colors.violet};
  font-size: 11px;
  font-weight: bold;
  color: ${Colors.white};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    top: 16px;
    left: 16px;
    font-size: 12px;
    padding: 0 8px;
    min-height: 24px;
    display: flex;
  }
`;

export const DescriptionStyle = styled.div<{ smLandscape?: boolean; type?: string; showFormatTitle?: boolean }>`
  padding: ${props => (props.smLandscape ? '0 8px' : '11px 0 0 0')};
  overflow: hidden;
  flex: 1;

  &:last-child {
    margin-bottom: ${props => (props.smLandscape ? '0' : '25px')};
    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      margin-bottom: 25px;
    }
  }

  ${Heading3} {
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    -webkit-line-clamp: ${({ showFormatTitle, type }) => (type === PROGRAM.EPISODE ? 2 : showFormatTitle ? 2 : 3)};
    -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
    font-weight: bold;
    overflow: hidden;
    overflow-wrap: break-word;
    @media (max-width: ${props => props.theme.breakpoints.smMax}) {
      font-size: ${props => props.smLandscape && '16px'};
    }
  }

  ${Heading4} {
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    -webkit-line-clamp: ${({ type }) => (type === PROGRAM.CLIP ? 3 : 2)};
    -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
    overflow: hidden;
    overflow-wrap: break-word;
    color: ${Colors.white};
    font-weight: normal;
    @media (max-width: ${props => props.theme.breakpoints.smMax}) {
      font-size: ${props => props.smLandscape && '14px'};
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      line-height: 22px;
    }
  }

  ${ActionLabel} {
    margin-top: 6px;
    color: ${Colors.brownGrey};
    text-transform: uppercase;
  }

  ${VideoItemSubtitleDate} {
    font-weight: bold;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    padding-top: 16px;
    padding-left: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
