import { gql } from '@apollo/client';
import { ImageMediaFragment, MediaFragment, SeriesTvSeasonsFragment, SourcesFragment } from './programs.fragments';

const ProgramsByGuidWithSeriesQuery: any = gql`
  query programs($guid: [String]) {
    programs(guid: $guid) {
      totalResults
      items {
        id
        guid
        availableRegion
        slug
        tvSeasonId
        type
        title
        description
        added
        publicationDateTime
        longDescription
        shortDescription
        displayGenre
        tvSeasonEpisodeNumber
        seasonNumber
        seriesId
        metadata
        duration
        series {
          id
          guid
          slug
          title
          ...SeriesTvSeasons
        }
        ...ImageMedia
        ...Media
        ...SeriesTvSeasons
        ...Sources
      }
    }
  }
  ${ImageMediaFragment}
  ${SeriesTvSeasonsFragment}
  ${MediaFragment}
  ${SourcesFragment}
`;

export default ProgramsByGuidWithSeriesQuery;
