import { ApolloClient, ApolloQueryResult } from '@apollo/client/core';
import { FETCH_POLICY } from '../../constants';
import {
  ContinueWatchingAddVideoMutation,
  ContinueWatchingByGuidQuery,
  VideosContinueWatchingMutation,
  ActiveVideoMutation
} from './continueWatchingQuery.query';

export const setPlayerPositionMutation = async (apollo: ApolloClient<object>) => {
  apollo.mutate({
    mutation: ContinueWatchingAddVideoMutation
  });
};

export const getContinueWatchingByGuidQuery = async <T>(
  apollo: ApolloClient<object>,
  id: string
): Promise<ApolloQueryResult<T>> =>
  apollo.query({
    query: ContinueWatchingByGuidQuery,
    variables: {
      id
    },
    fetchPolicy: FETCH_POLICY.NO_CACHE
  });

export const setVideosContinueWatchingMutation = async (
  apollo: ApolloClient<Object>,
  { videosContinueWatching }: { videosContinueWatching: IVideo[] }
) => {
  apollo.mutate({
    mutation: VideosContinueWatchingMutation,
    variables: {
      videosContinueWatching
    }
  });
};

export const setActiveVideoMutation = async (
  apollo: ApolloClient<object>,
  { guid, position, duration, completed }: IVideo
) => {
  apollo.mutate({
    mutation: ActiveVideoMutation,
    variables: {
      video: { guid, position, duration, completed }
    }
  });
};
