import styled from 'styled-components';
import { ICONS } from '../../constants';

export const SearchButtonStyle = styled.button`
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 20px;
  background-image: url(${ICONS.SEARCH});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border: 0;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (hover: hover) {
    &:hover {
      transform: scale(1.15);
      transition: transform ${props => props.theme.transitions.components.button.easeIn};
    }
  }
`;

export const SearchButtonPlaceholderStyle = styled.span`
  &:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;
