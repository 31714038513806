const zIndex: IZIndex = {
  component: {
    header: {
      container: 6000,
      overlay: 6000,
      content: 6000
    },
    EPGItem: {
      container: 3000,
      overlay: 3010,
      notification: 3020
    },
    videoHeading: {
      container: 5000
    },
    geoblockBanner: 6500,
    backdrop: 6400,
    searchModal: 7000,
    bottomNavigation: 999999999,
    referLink: 5100,
    toasterMessage: 9000
  }
};

export default zIndex;
