import styled from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';
import { LabelStyle } from '../../Label/Label.style';
import { BodyText, Subtext, VideoMetaDataTitle } from '../../Text/Text.style';
import { ButtonStyle } from '../../Button/Button.style';
import { BookmarkItemStyle } from '../../Bookmark/Bookmark.style';
import Colors from '../../../styles/colors';

export const VideoDescriptionBottomSeparatorStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  height: 2px;
  background: ${props => `
        ${linearGradient(
          props.theme.gradients.videoMetaData.collapseSeparator.format.direction,
          props.theme.gradients.videoMetaData.collapseSeparator.format.stops
        )}
      `};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    justify-content: center;
    background: ${props =>
      linearGradient(
        props.theme.gradients.videoMetaData.collapseSeparator.format.direction,
        props.theme.gradients.videoMetaData.collapseSeparator.format.stops
      )};
  }
`;

export const VideoMetaDataTitleStyle = styled(VideoMetaDataTitle)<{ isCollapsed: boolean }>`
  white-space: ${props => (props.isCollapsed ? '' : 'nowrap')};
  margin-top: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: calc(100% - 230px);
    font-size: 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    font-size: 24px;
    transition: none;
  }
`;

export const VideoMetaDataArrowStyle = styled.span<{ isSkeleton?: boolean }>`
  height: 32px;
  width: 32px;
  transform: rotate(-90deg);
  color: ${props => (!props.isSkeleton ? Colors.black : Colors.transparent)};
  background-color: ${props => (props.isSkeleton ? Colors.white25 : Colors.white)};
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  transition: color ${props => props.theme.transitions.easeIn};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (hover: hover) {
    &:hover {
      color: ${props => (!props.isSkeleton ? Colors.brownGrey : Colors.transparent)};
    }
  }
`;

export const VideoMetaDataSubtextStyle = styled(Subtext)`
  font-size: 14px;
  line-height: 24px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    font-size: 16px;
  }
`;

export const VideoMetaDataTimeStyle = styled(Subtext)`
  font-size: 11px;
  line-height: 20px;
  font-weight: normal;
  text-transform: uppercase;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    font-size: 12px;
  }
`;

export const VideoMetaDataLabelStyle = styled(LabelStyle)`
  position: relative;
  margin-right: 5px;
  padding: 4px 8px;
  background-color: ${Colors.mediumBlue};
`;

export const VideoMetaDataSubtextWrapperStyle = styled.div`
  display: flex;
`;

export const VideoMetaDataBookmarkWrapperStyle = styled.div`
  margin-top: 16px;

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    ${ButtonStyle} {
      width: 40px;
      margin-right: 16px;
    }

    ${BookmarkItemStyle} {
      width: 36px;
      height: 36px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;

    ${ButtonStyle} {
      margin-right: 8px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMax}) {
    margin-top: 16px;
  }
`;

export const VideoMetaDataDescriptionStyle = styled(BodyText)`
  margin-bottom: 32px;
  color: ${Colors.white};
  text-align: justify;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-bottom: 40px;
    line-height: 22px;
  }
`;

export const VideoDescriptionWrapperStyle = styled.div`
  position: relative;
  margin-top: 25px;
  overflow: hidden;
  transition: height ${props => props.theme.transitions.components.videoMetaData.easeIn};
`;
