import { PROGRAM } from '../../client/constants';

export const getProgramFullTitle = (program: IProgramGraphql): string => {
  switch (program.type) {
    case PROGRAM.MOVIE:
      return `${program.title} gemist? Bekijk hier de hele film - kijk.nl`;
    case PROGRAM.CLIP:
      return `${program.series?.title ? `${program.series.title}: ` : ''}${
        program.title
      } gemist? Bekijk hier het fragment - kijk.nl`;
    case PROGRAM.EPISODE:
      return `${program.series?.title ? `${program.series.title}: ` : ''}Seizoen ${program.seasonNumber} Aflevering ${
        program.tvSeasonEpisodeNumber
      }${program.title ? ` ${program.title}` : ''} gemist? Bekijk hier de hele uitzending - kijk.nl`;
    default:
      return '';
  }
};

export const getMediaNameFromProgram = (program: any) =>
  program.type === PROGRAM.EPISODE
    ? `${program.seriesTitle || program?.series?.title || program.title}, ${program.seasonNumber}, ${
        program.tvSeasonEpisodeNumber
      }`
    : program.title;
