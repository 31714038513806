import { availableDateFormat, isEmpty } from 'lib/helpers';
import ProgramModel from './program.model';

const REQUIRED_SERIES_FIELDS = ['guid', 'slug', 'id', 'sortTitle', 'type'];

export default class SeriesModel extends ProgramModel {
  isValid: boolean;
  sortTitle: string;
  seriesTvSeasons: ISeriesTvSeasonsGraphql[];
  path: string;
  labelText: string;
  seasonList: ISeriesSeasonAvailability[] = [];
  added: number | null;

  constructor(data: ISeriesGraphql, options: IPlaylistQueryParams | undefined = {}) {
    super(data, options);
    this.isValid = REQUIRED_SERIES_FIELDS.every(field => !isEmpty(data, field));

    this.sortTitle = data.sortTitle;
    this.seriesTvSeasons = data.seriesTvSeasons || [];
    this.path = `/programmas/${this.slug}`;
    this.added = data.added || null;

    const [media] = data.media || [];

    this.labelText = (!media?.availabilityState && availableDateFormat(media?.availableDate)) || '';

    if (data.seriesTvSeasons) {
      this.seasonList = data.seriesTvSeasons.map(({ id, seasonNumber, title, guid }) => ({
        value: id.split('/').pop() || '',
        label: title || `Seizoen ${seasonNumber}`,
        ariaLabel: `Seizoen ${seasonNumber}`,
        id,
        guid,
        title,
        seasonNumber
      }));
    }
  }
}
