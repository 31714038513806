import { FunctionComponent } from 'react';
import { Heading1 } from '../Text/Text.style';
import { Grid, GridElement } from '../../styles/grid';
import { ErrorMessageStyle } from './ErrorMessage.style';

const ErrorMessage: FunctionComponent<IErrorMessageProps> = props => (
  <ErrorMessageStyle data-testid="errorMessage" marginTop={props.marginTop}>
    <Grid>
      <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
        {props.title && <Heading1>{props.title}</Heading1>}
        <Heading1 data-testid="boundaryErrorMessage">
          Oeps, er ging iets fout. Kan je het later nog eens proberen?
        </Heading1>
      </GridElement>
    </Grid>
  </ErrorMessageStyle>
);

export default ErrorMessage;
