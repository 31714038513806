import styled from 'styled-components';
import { RemoveItemIconCloseStyle } from 'components/RemoveItemDialog/RemoveItemDialog.style';
import { LabelStyle } from '../Label/Label.style';
import { ImageMediaWrapperStyle } from '../ImageMedia/ImageMedia.style';
import Colors from '../../styles/colors';

export const PortraitItemStyle = styled.div<{ isRemoveDialogShown?: boolean }>`
  position: relative;
  width: 115px;

  ${RemoveItemIconCloseStyle} {
    display: ${props => (props.isRemoveDialogShown ? 'block' : 'none')};
  }

  &:hover {
    ${RemoveItemIconCloseStyle} {
      display: block;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: 162px;
  }
`;

export const ImageWrapperStyle = styled.div<{ isActive?: boolean }>`
  position: relative;
  margin-bottom: 8px;
  border: ${props => (props.isActive ? `2px solid ${Colors.greenishTeal}` : 'none')};
  border-radius: 4px;
  box-shadow: ${props => (props.isActive ? `0 0 4px 0 ${Colors.greenishTeal}` : '0')};
  transition: transform ${props => props.theme.transitions.easeOut};
  overflow: hidden;

  ${ImageMediaWrapperStyle} {
    border-radius: 4px;
    overflow: hidden;
  }

  @media (hover: hover) {
    ${PortraitItemStyle}:hover & {
      transform: translateY(-8px);
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-bottom: 14px;
  }
`;

export const LabelPortraitStyle = styled(LabelStyle)`
  margin: 8px 8px 0;
  padding: 2px 8px;
  background-color: ${Colors.violet};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin: 16px 16px 0;
  }
`;
