import { ROUTES } from '../constants';
import { NAVIGATION_ITEM_OVERVIEW } from '../constants/navigations';

export default class NavigationModel {
  items: INavListItem[];
  isValid: boolean = true;

  constructor(navigation: INavigation) {
    if (!navigation || !navigation.navigationItems.length) {
      this.isValid = false;
      return;
    }
    this.items = navigation.navigationItems.map(item => {
      const icon = item.icon?.[0].url || null;
      if (item.overview) {
        if (item.overview === NAVIGATION_ITEM_OVERVIEW.HOME) {
          return {
            ...item,
            icon,
            path: ROUTES.HOME
          };
        }
        return {
          ...item,
          icon,
          path: `/${item.overview}`
        };
      }
      if (item.link) {
        return {
          ...item,
          icon,
          path: item.link
        };
      }
      return { ...item, icon };
    });
  }
}
