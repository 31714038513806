import styled from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';
import { PreviewContainer } from 'components/VideoItem/VideoPreview/VideoPreview.style';
import { LogoStyle } from '../Logo/Logo.style';
import { ImageMediaInnerStyle, ImageMediaWrapperStyle } from '../ImageMedia/ImageMedia.style';
import { Heading3, ActionLabel } from '../Text/Text.style';

export const PromoImageStyle = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: 575px;
  }
`;

export const HeadingWithPromoListStyle = styled.div<{ isBrandPage?: boolean }>`
  position: relative;
  height: 75vh;
  padding-bottom: 6px;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  & > ${ImageMediaWrapperStyle}, ${PromoImageStyle} > ${ImageMediaWrapperStyle} {
    position: absolute;
    height: ${props => (!props.isBrandPage ? '271px' : '100%')};
    top: 0;
    ${props =>
      `mask-image:${linearGradient(
        props.theme.gradients.alphaLayerTransparent.bottomBorder.direction,
        props.theme.gradients.alphaLayerTransparent.bottomBorder.stops
      )};
      -webkit-mask-composite: source-out;`}

    img {
      object-fit: cover;
      height: inherit;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      height: ${props => (!props.isBrandPage ? '575px' : '100%')};
    }

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      ${props =>
        `mask-image:${linearGradient(
          props.theme.gradients.alphaLayerTransparent.leftBorder.direction,
          props.theme.gradients.alphaLayerTransparent.leftBorder.stops
        )},${linearGradient(
          props.theme.gradients.alphaLayerTransparent.bottomBorderInverse.direction,
          props.theme.gradients.alphaLayerTransparent.bottomBorderInverse.stops
        )};`}
    }
  }

  @media (max-height: ${props => props.theme.breakpoints.smMax}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) and (orientation: landscape) {
    height: 100vh;
  }

  ${({ isBrandPage, theme }) =>
    !isBrandPage &&
    `
    @media (max-width: ${theme.breakpoints.mdMin}) {
      height: 525px;
    }
    @media (min-width: ${theme.breakpoints.mdMin}) {
      height: 675px;
    }
    @media (min-width: ${theme.breakpoints.lgMin}) {
      height: 740px;
    }
    @media (min-width: ${theme.breakpoints.lgMid}) {
      height: 815px;
    }
    @media (min-width: ${theme.breakpoints.lgMax}) {
      height: 915px;
    }
  `}

  @media (max-width: ${props => props.theme.breakpoints.mdMax}) and (orientation: landscape) {
    min-height: 450px;

    ${LogoStyle} {
      display: none;
    }
  }
`;

export const HeadingWithPromoInnerWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  position: relative;
  z-index: 2;
`;

export const HeadingWithPromoLogoStyle = styled.div`
  position: relative;
  height: 78px;
  margin-bottom: 32px;

  ${ImageMediaWrapperStyle} {
    position: absolute;
    width: auto;
    height: 100%;

    img {
      object-fit: contain;
      width: auto;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: 139px;
  }
`;

export const HeadingPromoScrollContainerStyle = styled.div`
  max-width: 1760px;
  padding: 0 16px;
  margin-top: 24px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin: 0 24px;
    padding: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
    margin: 36px 80px 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    margin: 0 auto;
  }

  @media (max-width: ${props => props.theme.breakpoints.mdMid}) {
    position: relative;
    width: 100vw;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      width: 0;
      height: 0;
      background-color: transparent;
      appearance: none;
    }
  }
`;

export const HeadingWithPromoWrapperStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  width: 100%;
  padding: 8px 0;

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    grid-column-gap: 24px;
  }
`;

export const HeadingWithPromoItemStyle = styled.div`
  position: relative;
  min-width: 211px;
  overflow: hidden;
  display: flex;
  transition: transform ${props => props.theme.transitions.components.button.easeIn};

  img {
    border-radius: 4px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (hover: hover) {
    &:hover {
      transform: scale(1.04);
    }
  }

  ${ImageMediaWrapperStyle} {
    position: relative;
    height: 100%;
    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 4px;
        background: ${props =>
          linearGradient(
            props.theme.gradients.headingPromoList.promoItem.direction,
            props.theme.gradients.headingPromoList.promoItem.stops
          )};
      }

      @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
        height: 50%;
      }
    }
    ${ImageMediaInnerStyle} {
      display: flex;
    }
  }
`;

export const HeadingWithPromoItemLayout = styled.div`
  display: flex;
  flex-direction: column;
  & ${PreviewContainer} {
    border-radius: 4px;
  }
  & ${PreviewContainer}:hover {
    scale: 1;
  }
`;

export const HeadingWithPromoTitleStyle = styled(Heading3)`
  font-size: 18px;
  line-height: normal;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    font-size: 16px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    font-size: 18px;
  }
`;

export const HeadingWithPromoSubtitleStyle = styled(HeadingWithPromoTitleStyle)`
  font-weight: 500;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  overflow: hidden;
  overflow-wrap: break-word;
`;

export const HeadingWithPromoDateStyle = styled(ActionLabel)`
  margin: 4px 0 0;
  font-size: 11px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    font-size: 12px;
  }
`;

export const HeadingRecommendedStyle = styled(Heading3)`
  margin-bottom: 16px;
  font-size: 24px;

  @media (min-width: ${props => props.theme.breakpoints.smMax}) {
    font-size: 32px;
    font-weight: bold;
  }
`;

export const HeadingWithPromoItemContentStyle = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
  }
`;
