import styled from 'styled-components';
import { Heading2 } from '../Text/Text.style';
import { ButtonStyle } from '../Button/Button.style';
import { TileListStyle } from '../Lists/TileList/TileList.style';
import { LayoutStyle } from '../Layout/Layout.style';
import { ContinueWatchingItemWrapperStyle } from '../ContinueWatching/ContinueWatching.style';
import Colors from '../../styles/colors';

export const HomeStyle = styled.div`
  margin-bottom: 20px;

  ${ButtonStyle} {
    margin-top: 32px;
  }

  ${TileListStyle} {
    margin-bottom: 34px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-bottom: 100px;
    padding-top: 30px;

    ${TileListStyle} {
      margin-bottom: 55px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    margin-bottom: 110px;
  }

  @media (max-height: ${props => props.theme.breakpoints.smMax}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) and (orientation: landscape) {
    padding-top: 62px;
  }
`;

export const ClickableTitleStyle = styled.div`
  display: inline-block;
  text-align: right;
  font-size: 16px;
  color: ${Colors.white};

  ${Heading2} {
    display: inline-block;
    cursor: pointer;
  }

  svg {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    margin-bottom: -2px;
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;
  }

  :hover {
    svg {
      transform: translateX(6px);
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    svg {
      width: 30px;
      height: 30px;
      margin-bottom: -4px;
    }
  }
`;

export const HomeWrapperStyle = styled(LayoutStyle)`
  ${Heading2} {
    margin-bottom: 16px;
    margin-right: 3px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    padding-top: 0;
  }
`;

export const HomeBrandsSectionWrapperStyle = styled.div`
  margin-top: 12px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 42px;
  }
`;

export const HomeMissedSectionWrapperStyle = styled.div`
  margin-top: 12px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 42px;
  }
`;

export const HomeContinueWatchingSectionWrapperStyle = styled.div`
  margin-top: 12px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 42px;
  }
`;

export const HomeContinueWatchingCarouselStyle = styled.div`
  ${ContinueWatchingItemWrapperStyle} {
    width: 58vw;
    min-width: 58vw;

    @media (min-width: ${props => props.theme.breakpoints.smMid}) {
      width: 38vw;
      min-width: 38vw;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
      width: 302px;
      min-width: 302px;
    }
  }
`;

export const HomeTrendingSectionWrapperStyle = styled.div`
  margin-top: 12px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 42px;
  }
`;

export const DarkStyle = styled.div`
  padding-top: 40px;
  padding-bottom: 50px;
  background-color: ${Colors.black};
  ${Heading2} {
    margin-bottom: 32px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    padding-top: 65px;
  }
`;

export const SortContainerStyle = styled.div`
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: 171px;
  }
`;

export const WatchlistSectionWrapper = styled.div`
  margin-top: 12px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 42px;
  }
`;

export const MovieSectionWrap = styled.div`
  margin-top: 12px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 42px;
  }
`;

export const HomeOverviewStyle = styled.div`
  margin-bottom: 32px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-bottom: 50px;
  }
`;
