import { isEmpty } from 'lib/helpers/helpers';
import { PROGRAM } from '../constants';
import EditorialPromotion from './editorialPromotion.model';

const REQUIRED_OVERVIEW_PROMOTION_FIELDS = ['title', 'type', 'slug', 'promotions'];

export default class PromotionCollection {
  slug: string;
  title: string;
  type: 'singlePromotion' | 'headerPromotion';
  promotions: EditorialPromotion[] = [];
  isValid: boolean;
  recommendedTitle = 'Aanbevolen';

  constructor(data: IPromotionCollection) {
    if (!data) return;
    this.isValid = REQUIRED_OVERVIEW_PROMOTION_FIELDS.every(field => !isEmpty(data, field));
    this.slug = data.slug;
    this.title = data.title;
    this.type = data.type;
    if (data.promotions) {
      this.promotions = data.promotions
        .map(promotion => new EditorialPromotion(promotion))
        .filter(promotion => promotion.isValid);
    }

    if (
      this.type === 'headerPromotion' &&
      this.promotions.slice(1, 4).every(promotion => promotion.program.type === PROGRAM.CLIP)
    ) {
      this.recommendedTitle = 'Aanbevolen clips';
    }
  }
}
