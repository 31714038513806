import { FunctionComponent } from 'react';
import { IProgramListProps } from 'typings/IProgramListProps';
import CommonFallback from '../../CommonFallback';
import { FALLBACK_UI_LABELS } from '../../../constants';
import Carousel from '../../Lists/Carousel';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import PortraitItemFallback from '../../PortraitItem/PortraitItemFallback';
import PortraitItem from '../../PortraitItem';

const ProgramList: FunctionComponent<IProgramListProps> = ({ programs }) => (
  <ErrorBoundary onError={(_: any) => <CommonFallback message={FALLBACK_UI_LABELS.PROGRAM_LIST} />}>
    <Carousel data-testid="programList">
      {programs.map(program => (
        <ErrorBoundary
          onError={
            /* istanbul ignore next */
            (_: any) => <PortraitItemFallback />
          }
          key={program.guid}
        >
          <PortraitItem program={program} />
        </ErrorBoundary>
      ))}
    </Carousel>
  </ErrorBoundary>
);

export default ProgramList;
