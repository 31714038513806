const transitions: ITransitions = {
  easeIn: '0.15s ease-in',
  easeOut: '0.15s ease-out',
  components: {
    videoMetaData: {
      easeIn: '0.5s ease-in'
    },
    imageMedia: {
      easeIn: '0.5s ease-in'
    },
    button: {
      easeIn: '0.05s ease-in'
    }
  }
};

export default transitions;
