import { FunctionComponent } from 'react';
import { IPromoSectionProps } from 'typings/IPromoSectionProps';
import {
  ICONS,
  IMAGE_MEDIA_TYPE,
  TEALIUM_EVENT_NAME,
  TEALIUM_EVENT_CATEGORY,
  TEALIUM_EVENT_LABEL
} from '../../constants';
import { BodyText, Heading2 } from '../Text/Text.style';
import Link from '../Link';
import { TextLabelStyle } from '../VideoItem/VideoItemDescription/VideoItemDescription.style';
import ImageMedia from '../ImageMedia';
import PlayButton from '../PlayButton';
import Bookmark from '../Bookmark';
import {
  PromoSectionStyle,
  PromoSectionInnerStyle,
  PromoSectionImageStyle,
  PromoSectionDescriptionStyle,
  PromoSectionButtonsContainerStyle,
  PromoSectionContentStyle
} from './PromoSection.style';

const PromoSection: FunctionComponent<IPromoSectionProps> = ({ promotion }) => {
  if (!promotion?.isValid || !promotion.program) return null;

  const PromoFormatGuid = promotion.program?.series?.guid;

  const clickEventValues: ITealiumClickEvent = {
    name: TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK,
    category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
    label: TEALIUM_EVENT_LABEL.PROMO,
    defaultValues: promotion.program.metadata
  };

  return (
    <PromoSectionStyle data-testid="promoSection">
      <PromoSectionInnerStyle>
        <PromoSectionImageStyle data-testid="promoSectionImage">
          <Link path={promotion.path} clickEventValues={clickEventValues}>
            <ImageMedia
              testId="promoImageLink"
              publicId={promotion.program.imageLandscape.src}
              imageMedia={{ type: IMAGE_MEDIA_TYPE.REMOTE }}
              transformations={{
                width: '1024',
                crop: 'fill',
                gravity: 'faces:auto',
                fetch_format: 'auto'
              }}
            />
            {promotion.program?.media?.[0]?.availabilityState && <PlayButton />}
          </Link>
        </PromoSectionImageStyle>
        <PromoSectionDescriptionStyle>
          <PromoSectionContentStyle>
            {promotion.isLive && <TextLabelStyle data-testid="promo2Live">Live</TextLabelStyle>}
            <Heading2 data-testid="promoTitle">
              <Link path={promotion.path} clickEventValues={clickEventValues}>
                {promotion.title}
              </Link>
            </Heading2>
            {promotion.description && (
              <Link path={promotion.path} clickEventValues={clickEventValues}>
                <BodyText data-testid="promoDescription">{promotion.description}</BodyText>
              </Link>
            )}
            <PromoSectionButtonsContainerStyle>
              <Link path={promotion.path} clickEventValues={clickEventValues}>
                <img src={ICONS.PLAY_FILL} alt="Play button" />
                Kijk nu
              </Link>
              <Bookmark programGuid={PromoFormatGuid} clickEventValues={clickEventValues} />
            </PromoSectionButtonsContainerStyle>
          </PromoSectionContentStyle>
        </PromoSectionDescriptionStyle>
      </PromoSectionInnerStyle>
    </PromoSectionStyle>
  );
};

export default PromoSection;
