import styled from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';
import { ImageMediaInnerStyle, ImageMediaWrapperStyle } from '../ImageMedia/ImageMedia.style';
import { CarouselItemsStyle, NextArrowStyle, PrevArrowStyle } from '../Lists/Carousel/Carousel.style';
import { Heading1 } from '../Text/Text.style';

export const BrandedEditorialProgramsStyle = styled.div<{ backgroundColor?: string }>`
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: row;
  margin-top: 74px;

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    margin-top: 90px;
  }

  ${CarouselItemsStyle} {
    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      & > *:first-child {
        margin-left: 0;
      }
    }
  }

  ${PrevArrowStyle} {
    left: -60px;
    background: transparent;
  }

  ${NextArrowStyle} {
    left: auto;
    right: 0;
    background: ${props =>
        linearGradient('to left', [
          { color: props.backgroundColor ? props.backgroundColor : 'transparent', position: '0%' },
          { color: 'transparent', position: '100%' }
        ])}
      100% 100%;
  }
`;

export const HeroImageWrapperStyle = styled.div`
  display: none;
  position: relative;

  img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: auto;
    background-color: transparent;
    height: calc(100% + 40px);
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) {
    display: block;
    width: 201px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    display: block;
    width: 209px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
    width: 266px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    width: 249px;
  }
`;

export const BrandedEditorialProgramsContentWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 0;
  z-index: 99;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
      props.theme.breakpoints.mdMid}) {
    margin-left: 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    padding: 30px 0;
    width: calc(100% - 229px - 24px - 16px);
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: calc(100% - 238px - 80px - 24px);
  }
`;

export const BrandedEditorialProgramsTitleLogoWrapperStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 10px 0;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
`;

export const TitleWrapperStyle = styled.div`
  max-width: 1670px;
  width: 100%;
  padding-left: 16px;
  overflow-wrap: break-word;
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    padding-left: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: 85%;
  }

  ${Heading1} {
    margin: 10px 0;
  }
`;

export const LogoWrapperStyle = styled.div`
  width: 25%;

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: 15%;
  }

  ${ImageMediaWrapperStyle} {
    height: 100%;
    background-color: transparent;

    ${ImageMediaInnerStyle} {
      width: auto;
      left: auto;
      right: 16px;

      img {
        width: auto;
        height: auto;
        max-height: 50px;
        vertical-align: middle;

        @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
          max-height: 60px;
        }
      }

      @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
        right: 24px;
      }
    }
  }
`;
