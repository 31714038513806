import React, { FunctionComponent, ReactElement } from 'react';
import {
  RemoveItemDialogBoxButtonsStyle,
  RemoveItemDialogBoxInnerStyle,
  RemoveItemDialogBoxStyle,
  RemoveItemDialogButtonsContainer
} from 'components/RemoveItemDialog/RemoveItemDialog.style';
import { DialogButon, Subtext } from 'components/Text/Text.style';
import RemoveItemCloseButton from './RemoveItemCloseButton';

const RemoveItemDialog: FunctionComponent<IRemoveItemDialogProps> = ({
  toggleDialog,
  removeItem,
  isWatchlistPage
}): ReactElement => (
  <RemoveItemDialogBoxStyle data-testid="removeItemDialog" isWatchlistPage={isWatchlistPage}>
    <RemoveItemCloseButton toggleDialog={toggleDialog} />
    <RemoveItemDialogBoxInnerStyle>
      <div>
        <Subtext data-testid="closeDialogText" isPrimary>
          Verwijderen uit {isWatchlistPage ? '‘Favorieten’' : '‘verder kijken’'}
        </Subtext>
        <RemoveItemDialogButtonsContainer>
          <RemoveItemDialogBoxButtonsStyle
            data-testid="removeItemYesButton"
            onClick={() => {
              toggleDialog();
              removeItem();
            }}
          >
            <DialogButon>Ja</DialogButon>
          </RemoveItemDialogBoxButtonsStyle>
          <RemoveItemDialogBoxButtonsStyle data-testid="removeItemNoButton" onClick={toggleDialog}>
            <DialogButon>Nee</DialogButon>
          </RemoveItemDialogBoxButtonsStyle>
        </RemoveItemDialogButtonsContainer>
      </div>
    </RemoveItemDialogBoxInnerStyle>
  </RemoveItemDialogBoxStyle>
);
export default RemoveItemDialog;
