import { ApolloClient, InMemoryCache } from '@apollo/client';
import ProgramsByGuidQuery from 'queries/programs/programsByGuidQuery.query';
import EditorialOverviewQuery from 'queries/overview/overview.query';
import Series from 'queries/series/series.query';
import OverviewModel from 'models/overview.model';
import OverviewItemModel from 'models/overviewItem.model';
import EpisodeModel from 'models/episode.program.model';
import ClipModel from 'models/clip.program.model';
import VideoModel from 'models/video.model';
import SeriesModel from 'models/series.model';
import getVideosBySeriesId from 'lib/helpers/getVideosBySeriesId';
import { handle301, handle404 } from 'lib/pageHelpers';
import GET_SERIES_BY_SLUG from 'queries/programs/GetSeriesBySlug.query';
import { CONFIG_TYPES, PROGRAM, PROMO_QUERY_CLIENT } from '../../../src/client/constants';

// eslint-disable-next-line import/prefer-default-export
export const fetchFormatPageData = async (
  client: ApolloClient<InMemoryCache>,
  router: { query: any; push?: (_path: string) => void },
  isServer: boolean
): Promise<{
  video?: VideoModel;
  format?: SeriesModel;
  editorialOverview?: OverviewModel;
  initialTab?: string;
  overview?: { clips: ClipModel[]; episodes: EpisodeModel[] };
  error?: string;
  redirectUrl?: string;
  statusCode?: number;
  message404?: string;
}> => {
  const { query, push } = router;
  const formatData: {
    initialTab?: string;
    video?: VideoModel;
    format?: SeriesModel;
    overview?: { clips: ClipModel[]; episodes: EpisodeModel[] };
    editorialOverview?: OverviewModel;
  } = {};
  let format: ISeriesGraphql;

  if (query.videoId) {
    let ProgramsResponse;
    try {
      ProgramsResponse = await client.query<{ programs: { items: IProgramGraphql[] } }>({
        query: ProgramsByGuidQuery,
        variables: {
          guid: query.videoId
        }
      });
    } catch {
      return {
        error: 'Error while fetching API'
      };
    }
    const QueryResponse = ProgramsResponse.data.programs.items;
    if (!QueryResponse || QueryResponse.length === 0 || !QueryResponse[0]) {
      if (isServer) {
        const { message404, statusCode } = handle404(`Video doesn't exist`);
        return { message404, statusCode };
      }
      return {
        error: `Video doesn't exist`
      };
    }

    const Video = new VideoModel(QueryResponse[0]);
    if (Video.type === PROGRAM.SERIES) {
      const path = `/programmas/${Video.slug}`;
      if (isServer) {
        const { redirectUrl, statusCode } = handle301(path);
        return { redirectUrl, statusCode };
      }
      push && push(path);
      return {};
    }
    if (Video.series && Video.series.slug !== query.formatSlug) {
      const path = `/programmas/${Video.series.slug}/${query.videoId}`;
      if (isServer) {
        const { redirectUrl, statusCode } = handle301(path);
        return { redirectUrl, statusCode };
      }
      push && push(path);
      return {};
    }
    if (!query?.afspeellijst) formatData.initialTab = Video.type;
    formatData.video = Video;
  }

  try {
    const { data } = await client.query<{ overview: IEditorialOverviewData }>({
      query: EditorialOverviewQuery,
      variables: {
        slug: query.formatSlug,
        client: PROMO_QUERY_CLIENT.DESKTOP
      }
    });
    if (data.overview) {
      formatData.editorialOverview = new OverviewModel(data.overview, { isOnFormatPage: true });
      const { hideEpisodeTab, hideClipTab } = formatData.editorialOverview.config || {};

      if (
        (hideEpisodeTab && formatData.initialTab === PROGRAM.EPISODE) ||
        (hideClipTab && formatData.initialTab === PROGRAM.CLIP)
      ) {
        formatData.initialTab = undefined;
      }
    }
  } catch (ignore) {}

  try {
    const { data } = await client.query<{ programs: { items: ISeriesGraphql[] } }>({
      query: GET_SERIES_BY_SLUG,
      variables: {
        slugs: [query.formatSlug]
      }
    });
    format = data.programs.items?.[0];

    if (
      !format &&
      formatData.editorialOverview?.type === CONFIG_TYPES.FORMAT &&
      formatData.editorialOverview.slug === query.formatSlug
    ) {
      const { data } = await client.query<{ series: { items: ISeriesGraphql[] } }>({
        query: Series,
        variables: {
          slug: query.formatSlug
        }
      });
      format = data.series.items?.[0];
    }
  } catch {
    return {
      error: 'Error while fetching API'
    };
  }

  if (!format?.guid) {
    if (isServer) {
      const { message404, statusCode } = handle404(`Format doesn't exist`);
      return { message404, statusCode };
    }
    return {
      error: `Format doesn't exist`
    };
  }

  const series = new SeriesModel(format);
  formatData.format = series;
  formatData.overview = await getVideosBySeriesId(series.id, client);

  if (query.tab) {
    if (
      !formatData.editorialOverview ||
      !formatData.editorialOverview.items.some(
        item => item.slug === query.tab && (item as OverviewItemModel).onEditorialTabs
      )
    ) {
      const path = `/programmas/${query.formatSlug}${query.videoId ? `/${query.videoId}` : ''}`;
      if (isServer) {
        const { redirectUrl, statusCode } = handle301(path);
        return { redirectUrl, statusCode };
      }
      push && push(path);
      return {};
    }
    formatData.initialTab = query.tab;
  }

  return formatData;
};
