import { gql } from '@apollo/client';

export const HeaderStateQuery = gql`
  query headerState {
    headerState @client {
      isTransparent
    }
  }
`;

export const ToggleHeaderStateMutation = gql`
  mutation toggleHeaderState($isTransparent: Boolean!) {
    toggleHeaderState(isTransparent: $isTransparent) @client
  }
`;
