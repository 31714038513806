import { v4 as uuidv4 } from 'uuid';
import { getCookie } from 'lib/helpers/cookies';
import { IS_CLIENT, USER_QUERY_PARAMETER, USER_STORAGE_KEY, MATCH } from '../../constants';

export default class User {
  id: string | null = null;

  attributes: { [name: string]: string } = {};

  experiments: { [slug: string]: string } = {};

  log: (msg: string) => void;

  constructor(log: (msg: string) => void) {
    this.log = log;
    this.restoreAndSetUserId();
  }

  generateId = (): string => {
    if (IS_CLIENT) {
      const tnUUID = getCookie('TN_UUID');
      if (tnUUID) {
        return tnUUID;
      }
    }

    return uuidv4();
  };

  setUserId = (userId: string) => {
    if (typeof userId !== 'string') {
      this.log('User ID should be a string');
    }

    this.id = userId;

    this.save();
  };

  setAttributes = (attributes: any): User['attributes'] => {
    this.attributes = { ...this.attributes, ...attributes };

    this.save();

    return this.attributes;
  };

  clearAttributes = (): User['attributes'] => {
    this.attributes = {};

    this.save();

    return this.attributes;
  };

  save = (): void => {
    localStorage.setItem(
      USER_STORAGE_KEY,
      JSON.stringify({
        id: this.id,
        attributes: this.attributes,
        experiments: this.experiments
      })
    );
  };

  restoreAndSetUserId = (): void => {
    if (!IS_CLIENT) return;
    // Restore the old state if we had one
    this.restore();

    // Check url for overwriting user id
    const userId = this.getUserFromUrl();
    if (userId) {
      this.setUserId(userId);
      return;
    }

    // If we don't have a user id set one and save the state
    if (!this.id || userId === '') {
      this.setUserId(this.generateId());
    }
  };

  restore = (): void => {
    if (typeof localStorage === 'undefined') return;

    const storedUser = localStorage.getItem(USER_STORAGE_KEY);
    const saved = storedUser ? JSON.parse(storedUser) : null;

    if (saved) {
      this.id = saved.id;
      this.attributes = saved.attributes || {};
      this.experiments = saved.experiments || {};
    }
  };

  getUserFromUrl = (): string | null => {
    const { href } = window.location;
    if (!href) return null;

    const regex = new RegExp(`[?&]${USER_QUERY_PARAMETER}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  isInAudience = (audience: string): boolean => {
    try {
      const [relation, ...conditions] = JSON.parse(audience) as ['and' | 'or', ...TCondition[]];

      const getCurrentValue = (name: string): string => {
        if (name === 'userId') return this.id || '';
        if (name in this.attributes) return this.attributes[name];
        return '';
      };

      const conditionMet = ({ match, name, value }: TCondition): boolean => {
        const currentValue = getCurrentValue(name);
        if (match === MATCH.SUBSTRING) return currentValue.includes(value);
        if (match === MATCH.EXACT) return currentValue === value;
        if (match === MATCH.DIFFERENT) return currentValue !== value;
        if (match === MATCH.NOSUBSTRING) return !currentValue.includes(value);
        return false;
      };

      if (relation === 'and') {
        return conditions.every(conditionMet);
      }
      return conditions.some(conditionMet);
    } catch (error) {
      this.log((error as any).message);
    }
    return false;
  };

  getVariant = (feature: IFeature): IVariant | null => {
    if (!feature.experiment) return null;

    // Check whitelisted users
    const whitelistedVariant = feature.experiment.variants.find(variant => variant.whitelist === this.id);
    if (whitelistedVariant) {
      this.log(`user ${this.id} is whitelisted for variant '${whitelistedVariant.key}'`);
      return whitelistedVariant;
    }

    // Check for previous used variants
    const previousVariant = feature.experiment.variants.find(variant => variant.key === this.experiments[feature.slug]);
    if (previousVariant) {
      this.log(`using previous variant '${previousVariant.key}'`);
      return previousVariant;
    }

    // Retrieve variant based on traffic amount
    const { variants } = feature.experiment.variants.reduce(
      ({ start, variants }, variant) => {
        const end = start + variant.traffic;
        return {
          start: end,
          variants: [...variants, { ...variant, start, end }]
        };
      },
      { start: 0, variants: [] } as { start: number; variants: { start: number; end: number; key: string }[] }
    );
    const pointer = Math.random() * 100;
    const { key } = variants.find(variant => variant.start <= pointer && variant.end >= pointer) || {};
    if (key) {
      this.experiments[feature.slug] = key;
      this.save();
      return feature.experiment.variants.find(variant => variant.key === key) || null;
    }
    return null;
  };
}
