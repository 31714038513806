import { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import { trackClick } from 'lib/tealium/provider';
import pausePlayer from 'lib/pausePlayer';
import updateSearchParams from 'lib/updateSearchParams';
import { SearchButtonStyle, SearchButtonPlaceholderStyle } from './SearchButton.style';
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from '../../constants';

const SearchButton: FunctionComponent<ISearchFieldProps> = () => {
  const router = useRouter();
  const handleClick = () => {
    pausePlayer();
    updateSearchParams(router, '');
    trackClick({
      label: TEALIUM_EVENT_LABEL.SEARCH,
      name: TEALIUM_EVENT_NAME.HEADER_CLICK,
      category: TEALIUM_EVENT_CATEGORY.HEADER
    });
  };
  return (
    <SearchButtonStyle onClick={handleClick}>
      <SearchButtonPlaceholderStyle>Zoeken</SearchButtonPlaceholderStyle>
    </SearchButtonStyle>
  );
};
export default SearchButton;
