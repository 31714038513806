import styled, { css, keyframes } from 'styled-components';
import { linearGradient } from 'lib/helpers';
import { Grid } from '../../styles/grid';
import { Heading2 } from '../Text/Text.style';
import { ICONS } from '../../constants/icons';
import Colors from '../../styles/colors';

export const SearchModalStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props =>
    linearGradient(props.theme.gradients.main.small.direction, props.theme.gradients.main.small.stops)};
  z-index: ${props => props.theme.zIndex.component.searchModal};
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  ${(props: ISearchModal) =>
    props.isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`;

export const SearchModalHeaderStyle = styled.div`
  height: 56px;
  background: ${props =>
    linearGradient(props.theme.gradients.search.header.direction, props.theme.gradients.search.header.stops)};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: 80px;
  }
`;

export const SearchModalHeaderInnerStyle = styled(Grid)<ISearchModal>`
  grid-column-gap: 0;
  display: flex;
  align-items: center;
`;

const popIn = keyframes`
0%{
  transform: scale(1.2);
}
100%{
  transform:scale(1);
}`;

export const SearchModalCloseButtonStyle = styled.div<{ isOpen: boolean }>`
  opacity: ${props => (props.isOpen ? 1 : 0)};
  width: 40px;
  height: 40px;
  border-radius: 24px;
  padding: 20px;
  background-color: ${Colors.nero};
  background-image: url(${ICONS.CLOSE});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 27px 27px;
  cursor: pointer;
  transition: opacity 0.1s ease-in 0.6s;
  ${(props: ISearchModal) =>
    props.isOpen &&
    css`
      animation-name: ${popIn};
      animation-duration: 0.5s;
      animation-iteration-count: 1;
      animation-delay: 0.6s;
    `};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: 48px;
    padding: 24px;
    height: 48px;
    background-size: 32px 32px;
  }
`;

export const SearchModalInputWrapperStyle = styled.div<{ isOpen: boolean }>`
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  &::after {
    content: '';
    position: absolute;
    background: url(${ICONS.SEARCH}) no-repeat 6px;
    right: 0;
    height: 100%;
    width: 100%;
    padding: 8px 16px 8px 10px;
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    transition: transform 0.3s ease-in 0s;
    transform: translateX(calc(100% - 34px));

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      transform: translateX(calc(100% - 38px));
      background-position: 10px;
    }

    ${props =>
      props.isOpen &&
      css`
        transition-delay: 0.3s;
        transform: translateX(0);

        @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
          transform: translateX(0);
        }
      `};
  }
`;

export const SearchModalInputAnimationWrapperStyle = styled.div`
  height: 40px;
  position: relative;
  width: 100%;
  margin: 0 8px 0 0;
  border-radius: 40px;
  overflow: hidden;
  mask-image: radial-gradient(white, black);
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: 48px;
  }
`;

export const SearchModalInitialTrendingStyle = styled.div`
  margin-top: 40px;
  margin-bottom: 77px;

  @media (orientation: landscape) {
    margin-bottom: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 80px;
    margin-bottom: 0;
  }
`;

export const SearchModalOverviewStyle = styled.div`
  margin-bottom: 12px;

  ${Heading2} {
    margin-bottom: 8px;

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      margin-bottom: 24px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-bottom: 42px;
  }
`;
