import styled, { css, keyframes } from 'styled-components';
import { CarouselItemsStyle, CarouselWrapperStyle } from 'components/Lists/Carousel/Carousel.style';
import { Heading2 } from 'components/Text/Text.style';
import { VideoItemStyle } from 'components/VideoItem/VideoItem.style';
import { EditorialRowStyle } from 'components/EditorialOverview/EditorialOverview.style';
import { ICONS } from '../../constants';
import { Grid } from '../../styles/grid';
import Colors from '../../styles/colors';

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

export const GeoblockBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: ${Colors.black};
  cursor: pointer;
  position: fixed;
  width: 100%;
  z-index: ${props => props.theme.zIndex.component.searchModal};

  svg {
    color: ${Colors.white};
    transform: rotate(90deg);
  }
`;

export const GeoblockBannerText = styled.div`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  text-align: center;
  margin-right: 4px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    font-size: 18px;
    line-height: 1.33;
  }
`;

export const BannerWrapper = styled.div<{ isBannerOpen: boolean; isExiting: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px 40px 0;
  background-color: ${Colors.nero};
  z-index: ${props => props.theme.zIndex.component.searchModal};
  opacity: ${({ isBannerOpen }) => (isBannerOpen ? 1 : 0)};
  animation: ${({ isBannerOpen, isExiting }) =>
    isExiting
      ? css`
          ${slideOut} 0.35s ease-out forwards
        `
      : isBannerOpen
      ? css`
          ${slideIn} 0.35s ease-out forwards
        `
      : 'none'};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    padding: 16px 0px 40px 8px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
    padding: 16px 0 40px 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    padding: 16px 0 40px 0;
  }
`;

export const Banner = styled.div`
  width: 100%;
  ${CarouselWrapperStyle} {
    margin: 0;
  }

  ${CarouselItemsStyle} {
    padding-left: 16px;
    padding-top: 8px;

    & > *:first-child {
      margin-left: 0;

      @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
        margin-left: 64px;
      }

      @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
        margin-left: 0;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      padding-left: 24px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      padding-left: 16px;
    }

    @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
      padding-left: 0;
    }
  }

  ${Grid} {
    margin: 0;
  }

  ${EditorialRowStyle} {
    margin-top: 0;
  }

  ${Heading2} {
    padding-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      padding-left: 24px;
      margin-bottom: 16px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      padding-left: 16px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
      margin-right: 80px;
      margin-left: 64px;
    }

    @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
      padding: 0;
      margin-left: 0;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    width: 1760px;
  }
`;

export const BannerCloseButton = styled.button`
  border: 0;
  outline: 0;
  display: block;
  width: 48px;
  height: 48px;
  padding: 20px;
  border-radius: 24px;
  background-color: ${Colors.white};
  background-image: url(${ICONS.CLOSE_CURRENT_COLOR});
  color: ${Colors.black};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 27px 27px;
  cursor: pointer;
  margin-right: 16px;
  margin-left: auto;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-right: 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
    margin-right: 80px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    margin-right: 0;
  }
`;

export const BannerDescription = styled.div`
  font-size: 16px;
  line-height: 1.25;
  color: ${Colors.white};
  width: 100%;
  padding: 16px 16px 32px 16px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    padding: 16px 24px 32px 24px;
    font-size: 18px;
    line-height: 1.33;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: 85%;
    margin: 16px 0 32px 0;
    padding: 0 0 0 16px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
    padding-left: 80px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    padding: 0;
  }
`;

export const BannerProgramsWrapper = styled.div`
  ${VideoItemStyle} {
    width: 211px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    ${VideoItemStyle} {
      width: 229px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    ${VideoItemStyle} {
      width: 302px;
    }
  }
`;

export const BannerBackdrop = styled.div<{ isExiting: boolean }>`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${Colors.black};
  z-index: ${props => props.theme.zIndex.component.backdrop};
  opacity: ${({ isExiting }) => (isExiting ? 0 : 0.4)};
  transition: opacity 0.35s ease-out;
`;
