import { ApolloClient, FetchResult } from '@apollo/client/core';
import { getProfileWatchlistQuery, profileDataMutation } from './userQuery.query';

export const toggleWatchlistProgram = ({
  apollo,
  guid,
  isAdded
}: {
  apollo: ApolloClient<object>;
  guid: string;
  isAdded: boolean;
}): Promise<FetchResult> =>
  apollo.mutate({
    mutation: profileDataMutation,
    refetchQueries: [
      {
        query: getProfileWatchlistQuery
      }
    ],
    variables: {
      action: isAdded ? 'DELETE' : 'STORE',
      key: `watchlist.programs.${guid}`
    }
  });
