export enum NAVIGATIONS_SLUG {
  MAIN_WEB = 'main-web',
  MOBILE_WEB = 'mobile-web'
}

export enum NAVIGATION_ITEM_OVERVIEW {
  HOME = 'home'
}

export enum NAVIGATION_ITEM_TITLE {
  HOME = 'Home',
  GEMIST = 'Gemist',
  FILMS = 'Films',
  PROGRAMMAS = 'Programma’s'
}
