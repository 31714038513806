import { FunctionComponent } from 'react';
import Head from 'next/head';
import getConfig from 'next/config';
import { getStaticAsset } from 'lib/helpers/resources';
import { getCurrentRouteMeta } from 'lib/helpers/getCurrentRouteMeta';

const { publicRuntimeConfig } = getConfig();

const Meta: FunctionComponent<IMetaProps> = (props: IMetaProps) => {
  const ViewMode: string = props.viewMode.toLowerCase();
  const PageMeta = getCurrentRouteMeta(props.currentRouteName || '');
  const PagePreconnect: string[] | undefined = PageMeta?.find(meta => meta.hasOwnProperty('preconnect'))?.preconnect;
  const PageDNSPrefetch: string[] | undefined = PageMeta?.find(meta => meta.hasOwnProperty('dnsprefetch'))?.dnsprefetch;

  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1 viewport-fit=cover maximum-scale=1.0, user-scalable=0"
      />
      <meta name="application-name" content="KIJK" />
      <meta name="theme-color" content="#000000" />
      <title>KIJK opent je ogen</title>
      <link rel="shortcut icon" type="image/x-icon" href={getStaticAsset('static/favicons/favicon.ico')} />
      <link rel="apple-touch-icon" sizes="180x180" href={getStaticAsset('static/favicons/apple-touch-icon.png')} />
      <link
        rel="icon"
        type="image/png"
        href={getStaticAsset('static/favicons/android-chrome-192x192.png')}
        sizes="192x192"
      />
      <link
        rel="icon"
        type="image/png"
        href={getStaticAsset('static/favicons/android-chrome-384x384.png')}
        sizes="384x384"
      />
      <link rel="icon" type="image/png" href={getStaticAsset('static/favicons/favicon-16x16.png')} sizes="16x16" />
      <link rel="icon" type="image/png" href={getStaticAsset('static/favicons/favicon-32x32.png')} sizes="32x32" />
      <link rel="mask-icon" color="#000000" href={getStaticAsset('static/favicons/safari-pinned-tab.svg')} />
      <link rel="manifest" href={getStaticAsset('static/favicons/site.webmanifest')} />
      <meta name="x-kijk-device-type" property={ViewMode} />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="msapplication-TileImage" content={getStaticAsset('static/favicons/mstile-150x150.png')} />
      <meta name="msapplication-config" content={getStaticAsset('static/favicons/browserconfig.xml')} />
      <link rel="dns-prefetch" href="https://static.talpanetwork.com" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta property="og:site_name" content="KIJK" />
      <meta property="og:type" content="website" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
              window._hjSettings = {
                hjdebug: ${publicRuntimeConfig.hotjar.debug}
              };`
        }}
      />

      {PagePreconnect?.map(pc => (
        <link key={pc} rel="preconnect" href={pc} />
      ))}

      {PageDNSPrefetch?.map(pc => (
        <link key={pc} rel="dns-prefetch" href={pc} />
      ))}
    </Head>
  );
};

export default Meta;
