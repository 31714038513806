export enum FOOTER_LINKS {
  AANMELDEN_VOOR_PROGRAMMA = 'Aanmelden voor programma',
  VEELGESTELDE_VRAGEN = 'Veelgestelde vragen',
  ACTIES = 'Acties',
  OVER_KIJK = 'Over KIJK',
  ADVERTEREN = 'Adverteren',
  VACATURES = 'Vacatures',
  PRIVACY_POLICY = 'Privacyverklaring - geüpdatet',
  COOKIE_POLICY = 'Cookieverklaring',
  COOKIE_INSTELLINGEN = 'Cookie instellingen',
  GEBRUIKSVOORWAARDEN = 'Gebruiksvoorwaarden',
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  APP_STORE = 'appStore',
  GOOGLE_STORE = 'googleStore',
  AANMELDEN = 'Aanmelden'
}
