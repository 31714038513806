import styled, { css } from 'styled-components';
import Colors from '../../styles/colors';

export const sharedButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 15px;
  border-radius: 4px;
  border: 2px solid ${Colors.brownGrey};
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  transition: border-color ${props => props.theme.transitions.easeIn};

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) and (hover: hover) {
    &:hover {
      border-color: ${Colors.white};
    }
  }
`;

export const ButtonStyle = styled.button<{ isDisabled?: boolean; isActionButton?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 15px;
  border-radius: 4px;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  transition: border-color ${props => props.theme.transitions.easeIn};
  width: 100%;
  opacity: ${props => (props.isDisabled ? '0.4' : '1')};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  font-size: 16px;
  font-weight: 700;
  color: ${Colors.white};
  background-color: ${props => (!props.isActionButton ? `${Colors.transparent}` : `${Colors.greenishTeal}`)};
  border: ${props => (!props.isActionButton ? `2px solid ${Colors.brownGrey}` : 'none')};

  &:hover {
    border-color: ${props => (!props.isActionButton ? `${Colors.white}` : 'none')};
  }
`;
