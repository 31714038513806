import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import NavigationModel from 'models/navigation.model';
import { HeaderStateQuery } from 'queries/headerState/headerStateQuery.query';
import NavigationsQuery from 'queries/navigations/navigations.query';
import NavList from '../Nav/NavList';
import Account from '../Account';
import Logo from '../Logo';
import SearchWithTooltip from '../SearchButton/SearchWithTooltip';
import {
  NAVIGATION_ITEM_OVERVIEW,
  NAVIGATION_ITEM_TITLE,
  NAVIGATIONS_SLUG,
  PROMO_QUERY_CLIENT,
  ROUTES
} from '../../constants';
import {
  GradientStyle,
  HeaderContentStyle,
  HeaderHelperStyle,
  HeaderRightContentStyle,
  HeaderWrapperStyle,
  InnerStyle,
  TopbarTitleStyle
} from './Header.style';

const routesTitles = {
  [ROUTES.FORMAT_MISSED_INDEX]: NAVIGATION_ITEM_TITLE.GEMIST,
  [ROUTES.MOVIE_INDEX]: NAVIGATION_ITEM_TITLE.FILMS,
  [ROUTES.FORMAT_A_TO_Z]: NAVIGATION_ITEM_TITLE.PROGRAMMAS
};

const navigationFallback: NavigationModel = {
  isValid: true,
  items: [
    {
      path: '/',
      title: 'Home'
    },
    {
      path: '/programmas',
      title: 'Programma’s'
    },
    {
      path: '/films',
      title: 'Films'
    },
    {
      path: '/programmas/gemist',
      title: 'Gemist'
    }
  ]
};

const getActiveRouteIndex = (activeRoute: string, navigationItems: INavListItem[]): number => {
  if (!activeRoute || !navigationItems) {
    return 0;
  }

  const [currentPath] = activeRoute.split('?');

  return navigationItems.findIndex(item => item.path === currentPath);
};

export const Header: React.FC = () => {
  const router = useRouter();
  const [isHeaderHidden, setIsHeaderHidden] = useState<boolean>(false);
  const [scrolledToTop, setScrolledToTop] = useState<boolean>(
    () => typeof window !== 'undefined' && window.scrollY === 0
  );
  const [navigation, setNavigation] = useState<NavigationModel>(navigationFallback);
  const [pagesTitles, setPagesTitles] = useState<{ [key: string]: string }>(routesTitles);
  const [currentPageTitle, setCurrentPageTitle] = useState<string>();

  useEffect(() => {
    let scrollPosition = window.scrollY;
    const handleScroll = () => {
      setIsHeaderHidden(window.scrollY > 80 && window.scrollY >= scrollPosition);
      scrollPosition = window.scrollY;
      setScrolledToTop(scrollPosition === 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const pageTitle = pagesTitles[router.asPath];
    setCurrentPageTitle(pageTitle);
  }, [router.asPath]);

  useQuery<INavigationsResponse>(NavigationsQuery, {
    variables: {
      client: PROMO_QUERY_CLIENT.DESKTOP
    },
    onCompleted: data => {
      const mainNavigation = data.navigations.items.find(
        navigationItem => navigationItem.slug === NAVIGATIONS_SLUG.MAIN_WEB
      );
      if (!mainNavigation) return;
      const navigationModel = new NavigationModel(mainNavigation);
      if (navigationModel.isValid) {
        setNavigation(navigationModel);
      }

      const mobileNavigation = data.navigations.items.find(
        navigationItem => navigationItem.slug === NAVIGATIONS_SLUG.MOBILE_WEB
      );
      if (!mobileNavigation) return;
      const dynamicPages = mobileNavigation.navigationItems.filter(
        item => item.overview && item.overview !== NAVIGATION_ITEM_OVERVIEW.HOME
      );

      if (dynamicPages.length) {
        const dynamicPagesTitles: { [key: string]: string } = {};
        dynamicPages.forEach(({ overview, title }) => {
          dynamicPagesTitles[`/${overview}`] = title;
        });
        setPagesTitles({
          ...pagesTitles,
          ...dynamicPagesTitles
        });
      }
    }
  });

  const { data, client } = useQuery<{ headerState: ILocalHeaderState }>(HeaderStateQuery);

  return (
    <HeaderWrapperStyle data-testid="header" isHeaderHidden={isHeaderHidden}>
      <HeaderHelperStyle>
        <GradientStyle
          isTransparent={data?.headerState.isTransparent && scrolledToTop}
          isHeaderHidden={isHeaderHidden}
        />
        <InnerStyle>
          <HeaderContentStyle data-testid="headerContent">
            <Logo smallHeight="38px" mediumHeight="38px" midMediumHeight="48px" largeHeight="56px" />
            {currentPageTitle && <TopbarTitleStyle data-testid="pageTitle"> {currentPageTitle} </TopbarTitleStyle>}
            {navigation && (
              <NavList
                data-testid="navList"
                elements={navigation.items}
                activeItemIndex={getActiveRouteIndex(router.asPath, navigation.items)}
              />
            )}
            <HeaderRightContentStyle hasPageTitle={Boolean(currentPageTitle)}>
              <SearchWithTooltip client={client} isHeaderHidden={isHeaderHidden} />
              <Account />
            </HeaderRightContentStyle>
          </HeaderContentStyle>
        </InnerStyle>
      </HeaderHelperStyle>
    </HeaderWrapperStyle>
  );
};

export default Header;
