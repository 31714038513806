import { BREAKPOINTS } from '../../constants';
import transitions from './transitions';
import zIndex from './zIndex';
import gradients from './gradients';

const Theme: ITheme = {
  transitions,
  zIndex,
  gradients,
  breakpoints: Object.keys(BREAKPOINTS).reduce((acc, key) => ({ ...acc, [key]: `${BREAKPOINTS[key]}px` }), {})
};

export default Theme;
