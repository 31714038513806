import { gql } from '@apollo/client';

const LocalStateQuery = gql`
  query localState {
    continueWatching {
      videosContinueWatching
      isLoading
      __typename
    }
    activeVideo {
      guid
      position
      duration
      completed
      __typename
    }
    videoHeadingState {
      __typename
      headerImage
    }
    headerState {
      __typename
      isTransparent
    }
  }
`;

export default LocalStateQuery;
