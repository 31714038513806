import styled from 'styled-components';
import { Heading1 } from 'components/Text/Text.style';
import { VideoItemStyle } from 'components/VideoItem/VideoItem.style';

export const EditorialRowStyle = styled.div`
  margin-top: 12px;

  ${Heading1} {
    margin-bottom: 0;
  }

  ${VideoItemStyle} {
    width: 211px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 42px;

    ${VideoItemStyle} {
      width: 229px;
    }

    ${Heading1} {
      margin-bottom: 8px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    ${VideoItemStyle} {
      width: 302px;
    }

    ${Heading1} {
      margin-bottom: 14px;
    }
  }
`;
