import React, { useState } from 'react';
import useFeature from 'hooks/useFeature';
import { formatSeconds } from 'lib/helpers/datetime';
import useVideoProgress from 'hooks/useVideoProgress';
import RemoveItemDialog from 'components/RemoveItemDialog/RemoveItemDialog';
import RemoveItemCloseButton from 'components/RemoveItemDialog/RemoveItemCloseButton';
import { getPositionInPercentage } from 'lib/helpers';
import { IVideoItemProps } from 'typings/IVideoItemProps';
import Link from '../Link';
import { FALLBACK_UI_LABELS, FEATURE_SLUG } from '../../constants';
import VideoItemDescription from './VideoItemDescription';
import VideoPreview from './VideoPreview';
import ErrorBoundary from '../ErrorBoundary';
import CommonFallback from '../CommonFallback';
import ImageMedia from '../ImageMedia';
import PlayButton from '../PlayButton';
import {
  TimeLabelStyle,
  VideoItemStyle,
  VideoItemImageWrapperStyle,
  VideoItemImageGradientStyle,
  VideoItemProgressBarStyle
} from './VideoItem.style';

const VideoImage: React.FC<{
  imageMedia: IImageProps;
  previewEnabled: boolean;
  guid: string;
}> = ({ imageMedia, previewEnabled, guid }) => (
  <>
    {previewEnabled && guid && <VideoPreview guid={guid} image={imageMedia.src} />}
    <ImageMedia
      imageMedia={imageMedia}
      title={imageMedia.alt}
      transformations={{
        width: 'auto',
        height: '235',
        crop: 'fill',
        aspect_ratio: '16:9',
        gravity: 'face:auto',
        fetch_format: 'auto'
      }}
    />
  </>
);

const VideoItem: React.FC<IVideoItemProps> = ({
  isActive,
  isPosibleRemoveVideoItem,
  remoteProgress,
  removeVideoItem,
  smLandscape,
  isFormatPage,
  video,
  isPlayerSideBar = false
}) => {
  const { enabled: previewEnabled } = useFeature(FEATURE_SLUG.VIDEO_PREVIEW);
  const [isRemoveDialogShown, setIsRemoveDialogShown] = useState(false);
  const localProgress = useVideoProgress(video.guid);

  const progress = React.useMemo(
    () => localProgress || (remoteProgress && getPositionInPercentage(remoteProgress.position, video.duration)),
    [localProgress, remoteProgress, video]
  );

  const isCompleted = progress && progress >= 95;
  const showProgress = !isCompleted && !!progress && progress !== 0;

  return (
    <ErrorBoundary onError={(_: any) => <CommonFallback message={FALLBACK_UI_LABELS.VIDEO_ITEM} />}>
      <div data-testid="videoItem">
        {isPosibleRemoveVideoItem && isRemoveDialogShown ? (
          <VideoItemStyle smLandscape={smLandscape} isRemoveDialogShown>
            <RemoveItemDialog
              toggleDialog={() => setIsRemoveDialogShown(!isRemoveDialogShown)}
              removeItem={() => removeVideoItem?.(video.guid)}
            />
          </VideoItemStyle>
        ) : (
          <VideoItemStyle smLandscape={smLandscape} isRemoveDialogShown={false}>
            <VideoItemImageWrapperStyle data-testid="videoItemImage" isActive={isActive} smLandscape={smLandscape}>
              {isPosibleRemoveVideoItem && (
                <RemoveItemCloseButton toggleDialog={() => setIsRemoveDialogShown(!isRemoveDialogShown)} />
              )}
              <Link path={video.path} clickEventValues={video.eventValues}>
                <VideoImage guid={video.guid} imageMedia={video.imageLandscape} previewEnabled={!!previewEnabled} />
                {video.isAvailable && !previewEnabled && <PlayButton />}
                {showProgress && <VideoItemImageGradientStyle data-testid="videoItemImageGradient" />}
                {video.duration && (
                  <TimeLabelStyle
                    data-testid="durationLabel"
                    isProgressBarShown={showProgress}
                    isPlayerSideBar={isPlayerSideBar}
                  >
                    {isCompleted
                      ? 'Bekeken'
                      : showProgress
                      ? `Nog ${formatSeconds(video.duration - Math.floor((progress / 100) * video.duration))}`
                      : formatSeconds(video.duration)}
                  </TimeLabelStyle>
                )}
                {showProgress && (
                  <VideoItemProgressBarStyle
                    data-testid="VideoItemProgressBarStyle"
                    isActive={isActive}
                    progress={progress}
                  />
                )}
              </Link>
            </VideoItemImageWrapperStyle>
            <VideoItemDescription
              path={video.path}
              date={video.videoDateTime}
              textLabel={video.labelText}
              smLandscape={smLandscape}
              isFormatPage={isFormatPage}
              title={video.thumbnailTitle}
              type={video.type}
              subtitle={video.thumbnailSubtitle}
              clickEventValues={video.eventValues}
              showFormatTitle={video.showFormatTitle}
            />
          </VideoItemStyle>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default VideoItem;
