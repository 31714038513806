/* eslint-disable no-shadow */
export enum META_DESCRIPTION_CONTENT {
  PROGRAMMAS = 'Bekijk programma’s van SBS 6, Veronica, NET 5 en veel meer zenders online bij KIJK. Chateau Meiland, Familie Gillis, Vandaag Inside, Mr. Frank Visser, Lang Leve de Liefde, Hart van Nederland, Shownieuws en nog veel meer',
  GEMIST = 'Aflevering gemist? bij KIJK kijk je je favoriete programma’s terug. Chateau Meiland, Familie Gillis, Vandaag Inside, Mr. Frank Visser, Lang Leve de Liefde, Hart van Nederland, Shownieuws en nog veel meer'
}

export enum META_TITLE_CONTENT {
  PROGRAMMAS = 'Programmas',
  GEMIST = 'Aflevering gemist? Kijk terug op KIJK.nl'
}
