import { ApolloClient } from '@apollo/client';
import addDrm from 'lib/addDrm';
import { noticeError } from 'lib/newrelic';
import VideoModel from 'models/video.model';
import SourcesQuery, { SourcesUncachedQuery } from 'queries/programs/sources.query';
import { FETCH_POLICY, PLAYER_ERRORS } from '../../client/constants';
import isSearchBot from '../isSearchBot';

type JWSource = ISourceGraphql & { originalType?: string };

const fetchHeaders = async (source: ISourceGraphql) => {
  try {
    const response = await fetch(source.file, {
      method: 'HEAD'
    });

    return { ...source, available: response.status === 200 };
  } catch (error) {
    return { ...source, available: false };
  }
};

const validateSources = async (sources: JWSource[]) => {
  if (isSearchBot() || !sources?.length) {
    return sources;
  }
  const validatedSources = await Promise.all(sources.map(fetchHeaders));
  return validatedSources.filter(source => source.available);
};

const getSelectedSources = (currentSources: JWSource[], sources: JWSource[]): ISourceGraphql[] => {
  const [currentSource] = currentSources;
  const { type: originalType, drm } = currentSource || {};
  if (drm) {
    const [drmKey] = Object.keys(drm);
    return sources
      .filter(
        source =>
          drmKey === (source.drm && Object.keys(source.drm)[0]) &&
          (originalType === source.type || (originalType === 'hls' && source.type === 'm3u8')) // ! JW renames the type `m3u8` to `hls`
      )
      .map(addDrm);
  }

  return sources.filter(source => originalType === source.type || (originalType === 'hls' && source.type === 'm3u8')); // ! JW renames the type `m3u8` to `hls`

  // todo: might need fallback here
};

const refetchSources = async ({
  apolloClient,
  item,
  uncached = false
}: {
  apolloClient: ApolloClient<object>;
  uncached?: boolean;
  item: VideoModel & { sources: JWSource[]; starttime: number };
}): Promise<ISourceGraphql[]> => {
  let newSources: JWSource[] = [];

  try {
    const {
      data: {
        programs: { items }
      }
    } = await apolloClient.query<{
      programs: { items: { guid: string; sources: ISourceGraphql[] }[] };
    }>({
      query: uncached ? SourcesUncachedQuery : SourcesQuery,
      fetchPolicy: FETCH_POLICY.NO_CACHE,
      variables: { guid: item.guid }
    });

    if (items.length) {
      const [{ sources }] = items;
      newSources = await getSelectedSources(item.sources, sources);
    }
  } catch (error) {
    if (uncached) {
      noticeError(`Sources can't be fetched`, {
        media_id: item.guid,
        error_code: PLAYER_ERRORS.NO_PLAYABLE_SOURCES_FOUND,
        error_type: PLAYER_ERRORS.ERROR,
        graphql_error: (error as any).message
      });
    }
  }

  newSources = await validateSources(newSources);

  if (!newSources.length) {
    return [];
  }
  return newSources;
};

export default refetchSources;
