export const BREAKPOINTS: { [key: string]: any } = {
  xsMax: 319,
  smMin: 320,
  smMid: 481,
  smMax: 640,
  mdMin: 641,
  mdMid: 767,
  mdMax: 1024,
  lgMin: 1025,
  lgGrid: 1281,
  lgMid: 1461,
  lgMax: 1920,
  xlMin: 1921
};
