import { gql, InMemoryCache } from '@apollo/client';
import { ContinueWatchingQuery, ActiveVideo } from '../../client/queries/continueWatching/continueWatchingQuery.query';
import { updateLocalStorageContinueWatching } from '../helpers/continueWatching';
import { ACCOUNT } from '../../client/constants';

export const localStateDefaults: ILocalState = {
  continueWatching: {
    videosContinueWatching: [],
    isLoading: true,
    __typename: 'ContinueWatching'
  },
  activeVideo: {
    guid: '',
    position: 0,
    duration: 0,
    completed: false,
    __typename: 'ActiveVideo'
  },
  videoHeadingState: {
    __typename: 'VideoHeadingState',
    headerImage: false
  },
  headerState: {
    __typename: 'HeaderState',
    isTransparent: false
  }
};

export const localStateResolvers: any = {
  Query: {
    continueWatchingByGuid: (_: any, { guid }: { guid: string }, { cache }: { cache: InMemoryCache }) => {
      const query: any = ContinueWatchingQuery;
      const previous: ILocalState | null = cache.readQuery({ query });
      const guidExistinCache: IVideo | undefined = previous?.continueWatching?.videosContinueWatching?.find(
        e => e.guid === guid
      );
      return { ...guidExistinCache };
    }
  },
  Mutation: {
    activeVideo: (_: any, { video }: { video: IVideo }, { cache }: { cache: InMemoryCache }) => {
      localStorage.setItem('lastActiveVideo', JSON.stringify(video));

      cache.writeQuery({
        query: ActiveVideo,
        data: {
          activeVideo: {
            ...video,
            __typename: 'ActiveVideo'
          }
        }
      });

      return null;
    },
    continueWatching: (_: any, __: any, { cache }: { cache: InMemoryCache }) => {
      const query: any = ContinueWatchingQuery;
      const previous: ILocalState | null = cache.readQuery({ query });
      const PreviousActiveVideo: { activeVideo: IVideo } | null = cache.readQuery({ query: ActiveVideo });
      // Todo: check this
      // const User: { user: ILocalUserState } | null = cache.readQuery({ query: getUserDataQuery });
      const isLoggedIn = !!localStorage.getItem(ACCOUNT.AUTH_TOKEN);

      if (
        PreviousActiveVideo?.activeVideo.guid === '' ||
        PreviousActiveVideo === null ||
        PreviousActiveVideo.activeVideo.position < 5
      ) {
        return;
      }

      const guidExistinCache: number | undefined = previous?.continueWatching?.videosContinueWatching?.findIndex(
        e => e.guid === PreviousActiveVideo.activeVideo.guid
      );

      const videosContinueWatching = previous && [...previous.continueWatching.videosContinueWatching];

      if (guidExistinCache !== undefined && guidExistinCache > -1) {
        videosContinueWatching && videosContinueWatching.splice(guidExistinCache, 1);
      }

      videosContinueWatching && videosContinueWatching.unshift(PreviousActiveVideo.activeVideo);
      !isLoggedIn &&
        PreviousActiveVideo.activeVideo.guid !== '' &&
        updateLocalStorageContinueWatching(PreviousActiveVideo.activeVideo);

      // @todo | check if needed, why would activeVideo be cleared?
      cache.writeQuery({
        query: ActiveVideo,
        data: {
          activeVideo: {
            guid: '',
            position: 0,
            duration: 0,
            completed: false,
            __typename: 'ActiveVideo'
          }
        }
      });

      cache.writeFragment({
        id: cache.identify({ __typename: 'ContinueWatching' }),
        fragment: gql`
          fragment continueWatching on ContinueWatching {
            videosContinueWatching
            isLoading
          }
        `,
        data: {
          videosContinueWatching,
          isLoading: false
        }
      });
    },
    setVideosContinueWatching: (
      _: any,
      { videosContinueWatching }: { videosContinueWatching: IVideo[] },
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeFragment({
        id: cache.identify({ __typename: 'ContinueWatching' }),
        fragment: gql`
          fragment continueWatching on ContinueWatching {
            videosContinueWatching
            isLoading
          }
        `,
        data: {
          videosContinueWatching,
          isLoading: false
        }
      });
      return null;
    },
    setContinueWatchingIsLoading: (
      _: any,
      { isLoading }: { isLoading: boolean },
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeFragment({
        id: cache.identify({ __typename: 'ContinueWatching' }),
        fragment: gql`
          fragment continueWatching on ContinueWatching {
            isLoading
          }
        `,
        data: {
          isLoading
        }
      });
      return null;
    },
    videoHeadingState: (_: any, { headerImage }: { headerImage: string }, { cache }: { cache: InMemoryCache }) => {
      cache.writeFragment({
        id: cache.identify({ __typename: 'VideoHeadingState' }),
        fragment: gql`
          fragment videoHeadingState on VideoHeadingState {
            headerImage
          }
        `,
        data: {
          headerImage
        }
      });
      return null;
    },
    toggleHeaderState: (_: any, { isTransparent }: { isTransparent: boolean }, { cache }: { cache: InMemoryCache }) => {
      cache.writeFragment({
        id: cache.identify({ __typename: 'HeaderState' }),
        fragment: gql`
          fragment headerState on HeaderState {
            isTransparent
          }
        `,
        data: {
          isTransparent
        }
      });
      return null;
    }
  }
};

export const localStateTypeDefs: string = `
  enum Action {
    INSERT
    UPDATE
    DELETE
  }

  type VideoHeadingState {
    headerImage: Boolean | String
  }

  type ActiveVideo {
    guid: String!
    duration: Number
    position: Number
    completed: Boolean
  }

  type ContinueWatching {
    videosContinueWatching: [ActiveVideo]
    isLoading: Boolean
  }

  type Mutation {
    continueWatching(id: String, position: Number, duration: Number, completed: Boolean) @client
    videoHeadingState(headerImage: Boolean | String) @client
    activeVideo(guid: String, position: Number, duration: Number, completed: Boolean) @client
    toggleHeaderState(isTransparent: Boolean!) @client
  }

  type Query {
    continueWatchingByGuid: (guid:string) @client
  }

  type HeaderState {
    isTransparent: Boolean!
  }
`;
