import { FunctionComponent, useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import SVG from 'react-inlinesvg';
import NavigationsQuery from 'queries/navigations/navigations.query';
import NavigationModel from 'models/navigation.model';
import { addProtocolPrefix } from 'lib/helpers/string';
import Link from 'components/Link';
import { BottomNavigationStyle, BottomNavigationItemStyle, VisuallyHiddenIconText } from './BottomNavigation.style';
import {
  ICONS,
  NAVIGATIONS_SLUG,
  NAVIGATION_ITEM_OVERVIEW,
  NAVIGATION_ITEM_TITLE,
  PROMO_QUERY_CLIENT,
  ROUTES,
  TEALIUM_EVENT_CATEGORY,
  TEALIUM_EVENT_NAME
} from '../../constants';

export const navigationFallback: INavigation = {
  slug: 'mobile-fallback',
  navigationItems: [
    {
      title: NAVIGATION_ITEM_TITLE.HOME,
      slug: 'home-web',
      overview: NAVIGATION_ITEM_OVERVIEW.HOME,
      link: null,
      icon: [
        {
          url: ICONS.HOME
        }
      ]
    },
    {
      title: NAVIGATION_ITEM_TITLE.GEMIST,
      slug: 'gemist-web',
      overview: null,
      link: ROUTES.FORMAT_MISSED_INDEX,
      icon: [
        {
          url: ICONS.MISSED
        }
      ]
    },
    {
      title: NAVIGATION_ITEM_TITLE.PROGRAMMAS,
      slug: 'programmas-web',
      overview: null,
      link: ROUTES.FORMAT_A_TO_Z,
      icon: [
        {
          url: ICONS.PROGRAMMAS
        }
      ]
    },
    {
      title: NAVIGATION_ITEM_TITLE.FILMS,
      slug: 'films-web',
      overview: null,
      link: ROUTES.MOVIE_INDEX,
      icon: [
        {
          url: ICONS.FILMS
        }
      ]
    }
  ]
};

const BottomNavigation: FunctionComponent = () => {
  const router = useRouter();
  const [navigation, setNavigation] = useState<NavigationModel>(new NavigationModel(navigationFallback));

  useQuery<INavigationsResponse>(NavigationsQuery, {
    variables: {
      client: PROMO_QUERY_CLIENT.DESKTOP
    },
    onCompleted: data => {
      const mobileNavigation = data.navigations.items.find(({ slug }) => slug === NAVIGATIONS_SLUG.MOBILE_WEB);
      if (!mobileNavigation) return;
      const mobileNavigationModel = new NavigationModel(mobileNavigation);
      if (mobileNavigationModel.isValid) {
        setNavigation(mobileNavigationModel);
      }
    }
  });

  return (
    <BottomNavigationStyle>
      {navigation.items.map(item => (
        <BottomNavigationItemStyle
          key={item.title}
          isActive={item.path === router.pathname || item.overview === router.query.slug}
        >
          <Link
            path={item.path}
            clickEventValues={{
              category: TEALIUM_EVENT_CATEGORY.HEADER,
              label: `${item.title}`,
              name: TEALIUM_EVENT_NAME.HEADER_CLICK
            }}
          >
            {item.icon ? <SVG src={addProtocolPrefix(item.icon)} /> : null}
            <VisuallyHiddenIconText>{item.title}</VisuallyHiddenIconText>
          </Link>
        </BottomNavigationItemStyle>
      ))}
    </BottomNavigationStyle>
  );
};

export default BottomNavigation;
