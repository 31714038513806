import styled from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';
import { CarouselWrapperStyle } from '../Lists/Carousel/Carousel.style';
import Colors from '../../styles/colors';

export const PickerStyle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 50px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  ${CarouselWrapperStyle} {
    margin-top: -8px;
  }
`;

export const PickerItemStyle = styled.div<{ isSelected: boolean; isDisabled?: boolean; isGenrePicker?: boolean }>`
  min-width: ${props => (props.isGenrePicker ? 'fit-content' : '32px')};
  height: 32px;
  position: relative;
  color: ${props => (props.isDisabled ? Colors.greyishBrown : Colors.white)};
  opacity: ${props => !props.isSelected && '0.8'};
  font-size: 16px;
  font-weight: ${props => props.isSelected && 'bold'};
  line-height: 32px;
  text-align: center;
  text-transform: capitalize;
  user-select: none;
  white-space: nowrap;
  cursor: ${props => (props.isSelected || props.isDisabled ? 'default' : 'pointer')};

  &:first-child {
    min-width: ${props => (props.isGenrePicker ? 'fit-content' : '60px')};
  }

  &::after {
    content: ${props => (props.isSelected && !props.isDisabled ? "''" : 'none')};
    display: ${props => (props.isSelected && !props.isDisabled ? 'block' : 'none')};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${Colors.violet};
    transition: background-color ${props => props.theme.transitions.easeIn};
  }

  &:hover {
    ${props =>
      !props.isDisabled &&
      `
      opacity: 1;
      color: ${Colors.white};
      &::after {
        display: block;
      }
    `}
  }
`;

export const PickerCarouselStyle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: ${props => props.theme.breakpoints.lgMax};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: calc(100% - 48px);
    margin: 0 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    margin: auto;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
    width: calc(100% - 160px);
    max-width: 1760px;
    margin: auto 80px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    margin: auto;
  }
`;

export const PickerCarouselItemsStyle = styled.div`
  display: flex;
  position: relative;
  order: 1;
  flex-basis: 100%;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    width: 0;
    height: 0;
    background-color: transparent;
    appearance: none;
  }

  & > * {
    margin-left: 12px;
    margin-right: 12px;
  }

  & > *:first-child {
    margin-left: 16px;
  }

  & > *:last-child {
    margin-right: 0;
  }

  &::after {
    content: '';
    display: block;
    position: relative;
    min-width: 16px;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      display: none;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    order: 0;
    flex-basis: auto;

    & > *:first-child {
      margin-left: 0;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    & > * {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
`;

export const PrevArrowStyle = styled.div`
  display: none;
  position: absolute;
  top: -9px;
  left: 0;
  width: 72px;
  bottom: -9px;
  cursor: pointer;
  background: ${props =>
      linearGradient(
        props.theme.gradients.carouselPickerListLeftArrow.direction,
        props.theme.gradients.carouselPickerListLeftArrow.stops
      )}
    100% 100%;

  svg {
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
    color: white;
    transform: rotate(180deg);
  }

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    display: none !important;
  }

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    right: 100%;
    width: 100vw;
    background: rgba(0, 0, 0, 1);
  }
`;

export const NextArrowStyle = styled(PrevArrowStyle)`
  left: auto;
  right: 0;
  background: ${props =>
      linearGradient(
        props.theme.gradients.carouselPickerListRightArrow.direction,
        props.theme.gradients.carouselPickerListRightArrow.stops
      )}
    100% 100%;

  svg {
    right: 0;
    left: auto;
    transform: rotate(0deg);
  }

  ::after {
    right: auto;
    left: 100%;
  }
`;
