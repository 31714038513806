import styled from 'styled-components';

export const PreviewContainer = styled.div<{ backgroundImage: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  transition: scale 200ms ease-in-out, opacity 200ms ease;
  border-radius: 4px;
  overflow: hidden;
  background-position: center;
  background-size: cover;

  &:hover {
    opacity: 1;
    scale: 1.1;
  }

  & .jw-video {
    cursor: pointer;
  }

  & .jwplayer {
    pointer-events: none;
    opacity: 0;
    transition: opacity 400ms ease-in;
    transition-delay: 200ms;
    background-position: center;
    background-size: cover;
  }

  & .jw-wrapper {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0);
  }
`;
