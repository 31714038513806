import { pathToRegexp } from 'path-to-regexp';
import { PREVIEW_DOMAIN, ROUTE_PATHS } from '../client/constants';

const routes = Object.keys(ROUTE_PATHS).map(name => {
  const page = ROUTE_PATHS[name as keyof typeof ROUTE_PATHS];
  if (!page.includes(':')) {
    return { page, name };
  }
  const keys: [] = [];
  const regex = pathToRegexp(page, keys);
  return {
    name,
    page,
    keys,
    regex
  };
});

export const matchRoute = (route: any, path: string) => {
  if (route.page === path) {
    return {};
  }
  if (!route.regex) {
    return;
  }
  const values = route.regex.exec(path);

  if (values) {
    return values.slice(1).reduce((params: any, val: string, i: number) => {
      if (val === undefined) return params;
      return { ...params, [route.keys[i].name]: decodeURIComponent(val) };
    }, {});
  }
};

/**
 * Returns a route object
 * @param {any} req
 * @return {Route}
 *
 * @typedef {Object} Route
 * @property {URL} parsedUrl
 * @property {any} query
 * @property {Page=} page
 * @property {any=} params
 * @property {string=} pathname
 *
 * @typedef {Object} Page
 * @property {string} name
 * @property {string} page
 * @property {any[]=} keys
 * @property {any=} regex
 */
export const match = (req: any, asPath = '') => {
  try {
    const fullUrl = req ? `${process.env.DOMAIN}${asPath || req.originalUrl}` : `${document.location.origin}${asPath}`;

    const url = new URL(fullUrl);
    let { pathname } = url;

    if (fullUrl.includes(PREVIEW_DOMAIN)) {
      // remove feature branch from path prefix
      pathname = pathname.replace(/^\/[a-z0-9-]+/, '');
    }
    const query = Object.fromEntries(new URLSearchParams(url.search).entries());

    return routes.reduce(
      (result: any, route) => {
        if (result.page) return result;
        const params = matchRoute(route, pathname);
        if (!params) return result;
        return { ...result, page: route, params, query: { ...query, ...params }, pathname };
      },
      {
        query,
        parsedUrl: url
      }
    );
  } catch (ignore) {
    return {};
  }
};
