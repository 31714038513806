export enum PROGRAM_SORT_KEY {
  ADDED = 'ADDED',
  TITLE = 'TITLE',
  LASTPUBDATE = 'LASTPUBDATE',
  ADDED_ASC = 'ADDED_ASC',
  TITLE_DESC = 'TITLE_DESC',
  LASTPUBDATE_DESC = 'LASTPUBDATE_DESC',
  PUBLICATIONDATETIME = 'PUBLICATIONDATETIME',
  PUBLICATIONDATETIME_ASC = 'PUBLICATIONDATETIME_ASC',
  AIREDDATETIME = 'AIREDDATETIME',
  AIREDDATETIME_ASC = 'AIREDDATETIME_ASC',
  EPISODENUMBER_ASC = 'EPISODENUMBER_ASC',
  EPISODENUMBER_DESC = 'EPISODENUMBER_DESC'
}
