import { PROGRAM } from './program';
import { PROGRAM_SORT_KEY } from './types';

export enum TAB_NAMES {
  OVERZICHT = 'Overzicht',
  AFLEVERINGEN = 'Afleveringen',
  CLIPS = 'Clips',
  ALLE = 'Alle',
  PROGRAMMAS = `Programma's`,
  FILMS = 'Films'
}

export const tabProgramTypes: { [key: string]: string } = {
  [TAB_NAMES.CLIPS]: PROGRAM.CLIP,
  [TAB_NAMES.AFLEVERINGEN]: PROGRAM.EPISODE,
  [TAB_NAMES.FILMS]: PROGRAM.MOVIE,
  [TAB_NAMES.PROGRAMMAS]: PROGRAM.SERIES
};

export const tabSortValues: { [key: string]: string } = {
  [TAB_NAMES.CLIPS]: PROGRAM_SORT_KEY.ADDED,
  [TAB_NAMES.AFLEVERINGEN]: PROGRAM_SORT_KEY.LASTPUBDATE_DESC
};

export const TAB_CHANGE_EVENT = 'tab-change-event';
