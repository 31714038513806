import getConfig from 'next/config';
import builder from 'xmlbuilder';
import { format } from 'date-fns-tz';
import { getCurrentRouteMeta } from 'lib/helpers/getCurrentRouteMeta';
import { ROUTE_PATHS } from '../../client/constants';

const { publicRuntimeConfig } = getConfig();

export const getPagesForSitemap = (): ISitemapPage[] => {
  const Pages: {
    name: string;
    path: string;
  }[] = [];

  Object.keys(ROUTE_PATHS).forEach(name => {
    const path = (ROUTE_PATHS as { [key: string]: string })[name];
    const RouteMeta: IRouteMeta[] | null = getCurrentRouteMeta(name);
    if (!RouteMeta) return;
    if (RouteMeta.some(meta => meta.isInSitemap)) {
      Pages.push({
        name,
        path
      });
    }
  });

  return Pages;
};

export const buildUrlObject = (path: string, changeFreq: string = 'daily', updated?: number): IXmlObject => {
  const xmlObject: IXmlObject = {
    loc: { '#text': path },
    changefreq: { '#text': changeFreq },
    priority: { '#text': '1.0' }
  };
  if (updated) {
    const lastmod = format(updated, 'yyyy-MM-dd', { timeZone: publicRuntimeConfig.app.timezone });
    xmlObject.lastmod = { '#text': lastmod };
  }
  return xmlObject;
};

export const generateSitemap = (urls: IXmlObject[]): string => {
  const SitemapObject: IXmlSitemapObject = {
    urlset: {
      '@xmlns': 'http://www.sitemaps.org/schemas/sitemap/0.9',
      '@xmlns:image': 'http://www.google.com/schemas/sitemap-image/1.1',
      url: urls
    }
  };
  return builder.create(SitemapObject, { encoding: 'utf-8' }).end({ pretty: true });
};

export const generateSitemapIndex = (urls: IXmlObject[]): string => {
  const SitemapObject: IXmlSitemapObject = {
    sitemapindex: {
      '@xmlns': 'http://www.sitemaps.org/schemas/sitemap/0.9',
      sitemap: urls
    }
  };
  return builder.create(SitemapObject, { encoding: 'utf-8' }).end({ pretty: true });
};
