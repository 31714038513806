import OverviewItemModel from 'src/client/models/overviewItem.model';
import VideoModel from 'models/video.model';
import SwimlaneTitle from 'components/SwimlaneTitle/SwimlaneTitle';
import { Grid, GridElement } from '../../styles/grid';
import Carousel from '../Lists/Carousel';
import VideoList from '../VideoList';
import { CLIENT, IMAGE_MEDIA_TYPE, COLLECTION_TYPE, COLLECTION_PRESENTATION_TYPE } from '../../constants';
import ImageMedia from '../ImageMedia';
import {
  BrandedEditorialVideosStyle,
  HeroImageVideosWrapperStyle,
  BrandedEditorialVideosContentWrapperStyle,
  BrandedEditorialVideosTitleLogoWrapperStyle,
  TitleWrapperStyle,
  LogoWrapperStyle
} from './EditorialVideos.style';

const MIN_ITEMS_FOR_TWO_ROWS = 9;

const EditorialVideos: React.FC<IEditorialVideosProps & OverviewItemModel> = ({
  programs,
  activeVideoGuid,
  viewMode,
  presentationType,
  backgroundColor,
  title,
  type,
  isBranded,
  showMoreUrl,
  heroImageUrl,
  logoImageUrl
}) => {
  if (!programs.length) return null;

  const shouldShowInTwoRows =
    presentationType === COLLECTION_PRESENTATION_TYPE.TWO_ROWS &&
    programs.length >= MIN_ITEMS_FOR_TWO_ROWS &&
    type === COLLECTION_TYPE.CLIP_COLLECTION &&
    viewMode !== CLIENT.MOBILE;

  if (isBranded) {
    return (
      <BrandedEditorialVideosStyle data-testid={`editorial-${type}`} backgroundColor={backgroundColor}>
        {viewMode !== CLIENT.MOBILE && heroImageUrl ? (
          <HeroImageVideosWrapperStyle data-testid="heroImage">
            <img src={heroImageUrl} alt={title} />
          </HeroImageVideosWrapperStyle>
        ) : null}
        <BrandedEditorialVideosContentWrapperStyle>
          <BrandedEditorialVideosTitleLogoWrapperStyle>
            <TitleWrapperStyle>
              <SwimlaneTitle showMoreUrl={showMoreUrl} title={title} />
            </TitleWrapperStyle>
            {logoImageUrl ? (
              <LogoWrapperStyle data-testid="logoImage">
                <ImageMedia
                  publicId={logoImageUrl}
                  imageMedia={{ type: IMAGE_MEDIA_TYPE.REMOTE }}
                  transformations={{
                    width: '60',
                    height: '60',
                    fetch_format: 'auto'
                  }}
                />
              </LogoWrapperStyle>
            ) : null}
          </BrandedEditorialVideosTitleLogoWrapperStyle>
          <Carousel>
            <VideoList programList={(programs as VideoModel[]) || []} activeVideoGuid={activeVideoGuid} />
          </Carousel>
        </BrandedEditorialVideosContentWrapperStyle>
      </BrandedEditorialVideosStyle>
    );
  }

  return (
    <div data-testid={`editorial-${type}`}>
      <Grid>
        <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
          <SwimlaneTitle showMoreUrl={showMoreUrl} title={title} />
        </GridElement>
      </Grid>
      <Carousel>
        <VideoList
          programList={(programs as VideoModel[]) || []}
          activeVideoGuid={activeVideoGuid}
          showInTwoRows={shouldShowInTwoRows}
        />
      </Carousel>
    </div>
  );
};

export default EditorialVideos;
