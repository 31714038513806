import React, { FunctionComponent, useState, useEffect } from 'react';
import useImprovedQuery from 'hooks/useImprovedQuery';
import Carousel from 'components/Lists/Carousel';
import Link from 'components/Link';
import ImageMedia from 'components/ImageMedia';
import featureTooling from 'components/FeatureTooling';
import { Heading2 } from 'components/Text/Text.style';
import ErrorMessage from 'components/ErrorMessage';
import { SkeletonBrandList } from 'components/SkeletonElement/SkeletonElement';
import CommonFallback from 'components/CommonFallback';
import ErrorBoundary from 'components/ErrorBoundary';
import { HomeBrandsSectionWrapperStyle } from 'components/Home/Home.style';
import AllBrandsQuery from 'queries/brands/allBrands.query';
import { BrandModel } from 'models/index';
import { Grid, GridElement } from '../../../styles/grid';
import {
  IMAGE_MEDIA_TYPE,
  FEATURE_SLUG,
  FIXED_COUNT,
  TEALIUM_EVENT_CATEGORY,
  TEALIUM_EVENT_LABEL,
  TEALIUM_EVENT_NAME
} from '../../../constants';
import { BrandsSectionItemStyle, BrandsSectionItemGradientStyle } from './BrandsSection.style';

const BrandsHeading = () => (
  <Grid>
    <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
      <Heading2>Merken</Heading2>
    </GridElement>
  </Grid>
);

interface BrandsFeatureVariables {
  brandImages: { [key: string]: string };
  brandColors: { [key: string]: string };
}

const BrandsSection: FunctionComponent = () => {
  const [brandConfig, setBrandConfig] = useState<BrandsFeatureVariables | null>();

  const {
    processedData: brands,
    error,
    loading
  } = useImprovedQuery<{ brands: IBrandGraphql[] }, BrandModel[]>(AllBrandsQuery, {
    variables: {
      limit: FIXED_COUNT.BRANDS_LIMIT
    },
    processData: data => {
      const processedBrands = data.brands.map(brand => new BrandModel(brand));
      return processedBrands.filter(brand => brand.isValid);
    }
  });

  useEffect(() => {
    featureTooling.waitUntilReady().then(() => {
      const variables = featureTooling.getFeatureVariables<BrandsFeatureVariables>(FEATURE_SLUG.SMALL_BRAND_TILES);
      setBrandConfig(variables);
    });
  }, []);

  if (error) {
    return <ErrorMessage />;
  }

  if (loading || brandConfig === undefined) {
    return (
      <HomeBrandsSectionWrapperStyle>
        <BrandsHeading />
        <SkeletonBrandList noOfItems={FIXED_COUNT.SKELETON_BRAND_ITEMS} />
      </HomeBrandsSectionWrapperStyle>
    );
  }

  return (
    <ErrorBoundary onError={(_: any) => <CommonFallback />}>
      <HomeBrandsSectionWrapperStyle data-testid="onDemandBrandsSectionWrapperStyle">
        <div data-testid="brandsSection">
          <BrandsHeading />
          <Carousel>
            {brandConfig
              ? brands?.map((brand, index) => (
                  <BrandsSectionItemGradientStyle
                    data-testid="brandItem"
                    key={brand.id}
                    brandColor={brandConfig.brandColors[brand.slug]}
                  >
                    <Link
                      path={`/brands/${brand.slug}`}
                      clickEventValues={{
                        label: TEALIUM_EVENT_LABEL.BRANDS,
                        name: TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK,
                        category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
                        defaultValues: { event_value: index + 1, content_name: brand.title }
                      }}
                    >
                      <ImageMedia
                        publicId={brandConfig.brandImages[brand.slug] || brand.logoUrl}
                        title={brand.title}
                        imageMedia={{ type: IMAGE_MEDIA_TYPE.REMOTE }}
                        transformations={{ width: '139', crop: 'fill', aspect_ratio: '4:3', fetch_format: 'auto' }}
                        shouldLazyLoad={false}
                      />
                    </Link>
                  </BrandsSectionItemGradientStyle>
                ))
              : brands?.map((brand, index) => (
                  <BrandsSectionItemStyle data-testid="brandItem" key={brand.id}>
                    <Link
                      path={`/brands/${brand.slug}`}
                      clickEventValues={{
                        label: TEALIUM_EVENT_LABEL.BRANDS,
                        name: TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK,
                        category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
                        defaultValues: { event_value: index + 1, content_name: brand.title }
                      }}
                    >
                      <ImageMedia
                        publicId={brand.logoUrl}
                        title={brand.title}
                        imageMedia={{ type: IMAGE_MEDIA_TYPE.REMOTE }}
                        transformations={{ width: '302', crop: 'fill', aspect_ratio: '16:9', fetch_format: 'auto' }}
                      />
                    </Link>
                  </BrandsSectionItemStyle>
                ))}
          </Carousel>
        </div>
      </HomeBrandsSectionWrapperStyle>
    </ErrorBoundary>
  );
};

export default BrandsSection;
