import { gql } from '@apollo/client';

const SourcesQuery = gql`
  query sources($guid: [String]) {
    programs(guid: $guid) {
      items {
        guid
        sources {
          type
          file
          drm
        }
      }
    }
  }
`;

export const SourcesUncachedQuery = gql`
  query sourcesUncached($guid: [String]) {
    programs(guid: $guid) {
      items {
        guid
        sources {
          type
          file
          drm
        }
      }
    }
  }
`;

export default SourcesQuery;
