import React, { FunctionComponent } from 'react';
import { useSession } from 'next-auth/react';
import { IEditorialOverviewProps } from 'typings/IEditorialOverviewProps';
import { IEditorialRowProps } from 'typings/IEditorialRowProps';
import HighlightedSectionModel from 'models/highlightedSection.model';
import OverviewItemModel from 'models/overviewItem.model';
import PromotionCollection from 'models/promotionCollection.model';
import ContinueWatchingSection from 'components/Home/ContinueWatchingSection';
import WatchListSection from 'components/Home/WatchListSection';
import ErrorBoundary from 'components/ErrorBoundary';
import FeaturedPrograms from 'components/FeaturedPrograms';
import { HomeTrendingSectionWrapperStyle } from 'components/Home/Home.style';
import TrendingProgramsQuery from 'queries/featuredPrograms/trendingProgramsQuery.query';
import CommonFallback from 'components/CommonFallback';
import MoviesSection from 'components/Home/MoviesSection';
import MissedSection from 'components/Home/MissedSection';
import BrandsSection from 'components/Home/BrandsSection';
import { COLLECTION_TYPE, FALLBACK_UI_LABELS, PLACEHOLDER } from '../../constants';
import EditorialVideos from '../EditorialVideos';
import EditorialPrograms from '../EditorialPrograms';
import PromoSection from '../PromoSection';
import HighlightedSection from '../HighlightedSection';
import { EditorialRowStyle } from './EditorialOverview.style';
import { HIGHLIGHTED_SECTION_TYPE } from '../../constants/highlightedSectionType';

const EditorialRow: FunctionComponent<IEditorialRowProps> = ({
  item,
  index,
  viewMode,
  activeVideoGuid
}): JSX.Element | null => {
  const { type, title } = item;
  const { status } = useSession();
  const isAuthenticated = status === 'authenticated';

  if (isAuthenticated && type === PLACEHOLDER.CONTINUE_WATCHING) {
    return <ContinueWatchingSection title={title} />;
  }

  if (isAuthenticated && type === PLACEHOLDER.WATCHLIST) {
    return <WatchListSection />;
  }

  if (type === PLACEHOLDER.MISSED_SECTION) {
    return <MissedSection />;
  }

  if (type === PLACEHOLDER.TRENDING) {
    return (
      <ErrorBoundary onError={(_: any) => <CommonFallback message={FALLBACK_UI_LABELS.TRENDING_PROGRAMS} />}>
        <HomeTrendingSectionWrapperStyle data-testid="onDemandPopularSectionWrapperStyle">
          <FeaturedPrograms query={TrendingProgramsQuery} {...item} />
        </HomeTrendingSectionWrapperStyle>
      </ErrorBoundary>
    );
  }

  if (type === PLACEHOLDER.BRANDS_SECTION) {
    return <BrandsSection />;
  }

  if (type === PLACEHOLDER.MOVIES_SECTION) {
    return <MoviesSection isOnSearch={item.isOnSearch} />;
  }

  if (
    type === COLLECTION_TYPE.SERIES_COLLECTION ||
    type === COLLECTION_TYPE.MOVIE_COLLECTION ||
    type === COLLECTION_TYPE.PORTRAIT_COLLECTION
  ) {
    return (
      <EditorialRowStyle data-testid={`editorialRow${index}`}>
        <EditorialPrograms {...(item as OverviewItemModel)} viewMode={viewMode} />
      </EditorialRowStyle>
    );
  }

  if (type === COLLECTION_TYPE.EPISODE_COLLECTION || type === COLLECTION_TYPE.CLIP_COLLECTION) {
    return (
      <EditorialRowStyle data-testid={`editorialRow${index}`}>
        <EditorialVideos {...(item as OverviewItemModel)} activeVideoGuid={activeVideoGuid} viewMode={viewMode} />
      </EditorialRowStyle>
    );
  }

  if (type === 'singlePromotion') {
    const itemPromotionCollection = item as PromotionCollection;
    return itemPromotionCollection.promotions?.length ? (
      <EditorialRowStyle data-testid={`editorialRow${index}`}>
        <PromoSection promotion={itemPromotionCollection.promotions?.[0]} />
      </EditorialRowStyle>
    ) : null;
  }

  if (type === HIGHLIGHTED_SECTION_TYPE.MEDIA_LEFT || type === HIGHLIGHTED_SECTION_TYPE.MEDIA_RIGHT) {
    return <HighlightedSection {...(item as HighlightedSectionModel)} />;
  }
  return null;
};

const EditorialOverview: FunctionComponent<IEditorialOverviewProps> = ({
  activeVideoGuid,
  editorialOverviewItems,
  viewMode
}): JSX.Element | null => {
  if (!editorialOverviewItems?.length) {
    return null;
  }

  return (
    <>
      {editorialOverviewItems.map((item, index) => (
        <EditorialRow key={item.slug} item={item} index={index} viewMode={viewMode} activeVideoGuid={activeVideoGuid} />
      ))}
    </>
  );
};

export default EditorialOverview;
