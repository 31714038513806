import React, { FunctionComponent } from 'react';
import NavItem from '../NavItem';
import { NavListStyle } from './NavList.style';

const NavList: FunctionComponent<INavListProps> = ({ elements, activeItemIndex }: INavListProps) => (
  <NavListStyle data-testid="navList">
    {elements.map((item, index) => (
      <NavItem
        key={item.title}
        title={item.title}
        path={item.path}
        isActive={index === activeItemIndex}
        isTabNav={false}
        scroll
        onClick={item.onClick}
      >
        {item.title}
      </NavItem>
    ))}
  </NavListStyle>
);

export default NavList;
