import { v4 as uuidv4 } from 'uuid';
import featureTooling from 'components/FeatureTooling/FeatureTooling';
import { FEATURE_SLUG } from '../client/constants';
import { getCookie, setCookie } from '../lib/helpers';

const COOKIE_NAME = 'ad_detection';

const id = uuidv4();
const userId = ((): string => {
  if (typeof window === 'undefined') return '';

  const storedId = getCookie(COOKIE_NAME);
  if (storedId) return storedId;
  const newId = uuidv4();
  setCookie(COOKIE_NAME, newId);
  return newId;
})();

const DEFAULT_BAIT_URL = 'https://static.kijk.nl/prebid/ad.gif';

export function isBaitBlocked(): boolean {
  const elem = document.getElementById(id);
  if (
    !elem ||
    elem.offsetParent === null ||
    elem.offsetHeight === 0 ||
    elem.offsetWidth === 0 ||
    elem.clientHeight === 0 ||
    elem.clientWidth === 0
  ) {
    return true;
  }
  if (window.getComputedStyle !== undefined) {
    const elemCS = window.getComputedStyle(elem, null);
    if (
      elemCS &&
      (elemCS.getPropertyValue('display') === 'none' || elemCS.getPropertyValue('visibility') === 'hidden')
    ) {
      return true;
    }
  }

  return false;
}

function createBaitElement(): Promise<boolean> {
  return new Promise(resolve => {
    // clean up
    const elem = document.getElementById(id);
    if (elem) elem.remove();

    const bait = document.createElement('img');
    bait.setAttribute('id', id);
    bait.setAttribute(
      'class',
      'pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads text-ad-links ad-text adSense adBlock adContent adBanner'
    );
    bait.setAttribute('style', 'width: 1px !important; height: 1px !important; position: absolute !important;');

    const baitUrl = `${
      featureTooling.getFeatureVariable(FEATURE_SLUG.ADBLOCK_DETECTION, 'baitURL') || DEFAULT_BAIT_URL
    }?user=${userId}&slot=pre&ad-sense=1&adId=${Date.now()}`;

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function (): void {
      if (this.readyState === 4 && this.status === 200) {
        const url = window.URL || window.webkitURL;
        bait.setAttribute(
          'src',
          typeof url?.createObjectURL === 'function' ? url.createObjectURL(this.response) : baitUrl
        );
        document.body.appendChild(bait);
        return resolve(false);
      }
      if (this.readyState === 4) {
        return resolve(true);
      }
      // request not ready yet
    };
    xhr.open('GET', baitUrl);
    xhr.responseType = 'blob';
    xhr.send();
  });
}

export async function detectAdBlock(): Promise<boolean> {
  return new Promise(resolve => {
    createBaitElement().then(failed => {
      if (failed) {
        resolve(true);
        return;
      }
      setTimeout(() => {
        const baitBlocked = isBaitBlocked();
        resolve(baitBlocked);
      });
    });
  });
}
