import { gql } from '@apollo/client';

export const ContinueWatchingQuery: any = gql`
  query continueWatching {
    continueWatching @client {
      isLoading
      videosContinueWatching {
        guid
        position
        duration
        completed
      }
    }
  }
`;

export const ContinueWatchingByGuidQuery: any = gql`
  query continueWatchingByGuid($guid: String) {
    continueWatchingByGuid(guid: $guid) @client {
      guid
      position
      duration
      completed
    }
  }
`;

export const ContinueWatchingAddVideoMutation: any = gql`
  mutation continueWatching {
    continueWatching @client
  }
`;

export const VideosContinueWatchingMutation: any = gql`
  mutation setVideosContinueWatching($videosContinueWatching: [Video]) {
    setVideosContinueWatching(videosContinueWatching: $videosContinueWatching) @client
  }
`;

export const ActiveVideo: any = gql`
  query activeVideo {
    activeVideo @client(always: true) {
      guid
      position
      duration
      completed
    }
  }
`;

export const ActiveVideoMutation: any = gql`
  mutation activeVideo($video: Video) {
    activeVideo(video: $video) @client
  }
`;

export const getContinueWatchingIsLoading = gql`
  query {
    continueWatching @client {
      isLoading
    }
  }
`;

export const setContinueWatchingIsLoadingMutation = gql`
  mutation setContinueWatchingIsLoading($isLoading: boolean) {
    setUserIsLoading(isLoading: $isLoading) @client
  }
`;

export const deleteVideoProgressMutation = gql`
  mutation deleteVideoProgress($guid: String!) {
    deleteVideoProgress(guid: $guid) {
      __typename
      guid
      completed
      position
    }
  }
`;

export const ContinueWatchingUpdateProgressMutation = gql`
  mutation videoProgress($guid: String!, $completed: Boolean, $position: Float!) {
    videoProgress(guid: $guid, completed: $completed, position: $position) {
      __typename
      guid
      completed
      position
    }
  }
`;
