import { FunctionComponent, ReactElement, useState } from 'react';
import { IPortraitItemProps } from 'typings/IPortraitItemProps';
import RemoveItemDialog from 'components/RemoveItemDialog/RemoveItemDialog';
import RemoveItemCloseButton from 'components/RemoveItemDialog/RemoveItemCloseButton';
import { PortraitItemStyle, ImageWrapperStyle, LabelPortraitStyle } from './PortraitItem.style';
import { ActionLabel } from '../Text/Text.style';
import { PROGRAM } from '../../constants';
import Link from '../Link';
import ImageMedia from '../ImageMedia';
import PlayButton from '../PlayButton';

const PortraitItem: FunctionComponent<IPortraitItemProps> = ({
  removeWatchlistItem,
  program,
  removable
}): ReactElement => {
  const [isRemoveDialogShown, setIsRemoveDialogShown] = useState(false);
  const { guid, type, path, imagePortrait, title, slug, labelText, eventValues } = program;
  const isMovie = type === PROGRAM.MOVIE;
  const isAvailable = program.type === PROGRAM.MOVIE && program.media?.[0]?.availabilityState;

  if (isRemoveDialogShown && removable) {
    return (
      <RemoveItemDialog
        toggleDialog={() => setIsRemoveDialogShown(!isRemoveDialogShown)}
        removeItem={() => removeWatchlistItem?.(guid, eventValues?.defaultValues)}
        isWatchlistPage
      />
    );
  }

  return (
    <div>
      <PortraitItemStyle data-testid="portraitItem" isRemoveDialogShown={isRemoveDialogShown}>
        {removable && <RemoveItemCloseButton toggleDialog={() => setIsRemoveDialogShown(!isRemoveDialogShown)} />}
        <Link
          path={path || (isMovie ? `/films/${slug}/${guid}` : `/programmas/${slug}`)}
          clickEventValues={eventValues}
        >
          <ImageWrapperStyle data-testid={isMovie ? 'filmPortraitImage' : 'itemImage'}>
            <ImageMedia
              imageMedia={imagePortrait}
              title={title}
              transformations={{
                width: 'auto',
                height: '244',
                crop: 'scale',
                aspect_ratio: '2:3',
                fetch_format: 'auto'
              }}
            />
            {isAvailable && <PlayButton />}
            {labelText && (
              <LabelPortraitStyle>
                <ActionLabel data-testid="itemLabel" isPrimary suppressHydrationWarning>
                  {labelText}
                </ActionLabel>
              </LabelPortraitStyle>
            )}
          </ImageWrapperStyle>
        </Link>
      </PortraitItemStyle>
    </div>
  );
};
export default PortraitItem;
