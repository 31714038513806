import React from 'react';
import { EventLog } from '@4tn/talpatv-video-analytics';
import Tooltip from 'components/Tooltip';
import { useRouter } from 'next/router';
import useFeature from 'hooks/useFeature';
import { trackClick, trackView } from 'lib/tealium/provider';
import {
  FEATURE_SLUG,
  TEALIUM_EVENT_CATEGORY,
  TEALIUM_EVENT_LABEL,
  TEALIUM_EVENT_NAME,
  HEADER_SEARCH_TOOLTIP
} from '../../constants';
import SearchButton from './SearchButton';

const FIFTEEN_SECONDS = 15000;
const HALF_SECOND = 500;

enum TOOLTIP_STATE {
  Closed,
  Open,
  Confirmed
}

let clickCounter = 0;

const SearchWithTooltip: React.FC<ISearchFieldProps & { isHeaderHidden: boolean }> = ({ client, isHeaderHidden }) => {
  const router = useRouter();
  const [tooltipState, setTooltipState] = React.useState<TOOLTIP_STATE>(TOOLTIP_STATE.Closed);
  const { enabled: searchTooltipEnabled } = useFeature(FEATURE_SLUG.SEARCH_TOOLTIP);

  React.useEffect(() => {
    if (!searchTooltipEnabled || tooltipState === TOOLTIP_STATE.Confirmed) {
      return;
    }
    if (EventLog.eventLog.some(event => event.event_label === TEALIUM_EVENT_LABEL.SEARCH)) {
      setTooltipState(TOOLTIP_STATE.Confirmed);
      return;
    }

    if (tooltipState === TOOLTIP_STATE.Open) {
      clickCounter = 0;
      setTooltipState(TOOLTIP_STATE.Closed);
    }

    if (router.query.videoId) {
      clickCounter = 0;
      return;
    }

    let showTooltipTimeout: NodeJS.Timeout | undefined;

    const activateTooltip = () => {
      setTooltipState(TOOLTIP_STATE.Open);
      trackView({
        event_name: TEALIUM_EVENT_NAME.POPUP_VIEW,
        event_category: TEALIUM_EVENT_CATEGORY.POPUP,
        event_label: TEALIUM_EVENT_LABEL.SEARCH_TOOLTIP
      });
      cleanup();
    };

    const handleWindowClick = () => {
      clickCounter += 1;
      if (clickCounter >= 6) {
        clearTimeout(showTooltipTimeout);
        showTooltipTimeout = setTimeout(activateTooltip, HALF_SECOND);
        return;
      }
      // Restart timer
      clearTimeout(showTooltipTimeout);
      showTooltipTimeout = setTimeout(activateTooltip, FIFTEEN_SECONDS);
    };

    const cleanup = () => {
      clearTimeout(showTooltipTimeout);
      window.removeEventListener('click', handleWindowClick);
    };

    if (clickCounter >= 6) {
      activateTooltip();
      return;
    }

    showTooltipTimeout = setTimeout(activateTooltip, FIFTEEN_SECONDS);
    window.addEventListener('click', handleWindowClick);

    return () => {
      cleanup();
    };
  }, [searchTooltipEnabled, router]);

  if (tooltipState === TOOLTIP_STATE.Open && !isHeaderHidden) {
    return (
      <Tooltip
        title={HEADER_SEARCH_TOOLTIP.TITLE}
        description={HEADER_SEARCH_TOOLTIP.DESCRIPTION}
        buttonText={HEADER_SEARCH_TOOLTIP.BUTTON_TEXT}
        disableOnHover
        onButtonConfirm={() => {
          setTooltipState(TOOLTIP_STATE.Confirmed);
          trackClick({
            name: TEALIUM_EVENT_NAME.POPUP_CLICK,
            category: TEALIUM_EVENT_CATEGORY.POPUP,
            label: TEALIUM_EVENT_LABEL.SEARCH_TOOLTIP
          });
        }}
        isOpen
      >
        <SearchButton client={client} />
      </Tooltip>
    );
  }
  return <SearchButton client={client} />;
};

export default SearchWithTooltip;
