import styled from 'styled-components';
import { ButtonStyle } from '../Button/Button.style';
import { BookmarkItemStyle } from '../Bookmark/Bookmark.style';
import Colors from '../../styles/colors';

export const Tooltip = styled.div`
  position: static;

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    position: relative;
  }
`;

export const TooltipBottom = styled.div<ITooltipStyleProps>`
  position: absolute;
  width: calc(100vw - 32px);
  margin-top: ${props => (props.showSocialButtons ? '36px' : '45px')};
  padding-top: 9px;
  z-index: 100;
  left: 0;

  &::after {
    display: none;
    content: '';
    position: absolute;
    top: -4px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid rgb(68, 68, 68);
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) {
    left: ${props => (props.showSocialButtons ? '100%' : '320px')};
    margin-top: 44px;
    transform: translateX(-100%);
    width: 320px;
    &::after {
      display: none;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    left: ${props => (props.showSocialButtons ? '100%' : '50%')};
    margin-top: 46px;
    width: 320px;
    transform: ${props =>
      props.showSocialButtons ? 'translateX(-100%)' : props.rightStyle ? 'translateX(-8%)' : 'translateX(-50%)'};
    &::after {
      display: inline-block;
      left: ${props => (props.rightStyle ? '8%' : props.showSocialButtons ? '93%' : '50%')};
      transform: translateX(-50%);
    }
  }
`;

export const TooltipTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: ${Colors.white};
  padding-bottom: 10px;
`;
export const TooltipMessage = styled.div`
  padding: 20px;
  background-color: rgb(68, 68, 68);
  color: ${Colors.white};
  font-size: 16px;
  border-radius: 4px;
  line-height: 24px;
  text-align: left;

  span {
    margin-bottom: 16px;
  }

  ${ButtonStyle} {
    margin-top: 16px;
  }
`;

export const TooltipTrigger = styled.span<{
  displayTooltip: boolean;
  showSocialButtons?: boolean;
}>`
  display: inline-block;
  position: relative;
  width: 100%;

  span {
    display: inherit;
  }

  ${BookmarkItemStyle} {
    border-color: ${props => (props.displayTooltip ? Colors.white : Colors.greyishBrown)};
  }

  &::after {
    display: ${props => (props.displayTooltip ? 'inline-block' : 'none')};
    content: '';
    position: absolute;
    top: -4px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid rgb(68, 68, 68);
    margin-left: ${props => (props.showSocialButtons ? '6px' : '9px')};
    bottom: -6px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) {
    &::after {
      margin-left: ${props => (props.showSocialButtons ? '17px' : '10px')};
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    &::after {
      display: none;
    }
  }

  &:hover {
    ${ButtonStyle} {
      border-color: ${Colors.white};
    }
  }
`;
