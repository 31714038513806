import { isEmpty } from 'lib/helpers';
import { CLOUDINARY } from '../constants';

const REQUIRED_HIGHLIGHTED_COLLECTION_FIELDS = ['title', 'type', 'slug'];

export default class HighlightedSectionModel {
  slug: string;
  title: string;
  type: 'mediaLeft' | 'mediaRight';
  backgroundTransparent: boolean;
  label: string;
  description: string;
  buttonText: string;
  buttonTarget: string;
  buttonLink: string;
  imageUrl = '';
  isValid: boolean;

  constructor(data: IHighlightedSectionData) {
    if (!data) return;
    this.isValid = REQUIRED_HIGHLIGHTED_COLLECTION_FIELDS.every(field => !isEmpty(data, field));
    this.slug = data.slug;
    this.title = data.title;
    this.type = data.type;
    this.backgroundTransparent = data.backgroundTransparent;
    this.label = data.label || '';
    this.description = data.description || '';
    this.buttonText = data.buttonText || '';
    this.buttonTarget = data.buttonTarget || '_self';
    this.buttonLink = data.buttonLink || '';

    const [imageMedia] = data.imageMedia || [];
    if (imageMedia) {
      this.imageUrl = `${CLOUDINARY.BASE_PATH}fetch/w_720,ar_16:9,c_fill,f_auto/${imageMedia.url}`;
    }
  }
}
