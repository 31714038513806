import gql from 'graphql-tag';

export const profileDataMutation = gql`
  mutation profile($action: Action!, $key: String!) {
    profile(action: $action, key: $key) {
      statusCode
      statusReason
      statusMessage
    }
  }
`;

export const getProfileWatchlistQuery = gql`
  query profile {
    profile {
      watchlist
    }
  }
`;
