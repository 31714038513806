import gql from 'graphql-tag';

export const ImageMediaFragment = gql`
  fragment ImageMedia on Program {
    imageMedia {
      url
      title
      label
      type
    }
  }
`;

export const MediaFragment = gql`
  fragment Media on Program {
    media {
      availableDate
      availabilityState
      airedDateTime
      expirationDate
      type
    }
  }
`;

export const RatingsFragment = gql`
  fragment Ratings on Program {
    ratings {
      rating
      subRatings
    }
  }
`;

export const SeriesTvSeasonsFragment = gql`
  fragment SeriesTvSeasons on Program {
    seriesTvSeasons {
      id
      guid
      title
      seasonNumber
    }
  }
`;

export const SourcesFragment = gql`
  fragment Sources on Program {
    sources {
      type
      file
      drm
    }
  }
`;

export const TracksFragment = gql`
  fragment Tracks on Program {
    tracks {
      type
      file
      kind
      label
    }
  }
`;

export const ProgramFragment = gql`
  fragment Program on Program {
    id
    guid
    title
    availableRegion
    type
    slug
    seriesId
    tvSeasonId
    seasonNumber
    tvSeasonEpisodeNumber
    added
    publicationDateTime
    duration
    displayGenre
    metadata
    series {
      id
      guid
      slug
      title
    }
    sources {
      type
      file
      drm
    }
    media {
      availableDate
      availabilityState
      airedDateTime
      expirationDate
      type
    }
    imageMedia {
      url
      title
      label
      type
    }
  }
`;
