import React, { useState, useEffect } from 'react';
import { trackClick } from 'lib/tealium/provider';
import Carousel from '../Lists/Carousel';
import { Heading3 } from '../Text/Text.style';
import { Grid, GridElement } from '../../styles/grid';
import { TabNavWrapperStyle, TabsStyle, TabsWrapperStyle, ItemStyle, UnderlineStyle } from './Tabs.style';
import { TAB_CHANGE_EVENT, TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from '../../constants';

export const Tabs: React.FC<ITabsProps> = ({
  type,
  showSeasonSelector,
  children: childrenProp,
  initialTab,
  onTabChange
}) => {
  const [activeTab, setActiveTab] = useState(initialTab || '');

  const tabs: { name: string; title: string; selected: boolean }[] = [];
  const children = React.Children.map(childrenProp, child => {
    if (!React.isValidElement(child)) {
      return null;
    }
    const { name, title } = child.props;
    const childName = name;
    const selected = childName === activeTab || (!activeTab && tabs.length === 0);
    // remove duplicated
    if (tabs.some(tab => tab.name === childName)) {
      return null;
    }
    tabs.push({
      title,
      selected,
      name: childName
    });
    return React.cloneElement<any>(child, {
      selected,
      name: childName
    });
  });

  useEffect(() => {
    const tabName = activeTab || tabs.find(tab => tab.selected)?.name;
    if (onTabChange && tabName) {
      onTabChange(tabName);
    }
  }, [tabs, onTabChange, activeTab]);

  useEffect(() => {
    function handleTabChange({ detail }: CustomEvent) {
      if (detail.type === type) {
        setActiveTab(detail.tabName);
      }
    }
    window.addEventListener(TAB_CHANGE_EVENT, handleTabChange as EventListener);
    return () => {
      window.removeEventListener(TAB_CHANGE_EVENT, handleTabChange as EventListener);
    };
  }, [type]);

  const handleClick = (name: string, title: string) => () => {
    setActiveTab(name);
    trackClick({
      name: TEALIUM_EVENT_NAME.MENU_CLICK,
      category: TEALIUM_EVENT_CATEGORY.MENU,
      label: `${type} | ${title}`
    });
  };

  return (
    <>
      <TabsWrapperStyle shouldShowSeasonSelector={showSeasonSelector}>
        <TabNavWrapperStyle data-testid="tabNav">
          <Grid>
            <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
              <TabsStyle data-testid="tabsList" shouldShowSeasonSelector={showSeasonSelector}>
                <Carousel smallArrows numberOfTabs={tabs?.length || 0}>
                  {tabs.map(({ name, title, selected }) => (
                    <ItemStyle
                      key={name}
                      isActive={selected}
                      onClick={handleClick(name, title)}
                      data-testid="navItemTab"
                    >
                      <Heading3 align="center" isActive={selected} data-testid="tabTitle">
                        {title}
                      </Heading3>
                      <UnderlineStyle isActive={selected} data-testid="tabItemUnderline" />
                    </ItemStyle>
                  ))}
                </Carousel>
              </TabsStyle>
            </GridElement>
          </Grid>
        </TabNavWrapperStyle>
      </TabsWrapperStyle>
      {children}
    </>
  );
};

export default Tabs;
