import { ReactNode } from 'react';
import { VideoModel } from 'models/index';

// when provided function that returns one react node this function is going to multiply it desired number of times
export const multiplyElement = (
  renderElement: (_: any, index: number) => ReactNode,
  noOfElements: number
): ReactNode[] => Array(noOfElements).fill(null).map(renderElement);

/**
 * Filters out playlist item that have empty sources
 * @param playlist - Array of playlist items from channel or format season
 */
export const filterEmptySourcesPlaylist = (playlist?: VideoModel[], includeGuid?: string): VideoModel[] => {
  if (!playlist) return [];
  return playlist.filter(playlistItem => playlistItem.guid === includeGuid || playlistItem.sources?.length);
};

/**
 * Used for reducing videoList to be array od arrays - two dimensional array, each child array will contain two videos
 * First item is array of first and second video, second one is array of third and fourth video etc.
 * @param programList - Array of videos
 */
export const createTwoDimensionalArray = (programList: VideoModel[]): VideoModel[][] =>
  programList.reduce((accumulator: VideoModel[][], _, currentIndex: number, array: VideoModel[]) => {
    if (currentIndex % 2 === 0) {
      accumulator.push(array.slice(currentIndex, currentIndex + 2));
    }
    return accumulator;
  }, []);

export const shuffleArray = <T = null>(items: T[]): T[] => [...items].sort(() => 0.5 - Math.random());
