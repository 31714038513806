import { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { useSession } from 'next-auth/react';
import { deleteVideoProgressMutation } from 'queries/continueWatching/continueWatchingQuery.query';
import ProgramsByGuidWithSeriesQuery from 'queries/programs/programsByGuidWithSeriesQuery.query';
import useImprovedQuery from 'hooks/useImprovedQuery';
import VideoModel from 'models/video.model';
import ClipModel from 'models/clip.program.model';
import EpisodeModel from 'models/episode.program.model';
import MovieModel from 'models/movie.program.model';
import VideoItem from 'components/VideoItem';
import VideosProgressQuery from 'queries/videos/videosProgressQuery.query';
import { FETCH_POLICY, PROGRAM, FIXED_COUNT } from '../../constants';
import { SkeletonLandscapeItemCarousel, SkeletonLandscapeItemGrid } from '../SkeletonElement/SkeletonElement';
import Carousel from '../Lists/Carousel';

import {
  ContinueWatchingTileStyle,
  ContinueWatchingCarouselListStyle,
  ContinueWatchingItemWrapperStyle
} from './ContinueWatching.style';
import EmptyContinueWatching from './EmptyContinueWatching';
import ErrorMessage from '../ErrorMessage';

const Skeleton: FunctionComponent<{ isCarousel: boolean }> = ({ isCarousel }) =>
  isCarousel ? (
    <SkeletonLandscapeItemCarousel noOfItems={FIXED_COUNT.SKELETON_PROGRAM_NUMBER} />
  ) : (
    <ContinueWatchingTileStyle>
      <SkeletonLandscapeItemGrid noOfItems={FIXED_COUNT.SKELETON_PROGRAM_NUMBER} />
    </ContinueWatchingTileStyle>
  );

const ContinueWatching: FunctionComponent<IContinueWatchingProps> = ({
  isCarousel,
  collectionTitle,
  progressFetchPolicy
}) => {
  const { status } = useSession();
  const isAuthenticated = status === 'authenticated';
  const isUserLoading = status === 'loading';

  const {
    loading: loadingVideos,
    processedData: videoProgress,
    error: errorGuids
  } = useImprovedQuery<{ videoProgress: IVideo[] }, { guids: string[]; progress: IProgressMap }>(VideosProgressQuery, {
    fetchPolicy: progressFetchPolicy || FETCH_POLICY.NETWORK_ONLY,
    variables: { completed: false },
    skip: !isAuthenticated,
    processData: data => {
      if (!data.videoProgress?.length) return null;
      const guids = data.videoProgress.map(({ guid }) => guid);
      const progressMap: IProgressMap = data.videoProgress.reduce(
        (acc: IProgressMap, videoProgress: IVideoProgress) => ({ ...acc, [videoProgress.guid]: videoProgress }),
        {}
      );

      return { guids, progress: progressMap };
    }
  });

  const {
    loading: loadingPrograms,
    processedData: videos,
    error: errorPrograms
  } = useImprovedQuery<{ programs: { items: IProgramGraphql[] } }, VideoModel[]>(ProgramsByGuidWithSeriesQuery, {
    variables: { guid: videoProgress?.guids },
    skip: !videoProgress?.guids.length,
    processData: data => {
      const programs = data.programs.items || [];
      const videoList = programs
        .map((program, index) => {
          const config = {
            showFormatTitle: true,
            collectionTitle,
            position: index + 1
          };

          if (program.type === PROGRAM.MOVIE) return new MovieModel(program);
          if (program.type === PROGRAM.CLIP) return new ClipModel(program, config);
          if (program.type === PROGRAM.EPISODE) return new EpisodeModel(program, config);
          return new VideoModel(program);
        })
        .filter(item => item.isValid);
      return videoList;
    }
  });

  const [removeItem] = useMutation(deleteVideoProgressMutation, {
    refetchQueries: [{ query: VideosProgressQuery, variables: { completed: false } }]
  });

  const removeVideoItem = (guid: string): void => {
    if (!guid) return;
    removeItem({
      variables: { guid }
    });
  };

  if (errorGuids || errorPrograms) {
    return <ErrorMessage />;
  }

  if (loadingPrograms || loadingVideos || isUserLoading) {
    return <Skeleton isCarousel={isCarousel} />;
  }

  if (!videoProgress?.guids.length || !videos?.length || !isAuthenticated) {
    return <EmptyContinueWatching isUserAuthenticated={!!isAuthenticated} />;
  }

  const videoItems = videos.map(videoItem => (
    <ContinueWatchingItemWrapperStyle key={videoItem.id} data-testid="continueWatchingItem">
      <VideoItem
        video={videoItem}
        isActive={false}
        isPosibleRemoveVideoItem
        removeVideoItem={removeVideoItem}
        remoteProgress={videoProgress?.progress[videoItem.guid]}
      />
    </ContinueWatchingItemWrapperStyle>
  ));

  return (
    <div data-testid="continueWatchingSection">
      {isCarousel ? (
        <ContinueWatchingCarouselListStyle data-testid="continueWatchingCarouselList">
          <Carousel data-testid="continueWatchingList">{videoItems}</Carousel>
        </ContinueWatchingCarouselListStyle>
      ) : (
        <ContinueWatchingTileStyle data-testid="continueWatchingTileList">{videoItems}</ContinueWatchingTileStyle>
      )}
    </div>
  );
};

export default ContinueWatching;
