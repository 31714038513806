import { isEmpty } from 'lib/helpers';
import { COLLECTION_TYPE, PLACEHOLDER, CONFIG_TYPES } from '../constants';
import { HIGHLIGHTED_SECTION_TYPE } from '../constants/highlightedSectionType';
import { OverviewItemModel, PromotionCollection, HighlightedSectionModel, PlaceholderModel } from '.';

const REQUIRED_OVERVIEW_FIELDS = ['slug', 'items'];

export default class OverviewModel {
  slug: string;

  items: (OverviewItemModel | PromotionCollection | HighlightedSectionModel | PlaceholderModel)[];

  editorialTabs: {
    slug: string;
    title: string;
    overviewItem?: OverviewItemModel;
    type?: (typeof COLLECTION_TYPE)[keyof typeof COLLECTION_TYPE];
  }[];

  isValid: boolean = false;

  config: IEditorialOverviewData['config'] = null;

  metadata: IEditorialOverviewData['metadata'] = null;

  headerPromotion: PromotionCollection | null;

  type: string;

  constructor(data: IEditorialOverviewData, options: { isOnFormatPage?: boolean; isOnSearch?: boolean } = {}) {
    if (!data) return;
    this.isValid = REQUIRED_OVERVIEW_FIELDS.every(field => !isEmpty(data, field));
    this.slug = data.slug;
    this.type = data.config?.type || CONFIG_TYPES.DEFAULT;
    this.items = data.items
      ? data.items
          .map(item => {
            if (item.type === 'singlePromotion') {
              return new PromotionCollection(item);
            }
            if (item.type === 'headerPromotion') {
              const headerPromotion = new PromotionCollection(item);
              this.headerPromotion = headerPromotion.isValid ? headerPromotion : null;
            }
            if (
              item.type === HIGHLIGHTED_SECTION_TYPE.MEDIA_LEFT ||
              item.type === HIGHLIGHTED_SECTION_TYPE.MEDIA_RIGHT
            ) {
              return new HighlightedSectionModel(item);
            }
            if (Object.values(PLACEHOLDER).includes(item.type)) {
              return new PlaceholderModel(item, options);
            }
            return new OverviewItemModel(item, options);
          })
          .filter(item => item.isValid)
      : [];
    this.editorialTabs =
      data.editorialTabs &&
      data.editorialTabs.map(editorialTab => {
        const overviewItem = this.items && this.items.find(overviewItem => overviewItem.slug === editorialTab.slug);
        return {
          ...editorialTab,
          overviewItem: overviewItem as OverviewItemModel,
          type: overviewItem?.type as OverviewItemModel['type']
        };
      });
    if (data.config) {
      this.config = data.config;
    }
    if (data.metadata) {
      this.metadata = data.metadata;
    }
  }
}
