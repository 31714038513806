import VideoModel from './video.model';

export default class ClipModel extends VideoModel {
  constructor(data: IProgramGraphql, playlistQueryParams: IPlaylistQueryParams = {}) {
    super(data, playlistQueryParams);

    if (this.showFormatTitle) {
      this.thumbnailTitle = this.seriesTitle;
      this.thumbnailSubtitle = this.title;
    } else {
      this.thumbnailTitle = this.title;
    }
    this.subtitle = this.title;
    this.ctaLabel = 'Bekijk clip';
  }
}
