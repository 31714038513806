import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import { observe, unobserve } from 'lib/helpers/intersectionObserver';
import { IMAGE_MEDIA_TYPE, ASPECT_RATIO } from '../../constants';
import { ImageMediaWrapperStyle, ImageMediaInnerStyle } from './ImageMedia.style';

const { publicRuntimeConfig } = getConfig();
const dpr = '2.0';

const ImageMedia: FunctionComponent<IImageMediaComponentProps> = ({
  imageMedia,
  shouldLazyLoad,
  title,
  testId,
  transformations,
  responsive,
  publicId
}) => {
  const imgRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(!shouldLazyLoad);

  useEffect(() => {
    const target = imgRef?.current;
    if (!target) return;

    observe(target, () => setIsVisible(true));
    return () => {
      unobserve(target);
    };
  }, []);

  if (!imageMedia || transformations?.height === 0) {
    return null;
  }

  if (imageMedia.type === IMAGE_MEDIA_TYPE.INVALID) {
    return <img src={imageMedia.src} alt={title} data-testid={testId || 'imageMediaComponent'} />;
  }

  return (
    <ImageMediaWrapperStyle
      aspectRatio={ASPECT_RATIO[transformations?.aspect_ratio || 'default']}
      data-testid="imageMediaWrapper"
      ref={imgRef}
      shouldLazyLoad={shouldLazyLoad}
    >
      <ImageMediaInnerStyle isVisible={isVisible} shouldLazyLoad={shouldLazyLoad}>
        {isVisible && (
          <Image
            data-testid={testId || 'imageMediaComponent'}
            publicId={publicId || imageMedia.src}
            type={imageMedia.type === IMAGE_MEDIA_TYPE.CLOUDINARY ? 'upload' : 'fetch'}
            alt={title}
            responsive={responsive}
            secure
            secure_distribution={publicRuntimeConfig.cloudinary.talpaConfig}
          >
            <Transformation {...transformations} fetchFormat="auto" dpr={dpr} />
          </Image>
        )}
      </ImageMediaInnerStyle>
    </ImageMediaWrapperStyle>
  );
};

ImageMedia.propTypes = {
  shouldLazyLoad: PropTypes.bool
};

ImageMedia.defaultProps = {
  shouldLazyLoad: true
};

export default ImageMedia;
