import { FunctionComponent, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { signIn, useSession } from 'next-auth/react';
import { getProfileWatchlistQuery } from 'queries/user/userQuery.query';
import { trackClick } from 'lib/tealium/provider';
import pausePlayer from 'lib/pausePlayer';
import Button from 'components/Button';
import { handleWatchlistClick } from 'lib/helpers/watchlist';
import { ICONS, SOCIAL_NETWORK, TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from '../../constants';
import Tooltip from '../Tooltip/Tooltip';
import { BookmarkStyle, BookmarkItemStyle } from './Bookmark.style';
import { BOOKMARK_TOOLTIP } from '../../constants/bookmark';

const SOCIAL_BUTTONS = [
  {
    title: SOCIAL_NETWORK.FACEBOOK,
    icon: ICONS.FACEBOOK
  },
  {
    title: SOCIAL_NETWORK.TWITTER,
    icon: ICONS.TWITTER
  }
];

const Bookmark: FunctionComponent<IBookmarkProps> = ({
  programGuid,
  showSocialButtons,
  clickEventValues
}): JSX.Element => {
  const [isInWatchlist, setIsInWatchlist] = useState(false);

  const apollo = useApolloClient();

  const { status } = useSession();
  const isAuthenticated = status === 'authenticated';

  useQuery<{ profile?: { watchlist: string[] } }>(getProfileWatchlistQuery, {
    ssr: false,
    skip: !isAuthenticated,
    onCompleted: data => {
      if (!data.profile) return;
      setIsInWatchlist(data.profile.watchlist?.includes(programGuid));
    }
  });

  const handleShareClick = (shareSite: string): void => {
    pausePlayer();
    const link = typeof window !== 'undefined' ? window.location.href : '';

    if (shareSite) {
      trackClick({
        name: TEALIUM_EVENT_NAME.SHARE_INTERACTION,
        category: TEALIUM_EVENT_CATEGORY.PLAYER_META,
        label: shareSite,
        defaultValues: { ...clickEventValues?.defaultValues }
      });
      shareSite === SOCIAL_NETWORK.TWITTER
        ? window.open(`https://twitter.com/intent/tweet?url=${link}`, '_blank')
        : window.open(`https://www.facebook.com/sharer/sharer.php?u=${link}`, '_blank');
    }
  };

  const handleLoginClick = () => {
    signIn('talpaauth', { redirect: true });
  };

  return (
    <BookmarkStyle isInWatchlist={isInWatchlist}>
      {showSocialButtons &&
        SOCIAL_BUTTONS.map(({ title, icon }) => (
          <Button key={title} onClick={() => handleShareClick(title)}>
            <BookmarkItemStyle data-testid="bookmark-item">
              <img src={icon} data-testid={`${title}-icon`} alt={title} />
            </BookmarkItemStyle>
          </Button>
        ))}
      {isAuthenticated ? (
        <Button
          onClick={() => {
            handleWatchlistClick({
              isInWatchlist,
              programGuid,
              apollo,
              clickEventValues
            });
          }}
        >
          <BookmarkItemStyle data-testid="bookmark-item">
            <img
              src={isInWatchlist ? ICONS.WATCHLIST_ACTIVE : ICONS.WATCHLIST_INACTIVE}
              alt={isInWatchlist ? 'watchlist active' : 'watchlist inactive'}
            />
          </BookmarkItemStyle>
        </Button>
      ) : (
        <Tooltip
          showSocialButtons={showSocialButtons}
          description={BOOKMARK_TOOLTIP.DESCRIPTION}
          buttonText={BOOKMARK_TOOLTIP.BUTTON_TEXT}
          onButtonConfirm={handleLoginClick}
        >
          <Button>
            <BookmarkItemStyle data-testid="bookmark-item">
              <img src={ICONS.WATCHLIST_INACTIVE} data-testid="watchlist-inactive-icon" alt="watchlist inactive" />
            </BookmarkItemStyle>
          </Button>
        </Tooltip>
      )}
    </BookmarkStyle>
  );
};

export default Bookmark;
