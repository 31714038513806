import gql from 'graphql-tag';
import {
  ImageMediaFragment,
  SeriesTvSeasonsFragment,
  RatingsFragment,
  MediaFragment,
  SourcesFragment,
  TracksFragment
} from './programs.fragments';

const ProgramsByGuidQuery: any = gql`
  query programs($guid: [String], $limit: Int) {
    programs(guid: $guid, limit: $limit) {
      items {
        id
        guid
        availableRegion
        slug
        tvSeasonId
        sourceProgram
        type
        title
        sortTitle
        added
        publicationDateTime
        description
        longDescription
        shortDescription
        displayGenre
        tvSeasonEpisodeNumber
        seasonNumber
        seriesId
        duration
        series {
          id
          guid
          slug
          title
        }
        metadata
        ...ImageMedia
        ...Media
        ...SeriesTvSeasons
        ...Sources
        ...Tracks
        ...Ratings
      }
    }
  }
  ${MediaFragment}
  ${ImageMediaFragment}
  ${SeriesTvSeasonsFragment}
  ${SourcesFragment}
  ${TracksFragment}
  ${RatingsFragment}
`;

export default ProgramsByGuidQuery;
