import { ClipModel, EpisodeModel, MovieModel, SeriesModel } from 'models/index';
import { TAB_NAMES, TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from '../../client/constants';

type Model = typeof EpisodeModel | typeof ClipModel | typeof MovieModel | typeof SeriesModel;

export const applyModel = (programs: ISeriesGraphql[] | IProgramGraphql[], Model: Model, collectionTitle: string) =>
  programs?.map((program, index) => {
    const item = new Model(program, { collectionTitle, position: index + 1 });
    item.eventValues.name = TEALIUM_EVENT_NAME.SEARCH_CLICK;
    item.eventValues.category = TEALIUM_EVENT_CATEGORY.SEARCH;
    return item;
  }) || [];

export const getProgramModel = (activeTab: string): Model | undefined => {
  if (activeTab === TAB_NAMES.AFLEVERINGEN) return EpisodeModel;
  if (activeTab === TAB_NAMES.CLIPS) return ClipModel;
  if (activeTab === TAB_NAMES.FILMS) return MovieModel;
  if (activeTab === TAB_NAMES.PROGRAMMAS) return SeriesModel;
};
