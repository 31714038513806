import { FunctionComponent } from 'react';
import { trackClick } from 'lib/tealium/provider';
import Link from '../../Link';
import { Heading3 } from '../../Text/Text.style';
import { ItemStyle, UnderlineStyle } from './NavItem.style';
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from '../../../constants';

export const NavItem: FunctionComponent<INavItemProps> = ({
  isTabNav,
  isActive,
  path,
  onClick,
  hide,
  children,
  title
}) => {
  if (hide) return null;
  if (isTabNav) {
    return (
      <ItemStyle
        isTabNav={isTabNav}
        data-testid="navItemTab"
        isActive={isActive}
        onClick={() => {
          trackClick({
            label: title || null,
            category: TEALIUM_EVENT_CATEGORY.HEADER,
            name: TEALIUM_EVENT_NAME.HEADER_CLICK
          });
          onClick?.();
        }}
      >
        <Heading3 data-testid="tabTitle" align="center">
          {children}
        </Heading3>
        <UnderlineStyle isActive={isActive} data-testid="navItemUnderline" />
      </ItemStyle>
    );
  }
  return (
    <ItemStyle
      isActive={isActive}
      isTabNav={isTabNav}
      data-testid="navItem"
      onClick={() => {
        trackClick({
          label: title || null,
          category: TEALIUM_EVENT_CATEGORY.HEADER,
          name: TEALIUM_EVENT_NAME.HEADER_CLICK
        });
      }}
    >
      <Link path={path}>
        <Heading3 data-testid="tabTitle" align="center">
          {children}
        </Heading3>
        <UnderlineStyle isActive={isActive} data-testid="navItemUnderline" />
      </Link>
    </ItemStyle>
  );
};
export default NavItem;
