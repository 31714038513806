import styled from 'styled-components';
import Colors from '../../styles/colors';
import { ASPECT_RATIO } from '../../constants';

export const ImageMediaWrapperStyle = styled.div<{
  shouldLazyLoad?: boolean;
  aspectRatio: ASPECT_RATIO;
}>`
  position: relative;
  width: 100%;
  padding-top: ${props => (props.shouldLazyLoad ? props.aspectRatio : 0)};
  background-color: ${props => (props.shouldLazyLoad ? Colors.white25 : 'transparent')};
`;
export const ImageMediaInnerStyle = styled.div<ILazyLoadStyleProps>`
  position: ${props => (props.shouldLazyLoad ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity ${props => props.theme.transitions.components.imageMedia.easeIn};

  img {
    width: 100%;
    height: 100%;
  }
`;
