import React, { useEffect, useRef } from 'react';
import Button from 'components/Button';
import { observe, unobserve } from 'lib/helpers/intersectionObserver';
import { FIXED_COUNT } from '../../constants';
import { GridElement } from '../../styles/grid';
import * as Styled from './ShowMoreButton.styled';

const ShowMoreButton: React.FC<IShowMoreButton> = ({ isDisabled, isVisible, type, handleClick, infiniteScroll }) => {
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const target = buttonRef?.current;
    if (!infiniteScroll || !target || !isVisible) return;
    const timeout = setTimeout(observe, FIXED_COUNT.DEBOUNCE_TIME, target, () => handleClick());

    return () => {
      clearTimeout(timeout);
      unobserve(target);
    };
  }, [isDisabled, isVisible]);

  return (
    <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
      <Styled.ShowMoreCenterButton isVisible={isVisible} ref={buttonRef}>
        <Styled.ShowMoreButtonWrapper>
          <Button onClick={handleClick} isDisabled={isDisabled}>{`Bekijk meer ${type.toLowerCase()}`}</Button>
        </Styled.ShowMoreButtonWrapper>
      </Styled.ShowMoreCenterButton>
    </GridElement>
  );
};

export default ShowMoreButton;
