import React, { FunctionComponent } from 'react';
import SVG from 'react-inlinesvg';
import TrendingProgramsQuery from 'queries/featuredPrograms/trendingProgramsQuery.query';
import Link from '../Link';
import { ErrorImageStyle, ErrorHeading1Style, ErrorButtonWrapperStyle } from '../Error/Error.style';
import { Grid, GridElement } from '../../styles/grid';
import { LayoutStyle } from '../Layout/Layout.style';
import { BodyText } from '../Text/Text.style';
import FeaturedPrograms from '../FeaturedPrograms';
import { FEATURED_PROGRAMS, ICONS } from '../../constants';
import Colors from '../../styles/colors';

const ErrorPage: FunctionComponent = () => (
  <div data-testid="errorPage">
    <LayoutStyle data-testid="layoutStyle1">
      <Grid>
        <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
          <ErrorImageStyle>
            <SVG color={Colors.white} src={ICONS.KIJK_TEAR} />
          </ErrorImageStyle>
          <ErrorHeading1Style data-testid="errorHeading" align="center">
            Oeps… er ging iets verkeerd
          </ErrorHeading1Style>
          <BodyText data-testid="errorBodyText" align="center">
            De door jou gezochte pagina is helaas niet (meer) beschikbaar.
            <br />
            Ga naar de homepage of gebruik onze zoekfunctie.
          </BodyText>
          <ErrorButtonWrapperStyle>
            <Link path="/">Terug naar home</Link>
          </ErrorButtonWrapperStyle>
        </GridElement>
      </Grid>
      <FeaturedPrograms query={TrendingProgramsQuery} type={FEATURED_PROGRAMS.TRENDING} title={'Populair'} />
    </LayoutStyle>
  </div>
);

export default ErrorPage;
