import React from 'react';
import { useRouter } from 'next/compat/router';
import featureTooling from '../components/FeatureTooling';

export interface IFeatureState<T> {
  enabled: boolean | null;
  variant: string | null;
  variables: T | null;
}

const useFeature = <T = any>(featureSlug: string): IFeatureState<T> => {
  const router = useRouter();
  const [state, setState] = React.useState<IFeatureState<T>>(() => {
    if (featureTooling.isLoaded) {
      return {
        enabled: featureTooling.isFeatureEnabled(featureSlug),
        variant: featureTooling.getFeatureVariant(featureSlug),
        variables: featureTooling.getFeatureVariables(featureSlug)
      };
    }
    return {
      enabled: null,
      variant: null,
      variables: null
    };
  });

  React.useEffect(() => {
    featureTooling.waitUntilReady().then(() => {
      const newState: IFeatureState<T> = {
        enabled: featureTooling.isFeatureEnabled(featureSlug),
        variant: featureTooling.getFeatureVariant(featureSlug),
        variables: featureTooling.getFeatureVariables(featureSlug)
      };
      // Only update state when enables has changed
      if (newState.enabled === state.enabled) return;
      setState(newState);
    });
  }, [featureSlug, router, state.enabled]);

  return state;
};

export default useFeature;
