import styled from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';

export const BrandsSectionItemStyle = styled.div`
  position: relative;
  min-width: 211px;
  height: 118px;
  border-radius: 4px;
  overflow: hidden;

  span,
  a {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    min-width: 302px;
    height: 170px;
  }
`;

export const BrandsSectionItemGradientStyle = styled.div<{ brandColor: string }>`
  position: relative;
  min-width: 120px;
  max-width: 175px;
  height: 90px;
  border-radius: 4px;
  overflow: hidden;
  background-image: ${props =>
    linearGradient(
      props.theme.gradients.brands.background.normal.direction,
      props.theme.gradients.brands.background.normal.stops
    )};
  transition: transform ${props => props.theme.transitions.easeOut};

  span,
  a {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    min-width: 139px;
    height: 104px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMid}) {
    height: auto;

    img {
      margin-bottom: -3px;
    }
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
      background-image: ${props =>
        linearGradient(props.theme.gradients.brands.background.normal.direction, [
          props.theme.gradients.brands.background.normal.stops[0],
          {
            color: props.brandColor,
            position: '100%'
          }
        ])};
    }
  }
`;
