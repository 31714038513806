import { gql } from '@apollo/client';
import {
  ImageMediaFragment,
  SeriesTvSeasonsFragment,
  RatingsFragment,
  MediaFragment,
  SourcesFragment,
  TracksFragment
} from '../programs/programs.fragments';

const EditorialOverviewQuery: any = gql`
  query overview($slug: String, $client: Client) {
    overview(slug: $slug, client: $client) {
      slug
      config
      editorialTabs {
        slug
        title
      }
      metadata {
        title
        description
      }
      items {
        title
        slug
        type
        ... on Collection {
          onEditorialTabs
          programs {
            id
            guid
            slug
            tvSeasonId
            type
            title
            sortTitle
            added
            publicationDateTime
            description
            longDescription
            shortDescription
            displayGenre
            tvSeasonEpisodeNumber
            seasonNumber
            seriesId
            duration
            series {
              id
              guid
              slug
              title
            }
            metadata
            ...ImageMedia
            ...Media
            ...SeriesTvSeasons
            ...Sources
            ...Tracks
            ...Ratings
          }
          heroImageMedia {
            url
          }
          logoImageMedia {
            url
          }
          backgroundColor
          presentationType
          showFormatTitle
          showMoreUrl
        }
        ... on PromotionCollection {
          promotions {
            title
            slug
            description
            imageMedia {
              url
            }
            program {
              id
              guid
              slug
              tvSeasonId
              type
              title
              sortTitle
              added
              publicationDateTime
              description
              longDescription
              shortDescription
              displayGenre
              tvSeasonEpisodeNumber
              seasonNumber
              seriesId
              duration
              series {
                id
                guid
                slug
                title
              }
              metadata
              ...ImageMedia
              ...Media
              ...SeriesTvSeasons
              ...Sources
              ...Tracks
              ...Ratings
            }
          }
        }
        ... on HighlightedSection {
          backgroundTransparent
          buttonLink
          buttonText
          buttonTarget
          description
          imageMedia {
            url
            label
            title
          }
          label
          slug
          title
          type
        }
      }
    }
  }
  ${MediaFragment}
  ${ImageMediaFragment}
  ${SeriesTvSeasonsFragment}
  ${SourcesFragment}
  ${TracksFragment}
  ${RatingsFragment}
`;

export default EditorialOverviewQuery;
