import React, { ReactNode } from 'react';
import { ApolloClient } from '@apollo/client/core';
import initApollo from './initApollo';

const withApollo = (App: any) =>
  class WithData extends React.Component {
    static displayName = `WithData(${App.displayName})`;

    static async getInitialProps(ctx: any): Promise<any> {
      const {
        ctx: { res, asPath }
      } = ctx;

      const config = {
        graphql: process.env.VIDEO_API_ENDPOINT as string,
        kijkWebApi: process.env.KIJK_WEB_API_ENDPOINT as string
      };
      const apollo = initApollo({}, config, asPath);

      ctx.ctx.apolloClient = apollo;

      let appProps = {};
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx);
      }

      if (res && res.finished) {
        // When redirecting, the response is finished.
        // No point in continuing to render
        return {};
      }

      // Extract query data from the Apollo's store
      const apolloState = apollo.cache.extract();

      return {
        ...appProps,
        apolloState,
        config
      };
    }

    apolloClient: ApolloClient<any> | {};

    constructor(props: any) {
      super(props);
      // `getDataFromTree` renders the component first, the client is passed off as a property.
      // After that rendering is done using Next's normal rendering pipeline
      this.apolloClient = initApollo(props.apolloState, props.config);
    }

    render(): ReactNode {
      return <App {...this.props} apolloClient={this.apolloClient} />;
    }
  };
export default withApollo;
