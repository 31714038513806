import styled from 'styled-components';
import Colors from '../../styles/colors';
import { Heading1 } from '../Text/Text.style';

export const ErrorImageStyle = styled.div`
  width: 150px;
  margin: 0 auto 30px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: 220px;
    margin: 40px auto 30px;
  }
`;

export const ErrorHeading1Style = styled(Heading1)`
  margin-bottom: 16px !important;
`;

export const ErrorButtonWrapperStyle = styled.div`
  max-width: 210px;
  margin: 45px auto 80px;
  line-height: 48px;
  color: ${Colors.white};
  background-color: ${Colors.greenishTeal};
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
  font-weight: 700;
`;
