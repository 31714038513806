import { Component, ReactNode, RefObject, cloneElement } from 'react';
import { withRouter } from 'next/router';
import getConfig from 'next/config';
import Head from 'next/head';
import { CloudinaryContext } from 'cloudinary-react';
import { ThemeProvider } from 'styled-components';
import { toggleHeaderState } from 'queries/headerState/headerStateActions';
import ErrorPage from 'components/ErrorPage';
import GeoblockBanner from 'components/GeoblockBanner';
import isSearchBot from 'lib/isSearchBot';
import GlobalStyle from '../../styles/globalStyle';
import Theme from '../../styles/globalVariables';
import ErrorBoundary from '../ErrorBoundary';
import Header from '../Header';
import Meta from '../Meta';
import Footer from '../Footer';
import SearchModal from '../SearchModal';
import BottomNavigation from '../BottomNavigation';
import { MainStyle, MainContentStyle, MainBottomNavigationWrapperStyle } from './Main.style';
import { FALLBACK_UI_LABELS, CLIENT, IS_CLIENT } from '../../constants';
import CommonFallback from '../CommonFallback';

const { publicRuntimeConfig } = getConfig();

class Main extends Component<IMainProps> {
  private readonly mainStyleRef: RefObject<any>;
  viewMode: string;
  searchBot = IS_CLIENT && isSearchBot();

  constructor(props: IMainProps) {
    super(props);
    this.viewMode = this.props?.viewMode || CLIENT.DESKTOP;
    this.setHeaderState(this.props.isHeaderTransparent);
  }

  componentDidMount(): void {
    if (this.props.viewMode !== CLIENT.DESKTOP) {
      this.viewMode = this.props.viewMode;
    }
  }

  setHeaderState = (isHeaderTransparent: boolean | undefined): void => {
    toggleHeaderState(this.props.client, { isTransparent: !!isHeaderTransparent });
  };

  render(): ReactNode {
    const { isHeaderTransparent, show404, host, router, currentRouteName, children } = this.props;
    const canonicalUrl = `${host}${router.asPath.split('?')[0]}`;
    this.setHeaderState(isHeaderTransparent);

    return (
      <>
        <Head>
          <link rel="canonical" href={canonicalUrl} />
        </Head>
        <ThemeProvider theme={Theme}>
          <CloudinaryContext cloudName={publicRuntimeConfig.cloudinary.cloudName}>
            <MainStyle data-testid="main" ref={this.mainStyleRef}>
              <GlobalStyle isHeaderTransparent={isHeaderTransparent} />
              <Meta viewMode={this.viewMode} host={host} currentRouteName={currentRouteName} />
              <ErrorBoundary onError={_ => <CommonFallback message={FALLBACK_UI_LABELS.MAIN} />}>
                {!this.searchBot && <GeoblockBanner viewMode={this.viewMode} />}
                <Header />
                <MainContentStyle data-testid="mainContent" isHeaderTransparent={isHeaderTransparent}>
                  {show404 ? (
                    <ErrorPage />
                  ) : (
                    cloneElement(children, {
                      viewMode: this.viewMode
                    })
                  )}
                </MainContentStyle>
                <Footer />
                <ErrorBoundary onError={_ => null}>
                  <SearchModal viewMode={this.viewMode} />
                  <MainBottomNavigationWrapperStyle>
                    <BottomNavigation />
                  </MainBottomNavigationWrapperStyle>
                </ErrorBoundary>
              </ErrorBoundary>
            </MainStyle>
          </CloudinaryContext>
        </ThemeProvider>
      </>
    );
  }
}

export default withRouter(Main);
