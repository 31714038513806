import { gql } from '@apollo/client';
import {
  ImageMediaFragment,
  SeriesTvSeasonsFragment,
  RatingsFragment,
  MediaFragment
} from '../programs/programs.fragments';

const Series: any = gql`
  query series($slug: String) {
    series(slug: $slug) {
      items {
        id
        guid
        slug
        sortTitle
        type
        title
        description
        shortDescription
        displayGenre
        metadata
        ...ImageMedia
        ...Media
        ...SeriesTvSeasons
        ...Ratings
      }
    }
  }
  ${MediaFragment}
  ${ImageMediaFragment}
  ${SeriesTvSeasonsFragment}
  ${RatingsFragment}
`;

export default Series;
