import { gql } from '@apollo/client';
import {
  ImageMediaFragment,
  SeriesTvSeasonsFragment,
  RatingsFragment,
  MediaFragment,
  SourcesFragment,
  TracksFragment
} from '../programs/programs.fragments';

const CollectionQuery: any = gql`
  query collection($slug: String) {
    collection(slug: $slug) {
      slug
      type
      onEditorialTabs
      title
      showMoreUrl
      programs {
        id
        guid
        slug
        tvSeasonId
        type
        title
        sortTitle
        added
        publicationDateTime
        description
        longDescription
        shortDescription
        displayGenre
        tvSeasonEpisodeNumber
        seasonNumber
        seriesId
        duration
        series {
          id
          guid
          slug
          title
        }
        metadata
        ...ImageMedia
        ...Media
        ...SeriesTvSeasons
        ...Sources
        ...Tracks
        ...Ratings
      }
    }
  }
  ${MediaFragment}
  ${ImageMediaFragment}
  ${SeriesTvSeasonsFragment}
  ${SourcesFragment}
  ${TracksFragment}
  ${RatingsFragment}
`;

export default CollectionQuery;
