import styled from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';
import {
  VideoItemImageGradientStyle,
  VideoItemImageWrapperStyle,
  VideoItemProgressBarStyle,
  VideoItemStyle
} from 'components/VideoItem/VideoItem.style';
import { DescriptionStyle } from 'components/VideoItem/VideoItemDescription/VideoItemDescription.style';
import { ActionLabel, Heading3, Heading4 } from '../Text/Text.style';
import PlayButtonStyle from '../PlayButton/PlayButton.style';
import Colors from '../../styles/colors';

export const PlayerSidebarStyle = styled.div<{ height?: number }>`
  background: ${props =>
    linearGradient(
      props.theme.gradients.videoHeading.background.direction,
      props.theme.gradients.videoHeading.background.stops
    )};
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 35%;
  padding-left: 24px;
  overflow: hidden;
  padding-right: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.lgGrid}) {
    padding-right: 80px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xlMin}) {
    padding-right: calc((100% - 1760px) / 2);
  }
`;

export const PlayerSidebarTitleStyle = styled.h2`
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 24px;
  font-weight: bold;
  color: ${Colors.white};
`;

export const SidebarVideoListStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  ${VideoItemStyle} {
    flex-direction: row;
    cursor: pointer;
  }

  ${VideoItemImageWrapperStyle} {
    height: 70px;
    border-radius: 4px;

    :first-child {
      flex: 0 1 130px;
    }
  }

  ${PlayButtonStyle} {
    display: none;
  }

  ${VideoItemStyle} {
    height: 70px;
  }

  ${VideoItemImageGradientStyle} {
    bottom: -2px;
  }

  ${VideoItemProgressBarStyle} {
    left: 0;
    width: 110px;
    margin-left: 10px;
    bottom: 0;
  }

  ${DescriptionStyle} {
    flex: 1 1 0%;
    padding-top: 0;
    padding-left: 10px;
    padding-right: 20px;
    width: 0;
    overflow: hidden;
  }

  ${Heading3} {
    font-size: 16px;
  }

  ${Heading4},${Heading3} {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block !important;
  }

  ${ActionLabel} {
    font-size: 12px;
  }
`;
