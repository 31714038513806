import React, { FunctionComponent, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useQuery } from '@apollo/client';
import { getProfileWatchlistQuery } from 'queries/user/userQuery.query';
import ProgramsByGuidQuery from 'queries/programs/programsByGuidQuery.query';
import SeriesModel from 'models/series.model';
import MovieModel from 'models/movie.program.model';
import { SkeletonPortraitItemCarousel } from '../../SkeletonElement/SkeletonElement';
import ErrorMessage from '../../ErrorMessage';
import Link from '../../Link';
import {
  FALLBACK_UI_LABELS,
  ROUTES,
  ICONS,
  SWIMLANE_TITLE,
  TEALIUM_EVENT_NAME,
  TEALIUM_EVENT_CATEGORY,
  PROGRAM,
  FETCH_POLICY
} from '../../../constants';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import CommonFallback from '../../CommonFallback';
import ProgramList from '../ProgramListSection/ProgramList';
import { ClickableTitleStyle, WatchlistSectionWrapper } from '../Home.style';
import { Heading2 } from '../../Text/Text.style';
import { Grid, GridElement } from '../../../styles/grid';

const SKELETON_NUMBER_OF_PROGRAM_ITEMS = 3;
const WATCHLIST_ITEMS_LIMIT = 10;

const WatchlistSection: FunctionComponent = () => {
  const [guids, setGuids] = useState<string[]>([]);
  const [programs, setPrograms] = useState<(SeriesModel | MovieModel)[]>([]);

  const { error, loading } = useQuery<{ profile: { watchlist?: string[] } }>(getProfileWatchlistQuery, {
    ssr: false,
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    onCompleted: data => {
      if (!data.profile?.watchlist) return;
      setGuids(data.profile.watchlist);
    }
  });

  const { error: errorByGuid, loading: loadingByGuid } = useQuery(ProgramsByGuidQuery, {
    variables: { guid: guids, limit: WATCHLIST_ITEMS_LIMIT },
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    skip: !guids.length,
    onCompleted: (data: { programs: { items: IProgramGraphql[] } }) => {
      if (!data.programs.items.length) return;

      const items = data.programs.items
        .map((program, index) => {
          const options = { collectionTitle: SWIMLANE_TITLE.WATCHLIST, position: index + 1 };

          return program.type === PROGRAM.SERIES ? new SeriesModel(program, options) : new MovieModel(program, options);
        })
        .filter(item => item.isValid);

      setPrograms(items);
    }
  });

  if (errorByGuid || error) {
    return <ErrorMessage title={errorByGuid ? 'Mijn Favorieten' : ''} />;
  }

  if (programs.length) {
    return (
      <ErrorBoundary
        onError={(_: any) => <CommonFallback title="Mijn Favorieten" message={FALLBACK_UI_LABELS.FAVORITES} />}
      >
        <WatchlistSectionWrapper data-testid="watchlistSectionWrapper">
          <Grid>
            <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
              <ClickableTitleStyle data-testid="watchlistLink">
                <Heading2 data-testid="watchlistTitle">
                  <Link
                    path={ROUTES.ACCOUNT_WATCHLIST}
                    clickEventValues={{
                      name: TEALIUM_EVENT_NAME.CONTENT_LISTING_MORE,
                      category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
                      label: SWIMLANE_TITLE.WATCHLIST
                    }}
                  >
                    {SWIMLANE_TITLE.WATCHLIST}
                    <SVG src={ICONS.ARROW_SMALL_30} />
                  </Link>
                </Heading2>
              </ClickableTitleStyle>
            </GridElement>
          </Grid>
          {loading || loadingByGuid ? (
            <SkeletonPortraitItemCarousel noOfItems={SKELETON_NUMBER_OF_PROGRAM_ITEMS} />
          ) : (
            <ProgramList programs={programs} sectionType="watchlist" />
          )}
        </WatchlistSectionWrapper>
      </ErrorBoundary>
    );
  }
  return null;
};

export default WatchlistSection;
