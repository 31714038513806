import { NextRouter } from 'next/router';
import { PREVIEW_DOMAIN } from '../client/constants';

export default function updateSearchParams(router: NextRouter, searchTerm: string | null) {
  let path = window.location.pathname;
  const { tab } = router.query;

  if (window.location.host === PREVIEW_DOMAIN) {
    path = path.replace(/^\/[a-z0-9-]+/, '');
  }

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.set('tab', tab as string);
  }

  if (searchTerm != null) {
    searchParams.set('zoek', searchTerm);
  }

  const queryString = searchParams.toString();

  router.push(`${path}${queryString && `?${queryString}`}`, undefined, { shallow: true });
}
