import { gql } from '@apollo/client';
import { BaseProperties } from './brands.fragment';

const AllBrandsQuery: any = gql`
  query brands($limit: Int) {
    brands(limit: $limit) {
      ...BaseProperties
    }
  }
  ${BaseProperties}
`;
export default AllBrandsQuery;
