import styled from 'styled-components';
import Colors from '../../styles/colors';

const PlayButtonStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  & svg {
    height: 54px;
    width: 54px;
    border-radius: 50%;
    transition: opacity ${props => props.theme.transitions.easeIn},
      background-color ${props => props.theme.transitions.easeIn};
    background-color: ${Colors.black70};

    &:hover {
      background-color: ${Colors.black};
    }
  }
`;

export default PlayButtonStyle;
