import styled from 'styled-components';
import Colors from '../../styles/colors';

export const BottomNavigationStyle = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  width: 100%;
  position: fixed;
  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: ${Colors.black};
  border-top: 1px solid ${Colors.greyishBrown};
`;

export const BottomNavigationItemStyle = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  height: 60px;
  position: relative;
  padding: 0 20px 12px;
  cursor: pointer;

  ::before {
    content: '';
    display: none;
    width: 60px;
    height: 3px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 12px;
    margin: auto;
    border-radius: 1.5px;
    background-color: ${Colors.violet};
  }

  svg {
    color: ${Colors.brownGrey};
  }

  ${props =>
    props.isActive &&
    `
    ::before {
      display: block;
    }

    svg{
      color: ${Colors.white}
    }
  `}
`;

export const VisuallyHiddenIconText = styled.span`
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
