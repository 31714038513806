import styled from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';
import { ButtonStyle } from '../Button/Button.style';
import { Heading1, BodyText } from '../Text/Text.style';
import { TextLabelStyle } from '../VideoItem/VideoItemDescription/VideoItemDescription.style';
import { PlayEpisodeButtonStyle } from '../Link/Link.style';
import PlayButtonStyle from '../PlayButton/PlayButton.style';
import Colors from '../../styles/colors';

export const PromoSectionStyle = styled.div`
  position: relative;
  padding-bottom: 0;
  background: ${Colors.black};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    padding-bottom: 72px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) and (orientation: landscape) {
    padding-top: 50px;
    padding-bottom: 50px;

    ${BodyText} {
      font-size: 16px;
      width: auto;
      top: 100px;
    }

    ${Heading1} {
      font-size: 24px;
      margin-top: -10px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) and (orientation: landscape) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    padding-top: 72px;
    padding-bottom: 72px;
  }
`;

export const PromoSectionInnerStyle = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1760px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) and (orientation: landscape),
    (min-width: ${props => props.theme.breakpoints.lgMin}) {
    flex-direction: row;
    align-items: stretch;
    margin: auto 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
    margin: auto 80px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    margin: auto;
  }
`;

export const PromoSectionImageStyle = styled.div`
  position: relative;
  width: 100%;

  a > div::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: ${props =>
      linearGradient(
        props.theme.gradients.promoSection.background.direction,
        props.theme.gradients.promoSection.background.stops
      )};

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
        props.theme.breakpoints.mdMax}) and (orientation: landscape),
      (min-width: ${props => props.theme.breakpoints.lgMin}) {
      display: none;
    }
  }

  a > div {
    position: relative;
    padding-top: 75%;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      padding-top: 56.25%;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
        props.theme.breakpoints.mdMax}) and (orientation: landscape) {
      padding-top: 60%;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
        props.theme.breakpoints.mdMid}) and (orientation: landscape) {
      top: 10%;
    }
  }

  ${PlayButtonStyle} {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding-top: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) and (orientation: landscape),
    (min-width: ${props => props.theme.breakpoints.lgMin}) {
    flex: 1;
  }
`;

export const PromoSectionDescriptionStyle = styled.div`
  position: relative;
  margin: -42px 16px 56px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin: 16px 24px 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
      props.theme.breakpoints.mdMid}) and (orientation: landscape) {
    width: 280px;
    height: auto;
    margin: 0 0 0 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) and (orientation: landscape) {
    width: 360px;
    height: auto;
    margin: 0 0 0 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: 520px;
    height: auto;
    margin: 0 0 0 24px;
    align-self: center;
  }
`;

export const PromoSectionButtonsContainerStyle = styled.div`
  display: flex;
  margin-top: 24px;

  a {
    margin-right: 16px;
    ${PlayEpisodeButtonStyle}
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 32px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) and (orientation: landscape) {
    margin-top: 15px;
  }

  ${ButtonStyle} {
    margin-top: 0;
  }
`;

export const PromoSectionContentStyle = styled.div`
  display: inline-block;
  z-index: 2;

  ${Heading1} {
    margin-bottom: 8px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${TextLabelStyle} {
    top: -33px;
    left: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.smMax}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) {
    ${Heading1} {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    position: relative;
    top: 15%;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMid}) {
    position: relative;
  }
`;
