import styled from 'styled-components';
import { BodyText } from '../Text/Text.style';
import { CarouselItemsStyle } from '../Lists/Carousel/Carousel.style';
import {
  SkeletonVideoItemWrapperStyle,
  SkeletonVideoItemStyle,
  SkeletonVideoItemTextStyle,
  SkeletonVideoItemTextWrapperStyle
} from '../SkeletonElement/SkeletonElement.style';

export const ContinueWatchingStyle = styled.div`
  min-height: 336px;

  ${BodyText} {
    margin-bottom: 20px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    min-height: 536px;
  }
`;

export const ContinueWatchingTileStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 17px 16px;
  margin-top: 24px;
  margin-bottom: 50px;

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    ${SkeletonVideoItemWrapperStyle} {
      display: grid;
      grid-gap: 20px;
      max-width: 320px;
      height: 240px;
    }

    ${SkeletonVideoItemStyle} {
      height: 160px;
    }

    ${SkeletonVideoItemTextStyle} {
      top: 16px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    grid-template-columns: repeat(auto-fill, minmax(229px, 1fr));
    grid-gap: 47px 16px;
    margin-top: 64px;
    margin-bottom: 120px;

    ${SkeletonVideoItemWrapperStyle} {
      div:last-child {
        bottom: 0;
      }
    }
    ${SkeletonVideoItemTextWrapperStyle} {
      div:last-child {
        bottom: -15px;
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    grid-template-columns: repeat(auto-fill, minmax(247px, 1fr));
    grid-gap: 33px 21px;
  }
`;

export const ContinueWatchingTitleStyle = styled.div`
  margin-top: 32px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 64px;
  }
`;

export const ContinueWatchingEmptyVideoListStyle = styled.div`
  width: 100%;
  margin-top: 16px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: 75%;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: 45%;
  }

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    margin-bottom: 50px;
  }
`;

export const ContinueWatchingCarouselListStyle = styled.div`
  ${CarouselItemsStyle} {
    padding-top: 11px;
  }

  ${SkeletonVideoItemWrapperStyle} {
    width: 302px;
    height: 200px;
    min-width: 52vw;
    padding-top: 0;
    box-sizing: border-box;

    @media (min-width: ${props => props.theme.breakpoints.smMid}) {
      min-width: unset;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
      div:last-child {
        bottom: -40px;
      }
    }
  }

  ${SkeletonVideoItemStyle} {
    width: 100%;
    height: 109px;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      height: 170px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      height: auto;
    }
  }

  ${SkeletonVideoItemTextStyle} {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }
`;

export const ContinueWatchingItemWrapperStyle = styled.div`
  display: block;
`;
