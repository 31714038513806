import styled from 'styled-components';
import { WideScreenDivStyle } from 'components/PlayerContainer/PlayerContainer.style';
import Colors from '../../styles/colors';

export const SkeletonElementStyle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: ${Colors.white25};
`;

export const SkeletonContainerStyle = styled(SkeletonElementStyle)`
  position: relative;
  background-color: ${Colors.transparent};
`;

export const SkeletonBrandItemStyle = styled(SkeletonElementStyle)`
  min-width: 120px;
  height: 90px;
  border-radius: 4px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    min-width: 139px;
    height: 104px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMid}) {
    min-width: 160px;
    min-height: 115px;
  }
`;

export const SkeletonProgramItemTitleStyle = styled(SkeletonElementStyle)<{
  width?: string;
  height?: string;
  marginTop?: string;
  marginBottom?: string;
}>`
  position: relative;
  width: ${props => (props.width && props.width) || '100%'};
  height: ${props => (props.height && props.height) || '10px'};
  margin-top: ${({ marginTop }) => marginTop};
  background-color: ${Colors.white25};
  margin-bottom: ${({ marginBottom }) => marginBottom || '18px'};
  border-radius: 0;
`;

export const SkeletonProgramItemImageStyle = styled(SkeletonElementStyle)`
  width: 115px;
  height: 172px;
  margin-bottom: 14px;
  border-radius: 4px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: 162px;
    height: 243px;
  }
`;

export const SkeletonGridItemImageStyle = styled(SkeletonElementStyle)`
  width: 100%;
  margin-bottom: 14px;
  border-radius: 4px;
`;

export const SkeletonProgramItemStyle = styled(SkeletonContainerStyle)`
  width: 115px;
  margin: 0 16px 0 0;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: 162px;
    margin: 0 24px 0 0;
  }
`;

export const SkeletonGridItemStyle = styled(SkeletonContainerStyle)`
  width: 100%;
  height: 173px;
  margin-bottom: 14px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: 243px;
  }
`;

export const SkeletonVideoPlayerStyle = styled(WideScreenDivStyle)`
  background-color: ${Colors.black};
  position: relative;
`;

export const SkeletonVideoMetaDataTitleStyle = styled.div`
  height: 117px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: 124px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    height: 136px;
  }
`;

export const SkeletonVideoMetaTextStyle = styled(SkeletonElementStyle)<{ width: number; height: number }>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  margin-bottom: 12px;
  border-radius: 0;
  background: ${Colors.white25};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    &:first-child {
      margin-top: 15px;
    }
  }
`;

export const SkeletonVideoMetaIconStyle = styled(SkeletonElementStyle)`
  width: 48px;
  height: 48px;
  margin-right: 8px;
  background: ${Colors.white25};
`;

export const SkeletonVideoMetaWrapIconsStyle = styled.div`
  display: flex;
  top: 16px;
  right: 0;
  margin-bottom: 24px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    position: absolute;
  }
`;

export const SkeletonVideoMetaDataStyle = styled(SkeletonContainerStyle)<{ maxWidth: number | null }>`
  position: relative;
  margin: 0 auto;
  padding: 10px 16px 0;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};
`;

interface ISkeletonVideoItemTextStyle {
  width?: string;
  height?: string;
  smWidth?: string;
  smHeight?: string;
  mdWidth?: string;
  mdHeight?: string;
  lgWidth?: string;
  lgHeight?: string;
}

export const SkeletonVideoItemTextStyle = styled.div<ISkeletonVideoItemTextStyle>`
  position: relative;
  bottom: -16px;
  width: ${props => (props.smWidth && props.smWidth) || (props.width && props.width) || '100%'};
  height: ${props => (props.smHeight && props.smHeight) || (props.height && props.height) || '10px'};
  background-color: ${Colors.white25};
  margin-bottom: 11px;
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: ${props => (props.mdWidth && props.mdWidth) || (props.width && props.width) || '100%'};
    height: ${props => (props.mdHeight && props.mdHeight) || (props.height && props.height) || '10px'};
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: ${props => (props.lgWidth && props.lgWidth) || (props.width && props.width) || '100%'};
    height: ${props => (props.lgHeight && props.lgHeight) || (props.height && props.height) || '10px'};
  }
`;

export const SkeletonVideoItemWrapperStyle = styled.div`
  position: relative;
  margin-bottom: 50px;
  padding-top: 56.25%;
  box-sizing: border-box;

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    max-width: 340px;
    display: flex;
    height: 75px;
    padding-top: 0;
    margin-bottom: 0;
    div:first-child {
      position: relative;
      flex: 1;
      padding-top: 0;
    }
    div:nth-child(2) {
      bottom: 65px;
    }
    div:nth-child(3) {
      bottom: 45px;
    }
    div:last-child {
      flex: 1;
      bottom: 25px;
      padding-left: 11px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    div:nth-child(3) {
      bottom: -20px;
    }
    div:last-child {
      bottom: -30px;
    }
  }
`;

export const SkeletonVideoItemStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 56.25%;
  border-radius: 4px;
  background-color: ${Colors.white25};
`;

export const SkeletonVideoItemTextWrapperStyle = styled.div`
  position: relative;
`;

export const SkeletonPromoHeadingStyle = styled.div`
  position: relative;
  margin-bottom: 16px;
  height: 50vh;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-bottom: 80px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    height: 40vh;
  }

  @media (min-height: ${props => props.theme.breakpoints.mdMid}) and (max-width: ${props =>
      props.theme.breakpoints.lgMid}) {
    height: 55vh;
  }

  @media (max-height: ${props => props.theme.breakpoints.smMax}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) and (orientation: landscape) {
    height: 75vh;
  }
`;

export const SkeletonPromoHeadingInnerWrapperStyle = styled.div`
  width: auto;
  height: 100%;
  margin: auto 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin: auto 80px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    max-width: 1760px;
    margin: 0 auto;
  }
`;

export const SkeletonPromoHeadingTitleStyle = styled.div`
  width: 240px;
  height: 18px;
  background-color: ${Colors.white25};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: 300px;
    height: 21px;
  }
`;

export const SkeletonPromoHeadingDescriptionWrapperStyle = styled.div`
  margin-top: 19px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 28px;
  }
`;

export const SkeletonPromoHeadingDescriptionStyle = styled.div`
  width: 288px;
  height: 12px;
  margin-top: 8px;
  background-color: ${Colors.white25};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: 474px;
    margin-top: 12px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: 519px;
  }
`;

export const SkeletonPromoHeadingDescriptionLastStyle = styled(SkeletonPromoHeadingDescriptionStyle)`
  width: 170px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: 260px;
  }
`;

export const SkeletonPromoHeadingButtonWrapperStyle = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 32px;
  }
`;

export const SkeletonPromoHeadingWatchStyle = styled.div`
  width: 150px;
  height: 48px;
  margin-right: 16px;
  border-radius: 4px;
  background-color: ${Colors.white25};
`;

export const SkeletonPromoHeadingAddButtonStyle = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  background-color: ${Colors.white25};
`;

export const SkeletonVideoItemFormatIndexStyle = styled.div<{
  hasSeasonSelector?: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  max-width: 1000px;

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    margin-top: ${props => (props.hasSeasonSelector ? '52px' : 0)};

    ${SkeletonVideoItemWrapperStyle} {
      div:first-child {
        width: 133px;
        height: 75px;
        flex: 0 0 auto;
      }

      div:nth-child(2) {
        position: absolute;
        top: 5px;
        left: 140px;
        width: calc(90% - 133px);
      }

      div:nth-child(3) {
        position: absolute;
        top: 24px;
        left: 140px;
        width: calc(70% - 133px);
      }
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    grid-template-columns: repeat(2, minmax(187px, 1fr));
    height: 260px;
    overflow: hidden;
    max-width: unset;

    ${SkeletonVideoItemWrapperStyle} {
      div:last-child {
        bottom: -15px;
      }
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    grid-template-columns: repeat(4, minmax(226px, 1fr));
    grid-gap: 24px;
  }
  @media (min-width: ${props => props.theme.breakpoints.lgMid}) {
    grid-template-columns: repeat(5, minmax(226px, 1fr));
    gap: 33px 21px;
  }
`;

export const SkeletonHomeMissedContainerStyle = styled.div`
  padding-top: 46px;
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    padding-top: 56px;
  }
`;

export const SkeletonHomeMissedListWrapperStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-bottom: 78px;

  span {
    display: flex;

    a {
      display: block;
      width: 100%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.mdMax}) {
    ${SkeletonVideoItemWrapperStyle}:nth-child(7n+1) {
      display: none;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    ${SkeletonVideoItemWrapperStyle} {
      &:nth-child(2n + 1) {
        display: none;
      }
      div:first-child {
        width: 133px;
        height: 75px;
        flex: 0 0 auto;
      }

      div:nth-child(2) {
        position: absolute;
        top: 5px;
        left: 140px;
        width: calc(70% - 133px);
      }

      div:nth-child(3) {
        position: absolute;
        top: 24px;
        left: 140px;
        width: calc(90% - 133px);
      }

      div:last-child {
        display: none;
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 24px;
  }
`;

export const SkeletonGridWrapperStyle = styled.div<{ noOfItemsInRow?: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.noOfItemsInRow || 'auto-fill'}, minmax(115px, 1fr));
  grid-gap: 30px 16px;

  ${SkeletonProgramItemStyle} {
    width: 100%;
    min-width: unset;
    margin-bottom: 0 !important;
  }

  ${SkeletonProgramItemImageStyle} {
    height: unset !important;
    padding-top: 150%;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    grid-row-gap: 39px;
    grid-template-columns: repeat(${props => props.noOfItemsInRow || 'auto-fill'}, minmax(162px, 1fr));
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    grid-gap: 42px 24px;
  }
`;

export const SkeletonBrandPromoItemStyle = styled.div`
  width: 100%;
  min-width: 211px;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${Colors.white25};
  padding-top: 56.25%;
`;

export const SkeletonTitleWrapperStyle = styled.div`
  grid-column: 1 / 1;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    grid-column: 1 / 5;
  }
`;

export const SkeletonAToZPickerStyle = styled.div`
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  z-index: 1;
  background-color: ${Colors.black};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    transform: translateY(70px);
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    transform: translateY(80px);
  }
`;

export const SkeletonAToZSectionTitleStyle = styled(SkeletonElementStyle)`
  height: 40px;
  width: 80px;
  margin-top: 36px;
  margin-bottom: 14px;

  @media (min-width: ${props => props.theme.breakpoints.smMax}) {
    margin-top: 50px;
    margin-bottom: 19px;
  }
`;

export const SkeletonAToZFormatStyle = styled(SkeletonProgramItemStyle)`
  &:first-child {
    margin-left: 0 !important;
  }
`;

export const SkeletonMovieSectionTitle = styled(SkeletonElementStyle)`
  height: 40px;
  width: 200px;
  margin-bottom: 24px;
`;

export const SkeletonListItem = styled.div`
  position: relative;
  max-width: 340px;
  display: flex;
  height: 75px;
  padding-top: 0;
  margin-bottom: 15px;
  ${SkeletonProgramItemTitleStyle} {
    position: absolute;
    left: 140px;
  }
`;

export const SkeletonListThumbnail = styled.div`
  width: 130px;
  height: 100%;
  border-radius: 4px;
  background-color: ${Colors.white25};
`;

export const SkeletonVideoItemsWrapperStyle = styled.div`
  max-width: 1920px;
  margin: auto;
  margin-top: 55px;

  ${SkeletonPromoHeadingTitleStyle} {
    margin-left: 16px;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      margin-left: 24px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
      margin-left: 80px;
    }
  }
`;

export const SkeletonBrandsGridWrapperStyle = styled.div`
  margin-top: 10px;

  ${SkeletonGridWrapperStyle} {
    margin-top: 24px;
  }
`;

export const SkeletonPortraitCarouselTitleWrapper = styled.div`
  margin-top: 48px;
  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    margin-top: 45px;
  }

  ${SkeletonPromoHeadingTitleStyle} {
    margin-bottom: 8px;
    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      margin-bottom: 24px;
    }
  }
`;

export const SkeletonLandscapeCarouselTitleWrapper = styled.div`
  margin-top: 48px;
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 39px;
    margin-bottom: 26px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    margin-top: 48px;
  }

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    margin-top: 35px;
  }

  ${SkeletonPromoHeadingTitleStyle} {
    margin-bottom: 10px;

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      margin-bottom: 26px;
    }
  }
`;

export const SkeletonSearchResultsWrapperStyle = styled.div`
  ${SkeletonAToZPickerStyle} {
    @media (max-width: ${props => props.theme.breakpoints.smMax}) {
      top: unset;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      top: 10px;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
      top: 0px;
    }
  }

  ${SkeletonProgramItemStyle} {
    margin-left: 0px;
  }

  ${SkeletonVideoItemWrapperStyle}, ${SkeletonProgramItemStyle} {
    margin-right: 16px;

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      margin-right: 24px;
    }
  }

  ${SkeletonPromoHeadingTitleStyle} {
    width: 212px;
    height: 30px;

    @media (max-width: ${props => props.theme.breakpoints.smMax}) {
      height: 22px;
      width: 162px;
    }
  }

  ${SkeletonVideoItemWrapperStyle} {
    height: 225px;
    padding-top: 129px;
    min-width: 211px;
    margin-bottom: 0px;

    @media (max-width: ${props => props.theme.breakpoints.smMax}) {
      display: block;
      padding-top: 0;
      div:first-child {
        position: relative;
        padding-top: 119px;
      }
      div {
        bottom: -16px;
      }
    }
    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      min-width: 229px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      height: 260px;
      min-width: 302px;
      padding-top: 170px;
    }
  }

  ${SkeletonPromoHeadingTitleStyle}, ${SkeletonVideoItemWrapperStyle} {
    margin-left: 0px;
  }

  ${SkeletonProgramItemTitleStyle} {
    margin-top: 88px;
    width: 116px;
    height: 12px;

    @media (max-width: ${props => props.theme.breakpoints.smMax}) {
      margin-top: 78px;
      height: 12px;
      width: 120px;
    }
  }
`;
