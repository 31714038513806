export const ROUTE_PATHS = {
  home: '/',
  'account-continuous-watching': '/account/kijk-verder',
  'account-watchlist': '/account/favorieten',
  ads: '/ads.txt',
  'app-ads': '/app-ads.txt',
  'brand-index': '/brands',
  'health-check': '/elb-status',
  'movie-index': '/films',
  connect: '/koppelen',
  about: '/over',
  'format-a-to-z': '/programmas',
  'format-missed-index': '/programmas/gemist',
  robots: '/robots.txt',
  sitemap: '/sitemap.xml',
  'test-page': '/test-page',
  'unsupported-browser': '/unsupported-browser',
  // Dynamic Pages
  'brand-overview': '/brands/:brandSlug',
  'movie-detail': '/films/:videoSlug/:videoId',
  'format-index': '/programmas/:formatSlug',
  'format-video-detail': '/programmas/:formatSlug/:videoId',
  'overview-page': '/:slug'
};

export const ROUTES = {
  ABOUT: ROUTE_PATHS.about,
  ACCOUNT_WATCHLIST: ROUTE_PATHS['account-watchlist'],
  ACCOUNT_CONTINUOUS_WATCHING: ROUTE_PATHS['account-continuous-watching'],
  HOME: ROUTE_PATHS.home,
  FORMAT_A_TO_Z: ROUTE_PATHS['format-a-to-z'],
  MOVIE_INDEX: ROUTE_PATHS['movie-index'],
  FORMAT_MISSED_INDEX: ROUTE_PATHS['format-missed-index'],
  UNSUPPORTED_BROWSER: ROUTE_PATHS['unsupported-browser'],
  CONNECT: ROUTE_PATHS.connect,
  // Dynamic Pagess
  BRAND_OVERVIEW: '/brands/[brandSlug]',
  MOVIE_DETAIL: 'films/[videoSlug]/[videoId]',
  FORMAT_INDEX: '/programmas/[formatSlug]',
  FORMAT_VIDEO_DETAIL: '/programmas/[formatSlug]/[videoId]',
  DYNAMIC_OVERVIEW_PAGE: '/[slug]'
};
