import { FunctionComponent } from 'react';
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from '../../constants';
import Link from '../Link';
import { LogoStyle } from './Logo.style';

const Logo: FunctionComponent<ILogoProps> = props => (
  <Link
    data-testid="homeLink"
    path="/"
    clickEventValues={{
      label: TEALIUM_EVENT_LABEL.LOGO,
      name: TEALIUM_EVENT_NAME.HEADER_CLICK,
      category: TEALIUM_EVENT_CATEGORY.HEADER
    }}
  >
    <LogoStyle
      largeHeight={props.largeHeight}
      mediumHeight={props.mediumHeight}
      smallHeight={props.smallHeight}
      data-testid="logo"
    >
      <img data-testid="logoImage" src={'/static/logos/kijk2.svg'} alt="KIJK" />
    </LogoStyle>
  </Link>
);

export default Logo;
