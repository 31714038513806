import slugify from 'slugify';
import { getPublicId } from 'lib/helpers/cloudinary';
import { getStaticAsset } from 'lib/helpers/resources';
import {
  IMAGE_MEDIA_TYPE,
  IMAGE_VIEW_TYPE,
  DEFAULT_IMAGES,
  CLOUDINARY,
  TEALIUM_EVENT_CATEGORY,
  TEALIUM_EVENT_NAME
} from '../constants';

export default class ProgramModel {
  guid: string;
  id: string;
  slug: string;
  type: string;
  displayGenre: string;
  title: string;
  description: string;
  shortDescription: string;
  media: IMediaGraphql[];
  ratings: IRatingGraphql[];
  image: string;
  metadata: { [key: string]: string | boolean | number | null };

  imagePortrait: IImageProps = {
    alt: '',
    type: IMAGE_MEDIA_TYPE.REMOTE,
    label: IMAGE_VIEW_TYPE.EDITORIAL_PORTRAIT,
    src: getStaticAsset(DEFAULT_IMAGES.PORTRAIT)
  };
  imageLandscape: IImageProps = {
    alt: '',
    type: IMAGE_MEDIA_TYPE.REMOTE,
    label: IMAGE_VIEW_TYPE.EDITORIAL_LANDSCAPE,
    src: getStaticAsset(DEFAULT_IMAGES.LANDSCAPE)
  };

  eventValues: ITealiumClickEvent;

  constructor(data: ISeriesGraphql | IProgramGraphql, options: IPlaylistQueryParams = {}) {
    this.guid = data.guid;
    this.id = data.id;
    this.slug = data.slug;
    // ! Temp workaround since useQuery occasionally changes the slug to null
    if (!this.slug && data.title) {
      this.slug = slugify(data.title, {
        remove: /[^\w\s$_+-]/g,
        lower: true
      });
    }

    this.type = data.type;
    this.title = data.title || '';
    this.description = data.description || '';
    this.shortDescription = data.shortDescription || '';
    this.ratings = data.ratings || [];
    this.displayGenre = data.displayGenre || '';
    this.media = data.media || [];

    this.imagePortrait.alt = this.title;
    this.imageLandscape.alt = this.title;
    this.metadata = data.metadata;

    const portraitImage = data.imageMedia?.find(img => img.label === IMAGE_VIEW_TYPE.EDITORIAL_PORTRAIT);
    if (portraitImage) {
      const portraitImageId = getPublicId(portraitImage.url);
      this.imagePortrait.type = portraitImageId ? IMAGE_MEDIA_TYPE.CLOUDINARY : IMAGE_MEDIA_TYPE.REMOTE;
      this.imagePortrait.src = portraitImageId || portraitImage.url;
    }

    const landscapeImage = data.imageMedia?.find(img => img.label === IMAGE_VIEW_TYPE.EDITORIAL_LANDSCAPE);
    if (landscapeImage) {
      const landscapeImageId = getPublicId(landscapeImage.url);
      this.imageLandscape.type = landscapeImageId ? IMAGE_MEDIA_TYPE.CLOUDINARY : IMAGE_MEDIA_TYPE.REMOTE;
      this.imageLandscape.src = landscapeImageId || landscapeImage.url;
    }

    this.image = `${CLOUDINARY.BASE_PATH}${
      this.imageLandscape.type === IMAGE_MEDIA_TYPE.CLOUDINARY ? 'upload' : 'fetch'
    }/ar_16:9,c_fill,h_540,w_auto,dpr_2.0,f_auto/${this.imageLandscape.src}`;

    this.eventValues = {
      category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
      name: options.isOnSearch ? TEALIUM_EVENT_NAME.SEARCH_CLICK : TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK,
      label: options.collectionTitle || null,
      value: options.position || null,
      defaultValues: this.metadata
    };
  }
}
