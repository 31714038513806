import { ApolloClient } from '@apollo/client/core';
import { isBrowser } from '../isBrowser';
import { setVideosContinueWatchingMutation } from '../../client/queries/continueWatching/continueWatchingActions';
import { ActiveVideo } from '../../client/queries/continueWatching/continueWatchingQuery.query';

export const setContinueWatchingLocalState = async (
  isAuthenticated: boolean,
  client?: ApolloClient<object>
): Promise<void> => {
  if (client && isBrowser && !isAuthenticated) {
    const activeVideo: { data: { activeVideo: IVideo } } = await client.query({ query: ActiveVideo });
    const continueWatchingArray: IVideo[] = updateLocalStorageContinueWatching(activeVideo.data.activeVideo);
    setVideosContinueWatchingMutation(client, {
      videosContinueWatching: continueWatchingArray || []
    });
  } else if (isBrowser && isAuthenticated) {
    localStorage.setItem('continueWatching', JSON.stringify([]));
  }
};

export const updateLocalStorageContinueWatching = (activeVideo: IVideo) => {
  try {
    const storedData = localStorage.getItem('continueWatching');
    const localContinueWatching = JSON.parse(storedData ?? '[]') as IVideo[];

    const videoIndex = localContinueWatching.findIndex(({ guid }) => guid === activeVideo.guid);
    if (videoIndex !== -1) localContinueWatching.splice(videoIndex, 1);

    localContinueWatching.push(activeVideo);

    localStorage.setItem('continueWatching', JSON.stringify(localContinueWatching));
    return localContinueWatching;
  } catch (ignore) {
    localStorage.removeItem('continueWatching');
    return [];
  }
};
