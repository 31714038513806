import styled from 'styled-components';
import { Heading2, Heading3, BodyText } from 'components/Text/Text.style';
import { CarouselItemsStyle } from 'components/Lists/Carousel/Carousel.style';
import { TabsWrapperStyle } from 'components/Tabs/Tabs.style';
import { VideoItemStyle } from 'components/VideoItem/VideoItem.style';
import {
  SkeletonVideoItemWrapperStyle,
  SkeletonVideoItemTextWrapperStyle,
  SkeletonHomeMissedContainerStyle,
  SkeletonGridWrapperStyle,
  SkeletonGridItemStyle,
  SkeletonHomeMissedListWrapperStyle
} from 'components/SkeletonElement/SkeletonElement.style';
import { TileListStyle } from 'components/Lists/TileList/TileList.style';
import { ShowMoreButtonWrapper } from 'components/ShowMoreButton/ShowMoreButton.styled';

export const SearchModalResultCount = styled(Heading3)`
  margin: 24px 0 32px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin: 32px 0;
  }
`;

export const SearchModalResultsWrapper = styled.div`
  margin-bottom: 100px;

  ${TabsWrapperStyle} {
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-bottom: 70px;
  }

  ${SkeletonHomeMissedContainerStyle} {
    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      padding-top: 24px;
    }

    @media (max-width: ${props => props.theme.breakpoints.lgMin}) {
      padding-top: 16px;
    }
  }

  ${SkeletonGridWrapperStyle} {
    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      grid-row-gap: 68px;
      margin-top: 68px;
    }

    @media (max-width: ${props => props.theme.breakpoints.lgMin}) {
      margin-top: 42px;
    }
  }

  ${SkeletonGridItemStyle} {
    @media (max-width: 425px) {
      height: 225px;
    }

    @media (min-width: 426px) and (max-width: ${props => props.theme.breakpoints.smMax}) {
      height: 190px;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMid}) and (max-width: 752px) {
      height: 290px;
    }
  }

  ${TileListStyle} {
    grid-row-gap: 42px;

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      grid-row-gap: 68px;
    }
  }

  ${ShowMoreButtonWrapper} {
    @media (max-width: ${props => props.theme.breakpoints.lgMin}) {
      margin: 16px 16px 0 0;
    }
  }
`;

export const SearchModalNoResultsText = styled(BodyText)`
  margin-top: 40px;
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 60px;
  }
`;

export const SearchModalResetButton = styled.div`
  width: 224px;
  margin: 50px auto;
`;

export const SearchModalCarousel = styled.div`
  margin-bottom: 32px;

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    margin-bottom: 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${Heading2} {
    margin-bottom: 8px;

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      margin-bottom: 24px;
    }
  }

  ${CarouselItemsStyle} {
    & > *:first-child {
      margin-left: 0;
    }
  }
`;

export const SearchResultsVideo = styled.div`
  ${VideoItemStyle} {
    width: 211px;
  }

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    margin-right: -16px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) {
    margin-right: -24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    ${VideoItemStyle} {
      width: 229px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    ${VideoItemStyle} {
      width: 302px;
    }
  }
`;

export const SearchVideoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  min-height: 350px;
  span {
    display: flex;
    a {
      display: block;
      width: 100%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    ${SkeletonVideoItemWrapperStyle} {
      div:last-child {
        top: 0;
      }
      div:first-child {
        bottom: -3px;
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    grid-template-columns: repeat(3, minmax(187px, 1fr));
    grid-gap: 16px;

    ${SkeletonVideoItemWrapperStyle} {
      div:last-child {
        bottom: 0;
      }
    }
    ${SkeletonVideoItemTextWrapperStyle} {
      div:last-child {
        bottom: -15px;
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    grid-template-columns: repeat(4, minmax(226px, 1fr));
    grid-gap: 24px;
  }
`;

export const SearchTabWrapper = styled.div`
  ${SkeletonHomeMissedListWrapperStyle} {
    margin-bottom: 0;
  }
`;
