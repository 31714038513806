import { ICONS } from './icons';

export enum PLAYER_ERRORS {
  NO_PLAYABLE_SOURCES_FOUND = '102630',
  ERROR = 'error',
  SETUP_ERRORS_MISCELLANEOUS = 'Setup Errors - Miscellaneous',
  SETUP_ERRORS_LOADING_JAVASCRIPT_COMPONENTS = 'Setup Errors - Loading Javascript Components',
  PLAYLIST_PARSING = 'Setup Errors - Playlist Parsing',
  EMPTY_PLAYLIST = 'Setup Errors - Empty Playlist',
  PLAYER_ERROR_MISCELLANEOUS = 'Player Errors - Miscellaneous',
  LOADING_NEW_PLAYLIST = 'Player Errors - Loading New Playlist',
  PLAYLIST_ITEM = 'Player Errors - Playlist Item',
  PLAYER_ERROR_LOADING_JAVASCRIPT_COMPONENTS = 'Player Errors - Loading Javascript Components',
  MEDIA_PLAYBACK_FLASH = 'Player Errors - Media Playback Flash',
  MEDIA_PLAYBACK_HTML = 'Player Errors - Media Playback HTML5',
  MEDIA_PLAYBACK_HLS = 'Player Errors - Media Playback HLS.JS',
  MEDIA_PLAYBACK_SHAKA = 'Player Errors - Media Playback Shaka',
  UNKNOWN_ERROR = 'Unknown jwplayer error code',
  PLAY_ATTEMPT_FAIL = 'Warning - playAttemptFailed',
  WARNING_LOADING_JAVASCRIPT_COMPONENTS = 'Warning - Loading Javascript Components',
  RELATED = 'Warning - Related',
  CAPTIONS = 'Warning - Captions',
  VR = 'Warning - VR',
  LOCALIZATION = 'Warning - Localization',
  WARNING_MEDIA_PLAYBACK = 'Warning - Media Playback HLS.JS',
  CASTING = 'Warning - Casting',
  UNKNOWN_WARNING = 'Unknown jwplayer warning'
}

export enum PLAYER_ERROR_TYPE {
  ERROR = 'error',
  WARNING = 'warning'
}

export const PLAYER_MESSAGES = {
  GEO_BLOCKED: {
    icon: ICONS.ALERT,
    heading: 'Deze video is niet beschikbaar op je huidige locatie',
    body: 'Wij zien dat je KIJK op dit moment buiten Nederland bezoekt. Selecteer een ander programma om naar te kijken.',
    hasLargeHeading: true
  },
  NO_PLAYABLE_SOURCES_FOUND: {
    icon: null,
    heading: 'Helaas kan de video niet worden afgespeeld',
    body: 'Mogelijk is deze niet meer beschikbaar of wordt de browser niet ondersteund.',
    hasLargeHeading: true
  }
};
