import styled from 'styled-components';
import { PortraitItemStyle } from '../../PortraitItem/PortraitItem.style';

export const TileListStyle = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));

  ${PortraitItemStyle} {
    width: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    grid-template-columns: repeat(auto-fill, minmax(162px, 1fr));
    grid-row-gap: 39px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    grid-column-gap: 24px;
    grid-row-gap: 42px;
  }
`;
