import axios from 'axios';
import config from 'next/config';

export default async function getClientIP(): Promise<{ ip: string; country: string } | null> {
  try {
    const ipDataURL = config().publicRuntimeConfig.api.ipData;
    const { data } = await axios.get(`${ipDataURL}?timestamp=${new Date().getTime()}`);
    return { ip: data.clientIP, country: data.country } || '';
  } catch (error) {
    return null;
  }
}
