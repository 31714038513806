import { gql } from '@apollo/client';
import { ProgramFragment } from '../programs/programs.fragments';

const SearchQuery = gql`
  query SearchQuery($searchParam: String!, $portraitLimit: Int, $landscapeLimit: Int) {
    episodes: programs(
      searchParam: $searchParam
      programTypes: EPISODE
      limit: $landscapeLimit
      sort: LASTPUBDATE_DESC
      availableWithinHours: 24
    ) {
      totalResults
      items {
        ...Program
      }
    }
    clips: programs(
      searchParam: $searchParam
      programTypes: CLIP
      limit: $landscapeLimit
      sort: ADDED
      availableWithinHours: 24
    ) {
      totalResults
      items {
        ...Program
      }
    }
    series: programs(searchParam: $searchParam, programTypes: SERIES, limit: $portraitLimit) {
      totalResults
      items {
        ...Program
      }
    }
    movies: programs(searchParam: $searchParam, programTypes: MOVIE, limit: $portraitLimit, availableWithinHours: 24) {
      totalResults
      items {
        ...Program
      }
    }
  }
  ${ProgramFragment}
`;

export const SearchQueryProgrammasTab = gql`
  query SearchQueryProgrammasTab($searchParam: String!, $limit: Int, $skip: Int) {
    programs(searchParam: $searchParam, programTypes: SERIES, limit: $limit, skip: $skip) {
      totalResults
      items {
        ...Program
      }
    }
  }
  ${ProgramFragment}
`;

export const SearchQueryClipsTab = gql`
  query SearchQueryClipsTab($searchParam: String!, $limit: Int, $skip: Int, $sort: ProgramSortKey) {
    programs(
      searchParam: $searchParam
      programTypes: CLIP
      limit: $limit
      skip: $skip
      sort: $sort
      availableWithinHours: 24
    ) {
      totalResults
      items {
        ...Program
      }
    }
  }
  ${ProgramFragment}
`;

export const SearchQueryEpisodesTab = gql`
  query SearchQueryEpisodesTab($searchParam: String!, $limit: Int, $skip: Int, $sort: ProgramSortKey) {
    programs(
      searchParam: $searchParam
      programTypes: EPISODE
      limit: $limit
      skip: $skip
      sort: $sort
      availableWithinHours: 24
    ) {
      totalResults
      items {
        ...Program
      }
    }
  }
  ${ProgramFragment}
`;

export const SearchQueryFilmsTab = gql`
  query SearchQueryFilmsTab($searchParam: String!, $limit: Int, $skip: Int) {
    programs(searchParam: $searchParam, programTypes: MOVIE, limit: $limit, skip: $skip, availableWithinHours: 24) {
      totalResults
      items {
        ...Program
      }
    }
  }
  ${ProgramFragment}
`;

export default SearchQuery;
