import { FunctionComponent } from 'react';
import { BodyText } from '../Text/Text.style';
import { ContinueWatchingEmptyVideoListStyle } from './ContinueWatching.style';

const EmptyContinueWatching: FunctionComponent<{ isUserAuthenticated: boolean }> = props => (
  <ContinueWatchingEmptyVideoListStyle data-testid="continueWatchingEmpty">
    {props.isUserAuthenticated ? (
      <BodyText data-testid="messageEmptyContinueWatching1">
        Alles wat je deels bekijkt wordt automatisch aan je Verder kijken lijst toegevoegd.
      </BodyText>
    ) : (
      <BodyText data-testid="messageEmptyContinueWatching2">
        Als je bent ingelogd wordt alles wat je bekijkt automatisch aan deze lijst toegevoegd.
      </BodyText>
    )}
    <BodyText data-testid="messageEmptyContinueWatching3">
      Via deze Verder kijken lijst kan je het kijken van de door jou onderbroken film, serie of programma vervolgen.
    </BodyText>
  </ContinueWatchingEmptyVideoListStyle>
);

export default EmptyContinueWatching;
