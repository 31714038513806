import getConfig from 'next/config';
import { isEmpty } from 'lib/helpers';
import { IEditorialCollection } from 'typings/IEditorialCollection';
import { PROGRAM, COLLECTION_TYPE } from '../constants';
import ClipModel from './clip.program.model';
import EpisodeModel from './episode.program.model';
import SeriesModel from './series.model';
import MovieModel from './movie.program.model';
import VideoModel from './video.model';

const REQUIRED_ITEMS_FIELDS = ['slug', 'type', 'programs', 'title'];
const { publicRuntimeConfig } = getConfig();

export default class OverviewItemModel {
  title: string;

  type: (typeof COLLECTION_TYPE)[keyof typeof COLLECTION_TYPE];

  slug: string;

  onEditorialTabs: boolean;

  programs: (SeriesModel | VideoModel)[];

  isValid: boolean;

  isBranded: boolean;

  heroImageUrl = '';

  logoImageUrl = '';

  backgroundColor?: string;

  presentationType?: string;

  showMoreUrl?: string;

  constructor(data: IEditorialCollection, options: { isOnFormatPage?: boolean; isOnSearch?: boolean } = {}) {
    if (!data) return;
    this.isValid = REQUIRED_ITEMS_FIELDS.every(field => !isEmpty(data, field));
    this.title = data.title;
    this.type = data.type;
    this.slug = data.slug;
    this.onEditorialTabs = data.onEditorialTabs || false;
    this.showMoreUrl = data.showMoreUrl;
    const playlistQueryParams: IPlaylistQueryParams = {
      showFormatTitle: data.showFormatTitle
    };
    if (this.onEditorialTabs) {
      playlistQueryParams.editorialTabSlug = this.slug;
    } else if (options.isOnFormatPage) {
      playlistQueryParams.playlistSlug = this.slug;
    }
    if (data.heroImageMedia?.length) {
      this.heroImageUrl = `https://${publicRuntimeConfig.cloudinary.talpaConfig}/${publicRuntimeConfig.cloudinary.cloudName}/image/fetch/h_600,dpr_2.0,w_auto,f_auto/${data.heroImageMedia[0].url}`;
    }
    if (data.logoImageMedia?.length) {
      this.logoImageUrl = data.logoImageMedia[0].url!;
    }
    this.backgroundColor = data.backgroundColor;
    this.presentationType = data.presentationType;
    this.isBranded = !!this.backgroundColor && !!this.heroImageUrl;
    this.programs =
      data.programs &&
      data.programs
        .map((program, index) => {
          const modelOptions = { isOnSearch: options.isOnSearch, collectionTitle: this.title, position: index + 1 };
          switch (program.type) {
            case PROGRAM.CLIP:
              return new ClipModel(program, { ...playlistQueryParams, ...modelOptions });
            case PROGRAM.EPISODE:
              return new EpisodeModel(program, { ...playlistQueryParams, ...modelOptions });
            case PROGRAM.SERIES:
              return new SeriesModel(program, modelOptions);
            case PROGRAM.MOVIE:
              return new MovieModel(program, { ...playlistQueryParams, ...modelOptions });
            default:
              return new VideoModel(program, modelOptions);
          }
        })
        .filter(program => program?.isValid);
  }
}
