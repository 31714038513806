import styled from 'styled-components';

export const NavListStyle = styled.div`
  display: flex;
  align-self: stretch;
  flex-basis: 100%;
  justify-content: space-around;
  order: 1;
  height: 54px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: auto;
    order: 0;
    flex-basis: auto;
  }
`;
