export const animate = (CallbackObj: any, Duration: number): any => {
  const requestAnimationFrame =
    window.requestAnimationFrame ||
    (window as any)['webkitRequestAnimationFrame'] ||
    (window as any)['mozRequestAnimationFrame'] ||
    (window as any)['oRequestAnimationFrame'] ||
    (window as any)['msRequestAnimationFrame'];
  let StartTime: number = 0;
  let Percentage: number = 0;
  let AnimationTime: number = 0;
  const DurationAdjusted = Duration * 1000 || 1000;
  const animation: any = (timestamp: number) => {
    if (StartTime === 0) {
      StartTime = timestamp;
    } else {
      AnimationTime = timestamp - StartTime;
    }
    if (typeof CallbackObj.start === 'function' && StartTime === timestamp) {
      CallbackObj.start();
      requestAnimationFrame(animation);
    } else if (AnimationTime < DurationAdjusted) {
      if (typeof CallbackObj.progress === 'function') {
        Percentage = AnimationTime / DurationAdjusted;
        CallbackObj.progress(Percentage);
      }
      requestAnimationFrame(animation);
    } else if (typeof CallbackObj.done === 'function') {
      CallbackObj.done();
    }
  };
  return requestAnimationFrame(animation);
};
