import { Fragment, FunctionComponent, useState } from 'react';
import { createTwoDimensionalArray } from 'lib/helpers';
import VideoModel from 'models/video.model';
import { ITwoRowVideoList } from 'typings/ITwoRowVideoList';
import ErrorBoundary from '../../ErrorBoundary';
import VideoItem from '../../VideoItem';
import CommonFallback from '../../CommonFallback';
import { FALLBACK_UI_LABELS } from '../../../constants';
import { TwoRowListColumnStyle, TwoRowListVideoStyle } from './TwoRowVideoList.style';

const TwoRowVideoList: FunctionComponent<ITwoRowVideoList> = ({
  videoList,
  activeVideoGuid,
  progress
}): JSX.Element | null => {
  const [TwoDimensionalVideoList] = useState<VideoModel[][]>(() => {
    if (videoList.length) return createTwoDimensionalArray(videoList);
    return [];
  });

  if (!TwoDimensionalVideoList.length) return null;
  return (
    <>
      {TwoDimensionalVideoList.map((columnVideos, columnIndex) => (
        <TwoRowListColumnStyle key={columnVideos[0].id} data-testid="twoRowListColumnStyle">
          {columnVideos.map((video, videoIndex) => {
            const IsActive: boolean = activeVideoGuid === video.guid;

            video.eventValues.value = `${columnIndex + 1}.${videoIndex + 1}`;

            return (
              <Fragment key={video.id}>
                <ErrorBoundary onError={(_: any) => <CommonFallback message={FALLBACK_UI_LABELS.MISSED} />}>
                  <TwoRowListVideoStyle>
                    <VideoItem isActive={IsActive} remoteProgress={progress[video.guid]} video={video} />
                  </TwoRowListVideoStyle>
                </ErrorBoundary>
              </Fragment>
            );
          })}
        </TwoRowListColumnStyle>
      ))}
    </>
  );
};

export default TwoRowVideoList;
