import getConfig from 'next/config';
import { IMAGE_VIEW_TYPE, IMAGE_MEDIA_TYPE } from '../../client/constants';

const { publicRuntimeConfig } = getConfig();

const KillDuplicatesCallback = (match: string): string => match.replace(/(\/)+/, '/');
const KillDuplicateSlashes = (value: string): string => value.replace(/(([^:])|(^))(\/){2,}/g, KillDuplicatesCallback);

export const getStaticAsset = (path: string): string => {
  const prefix = path.indexOf('images') !== -1 ? 'https://kijk.nl/' : publicRuntimeConfig.assetPrefix;

  return KillDuplicateSlashes(`${prefix}${path}`);
};

export const getImageMediaSource = (imageMedia: IImage[] | undefined, imageType: string): string => {
  const image = imageMedia?.find(img => img.label === imageType);
  if (image?.url) return image.url;

  switch (imageType) {
    case IMAGE_VIEW_TYPE.EDITORIAL_PORTRAIT:
      return getStaticAsset('/static/images/default_editorial_portrait.png');
    case IMAGE_VIEW_TYPE.EDITORIAL_LANDSCAPE:
      return getStaticAsset('/static/images/default_editorial_landscape.png');
    default:
      return getStaticAsset('/static/images/default_editorial_portrait.png');
  }
};

const getImageMediaType = (imageMedia: IImage[] | undefined, imageType: string): string => {
  const image = imageMedia?.find(img => img.label === imageType);
  if (image?.type) return image.type;

  return IMAGE_MEDIA_TYPE.REMOTE;
};

export const getImageMedia = (imageMedia: IImage[] | undefined, imageType: string, title: string = ''): any => {
  return {
    alt: title,
    src: getImageMediaSource(imageMedia, imageType),
    type: getImageMediaType(imageMedia, imageType)
  };
};

export const preloadImage = (src: string = ''): void => {
  if (src === '') return;
  const Img = new Image();
  Img.src = src;
};

export const isImageValid = (imageMedia: IImageMediaGraphql[] | undefined, imageType: string): boolean =>
  !!imageMedia?.some(image => image?.label === imageType);
