import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { ToggleHeaderStateMutation } from './headerStateQuery.query';

export const toggleHeaderState = (
  apollo: ApolloClient<InMemoryCache>,
  { isTransparent }: { isTransparent: boolean }
) => {
  apollo.mutate({
    mutation: ToggleHeaderStateMutation,
    variables: {
      isTransparent
    }
  });
};
