import { FunctionComponent } from 'react';

export const Tab: FunctionComponent<ITabProps> = ({ selected, children }) => {
  if (selected && children) {
    return <>{children}</>;
  }
  return null;
};

export default Tab;
