import styled from 'styled-components';
import { DropdownButton } from 'components/Dropdown/Dropdown.styled';

export const AccountMenu = styled.div<{ isLoggedIn: boolean }>`
  ${({ isLoggedIn }) =>
    !isLoggedIn &&
    `
      ${DropdownButton} {
        cursor: pointer;
        background: none;
        border-color: rgba(0, 0, 0, 0);
      }
    `}
`;

export const AccountImgWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
`;
