import styled from 'styled-components';
import { Heading3 } from '../../Text/Text.style';
import Colors from '../../../styles/colors';

export const UnderlineStyle = styled.div<{ isActive: boolean }>`
  position: absolute;
  bottom: 12px;
  margin-left: 10px;
  margin-right: 10px;
  height: 3px;
  width: calc(100% - 20px);
  background-color: ${props => (props.isActive ? Colors.white : 'transparent')};
  border-radius: 4px;
  transition: background-color ${props => props.theme.transitions.easeIn};
`;

export const ItemStyle = styled.div<{ isTabNav?: boolean; isActive: boolean }>`
  position: relative;
  display: flex;
  height: ${props => (props.isTabNav ? '36px' : 'auto')};
  min-height: 36px;
  padding: 0;
  align-items: center;
  color: ${Colors.greenishTeal};
  font-weight: bold;
  ${Heading3} {
    white-space: nowrap;
    transition: color ${props => props.theme.transitions.easeIn};
  }

  @media (hover: hover) {
    &:hover {
      ${props =>
        !props.isActive &&
        `
        ${UnderlineStyle} {
          background-color: ${Colors.white};
        }
      `}
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    height: ${props => (props.isTabNav ? '40px' : 'auto')};
    min-height: 40px;
  }

  span {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }

  a {
    display: flex;
    align-self: stretch;
    width: 100%;
    height: 100%;
  }

  ${Heading3} {
    align-self: center;
    padding: 0 8px;
    cursor: pointer;

    @media (min-width: 400px) {
      padding: 0 15px;
    }
    @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
      padding: 0 23px;
    }
  }
`;
