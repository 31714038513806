import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { ICONS } from '../../constants';
import * as Styled from './Dropdown.styled';

const DropdownButtonContent: React.FC<{
  iconButton?: IDropdownIconButton | false;
  dropdownValue: ISeriesSeasonAvailability;
}> = ({ iconButton, dropdownValue }) => {
  if (iconButton) {
    if (iconButton.text) return <>{iconButton.text}</>;
    if (iconButton.image || iconButton.icon) {
      return (
        <>
          {iconButton.image ? (
            <img src={iconButton.image} alt={iconButton.altText || ''} />
          ) : (
            <SVG src={iconButton.icon as string} />
          )}
          {iconButton.hiddenText && <Styled.HiddenText>{iconButton.hiddenText}</Styled.HiddenText>}
        </>
      );
    }
  }

  return (
    <>
      <Styled.DropdownButtonText>{dropdownValue.label}</Styled.DropdownButtonText>
      <SVG src={ICONS.ARROW_SMALL} />
    </>
  );
};

const Dropdown: React.FC<IDropdownProps> = ({
  options,
  defaultValue,
  iconButton,
  callback,
  showActiveIcon,
  onButtonClick
}) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const isDisabled = iconButton ? !options.length : options.length <= 1;
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(defaultValue || options[0]);
  const handleDropdownClick = () => {
    if (onButtonClick) onButtonClick();
    if (isDisabled) return;
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    if (!isOpen) return () => {};
    const handleBlur = (event: MouseEvent) => {
      let parentNode: ParentNode | null = (event.target as HTMLElement)?.parentNode;
      let isInDropDownTree = false;
      while (parentNode && isInDropDownTree === false) {
        if (parentNode === dropdownRef.current) isInDropDownTree = true;
        parentNode = parentNode?.parentNode || null;
      }
      if (!isInDropDownTree) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener('click', handleBlur, false);
    return () => {
      document.body.removeEventListener('click', handleBlur);
    };
  }, [isOpen]);

  const handleSetDropdownValue = (option: ISeriesSeasonAvailability) => {
    setIsOpen(false);
    setDropdownValue(option);
    callback(option.value);
  };

  return (
    <Styled.Dropdown isIconButton={!!iconButton} ref={dropdownRef}>
      <Styled.DropdownButton
        type="button"
        onClick={handleDropdownClick}
        isOpen={isOpen}
        isDisabled={isDisabled}
        aria-label={dropdownValue?.ariaLabel}
      >
        <DropdownButtonContent dropdownValue={dropdownValue} iconButton={iconButton} />
      </Styled.DropdownButton>
      {isOpen && (
        <Styled.DropdownItems>
          {options.map(option => (
            <Styled.DropdownItem
              key={option.value}
              role="switch"
              aria-checked={option.value === dropdownValue.value}
              aria-label={option.ariaLabel}
              isDisabled={!!option.isDisabled}
              showActiveIcon={!!showActiveIcon}
              onClick={() => !option.isDisabled && handleSetDropdownValue(option)}
            >
              {option.icon && <SVG src={option.icon} />}
              <Styled.DropdownLabel>{option.label}</Styled.DropdownLabel>
              {showActiveIcon && option.value === dropdownValue.value && <Styled.ActiveIcon />}
            </Styled.DropdownItem>
          ))}
        </Styled.DropdownItems>
      )}
    </Styled.Dropdown>
  );
};

export default Dropdown;
