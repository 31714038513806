import { gql } from '@apollo/client';
import {
  ImageMediaFragment,
  SeriesTvSeasonsFragment,
  SourcesFragment,
  TracksFragment,
  RatingsFragment
} from './programs.fragments';

const ProgramsByProgramTypeQuery: any = gql`
  query programs($programTypes: [ProgramType], $limit: Int, $guid: [String], $sort: ProgramSortKey, $skip: Int) {
    programs(programTypes: $programTypes, limit: $limit, guid: $guid, sort: $sort, skip: $skip) {
      totalResults
      items {
        id
        guid
        availableRegion
        slug
        tvSeasonId
        type
        title
        description
        longDescription
        shortDescription
        displayGenre
        tvSeasonEpisodeNumber
        seasonNumber
        seriesId
        duration
        metadata
        media {
          availableDate
          availabilityState
          airedDateTime
          expirationDate
          type
        }
        ...ImageMedia
        ...SeriesTvSeasons
        ...Ratings
        ...Sources
        ...Tracks
      }
    }
  }
  ${ImageMediaFragment}
  ${SeriesTvSeasonsFragment}
  ${RatingsFragment}
  ${SourcesFragment}
  ${TracksFragment}
`;
export default ProgramsByProgramTypeQuery;
