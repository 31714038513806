import { ActionLabel, Heading3, Heading4 } from '../../Text/Text.style';
import Link from '../../Link';
import { DescriptionStyle, TextLabelStyle } from './VideoItemDescription.style';

const VideoItemDescription: React.FC<IVideoItemDescriptionProps> = ({
  clickEventValues,
  date,
  path,
  smLandscape,
  subtitle,
  textLabel,
  title,
  type,
  isFormatPage,
  showFormatTitle
}) => {
  if (!path) {
    return (
      <TextLabelStyle smLandscape={smLandscape} isFormatPage={isFormatPage}>
        {textLabel}
      </TextLabelStyle>
    );
  }

  return (
    <>
      {textLabel && (
        <TextLabelStyle smLandscape={smLandscape} isFormatPage={isFormatPage} suppressHydrationWarning>
          {textLabel}
        </TextLabelStyle>
      )}
      <DescriptionStyle smLandscape={smLandscape} type={type} showFormatTitle={showFormatTitle}>
        {title && (
          <Link path={path} clickEventValues={clickEventValues}>
            <Heading3>{title}</Heading3>
          </Link>
        )}
        {subtitle && (
          <Link path={path} clickEventValues={clickEventValues}>
            <Heading4>{subtitle}</Heading4>
          </Link>
        )}
        <Link path={path} clickEventValues={clickEventValues}>
          {date && (
            <ActionLabel data-testid="videoItemPubDate" suppressHydrationWarning>
              {date}
            </ActionLabel>
          )}
        </Link>
      </DescriptionStyle>
    </>
  );
};
export default VideoItemDescription;
