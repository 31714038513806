import React, { FunctionComponent } from 'react';
import HighlightedSectionModel from 'models/highlightedSection.model';
import Link from 'components/Link';
import { TEALIUM_EVENT_NAME, TEALIUM_EVENT_CATEGORY } from '../../constants';
import { ActionLabel, Heading2, BodyText } from '../Text/Text.style';
import {
  HighlightedSectionWrapper,
  HighlightedSectionBody,
  LabelHighlightedSection,
  HighlightedSectionImage,
  HighlightedSectionLink
} from './HighlightedSection.style';

const HighlightedSection: FunctionComponent<HighlightedSectionModel> = ({
  backgroundTransparent,
  buttonLink,
  buttonTarget,
  buttonText,
  description,
  imageUrl,
  label,
  title,
  type
}) => {
  const hasImage = !!imageUrl;

  const clickEventValues: ITealiumClickEvent = {
    name: buttonLink?.startsWith('http') ? TEALIUM_EVENT_NAME.OUTBOUND_CLICK : TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK,
    category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
    label: buttonText
  };

  return (
    <HighlightedSectionWrapper
      data-testid="highlightedSection"
      backgroundTransparent={backgroundTransparent}
      hasImage={hasImage}
      mediaType={type}
    >
      {hasImage && (
        <HighlightedSectionImage>
          <img src={imageUrl} alt={title} />
        </HighlightedSectionImage>
      )}
      <HighlightedSectionBody mediaType={type} hasImage={hasImage}>
        {label && (
          <LabelHighlightedSection>
            <ActionLabel isPrimary>{label}</ActionLabel>
          </LabelHighlightedSection>
        )}
        <Heading2>{title}</Heading2>
        {description && <BodyText>{description}</BodyText>}
        {buttonLink && buttonText && (
          <Link
            href={buttonLink}
            target={buttonTarget}
            Component={HighlightedSectionLink}
            clickEventValues={clickEventValues}
          >
            {buttonText}
          </Link>
        )}
      </HighlightedSectionBody>
    </HighlightedSectionWrapper>
  );
};

export default HighlightedSection;
