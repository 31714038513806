import { gql } from '@apollo/client';

const NavigationsQuery: any = gql`
  query navigations($slug: String, $client: Client) {
    navigations(slug: $slug, client: $client) {
      items {
        slug
        navigationItems {
          slug
          title
          overview
          link
          icon {
            url
          }
        }
      }
    }
  }
`;

export default NavigationsQuery;
