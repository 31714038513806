import { getImageMedia, isEmpty } from 'lib/helpers';
import { PROGRAM, IMAGE_VIEW_TYPE } from '../constants';
import ClipModel from './clip.program.model';
import EpisodeModel from './episode.program.model';
import MovieModel from './movie.program.model';
import VideoModel from './video.model';

const REQUIRED_OVERVIEW_PROMOTION_FIELDS = ['title', 'slug', 'program'];

export default class EditorialPromotion {
  slug: string;
  title: string;
  description: string;
  program: VideoModel;
  isValid: boolean;
  isLive: boolean;
  path: string;
  imageMediaId: string;

  constructor(data: IEditorialPromotions) {
    if (!data) return;
    this.isValid = REQUIRED_OVERVIEW_PROMOTION_FIELDS.every(field => !isEmpty(data, field));
    this.slug = data.slug;
    this.title = data.title;
    this.description = data.description || '';
    this.isLive = data.program?.media?.[0]?.type === PROGRAM.LIVE || false;
    const programType = data.program && data.program?.type;
    this.path =
      programType === PROGRAM.MOVIE
        ? `/films/${data.program?.slug}/${data.program?.guid}`
        : `/programmas/${data.program?.series?.slug}/${data.program?.guid}`;

    this.imageMediaId = getImageMedia(
      data.imageMedia?.map(imageMedia => ({
        ...imageMedia,
        label: IMAGE_VIEW_TYPE.EDITORIAL_LANDSCAPE
      })),
      IMAGE_VIEW_TYPE.EDITORIAL_LANDSCAPE
    ).src;

    if (data.program) {
      this.program =
        programType === PROGRAM.CLIP
          ? new ClipModel(data.program)
          : programType === PROGRAM.EPISODE
          ? new EpisodeModel(data.program)
          : programType === PROGRAM.MOVIE
          ? new MovieModel(data.program)
          : new VideoModel(data.program);
    }
  }
}
