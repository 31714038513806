export const handle404 = (message404: string): { statusCode: number; message404: string } => {
  return {
    message404,
    statusCode: 404
  };
};

export const handle301 = (redirectUrl: string): { statusCode: number; redirectUrl: string } => {
  return {
    redirectUrl,
    statusCode: 301
  };
};

export const handle500 = (message500: string): { statusCode: number; message500: string } => {
  return {
    message500,
    statusCode: 500
  };
};
