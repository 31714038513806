import { forwardRef, InputHTMLAttributes } from 'react';
import { SearchInputStyle } from './SearchInput.style';

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  isOpen: boolean;
}
const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>((props, ref) => (
  <SearchInputStyle data-testid="searchInput" {...props} type="text" ref={ref} maxLength={100} />
));

SearchInput.displayName = 'SearchInput';

export default SearchInput;
