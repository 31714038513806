import styled from 'styled-components';
import Colors from '../../../styles/colors';
import { ItemStyle } from '../../Nav/NavItem/NavItem.style';

export const ArrowWrapperStyle = styled.div<ICarouselArrowStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${({ arrowPosition }) => (arrowPosition ? `${arrowPosition}px` : 'calc(50% - 19px)')};
  opacity: 0;
  width: 55px;
  height: 55px;
  background-color: ${props => (props.smallArrows ? 'transparent' : Colors.white)};
  border-radius: 50%;
  transition: opacity ${props => props.theme.transitions.easeIn};
  & > img {
    margin-top: ${(props: ICarouselArrowStyleProps) => (props.smallArrows ? '-14px' : '0')};
  }
`;

export const CarouselWrapperStyle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: ${props => props.theme.breakpoints.lgMax};

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    margin: auto;
  }

  &:hover ${ArrowWrapperStyle} {
    opacity: 0.8;
  }
`;

export const CarouselItemsStyle = styled.div<{ hasPrevArrow: boolean; hasNextArrow: boolean }>`
  display: flex;
  position: relative;
  order: 1;
  flex-basis: 100%;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 8px 0;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  ${({ hasPrevArrow, hasNextArrow }) =>
    (hasPrevArrow || hasNextArrow) &&
    `mask: linear-gradient(to left, transparent 0%, #fff ${hasNextArrow ? '120' : '0'}px ${
      hasPrevArrow ? 'calc(100% - 120px)' : '100%'
    }, transparent 100%);`}

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    width: 0;
    height: 0;
    background-color: transparent;
    appearance: none;
  }

  & > * {
    margin-left: 8px;
    margin-right: 8px;
  }

  & > *:first-child {
    margin-left: 16px;
  }

  & > *:last-child {
    margin-right: 0;
  }

  &::after {
    content: '';
    display: block;
    position: relative;
    min-width: 16px;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      min-width: 24px;
    }
  }

  ${ItemStyle} {
    &:first-child {
      margin-left: 0;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    order: 0;
    flex-basis: auto;

    & > *:first-child {
      margin-left: 24px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.mdMax}) {
    mask: none;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    & > * {
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
    & > *:first-child {
      margin-left: 80px;
    }
  }
`;

export const PrevArrowStyle = styled.div<ICarouselArrowStyleProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 100%;
  cursor: pointer;

  & > div {
    left: ${props => (props.smallArrows ? '0' : '30px')};
  }

  & > div > img {
    transform: rotate(180deg);
  }

  &:hover ${ArrowWrapperStyle} {
    opacity: 1;
  }

  @media (max-width: ${props => props.theme.breakpoints.mdMax}) {
    display: none !important;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    left: -4px;
    right: auto;
  }
`;

export const NextArrowStyle = styled(PrevArrowStyle)`
  left: auto;
  right: 0;

  & > div {
    right: ${props => (props.smallArrows ? '0' : '30px')};
    left: auto;
  }

  & > div > img {
    transform: rotate(0deg);
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    right: -4px;
    left: auto;
  }
`;
