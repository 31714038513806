import { Component, ReactNode } from 'react';
import { ComponentDidCatchError } from './ComponentDidCatchError';
import getErrorAttributesModel from './ErrorAttributesModel';
import { IS_CLIENT } from '../../constants';

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  protected static NR: any;
  protected static IDENTIFIER: string;

  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      errorInfo: null,
      hasError: undefined
    };
    ErrorBoundary.NR = IS_CLIENT ? window.newrelic : null;
  }

  static getDerivedStateFromError(error: Error): IErrorBoundaryState {
    return { hasError: error };
  }

  componentDidCatch(error: Error, errorInfo: any): void {
    const { props } = this.props.children;

    // Extract affected component from componentStack
    const re = /in Query \(created by ((?!Query).*)\)|in (.+) \(at /;
    const isComponentNamePresent: RegExpExecArray | null = re.exec(errorInfo.componentStack);
    const componentName: string = isComponentNamePresent
      ? isComponentNamePresent[1]
        ? isComponentNamePresent[1]
        : isComponentNamePresent[2]
      : 'No Component';

    this.setState(
      {
        hasError: error,
        errorInfo: {
          component_stack: errorInfo.componentStack,
          context: this.context
        }
      },
      () => {
        try {
          // Has to re-throw error to be able to send it to New Relic
          throw new ComponentDidCatchError('ComponentDidCatchError', {
            ...this.state.errorInfo
          });
        } catch (e) {
          if (e instanceof ComponentDidCatchError) {
            // Enrich data we send to relic, with data we get from context layer
            const { section_item_index, section_index, section_id, event_category } = getErrorAttributesModel([
              props,
              this.context || {}
            ])(props.guid);
            ErrorBoundary.NR?.noticeError(e, {
              component_name: componentName,
              ...getErrorAttributesModel([props, this.context || {}])(props.guid),
              ...e.getErrors(),
              error_message:
                (section_item_index &&
                  section_index &&
                  section_id &&
                  event_category &&
                  `(E) We got an error on section item ${section_item_index} in section ${section_index} aliased as ${section_id}, in category ${event_category}`) ||
                ''
            });
          }
        }
      }
    );
  }

  render(): ReactNode {
    if (this.state.hasError) {
      const { hasError, errorInfo } = this.state;
      // Call the render prop function...
      return this.props.onError.call(this, hasError, errorInfo);
    }
    // Otherwise return children normally...
    return this.props.children;
  }
}

export default ErrorBoundary;
