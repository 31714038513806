import styled from 'styled-components';

export const PortraitItemFallbackStyle = styled.div`
  width: 163px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: 229px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: 238px;
  }
`;
