import React from 'react';
import SVG from 'react-inlinesvg';
import { ICONS } from '../../constants/icons';
import PlayButtonStyle from './PlayButton.style';

const PlayButton: React.FC = () => {
  const [visible, setVisible] = React.useState(false);
  return (
    <PlayButtonStyle
      data-testid="play-button"
      onMouseOver={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {visible ? <SVG src={ICONS.PLAY_BUTTON} /> : null}
    </PlayButtonStyle>
  );
};
export default PlayButton;
