import { useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';
/*
 * This hook provides an easy way to retrieve the current screen dimensions (width and height)
 * and keep them updated whenever the window is resized.
 * @param debouncems - Optional debounce time (in milliseconds) to limit the frequency of updates when resizing.
 */

export const useScreenDimensions = (debouncems: number = 100): number[] => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    const effectiveHandler = debounce(debouncems, (event: any) => {
      setWidth(event.target.innerWidth);
      setHeight(event.target.innerHeight);
    });
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', effectiveHandler);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', effectiveHandler);
      }
    };
  }, []);

  return [width, height];
};
