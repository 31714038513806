import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useSession } from 'next-auth/react';
import VideosProgressQuery from 'queries/videos/videosProgressQuery.query';
import { IVideoList } from 'typings/IVideoList';
import ErrorBoundary from '../ErrorBoundary';
import VideoItem from '../VideoItem';
import CommonFallback from '../CommonFallback';
import { FALLBACK_UI_LABELS } from '../../constants';
import TwoRowVideoList from './TwoRowVideoList/TwoRowVideoList';

const VideoList: FunctionComponent<IVideoList> = (props): JSX.Element => {
  const { programList, showInTwoRows, activeVideoGuid, smLandscape, isFormatPage, isPlayerSideBar } = props;
  const [listOfGuids, setListOfGuids] = useState<string[]>([]);
  const [progress, setProgress] = useState<IProgressMap>({});

  const { status } = useSession();
  const isAuthenticated = status === 'authenticated';

  useEffect(() => {
    setListOfGuids(programList.map(program => program.guid));
  }, [programList]);

  useQuery(VideosProgressQuery, {
    variables: {
      guids: listOfGuids
    },
    skip: !isAuthenticated || !listOfGuids.length,
    onCompleted: data => {
      const progressMap: IProgressMap = data.videoProgress.reduce(
        (acc: IProgressMap, videoProgress: IVideoProgress) => ({ ...acc, [videoProgress.guid]: videoProgress }),
        {}
      );
      setProgress(progressMap);
    }
  });

  if (showInTwoRows) {
    return <TwoRowVideoList videoList={programList} activeVideoGuid={activeVideoGuid} progress={progress} />;
  }

  return (
    <>
      {programList?.map(video => {
        const IsActive: boolean = activeVideoGuid === video.guid;

        return (
          <Fragment key={video.id}>
            <ErrorBoundary onError={(_: any) => <CommonFallback message={FALLBACK_UI_LABELS.MISSED} />}>
              <VideoItem
                isActive={IsActive}
                smLandscape={smLandscape}
                remoteProgress={progress[video.guid]}
                video={video}
                isFormatPage={isFormatPage}
                isPlayerSideBar={isPlayerSideBar}
              />
            </ErrorBoundary>
          </Fragment>
        );
      })}
    </>
  );
};

export default VideoList;
