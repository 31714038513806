import styled from 'styled-components';
import { ArrowWrapperStyle, CarouselWrapperStyle } from '../Lists/Carousel/Carousel.style';
import { Heading3 } from '../Text/Text.style';
import Colors from '../../styles/colors';

export const TabsWrapperStyle = styled.div<{ shouldShowSeasonSelector?: boolean }>`
  display: inline-block;
  width: 100%;
  margin: ${props => (props.shouldShowSeasonSelector ? '0 0 50px 0' : '0 0 24px 0')};

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    margin: 0 0 40px;
  }

  &:hover ${ArrowWrapperStyle} {
    opacity: 0.8;
  }
`;

export const TabNavWrapperStyle = styled.div`
  position: relative;
  width: 100%;
`;

export const TabsStyle = styled.div<{ shouldShowSeasonSelector?: boolean }>`
  display: flex;
  position: relative;
  order: 1;
  flex-basis: 100%;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  height: 50px;

  ${CarouselWrapperStyle} {
    height: 50px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: ${props => (props.shouldShowSeasonSelector ? '80%' : '100%')};
  }

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    width: 0;
    height: 0;
    background-color: transparent;
    appearance: none;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    order: 0;
    flex-basis: auto;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    flex-wrap: wrap;
    overflow-x: auto;
  }
`;

export const UnderlineStyle = styled.div<{ isActive: boolean }>`
  position: absolute;
  bottom: 2px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: ${props => (props.isActive ? Colors.violet : 'transparent')};
  transition: background-color ${props => props.theme.transitions.easeIn};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    bottom: 4px;
  }
`;

export const ItemStyle = styled.div<{ isActive: boolean }>`
  position: relative;
  display: flex;
  height: 36px;
  min-height: 36px;
  margin: 0 12px;
  padding: 0;
  align-items: center;
  color: ${Colors.white};

  ${Heading3} {
    white-space: nowrap;
    color: ${Colors.white};
    opacity: ${props => !props.isActive && '0.8'};
    font-size: 16px;
    font-weight: ${props => (props.isActive ? 'bold' : 'normal')};
    line-height: 1.19;
    text-align: center;
    user-select: none;
    cursor: ${props => (props.isActive ? 'default' : 'pointer')};
    padding: 0;
  }

  @media (hover: hover) {
    &:hover {
      ${props =>
        !props.isActive &&
        `
        ${UnderlineStyle} {
          background-color: ${Colors.violet};
        }
      `}
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    height: 40px;
    min-height: 40px;
  }

  span {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }

  a {
    display: flex;
    align-self: stretch;
    width: 100%;
    height: 100%;
  }

  &:first-child {
    margin-left: 0;
  }

  ${Heading3} {
    align-self: center;
    cursor: pointer;
  }
`;
