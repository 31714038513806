export enum FALLBACK_UI_LABELS {
  MAIN = 'MAIN error',
  BRANDS = 'BRANDS error',
  MOVIES = 'MOVIES error',
  PORTRAIT_ITEM = 'PORTRAIT_ITEM error',
  VIDEO_ITEM = 'VIDEO_ITEM error',
  VIDEO_HEADING = 'VIDEO_HEADING error',
  VIDEO_META_DATA = 'VIDEO_META_DATA error',
  TRENDING_PROGRAMS = 'TRENDING_PROGRAMS error',
  MISSED = 'MISSED error',
  DROPDOWN_MENU = 'DROPDOWN_MENU error',
  FORMAT = 'FORMAT error',
  FORMAT_INFO = 'FORMAT_INFO error',
  TAB_NAV = 'TAB_NAV error',
  FORMAT_VIDEO_LIST = 'FORMAT_VIDEO_LIST error',
  WATCH_LIST = 'WATCH_LIST error',
  PROGRAM_LIST = 'PROGRAM_LIST error',
  PLAYLIST = 'PLAYLIST error',
  PLAYLIST_ITEM = 'PLAYLIST_ITEM error',
  CONTINUE_WATCHING = 'CONTINUE_WATCHING error',
  EDITORIAL_OVERVIEW = 'EDITORIAL_OVERVIEW error',
  FAVORITES = 'FAVORITES error',
  AVAILABLE_SOON = 'Binnenkort beschikbaar'
}
