export enum FIXED_COUNT {
  SKELETON_PROGRAM_NUMBER = 3,
  SKELETON_BRAND_ITEMS = 8,
  SKELETON_SEARCH_PORTRAIT_ITEMS = 10,
  SKELETON_SEARCH_LANDSCAPE_ITEMS = 6,
  DEBOUNCE_TIME = 200,
  BRANDS_LIMIT = 25,
  MOVIES_LIMIT = 24,
  ALL_MOVIES_LIMIT = 100,
  TRENDING_LIMIT = 24,
  MISSED_NUMBER_OF_DAYS = 1,
  PROMO_IMAGE_MAX_WIDTH = 1900,
  IMAGE_HEIGHT_SMALL_SCREENS = 230,
  TEMPORARY_FORMAT_LIMIT = 50,
  SIDEBAR_ITEMS_MIN = 2,
  FORMAT_VIDEO_LIST_LIMIT = 12,
  MIN_PORTRAIT_ITEMS_PER_ROW = 2,
  ROWS_TO_FETCH = 2,
  MAX_NUM_REQUESTS = 10
}
