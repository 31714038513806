import { gql } from '@apollo/client';
import { ImageMediaFragment, MediaFragment } from './programs.fragments';

const ProgramsByDateQuery: any = gql`
  query programsByDate(
    $date: DateTime!
    $numOfDays: Int
    $programTypes: [ProgramType]
    $availability: Boolean
    $sort: ProgramsByDateSortKey
  ) {
    programsByDate(
      date: $date
      numOfDays: $numOfDays
      programTypes: $programTypes
      availability: $availability
      sort: $sort
    ) {
      date
      totalResults
      items {
        id
        type
        guid
        availableRegion
        slug
        title
        seasonNumber
        tvSeasonId
        duration
        tvSeasonEpisodeNumber
        displayGenre
        publicationDateTime
        metadata
        sources {
          drm
          file
          type
        }
        series {
          id
          guid
          slug
          title
        }
        ...ImageMedia
        ...Media
      }
    }
  }
  ${ImageMediaFragment}
  ${MediaFragment}
`;

export default ProgramsByDateQuery;
