import { PROGRAM } from '../../client/constants';
import VideoModel from '../../client/models/video.model';

/**
 * Returns season id from tvSeasonId that API returns as example given:
 * http://data.entertainment.tv.theplatform.eu/entertainment/data/TvSeason/115682856154
 * where 115682856154 is the season ID
 * @param tvSeasonId[String]
 */
export const getSeasonId = (tvSeasonId: string): string | undefined => {
  if (tvSeasonId?.indexOf?.('/') > -1) {
    return tvSeasonId?.split?.('/')?.pop?.();
  }
};

export const slugify = (path: string) => path.replace(/\W+/g, '-').toLocaleLowerCase();

export const getUserInitials = (firstName: string, lastName: string): string =>
  firstName.substring(0, 1).toUpperCase() + lastName.charAt(lastName.lastIndexOf(' ') + 1).toUpperCase();

const setSeason = (program: VideoModel): string => (program.seasonNumber ? `Seizoen ${program.seasonNumber},` : '');

export const setSubtitle = (program: VideoModel): string | null => {
  if (!program.tvSeasonEpisodeNumber && !program.seasonNumber) return null;
  return program.type === PROGRAM.EPISODE ? `${setSeason(program)} aflevering ${program.tvSeasonEpisodeNumber}` : '';
};

export const addProtocolPrefix = (url: string): string => {
  if (url.indexOf('//') === 0) {
    return `https:${url}`;
  }
  return url;
};
