import styled from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';
import { NavListStyle } from 'components/Nav/NavList/NavList.style';
import { SearchButtonStyle } from 'components/SearchButton/SearchButton.style';
import { AccountImgWrapper } from 'components/Account/AccountMenu/AccountMenu.style';
import { TooltipBottom, Tooltip, TooltipTrigger } from 'components/Tooltip/Tooltip.styled';
import { LogoStyle } from '../Logo/Logo.style';
import Colors from '../../styles/colors';

export const InnerStyle = styled.div`
  max-width: 1760px;
  margin: 0 16px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin: 0 10px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    margin: 0 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
    margin: 0 80px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    width: 1760px;
    margin: 0 auto;
  }
`;

export const HeaderWrapperStyle = styled.header<IHeaderStyleProps>`
  width: 100%;
  z-index: ${props => props.theme.zIndex.component.header.container};
  display: block;
  position: fixed;
  background-color: transparent;
  transition: transform ${props => props.theme.transitions.easeIn};
  transform: ${props => (props.isHeaderHidden ? 'translateY(-56px)' : 'none')};

  ${InnerStyle} {
    position: relative;
    z-index: ${props => props.theme.zIndex.component.header.content};

    @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
      width: 100%;
    }
  }

  @media (orientation: landscape) {
    display: block;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    transform: ${props => (props.isHeaderHidden ? 'translateY(-70px)' : 'none')};
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    transform: ${props => (props.isHeaderHidden ? 'translateY(-80px)' : 'none')};
  }

  ${NavListStyle} {
    @media (max-width: ${props => props.theme.breakpoints.smMax}) {
      display: none;
    }
  }

  ${SearchButtonStyle}, ${AccountImgWrapper} {
    background-color: transparent;
  }

  ${Tooltip} {
    margin-right: 0;
    margin-left: auto;
  }
  ${TooltipBottom} {
    @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
        props.theme.breakpoints.mdMax}) {
      left: calc(100% - 325px);
      transform: unset;
      margin-top: 45px;
    }
    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      transform: translateX(-77%);
      &::after {
        left: 77%;
      }
    }
  }
  ${TooltipTrigger} {
    &::after {
      margin-left: -33px;
      bottom: -15px;
      top: unset;
    }
    @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
        props.theme.breakpoints.mdMax}) {
      &::after {
        margin-left: -42px;
        bottom: -16px;
        top: unset;
      }
    }
  }
`;

export const GradientStyle = styled.div<IHeaderStyleProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 56px;
  width: 100%;
  z-index: ${props => props.theme.zIndex.component.header.overlay};
  background: ${props =>
    linearGradient(props.theme.gradients.header.solid.direction, props.theme.gradients.header.solid.stops)};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: 70px;
    transition: opacity ${props => props.theme.transitions.easeIn};
    background: ${props =>
      props.isTransparent
        ? linearGradient(
            props.theme.gradients.header.transparent.direction,
            props.theme.gradients.header.transparent.stops
          )
        : linearGradient(props.theme.gradients.header.solid.direction, props.theme.gradients.header.solid.stops)};
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    height: 80px;
  }
`;

export const HeaderContentStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    a {
      flex: 1;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: 70px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) {
    height: 80px;
  }

  ${LogoStyle} {
    padding: 9px 0;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      padding: 16px 0;
      margin-right: 40px;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
        props.theme.breakpoints.mdMax}) {
      display: none;
    }

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      padding: 12px 0;
    }
  }
`;

export const HeaderRightContentStyle = styled.div<{ hasPageTitle: boolean }>`
  display: flex;
  margin-left: auto;

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    flex: ${props => props.hasPageTitle && '1'};
    margin: ${props => props.hasPageTitle && '0 1%'};

    ${SearchButtonStyle} {
      margin-right: 0;
      margin-left: ${props => props.hasPageTitle && 'auto'};
    }
  }
`;

export const HeaderHelperStyle = styled.div`
  position: relative;
`;

export const TopbarTitleStyle = styled.span`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  flex: 1;
  margin: 0 1%;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    display: none;
  }
`;
