import React from 'react';
import { useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import addDrm from 'lib/addDrm';
import SourcesQuery from 'queries/programs/sources.query';
import { PreviewContainer } from './VideoPreview.style';

const JW_BITRATE_KEY = 'jwplayer.bitrateSelection';
const JW_QUALITY_LABEL_KEY = 'jwplayer.qualityLabel';

const VideoPreview: React.FC<{ guid: string; image: string }> = ({ guid, image }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const imageUrl = `https://cldnr.kijk.nl/talpa-network/image/fetch/ar_16:9,c_fill,dpr_2.0,f_auto,g_face:auto,h_235,w_auto,f_auto/${image}`;
  const { client } = useQuery(SourcesQuery, { skip: true });
  React.useEffect(() => {
    if (!ref.current) return;
    const id = uuidv4();

    let playerInstance: jwplayer.JWPlayer | null = null;
    let timeout: null | number = null;

    const loadPreview = async () => {
      try {
        const {
          data: {
            programs: { items }
          }
        } = await client.query<{
          programs: { items: { guid: string; sources: ISourceGraphql[] }[] };
        }>({
          query: SourcesQuery,
          variables: { guid }
        });

        const [{ sources }] = items;

        if (!sources.length) {
          return;
        }
        playerInstance = window.jwplayer(id) as jwplayer.JWPlayer;
        playerInstance.setup({
          playlist: [{ sources: JSON.parse(JSON.stringify(sources)), image: imageUrl }],
          displaytitle: false,
          autostart: true,
          mute: true,
          width: '100%',
          height: '100%',
          controls: false,
          key: 'VYuWswBjYg8cox2qLLfIZa/VrTHW40IV5PAflcfcKvk8TZM7',
          pipIcon: 'disabled'
        });

        (playerInstance as any).setPlaylistItemCallback((item: { sources: ISourceGraphql[] }) => {
          // add DRM
          item.sources = item.sources.map(addDrm);
          return item;
        });
        playerInstance.on('ready', () => {
          if (!playerInstance) return;
          const container = playerInstance.getContainer();
          container.style.backgroundImage = `url(${imageUrl})`;
        });
        playerInstance.on('firstFrame', () => {
          if (!playerInstance) return;
          const container = playerInstance.getContainer();
          container.style.opacity = '1';
        });
        playerInstance.on('meta', () => {
          if (!playerInstance) return;
          const levels = playerInstance.getQualityLevels();
          if (!levels.length) return;
          const lowestLevel = levels.length - 1;
          playerInstance.setCurrentQuality(lowestLevel);
        });
        playerInstance.on('time', ({ position, duration }: { position: number; duration: number }) => {
          if (!playerInstance) return;
          if (duration !== Infinity && position > 10) {
            playerInstance.seek(0);
          }
        });
        playerInstance.on('error', () => {
          if (!playerInstance) return;
          playerInstance.remove();
        });
      } catch (ignore) {}
    };

    ref.current.onmouseenter = () => {
      if (!ref.current) return;
      // add player div
      const div = document.createElement('div');
      div.id = id;
      ref.current.appendChild(div);
      ref.current.style.backgroundImage = `url(${imageUrl})`;

      timeout = window.setTimeout(loadPreview);
    };

    ref.current.onmouseleave = () => {
      localStorage.setItem(JW_BITRATE_KEY, 'null');
      localStorage.setItem(JW_QUALITY_LABEL_KEY, 'Auto');
      if (ref.current) ref.current.innerHTML = '';
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      try {
        if (playerInstance) playerInstance.remove();

        window.setTimeout(() => {
          if (ref.current) ref.current.style.backgroundImage = '';
        }, 100);
      } catch (ignore) {}
    };

    return () => {
      try {
        localStorage.setItem(JW_BITRATE_KEY, 'null');
        localStorage.setItem(JW_QUALITY_LABEL_KEY, 'Auto');

        if (playerInstance) playerInstance.remove();
      } catch (ignore) {}
    };
  }, [guid]);

  return <PreviewContainer data-testid="previewContainer" ref={ref} backgroundImage={imageUrl} />;
};

export default VideoPreview;
