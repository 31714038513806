import { FunctionComponent } from 'react';
import NextLink from 'next/link';
import { trackClick } from 'lib/tealium/provider';

const Link: FunctionComponent<ILinkProps> = ({
  path,
  href,
  isDisabled,
  target,
  children,
  clickEventValues,
  Component
}) => {
  if (isDisabled || (!href && !path)) {
    return children;
  }

  const handleClick = () => {
    if (clickEventValues) trackClick(clickEventValues);
  };

  const LinkComponent = Component || 'a';

  if (path) {
    return (
      <NextLink href={path} passHref legacyBehavior>
        <LinkComponent target={target} onClick={handleClick}>
          {children}
        </LinkComponent>
      </NextLink>
    );
  }
  return (
    <LinkComponent href={href} target={target} onClick={handleClick}>
      {children}
    </LinkComponent>
  );
};

export default Link;
