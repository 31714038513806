import styled, { css } from 'styled-components';
import Colors from '../../styles/colors';

const headingStyles = css`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: ${Colors.white};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const Heading1 = styled.h1<{ align?: string }>`
  ${headingStyles}
  text-align: ${({ align }) => align || 'left'};
`;

export const Heading2 = styled.h2<{ align?: string }>`
  ${headingStyles}
  text-align: ${({ align }) => align || 'left'};
`;

export const Heading3 = styled.h3`
  font-size: 16px;
  line-height: initial;
  font-weight: 700;
  color: ${(props: ITextProps) => (props.isActive ? Colors.greenishTeal : Colors.white)};
  text-align: ${(props: ITextProps) => (props.align ? props.align : 'left')};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    line-height: 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    font-size: 18px;
  }
`;

export const Heading4 = styled.h4`
  font-size: 16px;
  line-height: normal;
  font-weight: 700;
  color: ${Colors.white};
  text-align: ${(props: ITextProps) => (props.align ? props.align : 'left')};
`;

export const BodyText = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: ${Colors.white};
  text-align: ${(props: ITextProps) => (props.align ? props.align : 'left')};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Subtext = styled.div`
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  color: ${(props: ITextProps) => (props.isPrimary ? Colors.white : Colors.brownGrey)};
  text-align: ${(props: ITextProps) => (props.align ? props.align : 'left')};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ActionLabel = styled.div`
  font-size: 12px;
  line-height: normal;
  font-weight: 700;
  color: ${(props: ITextProps) => (props.isPrimary ? Colors.white : Colors.brownGrey)};
  text-align: ${(props: ITextProps) => (props.align ? props.align : 'left')};
`;

export const DialogButon = styled.h4`
  font-size: 12px;
  line-height: normal;
  font-weight: 700;
  color: ${Colors.white};
`;

export const VideoItemSubtitleDate = styled(Heading4)`
  text-transform: uppercase;
  font-size: 11px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    font-size: 12px;
  }
`;

export const AboutInfo = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.white};
`;

export const VideoMetaDataTitle = styled.h1`
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  color: ${Colors.white};

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
  }
`;

export const BoldText = styled.span`
  font-weight: bold;
`;
