import { gql } from '@apollo/client';
import { ImageMediaFragment, MediaFragment, SourcesFragment } from './programs.fragments';

const ProgramsBySeriesId: any = gql`
  query ($seriesId: String, $limit: Int!) {
    clips: programs(programTypes: CLIP, limit: $limit, seriesId: $seriesId, sort: PUBLICATIONDATETIME) {
      totalResults
      items {
        id
        guid
        availableRegion
        title
        type
        slug
        seriesId
        tvSeasonId
        seasonNumber
        tvSeasonEpisodeNumber
        added
        publicationDateTime
        duration
        displayGenre
        metadata
        series {
          id
          guid
          slug
          title
        }
        ...Media
        ...ImageMedia
        ...Sources
      }
    }
    episodes: programs(programTypes: EPISODE, limit: $limit, seriesId: $seriesId, sort: LASTPUBDATE_DESC) {
      totalResults
      items {
        id
        guid
        title
        type
        slug
        seriesId
        tvSeasonId
        seasonNumber
        tvSeasonEpisodeNumber
        added
        publicationDateTime
        duration
        displayGenre
        metadata
        series {
          id
          guid
          slug
          title
        }
        ...Media
        ...ImageMedia
        ...Sources
      }
    }
  }
  ${MediaFragment}
  ${ImageMediaFragment}
  ${SourcesFragment}
`;

export default ProgramsBySeriesId;
