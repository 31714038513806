import { useRouter } from 'next/router';
import type { Session } from 'next-auth';
import { signIn, signOut, useSession } from 'next-auth/react';
import { getUserInitials } from 'lib/helpers/string';
import { trackClick } from 'lib/tealium/provider';
import Dropdown from 'components/Dropdown';
import React from 'react';
import * as Styled from './AccountMenu.style';
import AccountLoader from './AccountLoader';
import {
  ACCOUNT_MENU_LABELS,
  ACCOUNT_OPTIONS,
  ICONS,
  ROUTES,
  TEALIUM_EVENT_CATEGORY,
  TEALIUM_EVENT_LABEL,
  TEALIUM_EVENT_NAME
} from '../../../constants';

const AccountMenu: React.FC = () => {
  const { data: session, status, update } = useSession();

  const isLoggedIn = status === 'authenticated';
  const isLoading = status === 'loading';

  const { profileUrl, firstName, lastName } = (session || {}) as Session & {
    idToken: string;
    profileUrl: string;
    firstName: string;
    lastName: string;
  };

  const router = useRouter();
  const handleButtonClick = () => {
    trackClick({
      label: TEALIUM_EVENT_LABEL.ACCOUNT,
      name: TEALIUM_EVENT_NAME.HEADER_CLICK,
      category: TEALIUM_EVENT_CATEGORY.HEADER
    });
    if (!isLoggedIn) {
      signIn('talpaauth');
    }
  };

  const iconButton = React.useMemo(() => {
    if (!isLoggedIn || !firstName) {
      return {
        icon: ICONS.ACCOUNT,
        hiddenText: 'Account'
      };
    }

    return {
      text: getUserInitials(firstName, lastName || '')
    };
  }, [isLoggedIn, firstName, lastName]);

  const handleDropdownChange = (value: string) => {
    trackClick({
      label: `account | ${value}`,
      name: TEALIUM_EVENT_NAME.MENU_CLICK,
      category: TEALIUM_EVENT_CATEGORY.MENU
    });
    if (value === ACCOUNT_MENU_LABELS.FAVORITES) router.push(ROUTES.ACCOUNT_WATCHLIST);
    if (value === ACCOUNT_MENU_LABELS.CONTINUE_WATCHING) router.push(ROUTES.ACCOUNT_CONTINUOUS_WATCHING);
    if (value === ACCOUNT_MENU_LABELS.MY_ACCOUNT) {
      window.open(`${profileUrl}&redirect_uri=${document.location.href}`, '_self');
    }
    if (value === ACCOUNT_MENU_LABELS.HELP) window.open('https://klantenservice.kijk.nl', '_blank');
    if (value === ACCOUNT_MENU_LABELS.LOG_OUT) signOut();
    if (value === 'update') update();
  };

  if (isLoading) {
    return <AccountLoader />;
  }

  return (
    <Styled.AccountMenu isLoggedIn={isLoggedIn}>
      <Dropdown
        options={isLoggedIn ? ACCOUNT_OPTIONS : []}
        callback={handleDropdownChange}
        onButtonClick={handleButtonClick}
        iconButton={iconButton}
      />
    </Styled.AccountMenu>
  );
};

export default AccountMenu;
