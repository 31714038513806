import { css } from 'styled-components';
import Colors from '../../styles/colors';

export const PlayEpisodeButtonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background-color: ${Colors.white};
  border-radius: 4px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;

  img {
    max-height: 25px;
    height: 25px;
    width: 25px;
    margin: 0 5px 0 -5px;
    filter: invert(1);
  }

  &:hover {
    color: ${Colors.grey};
  }
`;
