import { ApolloClient, InMemoryCache } from '@apollo/client';
import EpisodeModel from '../../client/models/episode.program.model';
import ClipModel from '../../client/models/clip.program.model';
import ProgramsBySeriesId from '../../client/queries/programs/programsBySeriesId.query';
import { TAB_NAMES } from '../../client/constants';

const getVideosBySeriesId = async (
  seriesId: string,
  client: ApolloClient<InMemoryCache>,
  limit: number = 12
): Promise<{ clips: ClipModel[]; episodes: EpisodeModel[] }> => {
  try {
    const { data } = await client.query<{
      clips: { items: IProgramGraphql[] };
      episodes: { items: IProgramGraphql[] };
    }>({
      query: ProgramsBySeriesId,
      variables: {
        seriesId,
        limit
      }
    });
    const { clips, episodes } = data || {};

    return {
      clips:
        clips?.items
          ?.map(
            (item, index) =>
              new ClipModel(item, {
                showFormatTitle: false,
                collectionTitle: TAB_NAMES.AFLEVERINGEN,
                position: index + 1
              })
          )
          .filter(item => item.isValid) || [],
      episodes:
        episodes?.items
          ?.map(
            (item, index) =>
              new EpisodeModel(item, { showFormatTitle: false, collectionTitle: TAB_NAMES.CLIPS, position: index + 1 })
          )
          .filter(item => item.isValid) || []
    };
  } catch {
    return {
      clips: [],
      episodes: []
    };
  }
};

export default getVideosBySeriesId;
