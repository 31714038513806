const Colors = {
  azure: '#1da1f2',
  battleshipGrey: 'rgba(110, 116, 120)',
  black: '#000000',
  nero: '#222222',
  black20: 'rgba(0, 0, 0, 0.2)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black40: 'rgba(0, 0, 0, 0.4)',
  black50: 'rgba(0, 0, 0, 0.5)',
  black68: 'rgba(0, 0, 0, 0.68)',
  black60: 'rgba(0, 0, 0, 0.6)',
  black70: 'rgba(0, 0, 0, 0.7)',
  black80: 'rgba(0, 0, 0, 0.8)',
  nero40: 'rgba(34, 34, 34, 0.4)',
  nero60: 'rgba(34, 34, 34, 0.6)',
  nero80: 'rgba(34, 34, 34, 0.8)',
  brownGrey: '#888888',
  grey: '#7f7f7f',
  butter: '#feff80',
  denimBlue: '#3b5998',
  greenishTeal: '#33c497',
  greyishBrown: '#444444',
  greyishBrown40: 'rgba(68, 68, 68, 0.4)',
  khaki: '#cab53c',
  mediumBlue: '#2f7eb0',
  transparentMediumBlue: 'rgba(47, 126, 176, 0)',
  paleLavender: '#eee1f8',
  royal: '#280f80',
  violet: '#ab13dd',
  white: '#ffffff',
  transparentBlack: 'rgba(0, 0, 0, 0)',
  transparentNero: 'rgba(34, 34, 34, 0)',
  transparent: 'transparent',
  red: '#df1a0a',
  paleGrey: '#d2d2d2',
  darkWhite: '#e8e8e8',
  twitterBlue: '#1da1f2',
  facebookBlue: '#3b5998',
  brandsGrey: '#2e2e2e',
  white25: 'rgba(255,255,255, 0.25)',
  white0: 'rgba(255,255,255, 0)',
  white60: 'rgba(255, 255, 255, 0.6)'
};

export default Colors;
