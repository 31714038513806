import styled from 'styled-components';
import Colors from '../../../styles/colors';

export const WatchMoreButtonStyle = styled.div`
  grid-column: 1 / 13;
  justify-self: center;
  width: 288px;
  margin: 0 auto;
  line-height: 48px;
  color: ${Colors.white};
  border-radius: 4px;
  border: 2px solid ${Colors.brownGrey};
  font-size: 16px;
  text-align: center;
  font-weight: 700;
  cursor: pointer;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    display: none;
  }
`;
