export const getCookie = (cookieName: string): string | null => {
  try {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].trim();
      const [name, value] = cookie.split('=');
      if (name === cookieName) {
        return decodeURIComponent(value);
      }
    }
  } catch {}
  return null;
};

export const setCookie = (name: string, value: string, expDays = 365) => {
  const d = new Date();
  d.setTime(d.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

export const removeCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=1; expires=1 Jan 1970 00:00:00 GMT;`;
};
