import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import SearchInput from 'components/SearchInput';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import ErrorMessage from 'components/ErrorMessage';
import CommonFallback from 'components/CommonFallback';
import EditorialOverview from 'components/EditorialOverview';
import { SkeletonPortraitItemCarousel } from 'components/SkeletonElement/SkeletonElement';
import EditorialOverviewQuery from 'queries/overview/overview.query';
import { setDataLayer, trackClick } from 'lib/tealium/provider';
import OverviewModel from 'models/overview.model';
import updateSearchParams from 'lib/updateSearchParams';
import {
  SearchModalCloseButtonStyle,
  SearchModalHeaderInnerStyle,
  SearchModalHeaderStyle,
  SearchModalInitialTrendingStyle,
  SearchModalInputAnimationWrapperStyle,
  SearchModalInputWrapperStyle,
  SearchModalOverviewStyle,
  SearchModalStyle
} from './SearchModal.style';
import { Grid, GridElement } from '../../styles/grid';
import {
  BREAKPOINTS,
  FALLBACK_UI_LABELS,
  FIXED_COUNT,
  KEYBOARD,
  PROMO_QUERY_CLIENT,
  TEALIUM_EVENT_CATEGORY,
  TEALIUM_EVENT_NAME
} from '../../constants';
import SearchResults from './SearchResults';

const DEBOUNCE_TIME = 300;
const ANIMATION_DURATION = 500;
const SEARCH_KEYWORD_TEALIUM_DURATION = 2000;
const SKELETON_NUMBER_OF_PROGRAM_ITEMS = 3;
const SEARCH_SLUG = 'search';

const SearchModal: FunctionComponent<ISearchModalProps> = ({ viewMode }) => {
  const router = useRouter();
  const isOpen = typeof router.query.zoek !== 'undefined';
  const SearchInputRef = useRef<HTMLInputElement>(null);
  const [animationFinished, setAnimationState] = useState(false);
  const [EditorialOverviewData, setEditorialOverview] = useState<OverviewModel | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showEditorialContent, setShowEditorialContent] = useState(false);
  const gridRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const handleSearchInput = (value: string): void => {
    const searchTerm: string | undefined = value.replace(/^\s*/g, '');
    const term: string =
      searchTerm
        ?.match?.(/([A-Za-z0-9 .\-_ ]*)/gm)
        ?.join('')
        ?.trim() || '';

    setSearchQuery(term);
    updateSearchParams(router, term);
  };

  const sendSearchQueryToTealium = (value: string): void => {
    if (!value) return;
    setDataLayer({ search_keyword: value });
    trackClick({
      name: TEALIUM_EVENT_NAME.SEARCH_QUERY,
      category: TEALIUM_EVENT_CATEGORY.SEARCH,
      label: value
    });
  };

  const debouceSearchRequest = React.useCallback(debounce(DEBOUNCE_TIME, handleSearchInput), []);
  const debouceTealiumRequest = React.useCallback(
    debounce(SEARCH_KEYWORD_TEALIUM_DURATION, sendSearchQueryToTealium),
    []
  );

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    const handleKeydown = (event: KeyboardEvent): void => {
      if (event.key === KEYBOARD.ESCAPE) {
        handleCloseSearch();
      }
    };

    if (isOpen) {
      if (typeof window !== 'undefined' && window.document) {
        document.body.style.setProperty('overflow', 'hidden', 'important');
      }
      const SearchParam = router.query.zoek && decodeURI(router.query.zoek as string);
      if (SearchParam) {
        setSearchQuery(SearchParam);
        debouceTealiumRequest(SearchParam);
        if (SearchInputRef && SearchInputRef.current) {
          SearchInputRef.current.value = SearchParam;
        }
      } else {
        updateSearchParams(router, '');
      }

      setTimeout(() => {
        SearchInputRef.current?.focus();
        setAnimationState(true);
      }, ANIMATION_DURATION);
      timer = setTimeout(setShowEditorialContent, 600, true);
    } else {
      if (typeof window !== 'undefined' && window.document) {
        document.body.style.setProperty('overflow', 'auto', 'important');
      }
      setAnimationState(false);
    }

    document.addEventListener(KEYBOARD.KEYDOWN, handleKeydown);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      document.removeEventListener(KEYBOARD.KEYDOWN, handleKeydown);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleResize = debounce(FIXED_COUNT.DEBOUNCE_TIME, () => {
      if (!SearchInputRef.current) return;

      if (gridRef.current) setContainerWidth(gridRef.current.offsetWidth);

      SearchInputRef.current.setAttribute(
        'placeholder',
        window.innerWidth > BREAKPOINTS.mdMin ? "Zoek op programma's, aflevering of film" : 'Zoeken'
      );
    });
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [SearchInputRef, gridRef]);

  const { loading, error } = useQuery(EditorialOverviewQuery, {
    variables: {
      slug: SEARCH_SLUG,
      client: PROMO_QUERY_CLIENT.DESKTOP
    },
    onCompleted: (data: { overview?: IEditorialOverviewData }) => {
      if (data?.overview) {
        const editorialOverview = new OverviewModel(data.overview, {
          isOnSearch: true
        });
        setEditorialOverview(editorialOverview.isValid ? editorialOverview : null);
      }
    }
  });

  const handleCloseSearch = (): void => {
    resetSearch();
    setShowEditorialContent(false);
    SearchInputRef.current?.blur();
  };

  const resetSearch = (): void => {
    updateSearchParams(router, null);
    setSearchQuery('');
    if (SearchInputRef.current) {
      SearchInputRef.current.value = '';
      SearchInputRef.current.focus();
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    debouceSearchRequest(value);
    debouceTealiumRequest(value);
  };

  return (
    <SearchModalStyle data-testid="searchModal" isOpen={isOpen}>
      <SearchModalHeaderStyle>
        <SearchModalHeaderInnerStyle ref={gridRef} isOpen={isOpen}>
          <SearchModalInputAnimationWrapperStyle>
            <SearchModalInputWrapperStyle isOpen={isOpen}>
              <SearchInput isOpen={isOpen} placeholder={''} ref={SearchInputRef} onChange={onChange} />
            </SearchModalInputWrapperStyle>
          </SearchModalInputAnimationWrapperStyle>
          <SearchModalCloseButtonStyle isOpen={isOpen} data-testid="buttonCloseSearch" onClick={handleCloseSearch} />
        </SearchModalHeaderInnerStyle>
      </SearchModalHeaderStyle>
      {searchQuery !== '' && searchQuery.length > 1 ? (
        <SearchResults
          searchQuery={searchQuery}
          resetSearch={resetSearch}
          overview={EditorialOverviewData}
          viewMode={viewMode}
          containerWidth={containerWidth}
        />
      ) : isOpen && animationFinished ? (
        <SearchModalInitialTrendingStyle data-testid="searchModalTrendingStyle">
          <ErrorBoundary onError={() => <CommonFallback message={FALLBACK_UI_LABELS.TRENDING_PROGRAMS} />}>
            {loading && <SkeletonPortraitItemCarousel noOfItems={SKELETON_NUMBER_OF_PROGRAM_ITEMS} />}
            {error && (
              <Grid>
                <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
                  <ErrorMessage />
                </GridElement>
              </Grid>
            )}
            {showEditorialContent && EditorialOverviewData && (
              <SearchModalOverviewStyle>
                <EditorialOverview editorialOverviewItems={EditorialOverviewData.items} viewMode={viewMode} />
              </SearchModalOverviewStyle>
            )}
          </ErrorBoundary>
        </SearchModalInitialTrendingStyle>
      ) : null}
    </SearchModalStyle>
  );
};

export default SearchModal;
