import { gql } from '@apollo/client';

const VideosProgressQuery = gql`
  query videoProgress($guids: [String], $completed: Boolean) {
    videoProgress(guids: $guids, completed: $completed, skipSeconds: 20) {
      guid
      position
      completed
    }
  }
`;

export default VideosProgressQuery;
