import styled from 'styled-components';

export const ShowMoreButtonWrapper = styled.div`
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: inherit;
  }
`;

export const ShowMoreCenterButton = styled.div<ILoadMoreButtonProps>`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  justify-content: center;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 47px;
  }
`;
