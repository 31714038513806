import { useQuery, QueryResult, OperationVariables, DocumentNode } from '@apollo/client';

const useImprovedQuery = <T, R>(
  query: DocumentNode,
  { processData, ...operationVariables }: OperationVariables & { processData: (data: T) => R | null }
): QueryResult<T> & { processedData: R | undefined | null } => {
  const queryResult = useQuery<T>(query, operationVariables);
  return {
    ...queryResult,
    processedData: queryResult.data ? processData(queryResult.data) : null
  };
};

export default useImprovedQuery;
