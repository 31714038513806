import styled from 'styled-components';
import { Heading1 } from '../Text/Text.style';

export const LayoutStyle = styled.div`
  padding: 30px 0;

  ${Heading1} {
    margin-bottom: 32px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    padding: 80px 0 0;

    &:first-child {
      padding: 40px 0 80px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    padding: 0;
  }
`;
