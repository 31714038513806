/* eslint-disable no-lonely-if */
import { RefObject } from 'react';
import { VideoModel } from 'models/index';
import { animate } from '../animate';
import EasingFunctions from '../easing';
import { getCookie, setCookie } from './cookies';
import { CLIENT, COOKIE_NAME, FIXED_COUNT, BREAKPOINTS } from '../../client/constants';

export const sideScroll = (element: HTMLElement | null, rangeInPixels: number): void => {
  if (element) {
    const sequenceObj: any = {};
    const seconds: number = 0.5;
    const startingScrollPosition = element.scrollLeft;
    sequenceObj.progress = (percentage: number) => {
      // eslint-disable-next-line no-param-reassign
      element.scrollLeft = EasingFunctions.easeOutQuad(percentage) * rangeInPixels + startingScrollPosition;
    };
    animate(sequenceObj, seconds);
  }
};

export const linearGradient = (
  direction: string = 'to right',
  stops: IStop[] = [{ color: '000', position: '0%' }]
): string => `linear-gradient(${direction}, ${stops.map((stop: IStop) => `${stop.color} ${stop.position}`)})`;

export const radialGradient = (direction: string, stops: IStop[]): string =>
  `radial-gradient(${direction}, ${stops.map((stop: IStop) => `${stop.color} ${stop.position}`)})`;

export const getContextMenuPosition = (ref: RefObject<any>, align: string): { top: number; left: number } => {
  const { top, left, height, width } = ref.current.getBoundingClientRect();
  const OffsetY: number = 8;
  const OffsetX: number = 16;
  const ContextMenuWidth: number = 288;

  const CalculatedTop: number = top + window.scrollY + height + OffsetY;
  let CalculatedLeft: number;

  if (align === 'right' && left + width - ContextMenuWidth >= OffsetX) {
    CalculatedLeft = width - ContextMenuWidth;
  } else {
    if (document.body.clientWidth - left > ContextMenuWidth + OffsetX) {
      CalculatedLeft = 0;
    } else if (left + width - ContextMenuWidth >= OffsetX) {
      CalculatedLeft = width - ContextMenuWidth;
    } else {
      CalculatedLeft = (document.body.clientWidth - ContextMenuWidth) / 2 - left;
    }
  }

  return {
    top: CalculatedTop,
    left: CalculatedLeft
  };
};

export const getViewMode = (ctx: any): string => {
  if (ctx && ctx.req && ctx.req.headers) {
    const { headers } = ctx.req;
    if (['true', true].indexOf(headers['true-cloudfront-is-tablet-viewer']) > -1) {
      return CLIENT.TABLET;
    }
    if (['true', true].indexOf(headers['true-cloudfront-is-mobile-viewer']) > -1) {
      return CLIENT.MOBILE;
    }
    if (headers.host.indexOf('local') === 0 && headers['user-agent'].toLowerCase().indexOf('mobile') > -1) {
      return CLIENT.MOBILE;
    }
    return CLIENT.DESKTOP;
  }
  return CLIENT.DESKTOP;
};

export const isVideoCompleted = (position: number | null = 0, duration: number | null = 0): boolean => {
  if (position === null || duration === null) {
    return false;
  }

  const SecondsInMinute: number = 60;
  const DurationInMinutes: number = Math.round(duration / SecondsInMinute);
  return DurationInMinutes > 20
    ? position >= duration - 60
    : +getPositionInPercentage(position, duration).toFixed(2) >= 95;
};

export const getPositionInPercentage = (position: number = 0, duration: number | null = 0): number =>
  position && duration ? (position / duration) * 100 : 0;

/*
 * isEmpty is a function that checks whether a property on an object is empty.
 * It checks if the property value is falsy, or if it's an object or array with no keys or elements.
 * @param {Object} data - The object that you want to check
 * @param {string} propertyName - The name of the property on the first param object that you want to check
 * @returns {boolean} - True if the property is empty, false otherwise
 */

export const isEmpty = (data: any, propertyName: string): boolean => {
  if (!data) return true;

  const propertyValue = data[propertyName];
  if (!propertyValue) return true;

  if (Array.isArray(propertyValue)) {
    return propertyValue.length === 0;
  }

  if (typeof propertyValue === 'object') {
    return Object.keys(propertyValue).length === 0;
  }

  return false;
};

export const getHeadingPromoHeight = (): number => {
  if (typeof window === 'undefined') {
    return 0;
  }
  if (window.innerWidth < 640) {
    return (window.innerHeight / 100) * 40;
  }
  if (window.innerWidth > 640 && window.innerWidth < 1025) {
    return (window.innerHeight / 100) * 55;
  }
  return (window.innerHeight / 100) * 75;
};

export const AD_IDS_LIMIT = 200;
export const setAdIdCookie = (adId: string) => {
  const adIdsInCookie = getCookie(COOKIE_NAME.WATCHED_AD_IDS);
  const adIds = adIdsInCookie ? adIdsInCookie.split(',') : [];
  adIds.unshift(adId);
  const result = [...new Set(adIds)].slice(0, AD_IDS_LIMIT).join(',');
  setCookie(COOKIE_NAME.WATCHED_AD_IDS, result);
};

export const filterVideosByUniqueSeriesGuid = (videos: VideoModel[]) =>
  videos.filter((video, index, arr) => arr.findIndex(({ series }) => series.guid === video.series.guid) === index);

export const getSizesForPromoImage = (
  offsetWidth: number,
  offsetHeight: number
): { newWidth: number; newHeight: number } => {
  const imageHeight = offsetWidth <= BREAKPOINTS.smMax ? FIXED_COUNT.IMAGE_HEIGHT_SMALL_SCREENS : offsetHeight;
  const aspectRatio = Math.round((imageHeight / offsetWidth) * 10) / 10;

  // Round to the nearest 100
  const newWidth = Math.min(FIXED_COUNT.PROMO_IMAGE_MAX_WIDTH, Math.ceil(offsetWidth / 100) * 100);
  const newHeight = Math.round(newWidth * aspectRatio);

  return {
    newWidth,
    newHeight
  };
};

export const getPlayerHeight = (width: number, height: number): number =>
  width > BREAKPOINTS.lgGrid ? Math.round(0.75 * height - 146) : 0;

export const getPlayerWidth = (width: number, height: number): number | null => {
  const playerHeight = getPlayerHeight(width, height);
  return playerHeight ? Math.round(playerHeight * 1.77777) : null;
};
