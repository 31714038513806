import styled from 'styled-components';
import { ButtonStyle } from 'components/Button/Button.style';
import Colors from '../../styles/colors';

export const BookmarkStyle = styled.div<{ isInWatchlist: boolean }>`
  display: flex;

  ${ButtonStyle} {
    padding: 0;
    margin-top: 0;
    height: unset;

    &:hover {
      border: ${`2px solid ${Colors.white}`};
    }
    &:last-child {
      margin-right: 0;
    }
  }

  ${props =>
    props.isInWatchlist &&
    `
    & > *:last-child {
      ${ButtonStyle} {
        border-color: ${Colors.white};
      }
    }
    `}
`;

export const BookmarkItemStyle = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border-color ${props => props.theme.transitions.easeIn};

  img {
    margin-bottom: 0 !important;
  }
`;
