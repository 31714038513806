import VideoModel from './video.model';

export default class MovieModel extends VideoModel {
  path: string;
  eventLabel: string;
  constructor(data: IProgramGraphql, playlistQueryParams: IPlaylistQueryParams = {}) {
    super(data, playlistQueryParams);
    this.thumbnailTitle = this.title;
    this.path = `/films/${this.slug}/${this.guid}`;
    this.eventLabel = this.title;
    this.ctaLabel = 'Bekijk film';
  }
}
