import { isEmpty } from 'lib/helpers';
import VideoModel from './video.model';

const REQUIRED_EPISODE_FIELDS = ['series', 'tvSeasonId'];

export default class EpisodeModel extends VideoModel {
  tvSeasonId: string = '';
  isValid: boolean;

  constructor(data: IProgramGraphql, playlistQueryParams: IPlaylistQueryParams = {}) {
    super(data, playlistQueryParams);
    this.isValid = this.isVideoValid && REQUIRED_EPISODE_FIELDS.every(field => !isEmpty(data, field));
    this.guid = data.guid;
    if (data.tvSeasonId) {
      this.tvSeasonId = data.tvSeasonId;
    }

    const prefix = this.showFormatTitle ? this.seriesTitle : '';
    const suffix = this.title || '';
    this.thumbnailTitle = `${prefix}${prefix && suffix && ': '}${suffix}`;
    this.thumbnailSubtitle = this.episodeNumberDetails;
    this.subtitle = this.episodeNumberDetails;
    this.ctaLabel = this.isLive ? 'Live' : 'Laatste aflevering';
  }
}
