import styled from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';
import { RemoveItemIconCloseStyle } from 'components/RemoveItemDialog/RemoveItemDialog.style';
import { LabelStyle } from '../Label/Label.style';
import { ImageMediaWrapperStyle } from '../ImageMedia/ImageMedia.style';
import Colors from '../../styles/colors';

export const VideoItemImageWrapperStyle = styled.div<{ isActive?: boolean; smLandscape?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  position: relative;
  border: ${props => (props.isActive ? `2px solid ${Colors.greenishTeal}` : 'none')};
  border-radius: 4px;
  box-shadow: ${props => (props.isActive ? `0 0 4px 0 ${Colors.greenishTeal}` : '0')};

  ${ImageMediaWrapperStyle} {
    border-radius: 4px;
    overflow: hidden;
  }

  a {
    width: 100%;
  }

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: ${Colors.nero};
    ${props =>
      props.smLandscape &&
      `
      img {
        height: 75px;
      }
    `}
    ${props =>
      !props.smLandscape &&
      `
      height: auto;
    `}
  }
`;

export const VideoItemImageGradientStyle = styled.div`
  position: absolute;
  width: 100%;
  height: 47px;
  bottom: 0;
  background-image: ${props =>
    linearGradient(props.theme.gradients.videoItem.direction, props.theme.gradients.videoItem.stops)};
`;

export const TimeLabelStyle = styled(LabelStyle)<{ isProgressBarShown: boolean; isPlayerSideBar: boolean }>`
  display: block;
  top: auto;
  left: auto;
  bottom: ${props => (props.isProgressBarShown ? '31px' : '8px')};
  right: 8px;
  padding: 0 6px;
  background: ${Colors.mediumBlue};
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  text-transform: none;
  color: ${Colors.white};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    bottom: ${props => {
      if (props.isPlayerSideBar && props.isProgressBarShown) return '20px !important';
      if (props.isPlayerSideBar && !props.isProgressBarShown) return '8px !important';
      if (props.isProgressBarShown) return '31px';
      return '16px';
    }};
    right: ${props => (props.isPlayerSideBar ? '8px' : '16px')};
  }
`;

export const VideoItemStyle = styled.div<{ isRemoveDialogShown: boolean; smLandscape?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: ${props => (props.smLandscape ? 'row' : 'column')};
  cursor: ${props => (props.isRemoveDialogShown ? 'default' : 'pointer')};

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    ${props =>
      props.smLandscape &&
      `
        div:first-child {
            flex: 0 1 133px;
        }
    `}
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    flex-direction: column;
  }

  ${RemoveItemIconCloseStyle} {
    display: ${props => (props.isRemoveDialogShown ? 'block' : 'none')};
  }

  &:hover {
    ${RemoveItemIconCloseStyle} {
      display: block;
    }
  }
`;

export const VideoItemProgressBarStyle = styled.div<IProgressBarProps>`
  position: absolute;
  left: 12px;
  bottom: 7px;
  width: 91%;
  height: 4px;
  margin-top: 11px;
  margin-bottom: 8px;
  border-radius: 2px;
  background: ${props => (props.isActive ? Colors.black : Colors.greyishBrown)};
  overflow: hidden;

  ::after {
    content: '';
    display: block;
    height: 100%;
    width: ${props => `${Math.floor(props.progress)}%`};
    background: ${props => (props.isActive ? Colors.greenishTeal : Colors.white)};
  }
`;
