import { linearGradient } from 'lib/helpers';
import styled from 'styled-components';
import Colors from '../../styles/colors';

export const DropdownButton = styled.button<{ isOpen: boolean; isDisabled: boolean }>`
  font-family: 'Cera', sans-serif;
  font-size: 18px;
  position: relative;
  width: 171px;
  padding: 2px 33px 2px 16px;
  height: 40px;
  background-color: ${Colors.transparent};
  color: ${Colors.white};
  border: 2px solid ${Colors.brownGrey};
  border-radius: 4px;
  cursor: pointer;
  box-shadow: none;
  opacity: 1;
  overflow: hidden;
  text-align: left;
  transition: scale 0.1s ease-in, border-color 0.15s ease-in;
  &:hover,
  &:focus {
    border-color: ${Colors.white};
    svg {
      color: ${Colors.white};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    width: 100%;
  }

  svg {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 25px;
    width: 24px;
    transform: rotate(90deg);
    color: ${Colors.greyishBrown};
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    background-color: ${Colors.greenishTeal};
    border-color: ${Colors.greenishTeal};
    &:hover,
    &:focus {
      border-color: ${Colors.greenishTeal};
    }
    svg {
      transform: rotate(-90deg);
      color: ${Colors.white};
    }
`}
  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.5;
    scale: 1 !important;
    cursor: default;
    &:hover,
    &:focus {
      border-color: ${Colors.brownGrey};
      svg {
        color: ${Colors.greyishBrown};
      }
    }
`}
`;

export const DropdownItems = styled.div`
  width: 288px;
  right: 0;
  max-width: calc(100vw - 40px);
  padding: 8px;
  background-color: ${Colors.greyishBrown};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);

  position: absolute;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  color: ${Colors.white};
`;

export const DropdownItem = styled.button<{ isDisabled: boolean; showActiveIcon: boolean }>`
  position: relative;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  border: none;
  background: none;
  color: ${Colors.white};

  display: flex;
  align-content: center;

  border-radius: 4px;
  padding: ${({ showActiveIcon }) => (showActiveIcon ? '10px 12px 10px 32px' : '8px 10px 8px 10px')};
  font-family: 'Cera', sans-serif;
  font-size: 18px;
  line-height: 24px;

  svg {
    width: 32px;
    margin-right: 8px;
    margin-top: -4px;
    margin-bottom: -4px;
  }

  &:hover {
    background: ${({ theme, isDisabled }) =>
      isDisabled
        ? undefined
        : linearGradient(theme.gradients.dropdown.option.direction, theme.gradients.dropdown.option.stops)};
  }
`;

export const DropdownLabel = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DropdownButtonText = styled(DropdownLabel)`
  font-family: Cera, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
`;

export const Dropdown = styled.div<{ isIconButton: boolean }>`
  min-width: 100px;
  width: 100%;
  position: relative;

  ${({ isIconButton }) =>
    isIconButton &&
    `
    text-align: right;
    min-width: 0;
    ${DropdownButton} {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      padding: 0;
      text-align: center;
      background-color: ${Colors.violet};
      border-color: ${Colors.violet};
      font-weight: bold;
      font-size: 16px;
      opacity: 1;
      &:hover {
        scale: 1.1;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;    
        width: 100%;
        height: 100%;
      }

      svg {
        transform: rotate(0deg);
        width: 32px;
        height: 32px;
        top: 2px;
        left: 2px;
      }
    }
    ${DropdownItems} {
      right: 0
    }
  `}
`;

export const HiddenText = styled.span`
  &:not(:focus):not(:active) {
    clip: rect(0px, 0px, 0px, 0px);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

export const ActiveIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: url(/static/images/icons/check-dropdown-32.svg) left 5px top 10px / 24px 24px no-repeat, none;
  width: 32px;
  height: 32px;
`;
