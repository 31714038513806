import { getAdheseAds } from 'adhese';
import { CLIENT } from '../../client/constants';
import { getCookie } from './cookies';

export const getAdheseConfig = async (videoId: string, viewMode: string): Promise<IAdheseConfig | null> => {
  if (!videoId) {
    return null;
  }
  const consentString = getCookie('euconsent-v2');

  const input = {
    videoId,
    consentString: consentString || '',
    account: 'talpa',
    cacheUrl: 'https://prebid.adnxs.com/pbc/v1/cache',
    slots: [
      'kijk.nl_web_pre-previd30',
      'kijk.nl_web_mid1-previd30',
      'kijk.nl_web_mid2-previd30',
      'kijk.nl_web_mid3-previd30',
      'kijk.nl_web_post-previd30',
      'kijk.nl_web_preboard-bumper',
      'kijk.nl_web_midboard-bumper',
      'kijk.nl_web_midboard2-bumper',
      'kijk.nl_web_postboard-bumper'
    ],
    deviceType: viewMode || CLIENT.DESKTOP
  };
  try {
    const adheseConfig = await getAdheseAds(null, input);
    return adheseConfig?.freewheel_config;
  } catch (error) {
    return null;
  }
};
