import styled from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';
import { PlayerSidebarStyle } from 'components/PlayerSidebar/PlayerSIdebar.style';
import { SkeletonVideoMetaDataStyle } from 'components/SkeletonElement/SkeletonElement.style';
import { PlayerContainerStyle, WideScreenDivStyle } from 'components/PlayerContainer/PlayerContainer.style';
import { TextLabelStyle } from '../../VideoItem/VideoItemDescription/VideoItemDescription.style';
import Colors from '../../../styles/colors';

export const PlayerWrapperStyle = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  overflow: hidden;

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    min-height: 270px;
  }

  & > div#player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const VideoHeadingStyle = styled.div<{ hasSidebar: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${Colors.transparent};

  @media (max-width: ${props => props.theme.breakpoints.lgMin}) {
    ${PlayerSidebarStyle} {
      display: none;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    @supports (aspect-ratio: 16 / 9) {
      ${PlayerWrapperStyle} {
        width: 100%;
      }

      ${({ hasSidebar, theme }) =>
        hasSidebar &&
        `
        ${PlayerWrapperStyle} {
          width: calc(65% - 16px);
          margin-left: 16px;
          @media (min-width: ${theme.breakpoints.lgGrid}) {
            width: calc(65% - 80px);
            margin-left: 80px;
          }
          @media (min-width: ${theme.breakpoints.xlMin}) {
            padding-left: calc((100% - 1760px) / 2);
            width: 65%;
            margin-left: 0;
          }
        }
        ${VideoMetaDataWrapperStyle}, ${SkeletonVideoMetaDataStyle} {
          max-width: 1760px;
          margin: auto;
          width: calc(100% - 32px);
          @media (min-width: ${theme.breakpoints.lgGrid}) {
            width: calc(100% - 160px);
          }
          @media (min-width: ${theme.breakpoints.xlMin}) {
            width: 100%;
          }
        }
       
        ${PlayerContainerStyle} {
          margin-right: 0;
          height: auto;
          width: 100%;
        }
        ${WideScreenDivStyle} {
          height: 100%;
          width: 100%;
          margin-right: 0;
          margin-left: auto;
          transform: none;
        }
      `};
    }
  }
`;

export const VideoHeadingInnerStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  position: fixed;
  width: 100%;
  margin: 0 auto;
  z-index: ${props => props.theme.zIndex.component.videoHeading.container};
  background: ${props =>
    linearGradient(
      props.theme.gradients.videoHeading.background.direction,
      props.theme.gradients.videoHeading.background.stops
    )};

  @media (max-height: ${props => props.theme.breakpoints.smMax}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) and (orientation: landscape) {
    position: relative;
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    position: relative;
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMid}) {
    margin: 0 auto;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    margin: auto;
  }
`;

export const VideoHeadingSeparatorStyle = styled.div`
  width: 100%;
  height: 2px;
  background: ${props =>
    linearGradient(props.theme.gradients.player.solid.direction, props.theme.gradients.player.solid.stops)};
`;

export const VideoMetaDataWrapperStyle = styled.div<{ headingWidth: number | null }>`
  align-self: center;
  position: relative;
  width: ${({ headingWidth }) => (headingWidth ? `${headingWidth}px` : '100%')};
  max-width: calc(100% - 32px);
  margin: auto;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    max-width: calc(100% - 48px);
  }

  padding-top: 56.25%;
  @media (max-height: ${props => props.theme.breakpoints.smMax}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) and (orientation: landscape) {
    padding-top: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    padding-top: 0;
  }
`;

export const VideoHeadingImageInnerStyle = styled(WideScreenDivStyle)`
  position: relative;
  margin: 0 auto;

  ${TextLabelStyle} {
    z-index: 1;
  }
`;

export const VideoHeadingErrorStyle = styled.div`
  position: relative;
  height: 350px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: 500px;
  }
`;
