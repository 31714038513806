export const USER_QUERY_PARAMETER = 'feature-user';

export const USER_STORAGE_KEY = 'feature.user';

export enum FEATURE_SLUG {
  ADBLOCK_DETECTION = 'adblock-detection',
  ADHESE_ADVERTISING = 'adhese-advertising',
  CASTING = 'casting',
  SMALL_BRAND_TILES = 'small-brand-tiles',
  POPULAR_MISSED_PAGE = 'popular-missed-page',
  HOME_FALLBACK = 'home-fallback',
  AUTOPLAY_GOOGLEBOT = 'autoplay-googlebot',
  CLIP_LINK_IN_HEADER = 'clip-link-in-header',
  GENRE_CATEGORIES = 'genre-categories',
  PLAYER_SIDEBAR = 'player-sidebar',
  VIDEO_PREVIEW = 'video-preview',
  CUSTOM_GRADIENT = 'custom-gradient',
  HOTJAR = 'hotjar',
  GEO_BANNER = 'geo-banner',
  CUSTOM_GEO_ERROR_MESSAGE = 'custom-geo-error-message',
  TIMED_HOME_CONTENT = 'timed-home-content',
  SEARCH_TOOLTIP = 'search-tooltip',
  PLAYER_ERROR_MESSAGES = 'player-error-messages',
  WATCH_FULL_EPISODE_WEB = 'watch-full-episode-web'
}

export enum FEATURE_VARIANT {
  ORIGINAL = 'original',
  NEW_DESIGN = 'new-design',
  NO_LINK = 'no-link'
}

export enum MATCH {
  EXACT = 'exact',
  SUBSTRING = 'substring',
  DIFFERENT = 'different',
  NOSUBSTRING = 'nosubstring'
}
