import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { trackClick } from 'lib/tealium';
import { OverviewModel } from 'models/index';
import CollectionQuery from 'queries/collection/collection.query';
import EditorialOverview from 'components/EditorialOverview';
import { SearchButtonPlaceholderStyle } from 'components/SearchButton/SearchButton.style';
import useFeature from 'hooks/useFeature';
import { FEATURE_SLUG, ICONS, TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from '../../constants';
import * as Styled from './GeoblockBanner.style';

const GEO_BANNER_TITLE = 'KIJK gebruiken buiten Nederland?';

const GeoblockBanner: React.FC<{ viewMode: string }> = ({ viewMode }) => {
  const router = useRouter();
  const [overview, setOverview] = useState<OverviewModel>();
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    router.events.on('routeChangeComplete', () => setIsBannerOpen(false));
    return () => {
      router.events.off('routeChangeComplete', () => setIsBannerOpen(false));
    };
  }, [router.events]);

  useEffect(() => {
    if (isBannerOpen && isExiting) {
      const timer = setTimeout(() => {
        setIsBannerOpen(false);
        setIsExiting(false);
      }, 350);
      return () => clearTimeout(timer);
    }
  }, [isBannerOpen, isExiting]);

  const { enabled, variables } = useFeature<{ slug: string; description: string }>(FEATURE_SLUG.GEO_BANNER);

  const { error } = useQuery<ICollectionQueryResult>(CollectionQuery, {
    variables: { slug: variables?.slug },
    skip: !enabled || !variables?.slug,
    onCompleted: ({ collection }) => {
      if (!collection) return;
      const geoBannerOverview = new OverviewModel({ slug: '', editorialTabs: [], items: [collection] });
      setOverview(geoBannerOverview);
      document.body.classList.add('geo-banner');
    }
  });

  const openBanner = () => {
    setIsBannerOpen(true);
    trackClick({
      label: TEALIUM_EVENT_LABEL.GEO_BANNER,
      category: TEALIUM_EVENT_CATEGORY.HEADER,
      name: TEALIUM_EVENT_NAME.HEADER_CLICK
    });
  };

  const closeBanner = () => {
    setIsExiting(true);
  };

  if (error || !overview) return null;

  return (
    <>
      <Styled.GeoblockBannerWrapper onClick={openBanner}>
        <Styled.GeoblockBannerText>{GEO_BANNER_TITLE}</Styled.GeoblockBannerText>
        <SVG src={ICONS.ARROW_SMALL} />
      </Styled.GeoblockBannerWrapper>
      {isBannerOpen && (
        <>
          <Styled.BannerWrapper isBannerOpen={isBannerOpen} isExiting={isExiting}>
            <Styled.Banner>
              <Styled.BannerCloseButton onClick={closeBanner}>
                <SearchButtonPlaceholderStyle>Sluiten</SearchButtonPlaceholderStyle>
              </Styled.BannerCloseButton>
              <Styled.BannerDescription>{variables?.description}</Styled.BannerDescription>
              <EditorialOverview editorialOverviewItems={overview.items} viewMode={viewMode} />
            </Styled.Banner>
          </Styled.BannerWrapper>
          <Styled.BannerBackdrop data-testid="banner-backdrop" onClick={closeBanner} isExiting={isExiting} />
        </>
      )}
    </>
  );
};

export default GeoblockBanner;
