import { gql } from '@apollo/client';

const PlayerOptionsQuery: any = gql`
  query playerOptions {
    playerOptions(config: WEB) {
      debug
      config
    }
  }
`;

export default PlayerOptionsQuery;
