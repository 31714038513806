import React, { useRef, FunctionComponent, useState, useEffect } from 'react';
import { sideScroll } from 'lib/helpers/helpers';
import {
  CarouselWrapperStyle,
  CarouselItemsStyle,
  PrevArrowStyle,
  NextArrowStyle,
  ArrowWrapperStyle
} from './Carousel.style';
import { ICONS } from '../../../constants/icons';

const ITEMS_TO_SCROLL = 10;
const DEFAULT_SCROLL_RANGE = 100;
const SCROLL_OFFSET = 5;

const Carousel: FunctionComponent<ICarouselProps> = ({ children, smallArrows }) => {
  const carouselContainer = useRef<HTMLDivElement>(null);
  const [arrowPosition, setArrowPosition] = useState<number | undefined>(0);
  const [{ hasPrevArrow, hasNextArrow }, setArrows] = React.useState({
    hasPrevArrow: false,
    hasNextArrow: false
  });
  const [rangeToScroll, setRangeToScroll] = useState<number>(DEFAULT_SCROLL_RANGE);

  useEffect(() => {
    if (children && carouselContainer.current) {
      window.addEventListener('resize', updateRangeToScroll);
      updateRangeToScroll();
      updateArrows();
      carouselContainer.current.onscroll = updateArrows;
      updateArrowPosition();
    }

    return () => window.removeEventListener('resize', updateRangeToScroll);
  }, [carouselContainer.current]);

  const updateArrowPosition = (): void => {
    let position: number | undefined;
    const firstChild = carouselContainer.current?.children[0];
    const firstChildHeight = firstChild?.querySelector<HTMLElement>('div[class^=ImageMediastyle]')?.offsetHeight;

    if (firstChild?.classList[0]?.startsWith('TwoRowVideoList') && firstChildHeight) {
      position = firstChildHeight + 55;
    } else if (firstChildHeight && firstChildHeight >= 50) {
      position = firstChildHeight / 2 - 19;
    }

    setArrowPosition(position);
  };

  /*
   * Set scroll range by specified amount of items (ITEMS_TO_SCROLL).
   * If total available screen width is less than specified scroll offset,
   * then scroll range will be set to half of that width.
   */
  const updateRangeToScroll = (): void => {
    const containerWidth = carouselContainer.current?.offsetWidth;
    const firstCarouselItem = carouselContainer.current?.children[0] as HTMLElement | null;
    if (firstCarouselItem && firstCarouselItem.offsetWidth && containerWidth) {
      if (containerWidth <= firstCarouselItem.offsetWidth * ITEMS_TO_SCROLL) {
        setRangeToScroll(containerWidth - firstCarouselItem.offsetWidth);
      } else {
        setRangeToScroll(firstCarouselItem.offsetWidth * ITEMS_TO_SCROLL);
      }
    }
    updateArrows();
  };

  const updateArrows = (): void => {
    if (!carouselContainer?.current) return;
    const showPrev = carouselContainer?.current?.scrollLeft > SCROLL_OFFSET;
    const showNext =
      carouselContainer?.current?.scrollLeft <
      carouselContainer?.current?.scrollWidth - carouselContainer.current?.offsetWidth - SCROLL_OFFSET;
    setArrows({ hasPrevArrow: showPrev, hasNextArrow: showNext });
  };

  const prevArrowClickHandler = (): void => {
    sideScroll(carouselContainer.current, -rangeToScroll);
  };

  const nextArrowClickHandler = (): void => {
    sideScroll(carouselContainer.current, rangeToScroll);
  };

  if (!children) return null;

  return (
    <CarouselWrapperStyle data-testid="carouselList">
      <CarouselItemsStyle
        data-testid="carouselListItems"
        ref={carouselContainer}
        hasPrevArrow={hasPrevArrow}
        hasNextArrow={hasNextArrow}
      >
        {children}
      </CarouselItemsStyle>
      {hasPrevArrow && (
        <PrevArrowStyle
          hasPrevArrow={hasPrevArrow}
          data-testid="prevArrowStyle"
          onClick={prevArrowClickHandler}
          smallArrows={smallArrows}
        >
          <ArrowWrapperStyle arrowPosition={arrowPosition} smallArrows={smallArrows}>
            {smallArrows ? (
              <img data-testid="leftArrow" src={ICONS.ARROW_SMALL} alt="left arrow" />
            ) : (
              <img data-testid="leftArrow" src={ICONS.ARROW} alt="left arrow" />
            )}
          </ArrowWrapperStyle>
        </PrevArrowStyle>
      )}
      {hasNextArrow && (
        <NextArrowStyle
          hasNextArrow={hasNextArrow}
          data-testid="nextArrowStyle"
          onClick={nextArrowClickHandler}
          smallArrows={smallArrows}
        >
          <ArrowWrapperStyle arrowPosition={arrowPosition} smallArrows={smallArrows}>
            {smallArrows ? (
              <img data-testid="rightArrow" src={ICONS.ARROW_SMALL} alt="right arrow" />
            ) : (
              <img data-testid="rightArrow" src={ICONS.ARROW} alt="right arrow" />
            )}
          </ArrowWrapperStyle>
        </NextArrowStyle>
      )}
    </CarouselWrapperStyle>
  );
};

export default Carousel;
