import { useQuery } from '@apollo/client';
import { ContinueWatchingQuery, ActiveVideo } from 'queries/continueWatching/continueWatchingQuery.query';
import { getPositionInPercentage } from 'lib/helpers/helpers';

const useVideoProgress = (guid: string): number => {
  const { data } = useQuery<{ continueWatching: { videosContinueWatching: IVideo[] } }>(ContinueWatchingQuery);
  const { data: ActiveVideoData } = useQuery<{ activeVideo: IVideo }>(ActiveVideo);
  const activeVideo = ActiveVideoData?.activeVideo?.guid !== '' ? ActiveVideoData?.activeVideo : null;
  let progress: number = 0;

  if (data?.continueWatching?.videosContinueWatching?.length) {
    const { videosContinueWatching } = data.continueWatching;
    const VideoItem = videosContinueWatching.find(video => video.guid === guid);
    if (VideoItem?.guid) {
      progress = VideoItem.completed ? 100 : getPositionInPercentage(VideoItem.position, VideoItem.duration);
    } else if (!VideoItem && activeVideo?.guid === guid) {
      progress = activeVideo?.completed ? 100 : getPositionInPercentage(activeVideo?.position, activeVideo?.duration);
    }
  }

  return +progress.toFixed(2);
};

export default useVideoProgress;
