import { FunctionComponent, ReactElement } from 'react';
import { trackClick } from 'lib/tealium';
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from '../../constants';
import Link from '../Link';
import { FooterLinkStyle, FooterDivStyle } from './Footer.style';

export const FooterLink: FunctionComponent<IFooterLink> = ({ href, path, target, children, onClick }): ReactElement => {
  const clickEventValues = {
    name: TEALIUM_EVENT_NAME.FOOTER_CLICK,
    category: TEALIUM_EVENT_CATEGORY.FOOTER,
    label: children
  };

  return (
    <FooterLinkStyle data-testid="footerLinks">
      {(href || path) && (
        <Link path={path} href={href} target={target || '_self'} clickEventValues={clickEventValues}>
          {children}
        </Link>
      )}
      {onClick && (
        <FooterDivStyle
          data-testid="footerCookieLink"
          onClick={() => {
            onClick();
            trackClick(clickEventValues);
          }}
        >
          {children}
        </FooterDivStyle>
      )}
    </FooterLinkStyle>
  );
};
