import { PLAYER_ERRORS, PLAYER_ERROR_TYPE } from '../../client/constants';

const errorCases = [
  { min: 100000, max: 100014, group: PLAYER_ERRORS.SETUP_ERRORS_MISCELLANEOUS },
  { min: 102000, max: 102621, group: PLAYER_ERRORS.PLAYLIST_PARSING },
  { min: 102630, max: 102700, group: PLAYER_ERRORS.EMPTY_PLAYLIST },
  { min: 101100, max: 104154, group: PLAYER_ERRORS.SETUP_ERRORS_LOADING_JAVASCRIPT_COMPONENTS },
  { min: 200001, max: 200001, group: PLAYER_ERRORS.PLAYER_ERROR_MISCELLANEOUS },
  { min: 202000, max: 202630, group: PLAYER_ERRORS.LOADING_NEW_PLAYLIST },
  { min: 203000, max: 203640, group: PLAYER_ERRORS.PLAYLIST_ITEM },
  { min: 204100, max: 204154, group: PLAYER_ERRORS.PLAYER_ERROR_LOADING_JAVASCRIPT_COMPONENTS },
  { min: 210000, max: 214000, group: PLAYER_ERRORS.MEDIA_PLAYBACK_FLASH },
  { min: 220001, max: 226599, group: PLAYER_ERRORS.MEDIA_PLAYBACK_HTML },
  { min: 230000, max: 239000, group: PLAYER_ERRORS.MEDIA_PLAYBACK_HLS },
  { min: 240000, max: 249000, group: PLAYER_ERRORS.MEDIA_PLAYBACK_SHAKA },
  { min: 302001, max: 302611, group: PLAYER_ERRORS.RELATED },
  { min: 303200, max: 303230, group: PLAYER_ERRORS.PLAY_ATTEMPT_FAIL },
  { min: 301121, max: 305107, group: PLAYER_ERRORS.WARNING_LOADING_JAVASCRIPT_COMPONENTS },
  { min: 306001, max: 306009, group: PLAYER_ERRORS.CAPTIONS },
  { min: 307000, max: 307012, group: PLAYER_ERRORS.VR },
  { min: 308000, max: 308640, group: PLAYER_ERRORS.LOCALIZATION },
  { min: 330000, max: 339000, group: PLAYER_ERRORS.WARNING_MEDIA_PLAYBACK },
  { min: 340000, max: 340000, group: PLAYER_ERRORS.MEDIA_PLAYBACK_SHAKA },
  { min: 350000, max: 350000, group: PLAYER_ERRORS.CASTING }
];

const getErrorGroup = (errorCode: number, type: string) => {
  const errorCase = errorCases.find(({ min, max }) => errorCode >= min && errorCode <= max);

  if (errorCase) return errorCase.group;
  if (type === PLAYER_ERROR_TYPE.ERROR) return PLAYER_ERRORS.UNKNOWN_ERROR;

  return PLAYER_ERRORS.UNKNOWN_WARNING;
};

export default getErrorGroup;
