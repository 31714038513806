import styled from 'styled-components';

export const TwoRowListColumnStyle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TwoRowListVideoStyle = styled.div`
  &:first-child {
    height: 265px;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      height: 295px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      height: 340px;
    }
  }
`;
