import React, { ReactElement } from 'react';
import { multiplyElement } from 'lib/helpers/array';
import { FormatAToZStyle } from 'components/FormatAToZ/FormatAToZ.style';
import { PlayerSidebarStyle } from 'components/PlayerSidebar/PlayerSIdebar.style';
import { getPlayerWidth } from 'lib/helpers';
import { useScreenDimensions } from 'lib/useScreenDimensions';
import {
  SkeletonElementStyle,
  SkeletonBrandItemStyle,
  SkeletonProgramItemStyle,
  SkeletonProgramItemTitleStyle,
  SkeletonProgramItemImageStyle,
  SkeletonVideoPlayerStyle,
  SkeletonVideoMetaDataStyle,
  SkeletonVideoMetaDataTitleStyle,
  SkeletonVideoItemStyle,
  SkeletonVideoItemWrapperStyle,
  SkeletonVideoItemTextStyle,
  SkeletonVideoItemTextWrapperStyle,
  SkeletonPromoHeadingStyle,
  SkeletonPromoHeadingInnerWrapperStyle,
  SkeletonPromoHeadingTitleStyle,
  SkeletonPromoHeadingDescriptionWrapperStyle,
  SkeletonPromoHeadingDescriptionStyle,
  SkeletonPromoHeadingDescriptionLastStyle,
  SkeletonPromoHeadingButtonWrapperStyle,
  SkeletonPromoHeadingWatchStyle,
  SkeletonPromoHeadingAddButtonStyle,
  SkeletonVideoItemFormatIndexStyle,
  SkeletonVideoMetaTextStyle,
  SkeletonVideoMetaIconStyle,
  SkeletonVideoMetaWrapIconsStyle,
  SkeletonHomeMissedContainerStyle,
  SkeletonGridWrapperStyle,
  SkeletonBrandPromoItemStyle,
  SkeletonGridItemImageStyle,
  SkeletonGridItemStyle,
  SkeletonTitleWrapperStyle,
  SkeletonAToZPickerStyle,
  SkeletonAToZSectionTitleStyle,
  SkeletonAToZFormatStyle,
  SkeletonMovieSectionTitle,
  SkeletonHomeMissedListWrapperStyle,
  SkeletonListItem,
  SkeletonListThumbnail,
  SkeletonVideoItemsWrapperStyle,
  SkeletonBrandsGridWrapperStyle,
  SkeletonSearchResultsWrapperStyle,
  SkeletonLandscapeCarouselTitleWrapper,
  SkeletonPortraitCarouselTitleWrapper
} from './SkeletonElement.style';
import {
  PlayerWrapperStyle,
  VideoHeadingInnerStyle,
  VideoHeadingSeparatorStyle,
  VideoHeadingStyle
} from '../VideoHeading/VideoHeadingLayout/VideoHeadingLayout.style';
import { PlayerLoader } from '../../components/PlayerContainer/PlayerContainer.style';
import {
  VideoMetaDataArrowStyle,
  VideoDescriptionBottomSeparatorStyle
} from '../VideoMetaData/VIdeoMetaDataLayout/VideoMetaDataLayout.style';
import { Grid, GridElement } from '../../styles/grid';
import Carousel from '../Lists/Carousel';
import {
  HeadingWithPromoInnerWrapperStyle,
  HeadingWithPromoListStyle,
  HeadingWithPromoWrapperStyle
} from '../HeadingWithPromoList/HeadingWithPromoList.style';
import { ContinueWatchingCarouselListStyle } from '../ContinueWatching/ContinueWatching.style';
import { FIXED_COUNT } from '../../constants';

export const SkeletonElement = (props: any): ReactElement => <SkeletonElementStyle {...props} />;

const SkeletonProgramItem = (): ReactElement => (
  <SkeletonProgramItemStyle data-testid="skeletonProgramItem">
    <SkeletonProgramItemImageStyle />
  </SkeletonProgramItemStyle>
);

const SkeletonGridItem = (): ReactElement => (
  <SkeletonGridItemStyle data-testid="skeletonProgramItem">
    <SkeletonGridItemImageStyle />
  </SkeletonGridItemStyle>
);

export const SkeletonPortraitItemCarousel = ({ noOfItems }: ISkeletonListProps): ReactElement => (
  <Carousel>
    {multiplyElement(
      (_, index) => (
        <SkeletonProgramItem key={index} />
      ),
      noOfItems
    )}
  </Carousel>
);

export const SkeletonPortraitItemGrid = ({ noOfItems = 6 }: { noOfItems?: number }): ReactElement => (
  <SkeletonGridWrapperStyle>
    {multiplyElement(
      (_, index) => (
        <SkeletonGridItem key={index} />
      ),
      noOfItems
    )}
  </SkeletonGridWrapperStyle>
);

export const SkeletonBrandsGrid = ({ noOfItems }: ISkeletonListProps): ReactElement => (
  <SkeletonBrandsGridWrapperStyle>
    <SkeletonPromoHeadingTitleStyle />
    <SkeletonGridWrapperStyle noOfItemsInRow={noOfItems}>
      {multiplyElement(
        (_, index) => (
          <SkeletonGridItem key={index} />
        ),
        noOfItems
      )}
    </SkeletonGridWrapperStyle>
  </SkeletonBrandsGridWrapperStyle>
);

export const SkeletonMovieSection = (): ReactElement => (
  <>
    {multiplyElement(
      (_, index) => (
        <React.Fragment key={index}>
          <SkeletonMovieSectionTitle />
          <SkeletonPortraitItemCarousel noOfItems={FIXED_COUNT.SKELETON_PROGRAM_NUMBER} />
        </React.Fragment>
      ),
      2
    )}
  </>
);

export const SkeletonSearchResults = (): ReactElement => (
  <SkeletonSearchResultsWrapperStyle>
    <SkeletonAToZPickerStyle />
    <Grid>
      <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
        <SkeletonProgramItemTitleStyle />
        <SkeletonPortraitCarouselTitleWrapper>
          <SkeletonPromoHeadingTitleStyle />
          <SkeletonPortraitItemCarousel noOfItems={FIXED_COUNT.SKELETON_SEARCH_PORTRAIT_ITEMS} />
        </SkeletonPortraitCarouselTitleWrapper>
        <SkeletonLandscapeCarouselTitleWrapper>
          <SkeletonPromoHeadingTitleStyle />
          <SkeletonLandscapeItemsCarousel noOfItems={FIXED_COUNT.SKELETON_SEARCH_LANDSCAPE_ITEMS} />
        </SkeletonLandscapeCarouselTitleWrapper>
      </GridElement>
    </Grid>
  </SkeletonSearchResultsWrapperStyle>
);

const SkeletonVideoMetaData = (): ReactElement => {
  const [maxWidth, setMaxWidth] = React.useState<null | number>(null);
  React.useEffect(() => {
    setMaxWidth(getPlayerWidth(window.innerWidth, window.innerHeight));
  }, []);
  return (
    <SkeletonVideoMetaDataStyle maxWidth={maxWidth}>
      <SkeletonVideoMetaDataTitleStyle>
        <SkeletonVideoMetaTextStyle width={250} height={20} />
        <SkeletonVideoMetaTextStyle width={200} height={12} />
        <SkeletonVideoMetaTextStyle width={120} height={12} />
      </SkeletonVideoMetaDataTitleStyle>
      <SkeletonVideoMetaWrapIconsStyle>
        <SkeletonVideoMetaIconStyle />
        <SkeletonVideoMetaIconStyle />
        <SkeletonVideoMetaIconStyle />
      </SkeletonVideoMetaWrapIconsStyle>
      <VideoDescriptionBottomSeparatorStyle data-testid="skeletonMetaExpandWrapper">
        <VideoMetaDataArrowStyle isSkeleton />
      </VideoDescriptionBottomSeparatorStyle>
    </SkeletonVideoMetaDataStyle>
  );
};

export const SkeletonPlayer: React.FC = () => (
  <SkeletonVideoPlayerStyle>
    <PlayerLoader />
  </SkeletonVideoPlayerStyle>
);

export const SkeletonSidebar: React.FC = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [amount, setAmount] = React.useState(0);
  const [width] = useScreenDimensions(10);

  React.useEffect(() => {
    const titleHeight = 70;
    const sidebarItemHeight = 105;
    setAmount(Math.max(Math.floor((ref.current?.clientHeight || 0 - titleHeight) / sidebarItemHeight), 0));
  }, [width, ref]);

  return (
    <PlayerSidebarStyle ref={ref}>
      <SkeletonProgramItemTitleStyle width="220px" height="20px" marginTop="20px" marginBottom="25px" />
      {amount
        ? multiplyElement(
            (_, index) => (
              <SkeletonListItem key={index}>
                <SkeletonListThumbnail />
                <SkeletonProgramItemTitleStyle width="40%" height="12px" marginTop="5px" />
                <SkeletonProgramItemTitleStyle width="40%" height="12px" marginTop="32px" />
                <SkeletonProgramItemTitleStyle width="25%" height="10px" marginTop="60px" />
              </SkeletonListItem>
            ),
            amount
          )
        : null}
    </PlayerSidebarStyle>
  );
};

export const SkeletonVideoHeading: React.FC<{ hasSidebar?: boolean; hideMetadata?: boolean }> = ({
  hasSidebar,
  hideMetadata
}) => (
  <VideoHeadingStyle hasSidebar={!!hasSidebar}>
    <VideoHeadingInnerStyle>
      <PlayerWrapperStyle>
        <SkeletonPlayer />
      </PlayerWrapperStyle>
      {hasSidebar && <SkeletonSidebar />}
    </VideoHeadingInnerStyle>
    <VideoHeadingSeparatorStyle />
    {!hideMetadata && <SkeletonVideoMetaData />}
  </VideoHeadingStyle>
);

export const SkeletonLandscapeItemCarousel = ({ noOfItems }: ISkeletonListProps): ReactElement => (
  <ContinueWatchingCarouselListStyle>
    <Carousel>
      {multiplyElement(
        (_, index) => (
          <SkeletonVideoItemWrapperStyle key={index} data-testid="skeletonVideoItemWrapper">
            <SkeletonVideoItemStyle data-testid="skeletonVideoItem" />
            <SkeletonVideoItemTextStyle width="80%" />
            <SkeletonVideoItemTextStyle width="60%" />
            <SkeletonVideoItemTextStyle width="40%" />
          </SkeletonVideoItemWrapperStyle>
        ),
        noOfItems
      )}
    </Carousel>
  </ContinueWatchingCarouselListStyle>
);

export const SkeletonLandscapeItemGrid = ({ noOfItems }: ISkeletonListProps): ReactElement => (
  <>
    {multiplyElement(
      (_, index) => (
        <SkeletonVideoItemWrapperStyle key={index} data-testid="skeletonVideoItemWrapper">
          <SkeletonVideoItemStyle data-testid="skeletonVideoItem" />
          <SkeletonVideoItemTextWrapperStyle>
            <SkeletonVideoItemTextStyle width="85%" height="12px" />
            <SkeletonVideoItemTextStyle width="65%" height="12px" />
            <SkeletonVideoItemTextStyle width="45%" height="12px" />
          </SkeletonVideoItemTextWrapperStyle>
        </SkeletonVideoItemWrapperStyle>
      ),
      noOfItems
    )}
  </>
);

export const SkeletonVideoItemFormatIndex = ({
  noOfItems,
  hasSeasonSelector
}: {
  noOfItems: number;
  hasSeasonSelector?: boolean;
}): ReactElement => (
  <SkeletonVideoItemFormatIndexStyle hasSeasonSelector={hasSeasonSelector}>
    {multiplyElement(
      (_, index) => (
        <SkeletonVideoItemWrapperStyle key={index} data-testid="skeletonVideoItemWrapper">
          <SkeletonVideoItemStyle data-testid="skeletonVideoItem" />
          <SkeletonVideoItemTextStyle width="90%" height="12px" />
          <SkeletonVideoItemTextStyle width="60%" height="12px" />
        </SkeletonVideoItemWrapperStyle>
      ),
      noOfItems
    )}
  </SkeletonVideoItemFormatIndexStyle>
);

export const SkeletonBrandList = ({ noOfItems }: ISkeletonListProps): ReactElement => (
  <Carousel>
    {multiplyElement(
      (_, index) => (
        <SkeletonBrandItemStyle key={index} data-testid="skeletonBrandItem" />
      ),
      noOfItems
    )}
  </Carousel>
);

export const SkeletonPromoHeading = (): ReactElement => (
  <SkeletonPromoHeadingStyle>
    <SkeletonPromoHeadingInnerWrapperStyle>
      <SkeletonPromoHeadingTitleStyle />
      <SkeletonPromoHeadingDescriptionWrapperStyle>
        <SkeletonPromoHeadingDescriptionStyle />
        <SkeletonPromoHeadingDescriptionStyle />
        <SkeletonPromoHeadingDescriptionLastStyle />
      </SkeletonPromoHeadingDescriptionWrapperStyle>
      <SkeletonPromoHeadingButtonWrapperStyle>
        <SkeletonPromoHeadingWatchStyle />
        <SkeletonPromoHeadingAddButtonStyle />
      </SkeletonPromoHeadingButtonWrapperStyle>
    </SkeletonPromoHeadingInnerWrapperStyle>
  </SkeletonPromoHeadingStyle>
);

export const SkeletonHomeMissed: React.FC = (): ReactElement => (
  <SkeletonHomeMissedContainerStyle>
    <Grid>
      <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
        <SkeletonHomeMissedListWrapperStyle>
          {multiplyElement(
            (_, index) => (
              <SkeletonVideoItemWrapperStyle key={index} data-testid="skeletonVideoItemWrapper">
                <SkeletonVideoItemStyle data-testid="skeletonVideoItem" />
                <SkeletonVideoItemTextStyle width="80%" />
                <SkeletonVideoItemTextStyle width="60%" />
                <SkeletonVideoItemTextStyle width="40%" />
              </SkeletonVideoItemWrapperStyle>
            ),
            8
          )}
        </SkeletonHomeMissedListWrapperStyle>
      </GridElement>
    </Grid>
  </SkeletonHomeMissedContainerStyle>
);
export const SkeletonHeadingWithPromoList = ({ isBrandPage }: { isBrandPage?: boolean }): ReactElement => (
  <HeadingWithPromoListStyle isBrandPage={isBrandPage}>
    <Grid>
      <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
        <HeadingWithPromoInnerWrapperStyle>
          <HeadingWithPromoWrapperStyle>
            <SkeletonBrandPromoItemStyle />
            <SkeletonBrandPromoItemStyle />
            <SkeletonBrandPromoItemStyle />
          </HeadingWithPromoWrapperStyle>
        </HeadingWithPromoInnerWrapperStyle>
      </GridElement>
    </Grid>
  </HeadingWithPromoListStyle>
);

export const SkeletonMissedSection = ({ noOfItems }: ISkeletonListProps): ReactElement => (
  <>
    <SkeletonTitleWrapperStyle>
      <SkeletonProgramItemTitleStyle width="15%" height="25px" />
    </SkeletonTitleWrapperStyle>
    <SkeletonLandscapeItemGrid noOfItems={noOfItems} />
  </>
);

export const SkeletonAToZFormats = (): ReactElement => (
  <FormatAToZStyle>
    <SkeletonAToZPickerStyle />
    <Grid>
      <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
        <SkeletonAToZSectionTitleStyle />
        <Carousel>
          {multiplyElement(
            (_, index) => (
              <SkeletonAToZFormatStyle key={index}>
                <SkeletonProgramItemImageStyle />
              </SkeletonAToZFormatStyle>
            ),
            FIXED_COUNT.SKELETON_PROGRAM_NUMBER
          )}
        </Carousel>
      </GridElement>
    </Grid>
  </FormatAToZStyle>
);

export const SkeletonVideoItemsCarouselWithTitle = (): ReactElement => (
  <SkeletonVideoItemsWrapperStyle>
    <SkeletonPromoHeadingTitleStyle />
    <SkeletonLandscapeItemCarousel noOfItems={FIXED_COUNT.SKELETON_PROGRAM_NUMBER} />
  </SkeletonVideoItemsWrapperStyle>
);

const SkeletonLandscapeItemsCarousel = ({ noOfItems }: ISkeletonListProps): ReactElement => (
  <Carousel>
    {multiplyElement(
      (_, index) => (
        <SkeletonVideoItemWrapperStyle key={index}>
          <SkeletonVideoItemStyle data-testid="skeletonVideoItem" />
          <SkeletonVideoItemTextStyle width="80%" />
          <SkeletonVideoItemTextStyle width="60%" />
          <SkeletonVideoItemTextStyle width="40%" />
        </SkeletonVideoItemWrapperStyle>
      ),
      noOfItems
    )}
  </Carousel>
);
