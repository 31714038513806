import { FunctionComponent } from 'react';
import OverviewItemModel from 'src/client/models/overviewItem.model';
import { IEditorialProgramsProps } from 'typings/IEditorialProgramsProps';
import SwimlaneTitle from 'components/SwimlaneTitle/SwimlaneTitle';
import { Grid, GridElement } from '../../styles/grid';
import ProgramList from '../Home/ProgramListSection/ProgramList';
import {
  BrandedEditorialProgramsStyle,
  HeroImageWrapperStyle,
  BrandedEditorialProgramsContentWrapperStyle,
  BrandedEditorialProgramsTitleLogoWrapperStyle,
  LogoWrapperStyle,
  TitleWrapperStyle
} from './EditoralPrograms.style';
import ImageMedia from '../ImageMedia';
import { CLIENT, IMAGE_MEDIA_TYPE } from '../../constants';

const EditorialPrograms: FunctionComponent<IEditorialProgramsProps & OverviewItemModel> = ({
  title,
  programs,
  backgroundColor,
  heroImageUrl,
  logoImageUrl,
  type,
  viewMode,
  isBranded,
  showMoreUrl
}): JSX.Element | null => {
  if (!programs.length) return null;

  if (isBranded) {
    return (
      <BrandedEditorialProgramsStyle data-testid={`editorial-${type}`} backgroundColor={backgroundColor}>
        {viewMode !== CLIENT.MOBILE && heroImageUrl ? (
          <HeroImageWrapperStyle data-testid="heroImage">
            <img src={heroImageUrl} alt={title} />
          </HeroImageWrapperStyle>
        ) : null}
        <BrandedEditorialProgramsContentWrapperStyle>
          <BrandedEditorialProgramsTitleLogoWrapperStyle>
            <TitleWrapperStyle>
              <SwimlaneTitle showMoreUrl={showMoreUrl} title={title} />
            </TitleWrapperStyle>
            {logoImageUrl ? (
              <LogoWrapperStyle data-testid="logoImage">
                <ImageMedia
                  publicId={logoImageUrl}
                  imageMedia={{ type: IMAGE_MEDIA_TYPE.REMOTE }}
                  transformations={{
                    width: '60',
                    height: '60',
                    fetch_format: 'auto'
                  }}
                />
              </LogoWrapperStyle>
            ) : null}
          </BrandedEditorialProgramsTitleLogoWrapperStyle>
          <ProgramList programs={programs || []} sectionType="editorial" />
        </BrandedEditorialProgramsContentWrapperStyle>
      </BrandedEditorialProgramsStyle>
    );
  }

  return (
    <>
      <Grid>
        <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
          <SwimlaneTitle showMoreUrl={showMoreUrl} title={title} />
        </GridElement>
      </Grid>
      <ProgramList programs={programs || []} sectionType="editorial" />
    </>
  );
};

export default EditorialPrograms;
