import styled from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';
import { Heading4 } from 'components/Text/Text.style';
import { Grid } from '../../styles/grid';
import Colors from '../../styles/colors';

export const FooterStyle = styled.div`
  margin-bottom: 53px;
  padding-top: 34px;
  background: ${Colors.black};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-bottom: 0;
    padding-top: 42px;
  }

  ${Grid} {
    min-height: inherit;
  }
`;

export const FooterHeadingStyle = styled.div`
  margin-bottom: 8px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-bottom: 16px;
  }
`;

export const FooterSpacingStyle = styled.div`
  height: 22px;
`;

export const FooterLinkStyle = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: ${Colors.brownGrey};

  a {
    transition: color ${props => props.theme.transitions.easeIn};
  }
  @media (hover: hover) {
    a:hover {
      color: ${Colors.white};
    }
  }
`;

export const FooterDivStyle = styled.div`
  display: inline-block;
  cursor: pointer;
  transition: color ${props => props.theme.transitions.easeIn};
  @media (hover: hover) {
    :hover {
      color: ${Colors.white};
    }
  }
`;

export const FooterAppStoreWrapperStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FooterAppStyle = styled.div`
  height: 38px;
  margin-right: 9px;
  margin-bottom: 5px;
`;

export const FooterAppStoreStyle = styled(FooterAppStyle)`
  width: 118px;
`;

export const FooterPlayStoreStyle = styled(FooterAppStyle)`
  width: 137px;
`;

export const FooterBannerStyle = styled.div`
  margin-top: 20px;
  background-image: ${props =>
    linearGradient(props.theme.gradients.footer.small.direction, props.theme.gradients.footer.small.stops)};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 72px;
    background-image: ${props =>
      linearGradient(props.theme.gradients.footer.medium.direction, props.theme.gradients.footer.medium.stops)};
  }
  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    height: 88px;
    margin-top: 86px;
    background-image: ${props =>
      linearGradient(props.theme.gradients.footer.large.direction, props.theme.gradients.footer.large.stops)};
  }
`;

export const FooterBannerLeftStyle = styled.div`
  display: flex;
  align-items: center;
  height: 66px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: 88px;
  }
`;

export const FooterTextStyle = styled.span`
  ${Heading4} {
    font-weight: 400;
    color: ${Colors.brownGrey};
  }
`;

export const FooterBtnStyle = styled.div`
  width: 139px;
  height: 48px;
  margin-top: 20px;
  line-height: 48px;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border-radius: 4px;
  background-color: ${Colors.white};
`;

export const FooterMidRowStyle = styled.div`
  margin-top: 30px;
`;

export const FooterCopyStyle = styled.span`
  margin-left: 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${Colors.paleLavender};

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    font-size: 18px;
  }
`;
