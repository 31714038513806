export class ComponentDidCatchError extends Error {
  public __proto__: any;
  public errorData: IErrorData;

  constructor(message: string, errorData: IErrorData) {
    super(message);

    const actualProto = new.target.prototype;

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      // eslint-disable-next-line no-proto
      this.__proto__ = actualProto;
    }

    this.name = 'ComponentDidCatchError';
    this.setErrors(errorData);
  }

  setErrors(errorData: IErrorData): void {
    this.errorData = errorData;
  }

  getErrors(): IErrorData {
    return this.errorData;
  }

  printErrors(): string {
    return JSON.stringify(this.errorData, null, 4);
  }
}
