import React, { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import { format, utcToZonedTime } from 'date-fns-tz';
import SVG from 'react-inlinesvg';
import getConfig from 'next/config';
import Link from 'components/Link';
import { MissedListWrapperStyle } from 'components/Missed/Missed.style';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import CommonFallback from 'components/CommonFallback';
import VideoList from 'components/VideoList';
import { Heading2 } from 'components/Text/Text.style';
import ProgramsByDateQuery from 'queries/programs/programsByDate.query';
import { SkeletonHomeMissed } from 'components/SkeletonElement/SkeletonElement';
import ErrorMessage from 'components/ErrorMessage';
import { useScreenDimensions } from 'lib/useScreenDimensions';
import EpisodeModel from 'models/episode.program.model';
import { ClickableTitleStyle, HomeMissedSectionWrapperStyle } from '../Home.style';
import { WatchMoreButtonStyle } from './MissedSection.style';
import { Grid, GridElement } from '../../../styles/grid';
import {
  FALLBACK_UI_LABELS,
  ICONS,
  PROGRAM,
  ROUTES,
  FIXED_COUNT,
  SWIMLANE_TITLE,
  TEALIUM_EVENT_CATEGORY,
  TEALIUM_EVENT_NAME,
  PROGRAM_SORT_KEY
} from '../../../constants';

const { publicRuntimeConfig } = getConfig();

const todayDate = format(utcToZonedTime(new Date(), publicRuntimeConfig.app.timezone), 'yyyy-MM-dd');
const numberOfMissedItems = 8;

const MissedSection: FC = () => {
  const [subsetOfPrograms, setSubsetOfPrograms] = useState<EpisodeModel[]>([]);

  const [screenWidth] = useScreenDimensions();
  const optionalItem = screenWidth >= 641 && screenWidth <= 1024 ? 1 : 0;

  const { error, loading } = useQuery<{ programsByDate: IProgramsByDateGraphql[] }>(ProgramsByDateQuery, {
    variables: {
      date: todayDate,
      numOfDays: FIXED_COUNT.MISSED_NUMBER_OF_DAYS,
      programTypes: PROGRAM.EPISODE,
      availability: true,
      sort: PROGRAM_SORT_KEY.AIREDDATETIME
    },
    onCompleted: data => {
      const items = data.programsByDate.reduce((acc, day) => [...acc, ...day.items], [] as IProgramGraphql[]);

      const missed = items
        .map((item, index) => new EpisodeModel(item, { position: index + 1, collectionTitle: SWIMLANE_TITLE.GEMIST }))
        .filter(item => item.isValid && !item.isLive)
        .slice(0, numberOfMissedItems + optionalItem);

      setSubsetOfPrograms(missed);
    }
  });

  if (loading) {
    return <SkeletonHomeMissed />;
  }

  if (error) {
    return <ErrorMessage />;
  }

  if (!subsetOfPrograms.length) {
    return null;
  }

  return (
    <HomeMissedSectionWrapperStyle data-testid="missedSection">
      <Grid>
        <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
          <ClickableTitleStyle data-testid="onDemandLinkStyleMissed">
            <Heading2 data-testid="missedTitle">
              <Link
                path={ROUTES.FORMAT_MISSED_INDEX}
                clickEventValues={{
                  name: TEALIUM_EVENT_NAME.CONTENT_LISTING_MORE,
                  category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
                  label: SWIMLANE_TITLE.GEMIST
                }}
              >
                {SWIMLANE_TITLE.GEMIST}
                <SVG src={ICONS.ARROW_SMALL_30} id="missedTitleArrow" />
              </Link>
            </Heading2>
          </ClickableTitleStyle>
        </GridElement>
      </Grid>
      <Grid>
        <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
          <MissedListWrapperStyle data-testid="missedListWrapperStyle">
            <ErrorBoundary onError={(_: any) => <CommonFallback message={FALLBACK_UI_LABELS.MISSED} />}>
              <VideoList smLandscape programList={subsetOfPrograms} />
            </ErrorBoundary>
          </MissedListWrapperStyle>
        </GridElement>
        <WatchMoreButtonStyle data-testid="btnWatchMore">
          <Link path={ROUTES.FORMAT_MISSED_INDEX}>Toon meer</Link>
        </WatchMoreButtonStyle>
      </Grid>
    </HomeMissedSectionWrapperStyle>
  );
};

export default MissedSection;
