export default function loadHotjar(config: { startTime: number; endTime: number; scriptUrl: string } | null): boolean {
  try {
    if (!config) return false;
    const { startTime, endTime, scriptUrl } = config;

    const hour = new Date().getHours();
    if (hour >= startTime && hour < endTime) {
      const script = document.createElement('script');
      script.src = scriptUrl;
      document.getElementsByTagName('head')[0].appendChild(script);
      return true;
    }
  } catch (ignore) {}

  return false;
}
