import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { debounce } from 'throttle-debounce';
import SearchQuery, {
  SearchQueryClipsTab,
  SearchQueryEpisodesTab,
  SearchQueryFilmsTab,
  SearchQueryProgrammasTab
} from 'queries/search/searchQuery.query';
import SeriesModel from 'models/series.model';
import MovieModel from 'models/movie.program.model';
import EpisodeModel from 'models/episode.program.model';
import ClipModel from 'models/clip.program.model';
import VideoModel from 'models/video.model';
import ErrorBoundary from 'components/ErrorBoundary';
import Button from 'components/Button';
import PortraitItem from 'components/PortraitItem';
import PortraitItemFallback from 'components/PortraitItem/PortraitItemFallback';
import EditorialOverview from 'components/EditorialOverview';
import SearchResultList from 'components/SearchModal/SearchResultList';
import { Tabs, Tab } from 'components/Tabs';
import VideoList from 'components/VideoList';
import CommonFallback from 'components/CommonFallback';
import ErrorMessage from 'components/ErrorMessage';
import {
  SkeletonHomeMissed,
  SkeletonSearchResults,
  SkeletonPortraitItemGrid
} from 'components/SkeletonElement/SkeletonElement';
import { ISearchTab } from 'typings/ISearchTab';
import ShowMoreButton from 'components/ShowMoreButton/ShowMoreButton';
import { trackClick } from 'lib/tealium';
import { applyModel, getProgramModel } from 'lib/helpers/searchResults';
import {
  FALLBACK_UI_LABELS,
  TAB_NAMES,
  TEALIUM_EVENT_CATEGORY,
  TEALIUM_EVENT_NAME,
  FETCH_POLICY,
  FIXED_COUNT,
  TEALIUM_EVENT_LABEL,
  tabProgramTypes,
  tabSortValues,
  BREAKPOINTS
} from '../../../constants';
import TileList from '../../Lists/TileList';
import { Grid, GridElement } from '../../../styles/grid';
import * as Styled from './SearchResults.styled';

const RESIZE_DEBOUNCE_TIME = 500;

const SearchTab: FunctionComponent<{
  limits: {
    portraitItems: number;
    landscapeItems: number;
  };
  searchQuery: string;
  tab: ISearchTab;
}> = ({ limits, searchQuery, tab }): JSX.Element | null => {
  const [items, setItems] = useState<MovieModel[] | SeriesModel[] | ClipModel[] | EpisodeModel[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const { tabName, totalResults, tabQuery } = tab;

  const activeTabItemLimit =
    tabName === TAB_NAMES.AFLEVERINGEN || tabName === TAB_NAMES.CLIPS ? limits.landscapeItems : limits.portraitItems;

  const { refetch } = useQuery<{ programs: { items: IProgramGraphql[]; totalResults: number } }>(tabQuery, {
    variables: {
      searchParam: searchQuery,
      limit: activeTabItemLimit,
      skip: 0,
      sort: tabSortValues[tabName]
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const { programs } = data;
      const Model = getProgramModel(tabName);

      if (!Model) return;

      const newItems = applyModel(programs.items, Model, tabName);
      setItems([...items, ...newItems] as VideoModel[]);
    }
  });

  useEffect(() => {
    const refetchDataOnResize = debounce(RESIZE_DEBOUNCE_TIME, () => {
      setItems([]);
      refetch({ skip: 0 });
    });

    window.addEventListener('resize', refetchDataOnResize);
    return () => {
      window.removeEventListener('resize', refetchDataOnResize);
    };
  }, []);

  const handleLoadMoreClick = () => {
    setIsLoadingMore(true);
    trackClick({
      category: TEALIUM_EVENT_CATEGORY.SEARCH,
      name: TEALIUM_EVENT_NAME.SEARCH_LOAD_MORE,
      label: TEALIUM_EVENT_LABEL.LOAD_MORE,
      defaultValues: {
        media_videotype: tabProgramTypes[tabName]
      }
    });
    refetch({ skip: items.length }).finally(() => setIsLoadingMore(false));
  };

  const shouldRenderLandscapeSkeleton = tabName === TAB_NAMES.CLIPS || tabName === TAB_NAMES.AFLEVERINGEN;

  if (!items.length) {
    return shouldRenderLandscapeSkeleton ? (
      <SkeletonHomeMissed />
    ) : (
      <SkeletonPortraitItemGrid noOfItems={activeTabItemLimit} />
    );
  }

  return (
    <Styled.SearchTabWrapper>
      {tabName === TAB_NAMES.AFLEVERINGEN || tabName === TAB_NAMES.CLIPS ? (
        <Styled.SearchVideoWrapper>
          <VideoList programList={items as VideoModel[]} smLandscape />
        </Styled.SearchVideoWrapper>
      ) : (
        <TileList>
          {(items as (SeriesModel | MovieModel)[]).map(item => (
            <React.Fragment key={item.guid}>
              <ErrorBoundary onError={_ => <PortraitItemFallback />}>
                <PortraitItem program={item} />
              </ErrorBoundary>
            </React.Fragment>
          ))}
        </TileList>
      )}
      {isLoadingMore &&
        (shouldRenderLandscapeSkeleton ? (
          <SkeletonHomeMissed />
        ) : (
          <SkeletonPortraitItemGrid noOfItems={activeTabItemLimit} />
        ))}
      <ShowMoreButton
        isDisabled={isLoadingMore}
        handleClick={handleLoadMoreClick}
        type={tabName}
        isVisible={totalResults > items.length}
        infiniteScroll
      />
    </Styled.SearchTabWrapper>
  );
};

const SearchResults: FunctionComponent<ISearchResultsProps> = ({
  searchQuery,
  resetSearch,
  overview,
  viewMode,
  containerWidth
}) => {
  const [results, setResults] = useState<{ totalResults: number; tabs: ISearchTab[]; searchQuery: string }>();

  const limits = useMemo(() => {
    const getAmount = (containerWidth: number): number => {
      const columnGap = window.innerWidth >= BREAKPOINTS.lgMin ? 24 : 16;
      const minWidth = window.innerWidth > BREAKPOINTS.smMax ? 162 : 115;

      return (
        Array.from({ length: 8 }, (_, i) => i + FIXED_COUNT.MIN_PORTRAIT_ITEMS_PER_ROW)
          .reverse()
          .find(item => item * minWidth + (item - 1) * columnGap <= containerWidth) ||
        FIXED_COUNT.MIN_PORTRAIT_ITEMS_PER_ROW
      );
    };

    const portraitItems = getAmount(containerWidth) * FIXED_COUNT.ROWS_TO_FETCH;
    const landscapeItems = (window.innerWidth >= BREAKPOINTS.lgMin ? 4 : 3) * FIXED_COUNT.ROWS_TO_FETCH;
    return { portraitItems, landscapeItems };
  }, [containerWidth]);

  const { error, loading } = useQuery<{
    series: { items: ISeriesGraphql[]; totalResults: number };
    episodes: { items: IProgramGraphql[]; totalResults: number };
    movies: { items: IProgramGraphql[]; totalResults: number };
    clips: { items: IProgramGraphql[]; totalResults: number };
  }>(SearchQuery, {
    variables: {
      searchParam: searchQuery,
      portraitLimit: limits.portraitItems,
      landscapeLimit: limits.landscapeItems
    },
    ssr: false,
    skip: searchQuery === results?.searchQuery,
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    onCompleted: data => {
      const { episodes, movies, series, clips } = data;
      const seriesItems = applyModel(series?.items, SeriesModel, TAB_NAMES.PROGRAMMAS) as SeriesModel[];
      const episodeItems = applyModel(episodes?.items, EpisodeModel, TAB_NAMES.AFLEVERINGEN) as EpisodeModel[];
      const movieItems = applyModel(movies?.items, MovieModel, TAB_NAMES.FILMS) as MovieModel[];
      const clipItems = applyModel(clips?.items, ClipModel, TAB_NAMES.CLIPS) as ClipModel[];

      setResults({
        searchQuery,
        totalResults: series.totalResults + clips.totalResults + movies.totalResults + episodes.totalResults,
        tabs: [
          {
            items: seriesItems || [],
            tabName: TAB_NAMES.PROGRAMMAS,
            totalResults: series.totalResults,
            tabQuery: SearchQueryProgrammasTab
          },
          {
            items: episodeItems || [],
            tabName: TAB_NAMES.AFLEVERINGEN,
            totalResults: episodes.totalResults,
            tabQuery: SearchQueryEpisodesTab
          },
          {
            items: clipItems || [],
            tabName: TAB_NAMES.CLIPS,
            totalResults: clips.totalResults,
            tabQuery: SearchQueryClipsTab
          },
          {
            items: movieItems || [],
            tabName: TAB_NAMES.FILMS,
            totalResults: movies.totalResults,
            tabQuery: SearchQueryFilmsTab
          }
        ]
      });
    }
  });

  if (loading) return <SkeletonSearchResults />;

  if (error) return <ErrorMessage />;

  return results && results.totalResults > 0 ? (
    <Styled.SearchModalResultsWrapper>
      <Tabs type="search">
        <Tab name={TAB_NAMES.ALLE} title={TAB_NAMES.ALLE}>
          <Grid>
            <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
              <ErrorBoundary onError={(_: any) => <CommonFallback message={FALLBACK_UI_LABELS.FORMAT_VIDEO_LIST} />}>
                <div data-testid="searchResults">
                  <Grid>
                    <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
                      <Styled.SearchModalResultCount data-testid="totalResults">
                        {results.totalResults > 100 ? '100+' : results.totalResults}
                        {results.totalResults === 1 ? ' resultaat' : ' resultaten'}
                      </Styled.SearchModalResultCount>
                      <SearchResultList tabs={results.tabs} />
                    </GridElement>
                  </Grid>
                </div>
              </ErrorBoundary>
            </GridElement>
          </Grid>
        </Tab>
        {results.tabs.map(result => {
          if (!result.items.length) return null;
          return (
            <Tab name={result.tabName} title={result.tabName} key={result.tabName}>
              <Grid>
                <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
                  <Styled.SearchModalResultCount data-testid="totalResults">
                    {result.totalResults > 100 ? '100+' : result.totalResults}
                    {result.items.length === 1 ? ' resultaat' : ' resultaten'}
                  </Styled.SearchModalResultCount>
                  <ErrorBoundary onError={_ => <PortraitItemFallback />}>
                    <SearchTab searchQuery={searchQuery} limits={limits} tab={result} />
                  </ErrorBoundary>
                </GridElement>
              </Grid>
            </Tab>
          );
        })}
      </Tabs>
    </Styled.SearchModalResultsWrapper>
  ) : (
    <>
      <Styled.SearchModalNoResultsText data-testid="noResults">
        Helaas hebben we geen resultaten gevonden.
      </Styled.SearchModalNoResultsText>
      <Styled.SearchModalResetButton>
        <Button onClick={() => resetSearch(true)}>Nieuwe zoekopdracht</Button>
      </Styled.SearchModalResetButton>
      <div>
        <ErrorBoundary onError={(_: any) => <CommonFallback message={FALLBACK_UI_LABELS.TRENDING_PROGRAMS} />}>
          {overview && <EditorialOverview editorialOverviewItems={overview.items} viewMode={viewMode} />}
        </ErrorBoundary>
      </div>
    </>
  );
};

export default SearchResults;
