import React, { FunctionComponent } from 'react';
import PortraitItem from 'components/PortraitItem';
import PortraitItemFallback from 'components/PortraitItem/PortraitItemFallback';
import ErrorBoundary from 'components/ErrorBoundary';
import Carousel from 'components/Lists/Carousel';
import VideoList from 'components/VideoList';
import SeriesModel from 'models/series.model';
import MovieModel from 'models/movie.program.model';
import VideoModel from 'models/video.model';
import { ISearchResultListProps } from 'typings/ISearchResultListProps';
import { ISearchTab } from 'typings/ISearchTab';
import SwimlaneTitle from 'components/SwimlaneTitle/SwimlaneTitle';
import { trackClick } from 'lib/tealium';
import * as Styled from '../SearchResults/SearchResults.styled';
import { Grid, GridElement } from '../../../styles/grid';
import { TAB_CHANGE_EVENT, TAB_NAMES, TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from '../../../constants';

const ProgramList = ({ programs }: { programs: SeriesModel[] | MovieModel[] }) => (
  <>
    {(programs as (SeriesModel | MovieModel)[]).map(program => (
      <React.Fragment key={program.guid}>
        <ErrorBoundary onError={(_: any) => <PortraitItemFallback />}>
          <PortraitItem key={program.guid} program={program} />
        </ErrorBoundary>
      </React.Fragment>
    ))}
  </>
);

const SearchRow = ({ tab }: { tab: ISearchTab }) => {
  const { items, tabName } = tab;

  const sendEventAndChangeTab = () => {
    trackClick({
      name: TEALIUM_EVENT_NAME.CONTENT_LISTING_MORE,
      category: TEALIUM_EVENT_CATEGORY.SEARCH,
      label: tabName
    });
    window.dispatchEvent(new CustomEvent(TAB_CHANGE_EVENT, { detail: { type: 'search', tabName } }));
  };

  const slicedItems = React.useMemo(() => items.slice(0, 15), [items]);

  if (!slicedItems || !slicedItems.length) return null;
  return (
    <Styled.SearchModalCarousel>
      <SwimlaneTitle title={tabName} onClickHandler={sendEventAndChangeTab} />
      <Styled.SearchResultsVideo>
        <Carousel>
          {tabName === TAB_NAMES.AFLEVERINGEN || tabName === TAB_NAMES.CLIPS ? (
            <VideoList programList={slicedItems as VideoModel[]} />
          ) : (
            <ProgramList programs={slicedItems} />
          )}
        </Carousel>
      </Styled.SearchResultsVideo>
    </Styled.SearchModalCarousel>
  );
};

const SearchResultList: FunctionComponent<ISearchResultListProps> = ({ tabs }) => (
  <Grid>
    <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
      {tabs.map(tab => (
        <SearchRow tab={tab} key={tab.tabName} />
      ))}
    </GridElement>
  </Grid>
);

export default SearchResultList;
