import React from 'react';
import SVG from 'react-inlinesvg';
import { ICONS } from '../../constants';
import { RemoveItemIconCloseStyle } from './RemoveItemDialog.style';

const RemoveItemCloseButton = ({ toggleDialog }: { toggleDialog: () => void }) => (
  <RemoveItemIconCloseStyle data-testid="removeItemToggleDialogButton" onClick={toggleDialog}>
    <SVG src={ICONS.CLOSE_CIRCULAR} />
  </RemoveItemIconCloseStyle>
);

export default RemoveItemCloseButton;
