import React, { useEffect, useState } from 'react';
import * as Styled from './Tooltip.styled';
import Button from '../Button';

const Tooltip: React.FC<ITooltipProps> = ({
  title,
  description,
  buttonText,
  showSocialButtons,
  rightStyle,
  onClick,
  onButtonConfirm,
  disableOnHover,
  isOpen = false,
  children
}) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);
  useEffect(() => {
    setDisplayTooltip(isOpen);
  }, [isOpen]);
  const handleButtonConfirm = () => {
    if (onButtonConfirm) onButtonConfirm();
    setDisplayTooltip(false);
  };

  return (
    <Styled.Tooltip onMouseLeave={() => !disableOnHover && setDisplayTooltip(false)} data-testid="tooltipComponent">
      {displayTooltip && (
        <Styled.TooltipBottom showSocialButtons={showSocialButtons} rightStyle={rightStyle}>
          <Styled.TooltipMessage>
            {title && <Styled.TooltipTitle>{title}</Styled.TooltipTitle>}
            <span>{description}</span>
            <Button onClick={handleButtonConfirm} isActionButton>
              {buttonText}
            </Button>
          </Styled.TooltipMessage>
        </Styled.TooltipBottom>
      )}
      <Styled.TooltipTrigger
        onMouseOver={() => !disableOnHover && setDisplayTooltip(true)}
        onClick={onClick}
        data-testid="tooltipComponentTrigger"
        displayTooltip={displayTooltip}
        showSocialButtons={showSocialButtons}
      >
        {children}
      </Styled.TooltipTrigger>
    </Styled.Tooltip>
  );
};

export default Tooltip;
