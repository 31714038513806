import * as Meta from '../../../config/meta.yml';

interface IRoutesMeta {
  name: string;
  meta: IRouteMeta[];
}

const MetaRoutes: IRoutesMeta[] = (Meta as any).routes;

export const getCurrentRouteMeta = (currentRouteName: string = ''): IRouteMeta[] | null => {
  if (!currentRouteName) {
    return null;
  }
  return MetaRoutes.find(meta => meta.name === currentRouteName)?.meta || null;
};
