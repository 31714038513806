import { Fragment, FunctionComponent, useState } from 'react';
import { useQuery } from '@apollo/client';
import SeriesModel from 'models/series.model';
import MovieModel from 'models/movie.program.model';
import { Grid, GridElement } from '../../styles/grid';
import { BodyText, Heading2 } from '../Text/Text.style';
import { SkeletonPortraitItemCarousel } from '../SkeletonElement/SkeletonElement';
import ErrorMessage from '../ErrorMessage';
import ErrorBoundary from '../ErrorBoundary';
import Carousel from '../Lists/Carousel';
import PortraitItem from '../PortraitItem';
import { PROGRAM, FIXED_COUNT } from '../../constants';
import PortraitItemFallback from '../PortraitItem/PortraitItemFallback';

const FeaturedPrograms: FunctionComponent<IFeaturedProgramsProps> = props => {
  const [featuredPrograms, setFeaturedPrograms] = useState<(SeriesModel | MovieModel)[]>([]);

  const { error, loading } = useQuery(props.query, {
    variables: { limit: FIXED_COUNT.TRENDING_LIMIT },
    onCompleted: data => {
      const programs = data.trendingPrograms
        .map((item: IProgramGraphql, index: number) => {
          const options = { collectionTitle: props.title, position: index + 1, isOnSearch: props.isOnSearch };
          if (item.type === PROGRAM.MOVIE) return new MovieModel(item, options);
          if (item.type === PROGRAM.SERIES) {
            return new SeriesModel(item, options);
          }
          return null;
        })
        .filter((item: MovieModel | SeriesModel) => !!item);
      setFeaturedPrograms(programs);
    },
    onError: () => {
      setFeaturedPrograms([]);
    }
  });

  if (loading) return <SkeletonPortraitItemCarousel noOfItems={FIXED_COUNT.SKELETON_PROGRAM_NUMBER} />;

  if (error) {
    return (
      <Grid>
        <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
          <ErrorMessage />
        </GridElement>
      </Grid>
    );
  }

  if (!featuredPrograms.length) {
    return (
      <Grid>
        <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
          <BodyText data-testid="featuredProgramsError">
            Helaas, op dit moment zijn er geen programmas beschikbaar op KIJK.
          </BodyText>
        </GridElement>
      </Grid>
    );
  }
  return (
    <>
      <Grid>
        <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
          <Heading2>{props.title}</Heading2>
        </GridElement>
      </Grid>
      <Carousel>
        {featuredPrograms.map((program: any, index: number) => (
          <Fragment key={program.guid || index}>
            <ErrorBoundary onError={(_: any) => <PortraitItemFallback />}>
              <PortraitItem program={program} />
            </ErrorBoundary>
          </Fragment>
        ))}
      </Carousel>
    </>
  );
};

export default FeaturedPrograms;
