import { FunctionComponent } from 'react';
import { Heading1 } from 'components/Text/Text.style';
import { PortraitItemFallbackStyle } from './PortraitItemFallback.style';

const PortraitItemFallback: FunctionComponent = () => (
  <PortraitItemFallbackStyle data-testid="portraitItemFallback">
    <Heading1 data-testid="boundaryErrorPortraitItem">
      Oeps, er ging iets fout. Kan je het later nog eens proberen?
    </Heading1>
  </PortraitItemFallbackStyle>
);

export default PortraitItemFallback;
