export const ICONS = {
  WATCHLIST_ACTIVE: '/static/images/icons/watchlist-active-32.svg',
  WATCHLIST_INACTIVE: '/static/images/icons/watchlist-inactive-32.svg',
  PLAY_FILL: '/static/images/icons/play-fill-32.svg',
  ARROW: '/static/images/icons/arrow.svg',
  ARROW_SMALL: '/static/images/icons/arrow-small.svg',
  ARROW_SMALL_GRAY: '/static/images/icons/arrow-small-gray-32.svg',
  CHECK_CIRCULAR: '/static/images/icons/check-green.svg',
  CHECK_DROPDOWN: '/static/images/icons/check-dropdown-32.svg',
  CHROME: '/static/images/icons/chrome.svg',
  MOZILLA: '/static/images/icons/mozilla.svg',
  EDGE: '/static/images/icons/edge.svg',
  SAFARI: '/static/images/icons/safari.svg',
  CONTINUE_WATCHING: '/static/images/icons/continue-watching-32.svg',
  ACCOUNT: '/static/images/icons/account-32.svg',
  ACCOUNT_CIRCULAR: '/static/images/icons/account-circular-32.svg',
  HELP: '/static/images/icons/help-32.svg',
  LOGOUT: '/static/images/icons/logout-32.svg',
  FACEBOOK: '/static/images/icons/social-facebook-32.svg',
  TWITTER: '/static/images/icons/social-twitter-32.svg',
  TRIANGLE_SEPARATOR: '/static/images/icons/play-long.svg',
  THREE_DOTS: '/static/images/icons/three-dots.svg',
  KIJK_TEAR: '/static/images/icons/kijk-tear.svg',
  ARROW_SMALL_30: '/static/images/icons/arrow-small-30.svg',
  PLAY_BUTTON: '/static/images/icons/play-button.svg',
  RATING_AL: '/static/images/icons/rating-al.svg',
  RATING_18: '/static/images/icons/rating-18.svg',
  RATING_16: '/static/images/icons/rating-16.svg',
  RATING_14: '/static/images/icons/rating-14.svg',
  RATING_12: '/static/images/icons/rating-12.svg',
  RATING_9: '/static/images/icons/rating-9.svg',
  RATING_6: '/static/images/icons/rating-6.svg',
  SUBRATING_A: '/static/images/icons/subrating-a.svg',
  SUBRATING_D: '/static/images/icons/subrating-d.svg',
  SUBRATING_G: '/static/images/icons/subrating-g.svg',
  SUBRATING_H: '/static/images/icons/subrating-h.svg',
  SUBRATING_S: '/static/images/icons/subrating-s.svg',
  SUBRATING_T: '/static/images/icons/subrating-t.svg',
  HALF_CIRCLE: '/static/images/icons/half-circle.svg',
  CLOSE: '/static/images/icons/close-32.svg',
  CLOSE_FILL: '/static/images/icons/close-fill.svg',
  CLOSE_CIRCULAR: '/static/images/icons/close-circular.svg',
  SEARCH: '/static/images/icons/search-32.svg',
  MISSED: '/static/images/icons/icon-32-missed.svg',
  HOME: '/static/images/icons/icon-32-home.svg',
  PROGRAMMAS: '/static/images/icons/icon-32-programs.svg',
  FILMS: '/static/images/icons/icon-32-films.svg',
  PAUSE_FILL: '/static/images/icons/pause-fill-32.svg',
  REWIND: '/static/images/icons/rewind-10-32.svg',
  VOLUME_FULL: '/static/images/icons/volume-full-32.svg',
  VOLUME_HALF: '/static/images/icons/volume-1-32.svg',
  VOLUME_OFF: '/static/images/icons/volume-off-32.svg',
  SUBTITLES_ACTIVE: '/static/images/icons/subtitles-active-32.svg',
  SUBTITITLES_OFF: '/static/images/icons/subtitles-32.svg',
  AIRPLAY_ACTIVE: '/static/images/icons/airplay-active-32.svg',
  AIRPLAY_OFF: '/static/images/icons/airplay-32.svg',
  FULLSCREEN_ON: '/static/images/icons/fullscreen-32.svg',
  FULLSCREEN_OFF: '/static/images/icons/fullscreen-exit-32.svg',
  SHARE: '/static/images/icons/share-32.svg',
  PREVIOUS_FILL: '/static/images/icons/previous-fill-48.svg',
  NEXT_FILL: '/static/images/icons/next-fill-48.svg',
  PLAY_FILL_LARGE: '/static/images/icons/play-fill-48.svg',
  PAUSE_FILL_LARGE: '/static/images/icons/pause-fill-48.svg',
  REPLAY: '/static/images/icons/replay-48.svg',
  SHAPE_EYE: '/static/images/icons/shape_eye.svg',
  SHAPE_ARROW: '/static/images/icons/shape_arrow.svg',
  PLAY: '/static/images/icons/play-32.svg',
  PAUSE: '/static/images/icons/pause-32.svg',
  NOTIFICATION: '/static/images/icons/notification-32.svg',
  SEPARATOR: '/static/images/icons/separator.svg',
  CLOSE_CURRENT_COLOR: '/static/images/icons/close.svg',
  ALERT: '/static/images/icons/alert.svg',
  PLAY_BUTTON_CIRCLE: '/static/images/icons/play-button-circle.svg'
};
