export default async function probeDrmSupport(): Promise<{ [key: string]: boolean }> {
  const testKeySystems = [
    'com.widevine.alpha',
    'com.microsoft.playready',
    'com.microsoft.playready.recommendation',
    'com.apple.fps'
  ];

  const basicVideoCapabilities = [
    { contentType: 'video/mp4; codecs="avc1.42E01E"' },
    { contentType: 'video/webm; codecs="vp8"' }
  ];

  const basicConfig = {
    initDataTypes: ['cenc'],
    videoCapabilities: basicVideoCapabilities
  };

  const support: { [key: string]: boolean } = {};

  const testSystem = async (keySystem: string): Promise<void> => {
    try {
      await navigator.requestMediaKeySystemAccess(keySystem, [basicConfig]);
      support[keySystem] = true;
    } catch (e) {
      support[keySystem] = false;
    }
  };

  // Test each key system.
  const tests = testKeySystems.map(keySystem => testSystem(keySystem));
  await Promise.all(tests);

  return { ...support, drmSupported: Object.values(support).some(supported => supported) };
}
