import { gql } from '@apollo/client';

export const BaseProperties = gql`
  fragment BaseProperties on Brand {
    slug
    title
    id
    headerMedia {
      url
    }
    logoMedia {
      url
    }
  }
`;
