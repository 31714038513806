import { ICONS } from './icons';

export enum ACCOUNT {
  AUTH_TOKEN = 'auth-token'
}

export enum ACCOUNT_MENU_LABELS {
  FAVORITES = 'Favorieten',
  CONTINUE_WATCHING = 'Kijk verder',
  MY_ACCOUNT = 'Mijn account',
  HELP = 'Help',
  LOG_OUT = 'Uitloggen'
}

export const ACCOUNT_OPTIONS: ISeriesSeasonAvailability[] = [
  {
    label: ACCOUNT_MENU_LABELS.FAVORITES,
    icon: ICONS.WATCHLIST_ACTIVE,
    value: ACCOUNT_MENU_LABELS.FAVORITES
  },
  {
    label: ACCOUNT_MENU_LABELS.CONTINUE_WATCHING,
    icon: ICONS.CONTINUE_WATCHING,
    value: ACCOUNT_MENU_LABELS.CONTINUE_WATCHING
  },
  {
    label: ACCOUNT_MENU_LABELS.MY_ACCOUNT,
    icon: ICONS.ACCOUNT_CIRCULAR,
    value: ACCOUNT_MENU_LABELS.MY_ACCOUNT
  },
  {
    label: ACCOUNT_MENU_LABELS.HELP,
    icon: ICONS.HELP,
    value: ACCOUNT_MENU_LABELS.HELP
  },
  {
    label: ACCOUNT_MENU_LABELS.LOG_OUT,
    icon: ICONS.LOGOUT,
    value: ACCOUNT_MENU_LABELS.LOG_OUT
  }
];
