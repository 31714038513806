export enum TEALIUM_EVENT_CATEGORY {
  MENU = 'menu',
  HEADER = 'header',
  CONTENT_LISTING = 'content_listing',
  CONNECT = 'connect',
  PLAYER_META = 'player_meta',
  PLAYER = 'player',
  SEARCH = 'search',
  FOOTER = 'footer',
  POPUP = 'popup'
}

export enum TEALIUM_EVENT_NAME {
  HEADER_CLICK = 'header_click',
  MENU_CLICK = 'menu_click',
  CONTENT_LISTING_CLICK = 'content_listing_click',
  CONTENT_LISTING_MORE = 'content_listing_more',
  ADD_TO_FAVORITE = 'add_to_favorite',
  REMOVE_FROM_FAVORTIE = 'remove_from_favorite',
  CONNECT_SUBMIT = 'connect_submit',
  SHARE_INTERACTION = 'share_interaction',
  SEARCH_CLICK = 'search_click',
  SEARCH_QUERY = 'search_query',
  SEARCH_LOAD_MORE = 'search_load_more',
  FOOTER_CLICK = 'footer_click',
  PLAYER_STOP = 'player_stop',
  PLAYER_NEXT = 'player_next',
  PLAYER_PREVIOUS = 'player_previous',
  OUTBOUND_CLICK = 'outbound_click',
  SIDEBAR_CLICK = 'sidebar_click',
  POPUP_CLICK = 'popup_click',
  POPUP_VIEW = 'popup_view',
  PLAYER_CLICK = 'player_click',
  PLAYER_VIEW = 'player_view',
  PLAYER_AUTOPLAY = 'player_autoplay'
}

export enum TEALIUM_EVENT_LABEL {
  SEARCH = 'search',
  ACCOUNT = 'account',
  LOGO = 'logo',
  RECOMMENDED = 'aanbevolen',
  HEADER_PROMO = 'header_promo',
  BRANDS = 'Merken',
  CONTINUE_WATCHING = 'Kijk verder',
  WATCHLIST = 'Mijn Favorieten',
  SUCCESS = 'success',
  NOT_FOUND = 'not_found',
  USER_CODE_EXPIRED = 'user_code_expired',
  FAVORIETEN = 'Favorieten',
  PROMO = 'promo',
  GEO_BANNER = 'geo-banner',
  LOAD_MORE = 'load_more',
  SEARCH_TOOLTIP = 'search_tooltip',
  FULL_EPISODE = 'full_episode',
  SUGGESTED_CONTENT = 'suggested_content'
}

export enum TEALIUM_PAGE_CATEGORY {
  HOME = 'home',
  INDEX = 'index',
  DETAIL = 'detail'
}
