import featureTooling from 'components/FeatureTooling/FeatureTooling';
import { FEATURE_SLUG } from '../../client/constants';
import { getAdheseConfig } from './getAdheseConfig';

export const getConsentData = async (): Promise<{ tcString: string; talpaConsent: string }> =>
  new Promise(resolve => {
    if (typeof window.__tcfapi === 'undefined') {
      resolve({ tcString: '', talpaConsent: '0' });
      return;
    }

    window.__tcfapi('getTCData', 2, (data, success) => {
      if (success) {
        return resolve({
          tcString: data.tcString,
          talpaConsent: [2, 3, 4, 7, 9].every(purpose => data.purpose.consents[purpose] === true) ? '1' : '0'
        });
      }
      return resolve({ tcString: '', talpaConsent: '0' });
    });
  });

export const getCustomFreewheelParameters = async ({
  playerOptions,
  metadata,
  guid,
  viewMode
}: {
  playerOptions: IPlayerOptions;
  metadata: { [key: string]: string | boolean | number | null };
  guid: string;
  viewMode: string;
}) => {
  if (!playerOptions?.config.advertising.freewheel) {
    return null;
  }

  await featureTooling.waitUntilReady();

  let adheseConfig;
  if (featureTooling.isFeatureEnabled(FEATURE_SLUG.ADHESE_ADVERTISING)) {
    adheseConfig = await getAdheseConfig(guid, viewMode);
  } else {
    adheseConfig = { adh: 'disabled' };
  }
  const { tcString, talpaConsent } = await getConsentData();
  const custom: { [key: string]: string } = {
    ...adheseConfig,
    embedded_domain: window.location.hostname,
    media_videotype: `${metadata.media_videotype}`,
    vdur: `${metadata.media_duration}`,
    cd_type: `${metadata.c_media_duration_type}`,
    _fw_h_x_country: featureTooling.user?.attributes.countryCode || '',
    _fw_site_page: window.location.href,
    _fw_h_user_agent: navigator.userAgent,
    _fw_gdpr: '1',
    _fw_gdpr_consent: tcString,
    talpa_consent: talpaConsent
  };

  if (window.utag?.data?.tealium_visitor_id) {
    custom._fw_vcid2 = window.utag.data.tealium_visitor_id;
  }

  return { custom };
};
