import styled from 'styled-components';

export const MainStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  &.fixed {
    position: fixed;
  }
`;

export const MainContentStyle = styled.div<{ isHeaderTransparent?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: ${props => props.theme?.breakpoints.smMax}) {
    margin-top: 56px;
  }

  @media (min-width: ${props => props.theme?.breakpoints.mdMin}) {
    margin-top: ${props => (props?.isHeaderTransparent ? '0' : '70px')};
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    margin-top: ${props => (props?.isHeaderTransparent ? '0' : '80px')};
  }
`;

export const MainBottomNavigationWrapperStyle = styled.div`
  display: block;
  z-index: ${props => props.theme.zIndex.component.bottomNavigation};

  @media (orientation: landscape) {
    display: none;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    display: none;
  }
`;
