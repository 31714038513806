const observerNotSupported = typeof IntersectionObserver === 'undefined';
const listenerCallbacks = new WeakMap();

let observer: IntersectionObserver;

function handleIntersections(entries: IntersectionObserverEntry[]): void {
  entries.forEach(entry => {
    if (listenerCallbacks.has(entry.target)) {
      const cb = listenerCallbacks.get(entry.target);
      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        observer.unobserve(entry.target);
        listenerCallbacks.delete(entry.target);
        cb();
      }
    }
  });
}

function getIntersectionObserver(): IntersectionObserver {
  if (observer === undefined) {
    observer = new IntersectionObserver(handleIntersections, {
      rootMargin: '100px',
      threshold: 0.15
    });
  }
  return observer;
}

export function observe(target: HTMLDivElement, callback: () => void): void {
  if (observerNotSupported) {
    callback();
    return;
  }
  observer = getIntersectionObserver();

  listenerCallbacks.set(target, callback);
  observer.observe(target);
}

export function unobserve(target: HTMLDivElement): void {
  if (observerNotSupported) return;
  listenerCallbacks.delete(target);
  observer.unobserve(target);
}
