import styled from 'styled-components';
import { linearGradient } from 'lib/helpers/helpers';
import { HeroImageWrapperStyle } from 'components/EditorialPrograms/EditoralPrograms.style';
import { ImageMediaInnerStyle, ImageMediaWrapperStyle } from '../ImageMedia/ImageMedia.style';
import { CarouselItemsStyle, NextArrowStyle, PrevArrowStyle } from '../Lists/Carousel/Carousel.style';
import { ActionLabel, Heading1 } from '../Text/Text.style';

export const BrandedEditorialVideosStyle = styled.div<{ backgroundColor?: string }>`
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: row;
  margin-top: 74px;

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    margin-top: 90px;
  }

  ${CarouselItemsStyle} {
    padding-left: 16px;

    @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
      padding-left: 24px;
    }

    & > *:first-child {
      margin-left: 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
      padding-left: 30px;
    }

    @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
      padding-left: 14px;
    }
  }

  ${PrevArrowStyle} {
    left: -60px;
    background: transparent;
  }

  ${NextArrowStyle} {
    left: auto;
    right: 0;
    background: ${props =>
        linearGradient('to left', [
          { color: props.backgroundColor ? props.backgroundColor : 'transparent', position: '0%' },
          { color: 'transparent', position: '100%' }
        ])}
      100% 100%;
  }

  ${ActionLabel} {
    opacity: 0.5;
  }
`;

export const HeroImageVideosWrapperStyle = styled(HeroImageWrapperStyle)`
  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    display: block;
    width: 238px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: 319px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
    width: 375px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlMin}) {
    width: 268px;
  }
`;

export const BrandedEditorialVideosContentWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 0;
  z-index: 99;

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    padding: 1px 0;
    width: calc(100% - 229px - 24px - 16px);
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: calc(100% - 302px - 80px - 24px);
    padding: 30px 0;
  }
`;

export const BrandedEditorialVideosTitleLogoWrapperStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 10px 0;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
`;

export const TitleWrapperStyle = styled.div`
  width: 75%;
  padding-left: 16px;
  overflow-wrap: break-word;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    padding-left: 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    padding-left: 30px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: 85%;
  }

  ${Heading1} {
    margin: 10px 0;
  }
`;

export const LogoWrapperStyle = styled.div`
  width: 25%;

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    width: 15%;
  }

  ${ImageMediaWrapperStyle} {
    height: 100%;
    background-color: transparent;

    ${ImageMediaInnerStyle} {
      width: auto;
      left: auto;
      right: 16px;

      img {
        width: auto;
        height: auto;
        max-height: 50px;
        vertical-align: middle;

        @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
          max-height: 60px;
        }
      }

      @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
        right: 24px;
      }
    }
  }
`;
