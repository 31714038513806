import Colors from '../../styles/colors';

const gradients: IGradients = {
  main: {
    small: {
      direction: '167deg',
      stops: [
        {
          color: Colors.violet,
          position: '0%'
        },
        {
          color: Colors.royal,
          position: '30%'
        },
        {
          color: Colors.black,
          position: '93%'
        }
      ]
    },
    medium: {
      direction: '159deg',
      stops: [
        {
          color: Colors.violet,
          position: '0%'
        },
        {
          color: Colors.royal,
          position: '28%'
        },
        {
          color: Colors.black,
          position: '87%'
        }
      ]
    },
    large: {
      direction: '145deg',
      stops: [
        {
          color: Colors.violet,
          position: '0%'
        },
        {
          color: Colors.royal,
          position: '24%'
        },
        {
          color: Colors.black,
          position: '74%'
        }
      ]
    }
  },
  header: {
    solid: {
      direction: '93deg',
      stops: [
        {
          color: Colors.violet,
          position: '0%'
        },
        {
          color: Colors.violet,
          position: '16%'
        },
        {
          color: Colors.royal,
          position: '100%'
        }
      ]
    },
    transparent: {
      direction: 'to top',
      stops: [
        {
          color: Colors.transparentBlack,
          position: '0%'
        },
        {
          color: Colors.black80,
          position: '100%'
        }
      ]
    }
  },
  headerNewDesign: {
    direction: '98deg',
    stops: [
      {
        color: Colors.violet,
        position: '0%'
      },
      {
        color: Colors.violet,
        position: '16%'
      },
      {
        color: Colors.royal,
        position: '100%'
      }
    ]
  },
  footer: {
    small: {
      direction: '231deg',
      stops: [
        {
          color: Colors.mediumBlue,
          position: '0'
        },
        {
          color: Colors.black,
          position: '16%'
        },
        {
          color: Colors.black,
          position: '43%'
        },
        {
          color: Colors.royal,
          position: '76%'
        },
        {
          color: Colors.violet,
          position: '100%'
        }
      ]
    },
    medium: {
      direction: '281deg',
      stops: [
        {
          color: Colors.mediumBlue,
          position: '0'
        },
        {
          color: Colors.black,
          position: '16%'
        },
        {
          color: Colors.black,
          position: '43%'
        },
        {
          color: Colors.royal,
          position: '76%'
        },
        {
          color: Colors.violet,
          position: '100%'
        }
      ]
    },
    large: {
      direction: '273deg',
      stops: [
        {
          color: Colors.mediumBlue,
          position: '0'
        },
        {
          color: Colors.black,
          position: '16%'
        },
        {
          color: Colors.black,
          position: '43%'
        },
        {
          color: Colors.royal,
          position: '76%'
        },
        {
          color: Colors.violet,
          position: '100%'
        }
      ]
    }
  },
  home: {
    direction: '244deg',
    stops: [
      {
        color: Colors.nero,
        position: '0%'
      },
      {
        color: Colors.black,
        position: '100%'
      }
    ]
  },
  carouselListScrollLeftDark: {
    direction: 'to right',
    stops: [
      {
        color: Colors.black,
        position: '0%'
      },
      {
        color: Colors.transparentBlack,
        position: '100%'
      }
    ]
  },
  carouselListScrollRightDark: {
    direction: 'to left',
    stops: [
      {
        color: Colors.black,
        position: '0%'
      },
      {
        color: Colors.transparentBlack,
        position: '100%'
      }
    ]
  },
  carouselListScrollLeftLight: {
    direction: 'to right',
    stops: [
      {
        color: Colors.nero,
        position: '0%'
      },
      {
        color: Colors.transparentNero,
        position: '100%'
      }
    ]
  },
  carouselListScrollRightLight: {
    direction: 'to left',
    stops: [
      {
        color: Colors.nero,
        position: '0%'
      },
      {
        color: Colors.transparentNero,
        position: '100%'
      }
    ]
  },
  carouselPickerListLeftArrow: {
    direction: 'to left',
    stops: [
      {
        color: Colors.transparentBlack,
        position: '0%'
      },
      {
        color: Colors.black,
        position: '50%'
      }
    ]
  },
  carouselPickerListRightArrow: {
    direction: 'to left',
    stops: [
      {
        color: Colors.black,
        position: '0%'
      },
      {
        color: Colors.black,
        position: '50%'
      },
      {
        color: Colors.transparentBlack,
        position: '100%'
      }
    ]
  },
  tabNavScrollLeft: {
    direction: 'to right',
    stops: [
      {
        color: Colors.nero,
        position: '0%'
      },
      {
        color: Colors.transparentNero,
        position: '100%'
      }
    ]
  },
  tabNavScrollRight: {
    direction: 'to left',
    stops: [
      {
        color: Colors.nero,
        position: '0%'
      },
      {
        color: Colors.transparentNero,
        position: '100%'
      }
    ]
  },
  nextUp: {
    background: {
      direction: '301deg',
      stops: [
        {
          color: Colors.black,
          position: '0'
        },
        {
          color: Colors.black,
          position: '30%'
        },
        {
          color: Colors.royal,
          position: '68%'
        },
        {
          color: Colors.violet,
          position: '100%'
        }
      ]
    },
    separator: {
      direction: 'to right',
      stops: [
        {
          color: Colors.transparentBlack,
          position: '0%'
        },
        {
          color: Colors.black,
          position: '100%'
        }
      ]
    }
  },
  dropdown: {
    title: {
      direction: 'to right',
      stops: [
        {
          color: Colors.nero,
          position: '0%'
        },
        {
          color: Colors.greyishBrown,
          position: '100%'
        }
      ]
    },
    option: {
      direction: 'to left',
      stops: [
        {
          color: Colors.brownGrey,
          position: '0%'
        },
        {
          color: Colors.greyishBrown,
          position: '100%'
        }
      ]
    }
  },
  account: {
    background: {
      direction: 'circle at bottom right',
      stops: [
        {
          color: Colors.mediumBlue,
          position: '0%'
        },
        {
          color: Colors.black,
          position: '33%'
        }
      ]
    }
  },
  videoHeading: {
    background: {
      direction: 'to top',
      stops: [
        {
          color: Colors.black,
          position: '0'
        },
        {
          color: Colors.nero,
          position: '100%'
        }
      ]
    }
  },
  videoMetaData: {
    background: {
      direction: 'circle at top right',
      stops: [
        {
          color: Colors.mediumBlue,
          position: '0%'
        },
        {
          color: Colors.transparent,
          position: '60%'
        }
      ]
    },
    collapseSeparator: {
      format: {
        direction: 'to right',
        stops: [
          {
            color: Colors.white0,
            position: '0'
          },
          {
            color: Colors.white,
            position: '50%'
          },
          {
            color: Colors.white0,
            position: '100%'
          }
        ]
      }
    }
  },
  upSellScreen: {
    direction: '299deg',
    stops: [
      {
        color: Colors.mediumBlue,
        position: '0%'
      },
      {
        color: Colors.black,
        position: '46%'
      },
      {
        color: Colors.royal,
        position: '76%'
      },
      {
        color: Colors.violet,
        position: '100%'
      }
    ]
  },
  shortcutsPopup: {
    direction: 'to right',
    stops: [
      {
        color: Colors.nero,
        position: '0%'
      },
      {
        color: Colors.greyishBrown,
        position: '100%'
      }
    ]
  },
  EPGItem: {
    direction: '138deg',
    stops: [
      {
        color: Colors.transparentBlack,
        position: '0%'
      },
      {
        color: Colors.black20,
        position: '50%'
      },
      {
        color: Colors.black,
        position: '100%'
      }
    ]
  },
  videoItem: {
    direction: 'to bottom',
    stops: [
      {
        color: Colors.transparentBlack,
        position: '0%'
      },
      {
        color: Colors.black60,
        position: '50%'
      },
      {
        color: Colors.black,
        position: '100%'
      }
    ]
  },
  about: {
    headingHorizontal: {
      small: {
        direction: '322deg',
        stops: [
          {
            color: Colors.black,
            position: '0%'
          },
          {
            color: Colors.black,
            position: '2%'
          },
          {
            color: Colors.royal,
            position: '68%'
          },
          {
            color: Colors.violet,
            position: '100%'
          }
        ]
      },
      medium: {
        direction: '304deg',
        stops: [
          {
            color: Colors.black,
            position: '0%'
          },
          {
            color: Colors.black,
            position: '2%'
          },
          {
            color: Colors.royal,
            position: '68%'
          },
          {
            color: Colors.violet,
            position: '100%'
          }
        ]
      },
      large: {
        direction: '272deg',
        stops: [
          {
            color: 'rgba(0, 0, 0, 0)',
            position: '0%'
          },
          {
            color: 'rgba(0, 0, 0, 0)',
            position: '40%'
          },
          {
            color: 'rgba(0, 0, 0, 0.5)',
            position: '46%'
          },
          {
            color: Colors.black,
            position: '60%'
          },
          {
            color: 'rgba(40, 15, 128, 0.9)',
            position: '80%'
          },
          {
            color: Colors.violet,
            position: '100%'
          }
        ]
      }
    },
    headingVertical: {
      direction: 'to bottom',
      stops: [
        {
          color: Colors.transparentBlack,
          position: '0%'
        },
        {
          color: Colors.black,
          position: '100%'
        }
      ]
    },
    more: {
      background: {
        medium: {
          direction: '222deg',
          stops: [
            {
              color: Colors.transparentBlack,
              position: '0%'
            },
            {
              color: Colors.black,
              position: '100%'
            }
          ]
        },
        large: {
          direction: '252deg',
          stops: [
            {
              color: Colors.transparentBlack,
              position: '0%'
            },
            {
              color: Colors.black,
              position: '100%'
            }
          ]
        }
      },
      corner: {
        direction: 'circle at top right',
        stops: [
          {
            color: Colors.mediumBlue,
            position: '0%'
          },
          {
            color: Colors.transparentMediumBlue,
            position: '60%'
          }
        ]
      }
    },
    account: {
      small: {
        direction: '327deg',
        stops: [
          {
            color: Colors.mediumBlue,
            position: '0%'
          },
          {
            color: Colors.black,
            position: '16%'
          },
          {
            color: Colors.black,
            position: '43%'
          },
          {
            color: Colors.royal,
            position: '76%'
          },
          {
            color: Colors.violet,
            position: '100%'
          }
        ]
      },
      medium: {
        direction: '304deg',
        stops: [
          {
            color: Colors.mediumBlue,
            position: '0%'
          },
          {
            color: Colors.black,
            position: '16%'
          },
          {
            color: Colors.black,
            position: '43%'
          },
          {
            color: Colors.royal,
            position: '76%'
          },
          {
            color: Colors.violet,
            position: '100%'
          }
        ]
      },
      large: {
        direction: '327deg',
        stops: [
          {
            color: Colors.mediumBlue,
            position: '0%'
          },
          {
            color: Colors.black,
            position: '16%'
          },
          {
            color: Colors.black,
            position: '43%'
          },
          {
            color: Colors.royal,
            position: '76%'
          },
          {
            color: Colors.violet,
            position: '100%'
          }
        ]
      }
    },
    faq: {
      direction: 'to left',
      stops: [
        {
          color: Colors.black,
          position: '0%'
        },
        {
          color: Colors.transparentBlack,
          position: '100%'
        }
      ]
    }
  },
  player: {
    controls: {
      direction: 'to top',
      stops: [
        {
          color: Colors.black,
          position: '0%'
        },
        {
          color: Colors.transparentBlack,
          position: '100%'
        }
      ]
    },
    volume: {
      direction: 'to bottom',
      stops: [
        {
          color: Colors.nero,
          position: '0%'
        },
        {
          color: Colors.greyishBrown,
          position: '100%'
        }
      ]
    },
    solid: {
      direction: 'to right',
      stops: [
        { color: Colors.violet, position: '0%' },
        { color: Colors.royal, position: '32%' },
        { color: Colors.black, position: '65%' },
        { color: Colors.black, position: '68%' },
        { color: Colors.mediumBlue, position: '100%' }
      ]
    },
    settingMenu: {
      item: {
        hover: {
          direction: 'to left',
          stops: [
            {
              color: Colors.brownGrey,
              position: '0%'
            },
            {
              color: Colors.greyishBrown,
              position: '100%'
            }
          ]
        }
      }
    }
  },
  promoHeading: {
    highlight: {
      direction: 'circle at 6% 100%',
      stops: [
        {
          color: Colors.nero,
          position: '0%'
        },
        {
          color: Colors.transparentNero,
          position: '50%'
        }
      ]
    },
    background: {
      top: {
        direction: 'to bottom',
        stops: [
          {
            color: Colors.black30,
            position: '0%'
          },
          {
            color: Colors.transparentBlack,
            position: '100%'
          }
        ]
      },
      bottom: {
        direction: 'to bottom',
        stops: [
          {
            color: Colors.transparent,
            position: '0%'
          },
          {
            color: Colors.transparentNero,
            position: '65%'
          },
          {
            color: Colors.nero,
            position: '100%'
          }
        ]
      },
      skeleton: {
        sm: {
          direction: 'to bottom',
          stops: [
            {
              color: Colors.transparentNero,
              position: '0%'
            },
            {
              color: Colors.nero,
              position: '40%'
            }
          ]
        },
        md: {
          direction: 'to bottom',
          stops: [
            {
              color: Colors.transparentNero,
              position: '74%'
            },
            {
              color: Colors.nero,
              position: '100%'
            }
          ]
        }
      },
      header: {
        direction: '180deg',
        stops: [
          {
            color: Colors.black40,
            position: '0%'
          },
          {
            color: Colors.transparentBlack,
            position: '100%'
          }
        ]
      },
      inner: {
        direction: '54deg',
        stops: [
          {
            color: Colors.nero80,
            position: '15%'
          },
          {
            color: Colors.nero60,
            position: '24%'
          },
          {
            color: Colors.nero40,
            position: '32%'
          },
          {
            color: Colors.transparentBlack,
            position: '53%'
          }
        ]
      }
    }
  },
  unsupportedBrowser: {
    borderRight: {
      direction: 'to right',
      stops: [
        {
          color: 'rgba(0, 0, 0, 0)',
          position: '0%'
        },
        {
          color: 'rgba(255, 255, 255, 0.3)',
          position: '50%'
        },
        {
          color: 'rgba(0, 0, 0, 0)',
          position: '100%'
        }
      ]
    },
    borderBottom: {
      direction: 'to bottom',
      stops: [
        {
          color: 'rgba(0, 0, 0, 0)',
          position: '0%'
        },
        {
          color: 'rgba(255, 255, 255, 0.3)',
          position: '50%'
        },
        {
          color: 'rgba(0, 0, 0, 0)',
          position: '100%'
        }
      ]
    }
  },
  promoSection: {
    background: {
      direction: 'to bottom',
      stops: [
        {
          color: Colors.transparentBlack,
          position: '50%'
        },
        {
          color: Colors.black,
          position: '100%'
        }
      ]
    }
  },
  loginPopup: {
    background: {
      direction: '319deg',
      stops: [
        {
          color: Colors.mediumBlue,
          position: '0%'
        },
        {
          color: Colors.black,
          position: '16%'
        },
        {
          color: Colors.black,
          position: '43%'
        },
        {
          color: Colors.royal,
          position: '76%'
        },
        {
          color: Colors.violet,
          position: '100%'
        }
      ]
    }
  },
  brands: {
    background: {
      normal: {
        direction: '127deg',
        stops: [
          {
            color: Colors.nero,
            position: '0%'
          },
          {
            color: Colors.brandsGrey,
            position: '100%'
          }
        ]
      }
    }
  },
  headingPromoList: {
    promoItem: {
      direction: 'to bottom',
      stops: [
        {
          color: Colors.transparentBlack,
          position: '47%'
        },
        {
          color: Colors.black50,
          position: '75%'
        },
        {
          color: Colors.black80,
          position: '100%'
        }
      ]
    }
  },
  alphaLayerTransparent: {
    leftBorder: {
      direction: 'to left',
      stops: [
        { color: Colors.white, position: '70%' },
        { color: Colors.transparent, position: '100%' }
      ]
    },
    bottomBorder: {
      direction: 'to bottom',
      stops: [
        { color: Colors.white, position: '42%' },
        { color: Colors.transparent, position: '100%' }
      ]
    },
    bottomBorderInverse: {
      direction: 'to bottom',
      stops: [
        { color: Colors.transparent, position: '58%' },
        { color: Colors.white, position: '100%' }
      ]
    }
  },
  search: {
    header: {
      direction: '87deg',
      stops: [
        { color: Colors.violet, position: '0%' },
        { color: Colors.violet, position: '16%' },
        { color: Colors.royal, position: '100%' }
      ]
    }
  }
};

export default gradients;
